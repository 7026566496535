import { JobStatus, Patient, Service } from '@doc-abode/data-models';
import { FormikErrors, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { dateFormat } from '../../../../../constants/patientsConst';
import { getMomentDateFormatter } from '../../../../../helpers/ucr';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Button, ButtonSizes } from '../../../../v2/components';
import {
    Checkbox,
    DateInput,
    DurationInput,
    Select,
    Text,
    TextArea,
    TimeInput,
} from '../../../../v2/form';
import { WarningBanner } from '../../../../v2/form/WarningBanner';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';
import { HcpSelect } from '../../components';
import { FormMode, FormSteps, friendlyCareComplexity } from '../common';
import { useCareDetailsViewModel } from './useCareDetailsViewModel';

export type CareDetailsType = {
    values: FormikValues;
    loading: boolean;
    formMode: FormMode;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    setValues: (
        values: React.SetStateAction<FormikValues>,
        shouldValidate?: boolean,
    ) => Promise<void | FormikErrors<FormikValues>>;
    onNextStep?: (nextStep: FormSteps, prevStep: FormSteps) => void;
    onSaveForm?: (curStep: FormSteps) => void;
};
export const CareDetails: FC<CareDetailsType> = ({
    values,
    loading,
    formMode,
    setFieldValue,
    setValues,
    onNextStep = () => {},
    onSaveForm = () => {},
}) => {
    const {
        MAXDATE,
        MAXTIME,
        MIN_EARLIEST_DAY,
        disabledPathwayValue,
        isContinueBtnClicked,
        MINTIME,
        highlightedWarnings,
        isHcp1Arrived,
        pathways,
        serviceList,
        isHcp1Aborted,
        isHcp2Aborted,
        defaultStartTime,
        disableStaff,
        isHcp1Completed,
        isHcp2Completed,
        setStaffRequirement,
        setShowUnavailableBuddyHcp,
        setShowUnavailableHcp,
        showUnavailableBuddyHcp,
        showUnavailableHcp,
        availableFromDisabled,
        buttonName,
        showHcp1PostNotes,
        showHcp2PostNotes,
        earliestDateOfVisitDisabled,
        hasWarningMessages,
        hcp2AbortNotesFieldName,
        hcp2AbortedNotes,
        onSubmit,
        setIsEarliestTimeChecked,
        warningMessages,
        hcp1AbortNotesFieldName,
        hcp1AbortedNotes,
        hcp1AbortedReason,
        isHcp2Arrived,
        hcp1AbortReasonFieldName,
        hcp2AbortReasonFieldName,
        hcp2AbortedReason,
    } = useCareDetailsViewModel({
        values,
        loading,
        formMode,
        setFieldValue,
        setValues,
        onNextStep,
        onSaveForm,
    });

    return (
        <>
            <div className="v2__form-block">
                <Select
                    name="referralPathway"
                    className="v2__form-group--pos-1-2"
                    label="Pathway"
                    required
                    disabled={loading || disabledPathwayValue}
                    isContinueBtnClicked={isContinueBtnClicked}
                    onChange={(e) => {
                        setFieldValue('referralPathway', e.target.value);
                        setFieldValue('disposition', '');
                    }}
                >
                    <option value="">Select...</option>
                    {pathways
                        .slice()
                        .sort((a: any, b: any) => a.label.localeCompare(b.label))
                        .map(
                            (pathway: any) =>
                                pathway.enabled && (
                                    <option key={pathway.value} value={pathway.value}>
                                        {pathway.label}
                                    </option>
                                ),
                        )}
                </Select>
                <Select
                    name="disposition"
                    className="v2__form-group--pos-2-2"
                    label="Service required"
                    required
                    disabled={loading}
                    isContinueBtnClicked={isContinueBtnClicked}
                >
                    <option value="">Select...</option>
                    {serviceList.map(
                        (service: Service) =>
                            service.enabled && (
                                <option key={service.value} value={service.value}>
                                    {service.label}
                                </option>
                            ),
                    )}
                </Select>
                <TextArea
                    name="notes"
                    className="v2__form-group--pos-1-1"
                    label="Pre-job notes"
                    disabled={loading}
                />
                <DateInput
                    name="visitDate"
                    className="v2__form-group--pos-1-4"
                    displayErrors={highlightedWarnings['visitDate']}
                    label="Planned date of visit"
                    {...getMomentDateFormatter(dateFormat)}
                    dateFormat={dateFormat}
                    required
                    disabled={loading || isHcp1Arrived}
                    isContinueBtnClicked={isContinueBtnClicked}
                    maxDate={MAXDATE}
                    minDate={MINTIME}
                />
                <TimeInput
                    name="startTime"
                    className="v2__form-group--pos-2-4"
                    displayErrors={highlightedWarnings['startTime']}
                    label="Planned start time"
                    defaultValue={values.startTime || defaultStartTime}
                    selectAllOnFocus
                    disabled={loading}
                    required={Boolean(values?.hcpId || values?.buddyId)}
                />
                <DurationInput
                    name="duration"
                    className="v2__form-group--pos-3-4"
                    label="Planned duration"
                    selectAllOnFocus
                    disabled={loading}
                    required
                />
                <TimeInput
                    name="endTime"
                    className="v2__form-group--pos-4-4"
                    label="Expected end time"
                    disabled
                />
            </div>
            <div className="v2__form-block">
                <Select
                    name="staffRequired"
                    className="v2__form-group--pos-1-2"
                    label="Staffing requirements"
                    required
                    disabled={
                        loading || isHcp1Arrived || isHcp1Aborted || isHcp2Aborted || disableStaff
                    }
                    isContinueBtnClicked={isContinueBtnClicked}
                    onChange={(changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
                        const staffRequired = Number(changeEvent.target.value);
                        setFieldValue('staffRequired', staffRequired);
                        if (staffRequired === 1) {
                            setFieldValue('buddyId', '');
                            setFieldValue('buddyJobStatus', '');
                        }
                        setStaffRequirement(values.staffRequired);
                    }}
                >
                    <option value="1">1</option>
                    <option value="2">2</option>
                </Select>

                <Checkbox
                    name="Show unavailable"
                    className="v2__form-group--pos-1-2"
                    label="Staff member #1"
                    title="Show unavailable"
                    disabled={loading}
                    checked={showUnavailableHcp}
                    onChange={() => setShowUnavailableHcp(!showUnavailableHcp)}
                    aria-label="show unavailable hcps"
                />
                <ConditionalDisplay show={values.staffRequired === 2}>
                    <Checkbox
                        name="Show unavailable"
                        className="v2__form-group--pos-2-2"
                        label="Staff member #2"
                        title="Show unavailable"
                        disabled={loading}
                        checked={showUnavailableBuddyHcp}
                        onChange={() => setShowUnavailableBuddyHcp(!showUnavailableBuddyHcp)}
                        aria-label="show unavailable buddys"
                    />
                </ConditionalDisplay>
                <HcpSelect
                    patient={values as VisitValuesType}
                    disabled={loading || isHcp1Completed || isHcp1Aborted}
                    includeUnavailableHcps={showUnavailableHcp}
                    ariaLabel="hcp select"
                />
                <ConditionalDisplay show={values.staffRequired === 2}>
                    <HcpSelect
                        patient={values as VisitValuesType}
                        disabled={loading || isHcp2Completed || isHcp2Aborted}
                        isFirstUser={false}
                        includeUnavailableHcps={showUnavailableBuddyHcp}
                        ariaLabel="buddy select"
                    />
                </ConditionalDisplay>
                <ConditionalDisplay show={Boolean(values.jobStatus)}>
                    <Text
                        name="jobStatus"
                        title="Status"
                        className="v2__form-group--pos-1-2"
                        description={Patient.getFriendlyVisitStatus(values.jobStatus as JobStatus)}
                    />
                </ConditionalDisplay>
                <ConditionalDisplay show={values.staffRequired === 2 && values.buddyJobStatus}>
                    <Text
                        name="buddyJobStatus"
                        title="Status"
                        className="v2__form-group--pos-2-2"
                        description={Patient.getFriendlyVisitStatus(
                            values.buddyJobStatus as JobStatus,
                        )}
                    />
                </ConditionalDisplay>
                <ConditionalDisplay show={formMode === FormMode.EDIT_VISIT && showHcp1PostNotes}>
                    <TextArea
                        name="postVisitNotes"
                        className={
                            values.staffRequired === 2
                                ? 'v2__form-group--pos-1-2'
                                : 'v2__form-group--pos-1-1'
                        }
                        label={values.staffRequired === 2 ? 'Post-job notes #1' : 'Post-job notes'}
                        disabled={loading}
                    />
                </ConditionalDisplay>
                <ConditionalDisplay
                    show={
                        formMode === FormMode.EDIT_VISIT &&
                        showHcp2PostNotes &&
                        values.staffRequired === 2
                    }
                >
                    <TextArea
                        name="postVisitNotesBuddy"
                        className="v2__form-group--pos-2-2"
                        label="Post-job notes #2"
                        disabled={loading}
                    />
                </ConditionalDisplay>
                <ConditionalDisplay
                    show={formMode === FormMode.EDIT_VISIT && values.arrivedDateTime}
                >
                    <TimeInput
                        name="arrivedDateTime"
                        className="v2__form-group--pos-1-4"
                        label="Actual start"
                        defaultValue={new Date(values.arrivedDateTime)}
                        selectAllOnFocus
                        required
                        disabled={loading || isHcp1Arrived}
                    />
                </ConditionalDisplay>
                <ConditionalDisplay
                    show={formMode === FormMode.EDIT_VISIT && values.finishedDateTime}
                >
                    <TimeInput
                        name="finishedDateTime"
                        className="v2__form-group--pos-2-4"
                        label="Actual end"
                        defaultValue={new Date(values.finishedDateTime)}
                        selectAllOnFocus
                        required
                        disabled={loading || isHcp1Arrived}
                    />
                </ConditionalDisplay>
                <ConditionalDisplay
                    show={formMode === FormMode.EDIT_VISIT && values.buddyArrivedDateTime}
                >
                    <TimeInput
                        name="buddyArrivedDateTime"
                        className="v2__form-group--pos-3-4"
                        label="Actual start"
                        defaultValue={new Date(values.buddyArrivedDateTime)}
                        selectAllOnFocus
                        required
                        disabled={loading || isHcp2Arrived}
                    />
                </ConditionalDisplay>
                <ConditionalDisplay
                    show={formMode === FormMode.EDIT_VISIT && values.buddyFinishedDateTime}
                >
                    <TimeInput
                        name="buddyFinishedDateTime"
                        className="v2__form-group--pos-4-4"
                        label="Actual end"
                        defaultValue={new Date(values.buddyFinishedDateTime)}
                        selectAllOnFocus
                        required
                        disabled={loading || isHcp2Arrived}
                    />
                </ConditionalDisplay>
                <ConditionalDisplay show={isHcp1Aborted}>
                    <div className="v2__form-group--pos-1-2">
                        <Text
                            name={hcp1AbortReasonFieldName}
                            title="Aborted Reason"
                            description={hcp1AbortedReason}
                        />
                        <Text
                            name={hcp1AbortNotesFieldName}
                            title="Aborted Notes"
                            description={hcp1AbortedNotes}
                        />
                    </div>
                </ConditionalDisplay>
                <ConditionalDisplay show={isHcp2Aborted}>
                    <div className="v2__form-group--pos-2-2">
                        <Text
                            name={hcp2AbortReasonFieldName}
                            title="Aborted Reason"
                            description={hcp2AbortedReason}
                        />
                        <Text
                            name={hcp2AbortNotesFieldName}
                            title="Aborted Notes"
                            description={hcp2AbortedNotes}
                        />
                    </div>
                </ConditionalDisplay>
            </div>
            <div className="v2__form-block">
                <Select
                    name="careComplexity"
                    className="v2__form-group--pos-1-2"
                    label="Care complexity"
                    disabled={loading}
                    isContinueBtnClicked={isContinueBtnClicked}
                >
                    <option value="">Select...</option>
                    {Object.keys(friendlyCareComplexity)
                        .sort((a, b) => a.localeCompare(b))
                        .map((careComplexity) => (
                            <option key={careComplexity} value={careComplexity}>
                                {friendlyCareComplexity[careComplexity]}
                            </option>
                        ))}
                </Select>
                <Checkbox
                    name="carRequired"
                    className="v2__form-group--pos-2-2"
                    label="Transport requirements"
                    title="Car required"
                    disabled={loading}
                    checked={values.carRequired}
                />
                <DateInput
                    name="earliestDateOfVisit"
                    className="v2__form-group--pos-1-4 v2__form-group--ml"
                    label="Earliest date of visit"
                    {...getMomentDateFormatter(dateFormat)}
                    dateFormat={dateFormat}
                    shouldClearValue={!isHcp1Arrived}
                    disabled={earliestDateOfVisitDisabled}
                    maxDate={MAXDATE}
                    minDate={MIN_EARLIEST_DAY}
                />
                <TimeInput
                    name="availableFrom"
                    className="v2__form-group--pos-2-4"
                    label="Earliest time of visit"
                    defaultValue={MINTIME}
                    defaultEnabled={!availableFromDisabled}
                    onChangeCheckbox={(isChecked: boolean) => {
                        setIsEarliestTimeChecked(isChecked);
                    }}
                    selectAllOnFocus
                    checkboxPosition="start"
                    checkboxTestId="availableFromCheckbox"
                    showCheckbox={true}
                    disabled={availableFromDisabled}
                />
                <TimeInput
                    name="availableTo"
                    className="v2__form-group--pos-3-4"
                    label="Latest time of visit"
                    defaultValue={MAXTIME}
                    selectAllOnFocus
                    disabled={loading}
                />
                <ConditionalDisplay show={hasWarningMessages}>
                    <div className="v2__form-group--pos-1-1">
                        <WarningBanner spacerTop={true} compact={true} warnings={warningMessages} />
                    </div>
                </ConditionalDisplay>
                <label className="v2__form-group">
                    <Button
                        className="v2__form-submit-button"
                        name={buttonName}
                        size={ButtonSizes.MEDIUM}
                        clickEvent={onSubmit}
                        type="submit"
                    />
                </label>
            </div>
        </>
    );
};

export default observer(CareDetails);
