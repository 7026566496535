import { Callout } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AdminUserDetails from '../../modules/users/detail/AdminUserDetails';
import UsersTable from './UsersTable';

const UsersList: FC = () => {
    const { userId } = useParams<{ userId: any }>();
    const history = useHistory();

    return (
        <section className="users users--admin">
            <main className="users__main">
                <Callout intent="primary" className="users__callout">
                    HCPs with notifications set to <strong>off</strong> are still able to view and
                    manage jobs via the mobile app but will not be notified when new jobs are added
                    or existing jobs are changed.
                </Callout>
                <UsersTable />
            </main>

            {userId && (
                <div className="users__modal">
                    <AdminUserDetails history={history} match={{ params: { userId } }} />
                </div>
            )}
        </section>
    );
};

export default observer(UsersList);
