import { Patient, PatientAlert } from '@doc-abode/data-models';
import { formatNameLastMiddleFirst } from '@doc-abode/helpers';
import { FC } from 'react';

import { getHcpName } from '../../../../../../helpers/ucr';
import { getPathwayServiceOrAdminTypeLabels } from '../../../../../../helpers/ucr/getPathwayServiceOrAdminTypeLabels';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { formatDisplayDateTime } from '../../../../../modules/helpers/formatData';
import { Info, InfoItem } from '../../../../../v2/components';
import { AlertStatusTimesAndUsers } from '../common/AlertStatusTimesAndUsers';

interface Props {
    alert: PatientAlert;
    alertedJob?: Patient;
}

export const PatientAlertDetails: FC<Props> = ({ alert, alertedJob }) => {
    const {
        RootStore: {
            usersStore: { users },
            configStore: { pathways },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { pathwayDisplayLabel, serviceOrAdminTypeDisplayLabel } = alertedJob
        ? getPathwayServiceOrAdminTypeLabels(alertedJob, pathways, [])
        : { pathwayDisplayLabel: undefined, serviceOrAdminTypeDisplayLabel: undefined };

    return (
        <div className="v2__form-section">
            <Info>
                <InfoItem label="Sender:">{getHcpName(users, alert.createdBy)}</InfoItem>
                <InfoItem label="Reason:">{alert.type}</InfoItem>
                <ConditionalDisplay show={!!alertedJob}>
                    <InfoItem label="Patient:">
                        {formatNameLastMiddleFirst({
                            firstName: alertedJob?.firstName,
                            middleName: alertedJob?.middleName,
                            lastName: alertedJob?.lastName,
                        })}
                    </InfoItem>
                    <InfoItem label="Planned Start Time:">
                        {alertedJob?.startDateTime &&
                            formatDisplayDateTime(alertedJob?.startDateTime)}
                        <br />
                    </InfoItem>
                    <InfoItem label="Pathway:">{pathwayDisplayLabel}</InfoItem>
                    <InfoItem label="Service:">{serviceOrAdminTypeDisplayLabel}</InfoItem>
                </ConditionalDisplay>
            </Info>
            <AlertStatusTimesAndUsers alert={alert} />
        </div>
    );
};
