import { sortBy } from 'lodash';

import { IconWarning } from '../../../helpers';
import { filterDuplicateWarningMessages } from '../../../helpers/ucr/getWarnings';
import { WarningType } from '../../../interfaces';
import { Callout, calloutIntent } from '../components';

interface IProps {
    warnings?: WarningType[];
    simpleWarnings?: string[];
    spacerTop?: boolean;
    spacerBottom?: boolean;
    intent?: calloutIntent;
    text?: string;
    compact?: boolean;
}

export const transformSimpleWarningsToWarningType = (warnings: string[]) => {
    return warnings.map(
        (val): WarningType => ({
            category: 'SIMPLE-WARNING',
            data: {},
            message: val,
        }),
    );
};

export const WarningBanner = ({
    warnings = [],
    simpleWarnings = [],
    spacerTop = false,
    spacerBottom = false,
    intent = 'warning',
    compact = false,
    text = undefined,
}: IProps) => {
    if (warnings.length === 0 && simpleWarnings.length === 0) {
        return <></>;
    }

    let combinedWarnings: WarningType[] = [...warnings];

    if (simpleWarnings.length > 0) {
        combinedWarnings.push(...transformSimpleWarningsToWarningType(simpleWarnings));
    }

    combinedWarnings = filterDuplicateWarningMessages({
        warnings: combinedWarnings,
    });

    return (
        <Callout
            intent={intent}
            Icon={IconWarning}
            spacerBottom={spacerBottom}
            spacerTop={spacerTop}
            compact={compact}
        >
            {text}
            {sortBy(combinedWarnings, ['message']).map(
                ({ message, category, data }: WarningType, i) => (
                    <p className="warning-block" data-testid={category} key={`warning-${i}`}>
                        {message}
                    </p>
                ),
            )}
        </Callout>
    );
};
