import { useCallback, useState } from 'react';

const sortFunc = (sortData, mapJob) => (a, b) => {
    const less = sortData.orderBy === 'asc' ? -1 : 1;
    const greater = sortData.orderBy === 'asc' ? 1 : -1;
    const mappedA = mapJob(a)[sortData.column];
    const mappedB = mapJob(b)[sortData.column];
    if (mappedB === null) {
        return -1;
    } else if (mappedA === null) {
        return 1;
    }
    if (mappedA < mappedB) {
        return less;
    }
    return greater;
};

const useHeaderSorting = (mapJob, onSort, isFrontEndSort, defaultState) => {
    const [sortData, setSortData] = useState({ column: null, orderBy: null });

    const sort = useCallback(
        (name, orderBy = 'asc') =>
            () => {
                let newSortData = { column: null, orderBy: null };
                if (sortData.column === name) {
                    if (sortData.orderBy !== 'desc') {
                        newSortData = {
                            column: name,
                            orderBy: sortData.orderBy === 'asc' ? 'desc' : 'asc',
                        };
                    }
                } else {
                    newSortData = {
                        column: name,
                        orderBy,
                    };
                }
                setSortData(newSortData);
                if (isFrontEndSort) {
                    // Check if sort data was passed, if no try default sort data and last just pass nothing
                    // so sorting will use a default sorting function instead
                    if (newSortData.column) {
                        onSort(sortFunc(newSortData, mapJob));
                    } else if (defaultState) {
                        onSort(sortFunc(defaultState, mapJob));
                    } else {
                        onSort(undefined);
                    }
                } else {
                    onSort(newSortData);
                }
            },
        [sortData, onSort, mapJob, isFrontEndSort, defaultState],
    );
    return {
        sort,
        sortData,
    };
};

export default useHeaderSorting;
