import { Patient } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { useSummaryJobListRowViewModel } from './useSummaryJobListRowViewModel';

interface IProps {
    job: Patient;
}

export const SummaryJobListRow: FC<IProps> = observer(({ job }) => {
    const {
        startTimeDisplayLabel,
        pathwayDisplayLabel,
        serviceOrAdminTypeDisplayLabel,
        patientName,
        statusLabel,
    } = useSummaryJobListRowViewModel({ job });

    return (
        <tr key={`staff-alert-info__job-list-row++${job.id}`}>
            <td>{startTimeDisplayLabel}</td>
            <td>{pathwayDisplayLabel}</td>
            <td>{serviceOrAdminTypeDisplayLabel}</td>
            <td>{patientName}</td>
            <td>{statusLabel}</td>
        </tr>
    );
});
