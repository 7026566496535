import { IconRefresh } from '../../../helpers';
import Callout from '../../v2/components/Callout/Callout';

export const ServiceWorkerUpdate = ({ applyUpdate }: { applyUpdate: () => void }) => {
    return (
        <Callout
            intent="primary"
            rounded={false}
            Icon={IconRefresh}
            action={{ onClick: applyUpdate, text: 'Update now' }}
            testId="service-worker-update"
        >
            A new version of Doc Abode is available, please update to ensure you have the latest
            functionality.
        </Callout>
    );
};
