import { S1Message } from '@doc-abode/data-models';
import { useEffect, useState } from 'react';

import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

export const useS1MessagesViewModel = () => {
    const {
        RootStore: {
            s1Store: {
                s1Messages,
                s1MessagesLoading,
                s1MessagesPaginationToken,
                s1MessagesError,
                fetchS1Messages,
                resetS1Messages,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [selectedMessage, setSelectedMessage] = useState<S1Message | null>(null);

    useEffect(() => {
        fetchS1Messages();

        return () => resetS1Messages();
    }, [fetchS1Messages, resetS1Messages]);

    return {
        s1Messages,
        s1MessagesLoading,
        s1MessagesPaginationToken,
        s1MessagesError,
        fetchS1Messages,
        selectedMessage,
        setSelectedMessage,
    };
};
