import { MarkerData } from '../../../../../interfaces/map';
import { Markers } from './Markers';

// Matches object in org-specific config
export interface IBaseMarkerForMap {
    lat?: number;
    lng?: number;
}

export function shouldShowBaseMarker(location: IBaseMarkerForMap): boolean {
    return Boolean(location.lat && location.lng);
}

export function prepareMarkerDataForBaseLocation(location: IBaseMarkerForMap): MarkerData {
    const latitude = location.lat ?? 0;
    const longitude = location.lng ?? 0;

    return {
        type: 'base',
        latitude,
        longitude,
        url: `data:image/svg+xml;charset=UTF-8;base64, ${btoa(Markers.base())}`,
        title: 'Location of base',
        key: `base-marker`,
        clickable: false,
    };
}
