import { useState } from 'react';

import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

export const useViewS1MessagesViewModel = () => {
    const {
        RootStore: {
            s1Store: { nhsNumberFilter, setNhsNumberFilter, fetchS1Messages, resetS1Messages },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [nhsNumber, setNhsNumber] = useState<string | undefined>('');

    const refresh = () => {
        resetS1Messages();
        fetchS1Messages();
    };

    const setFilter = (value?: string) => {
        setNhsNumberFilter(value);
        refresh();
    };

    return {
        nhsNumberFilter,
        setFilter,
        refresh,
        setNhsNumber,
        nhsNumber,
    };
};
