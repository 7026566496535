import { IHcp, IHcpType } from '../../interfaces/ucr';

const getHcpTypes = (users: IHcp[], hcpTypes: IHcpType[]): IHcpType[] => {
    return hcpTypes.reduce((acc: IHcpType[], hcpType: IHcpType) => {
        const hcpTypeFound = users.find((user: any) => user.hcpTypes.includes(hcpType.value));
        if (hcpTypeFound) {
            acc.push(hcpType);
        }
        return acc;
    }, []);
};

export default getHcpTypes;
