import { LinkedVisit, S1Message, S1Referrals } from '@doc-abode/data-models';
import { action, makeObservable, observable } from 'mobx';

import {
    getS1LinkedVisits,
    getS1Messages,
    GetS1MessagesParams,
    getS1Referrals,
    purgeLocks,
    purgeQueue,
    syncS1Referrals,
} from '../api/s1Api';
import RootStore from './RootStore';

class S1Store {
    rootStore: RootStore;

    linkedVisits: LinkedVisit[] = [];
    linkedVisitsLoading = false;
    s1Referrals: S1Referrals = {};
    s1ReferralsLoading = false;
    s1Messages: S1Message[] = [];
    s1MessagesLoading = false;
    s1MessagesPaginationToken?: string;
    s1MessagesError?: string;
    nhsNumberFilter?: string;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            linkedVisits: observable,
            linkedVisitsLoading: observable,
            s1Referrals: observable,
            s1ReferralsLoading: observable,
            s1Messages: observable,
            s1MessagesLoading: observable,
            s1MessagesPaginationToken: observable,
            s1MessagesError: observable,
            nhsNumberFilter: observable,

            setLinkedVisits: action,
            setLinkedVisitsLoading: action,
            setS1Referrals: action,
            setS1ReferralsLoading: action,
            setS1Messages: action,
            setS1MessagesLoading: action,
            setS1MessagesPaginationToken: action,
            setS1MessagesError: action,
            setNhsNumberFilter: action,
        });

        this.rootStore = rootStore;
    }

    fetchLinkedVisits = async (jobs: { jobId: string; personJobId: string; version: number }[]) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession?.tokens.id) {
            return;
        }

        this.setLinkedVisitsLoading(true);

        const { results } = await getS1LinkedVisits(userSession.tokens.id, jobs);

        this.setLinkedVisits(results);
        this.setLinkedVisitsLoading(false);
    };

    setLinkedVisits = (linkedVisits: LinkedVisit[]) => {
        this.linkedVisits = linkedVisits;
    };

    setLinkedVisitsLoading = (isLoading: boolean) => {
        this.linkedVisitsLoading = isLoading;
    };

    fetchS1Referrals = async (nhsNumber: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession?.tokens.id) {
            return;
        }

        this.setS1ReferralsLoading(true);

        const { referrals, updatedAt } = await getS1Referrals(userSession.tokens.id, nhsNumber);

        this.setS1Referrals({ ...this.s1Referrals, [nhsNumber]: { referrals, updatedAt } });
        this.setS1ReferralsLoading(false);

        return { referrals, updatedAt };
    };

    syncS1Referrals = async (nhsNumber: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession?.tokens.id) {
            return;
        }

        return syncS1Referrals(userSession.tokens.id, nhsNumber);
    };

    setS1Referrals = (s1Referrals: S1Referrals) => {
        this.s1Referrals = s1Referrals;
    };

    setS1ReferralsLoading = (isLoading: boolean) => {
        this.s1ReferralsLoading = isLoading;
    };

    fetchS1Messages = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession?.tokens.id) {
            return;
        }

        this.setS1MessagesLoading(true);
        this.setS1MessagesError();

        const params: GetS1MessagesParams = {};

        if (this.s1MessagesPaginationToken) {
            params.paginationToken = this.s1MessagesPaginationToken;
        }

        if (this.nhsNumberFilter) {
            params.nhsNumber = this.nhsNumberFilter.replace(/\s/g, '');
        }

        try {
            const { messages, paginationToken } = await getS1Messages(
                userSession.tokens.id,
                params,
            );
            this.setS1Messages([...this.s1Messages, ...messages]);
            this.setS1MessagesPaginationToken(paginationToken);
        } catch (error) {
            this.setS1MessagesError((error as Error).message);
        }

        this.setS1MessagesLoading(false);
    };

    setS1Messages = (s1Messages: S1Message[]) => {
        this.s1Messages = s1Messages;
    };

    setS1MessagesLoading = (isLoading: boolean) => {
        this.s1MessagesLoading = isLoading;
    };

    setS1MessagesPaginationToken = (paginationToken?: string) => {
        this.s1MessagesPaginationToken = paginationToken;
    };

    setS1MessagesError = (error?: string) => {
        this.s1MessagesError = error;
    };

    setNhsNumberFilter = (nhsNumberFilter?: string) => {
        this.nhsNumberFilter = nhsNumberFilter;
    };

    resetS1Messages = () => {
        this.setS1Messages([]);
        this.setS1MessagesPaginationToken();
    };

    purgeQueue = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession?.tokens.id) {
            return;
        }

        return purgeQueue(userSession.tokens.id);
    };

    purgeLocks = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession?.tokens.id) {
            return;
        }

        return purgeLocks(userSession.tokens.id);
    };
}

export default S1Store;
