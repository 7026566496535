export type UserRowStatusDisplayInfo = {
    status: string;
    label: string;
    icon: any; // Not sure what type to set to make Blueprint icons render
};

export function getUserStatusDisplayInfo(isUserEnabled: boolean): UserRowStatusDisplayInfo {
    return isUserEnabled
        ? { status: 'enabled', label: 'enabled', icon: 'tick-circle' }
        : { status: 'disabled', label: 'disabled', icon: 'ban-circle' };
}

export function getUserAvailabilityDisplayInfo(isUserAvailable: boolean): UserRowStatusDisplayInfo {
    return isUserAvailable
        ? { status: 'enabled', label: 'on', icon: 'tick-circle' }
        : { status: 'disabled', label: 'off', icon: 'ban-circle' };
}

export function getS1DisplayInfo(s1Username: string | undefined): UserRowStatusDisplayInfo {
    return s1Username && s1Username !== ''
        ? { status: 'enabled', label: s1Username, icon: 'tick-circle' }
        : { status: 'disabled', label: '-', icon: 'ban-circle' };
}

export function getUserMaxDriveDisplayLabel(
    maxDriveTimeMinutes: number | string | undefined,
): string {
    return maxDriveTimeMinutes ? `${maxDriveTimeMinutes} mins` : '-';
}
