import moment, { Moment, MomentInput } from 'moment';

import { getDurationInMinutes } from './getDurationTime';

export const getMomentEndTime = (startTime: MomentInput, duration?: string): Moment => {
    const tempStartTime = startTime || new Date();
    const durationInMinutes = getDurationInMinutes(duration);

    return moment(tempStartTime).add(durationInMinutes, 'minutes');
};

export const getDateEndTime = (startTime: MomentInput, duration?: string): Date => {
    return getMomentEndTime(startTime, duration).toDate();
};

export const getStringEndTime = (
    startTime: MomentInput,
    duration: string,
    format: string,
): string => {
    return getMomentEndTime(startTime, duration).format(format);
};
