import { observer } from 'mobx-react';
import { FC } from 'react';

import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { TagInputComponent } from '../../../../v2/form/TagInput/TagInput';
import { ActionsButtonActions } from '../../components/calendar/ActionsDropDown';
import { CommonControls } from '../CommonControls';

export const JobViewControls: FC<{ refreshJobs(): void }> = observer(({ refreshJobs }) => {
    const {
        RootStore: {
            ucrStore: { nameFilters, setNameFilters },
        },
    } = useStores<{ RootStore: RootStore }>();

    const actions = [
        ActionsButtonActions.EXPORT_JOBS,
        ActionsButtonActions.EXPORT_SCHEDULES,
        ActionsButtonActions.IMPORT_SCHEDULES,
    ];

    const onChangeInput = (name: string, values: string[]): void => {
        setNameFilters(name, values);
    };

    return (
        <CommonControls
            actions={actions}
            refresh={refreshJobs}
            additionalLeft={
                <>
                    <TagInputComponent
                        name="patientName"
                        className="ucr__filters-filter-input"
                        placeholder="Patient"
                        values={nameFilters.patientName}
                        onChange={(values) => onChangeInput('patientName', values as string[])}
                        inputProps={{ 'aria-label': 'patient filter' }}
                    />
                    <TagInputComponent
                        name="staffName"
                        className="ucr__filters-filter-input"
                        placeholder="Staff"
                        values={nameFilters.staffName}
                        onChange={(values) => onChangeInput('staffName', values as string[])}
                        inputProps={{ 'aria-label': 'staff filter' }}
                    />
                </>
            }
        />
    );
});
