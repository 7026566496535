import { ODSRecord, Vaccination, VaccinationDetails } from '@doc-abode/data-models';
import moment from 'moment';

import { getOrganisation } from '../../../../api/odsFhirApi';
import { VaccinationWithWarnings } from '../types';
import { getSelectedDateTime } from './helpers';

export const getWarnings = async (
    patients: Vaccination[],
    selectedDate: string | null | undefined,
    doseInterval: Record<string, Record<string, number>>,
    vaccinationDetails?: VaccinationDetails,
) => {
    const warnings = [] as unknown as VaccinationWithWarnings[];

    const odsCodes = patients.reduce((acc, { odsCode, vaccinationCategory }) => {
        if (odsCode && vaccinationDetails?.[vaccinationCategory]?.requiresPickup) {
            acc.add(odsCode);
        }
        return acc;
    }, new Set<string>());

    const practices: { [key: string]: ODSRecord } = {};

    await Promise.all(
        Array.from(odsCodes).map(async (odsCode) => {
            try {
                practices[odsCode] = await getOrganisation(odsCode);
            } catch (err) {}
        }),
    );

    patients.forEach((patient) => {
        const warningsForPatient = Vaccination.getVaccinationWarnings(
            patient,
            selectedDate as string | null, // todo change interface Vaccination.getVaccinationWarnings  for selectedDate to string | null | undefined
            doseInterval,
            vaccinationDetails,
        );

        if (patient.odsCode && vaccinationDetails?.[patient.vaccinationCategory]?.requiresPickup) {
            const practice = practices[patient.odsCode];

            if (!practice) {
                warningsForPatient.push(
                    'Unable to determine pick-up location; the provided ODS code for the GP practice does not exist',
                );
            } else if (!practice.active) {
                warningsForPatient.push(
                    'The GP practice appears to no longer exist; please check the practice',
                );
            } else if (!practice.address) {
                warningsForPatient.push(
                    'Unable to determine pick-up location; the GP practice has no address on ODS',
                );
            }
        }

        if (warningsForPatient.length > 0) {
            const patientWithWarning = {
                ...patient,
                warnings: warningsForPatient,
            } as VaccinationWithWarnings;
            warnings.push(patientWithWarning);
        }
    });

    return warnings;
};

export const checkDateTimeWarnings = (
    selectedDate: string | null | undefined,
    startTime: string,
    endTime: string | null | undefined,
): string[] => {
    const warnings = [];

    if (!selectedDate) {
        return ['The date is mandatory'];
    }
    const selectedStartDateTime = getSelectedDateTime(selectedDate, startTime);
    const selectedEndDateTime = getSelectedDateTime(selectedDate, endTime as string);

    if (moment(selectedStartDateTime).isBefore(moment())) {
        warnings.push(
            'The planned start time is in the past. Although you will be able to save the route with the current settings, please check your inputs for any mistakes.',
        );
    }

    if (moment(selectedEndDateTime).isBefore(moment())) {
        warnings.push(
            'The planned end time is in the past. Although you will be able to save the route with the current settings, please check your inputs for any mistakes.',
        );
    }
    return warnings;
};
