import { Button, MenuItem } from '@blueprintjs/core';
import cn from 'classnames';
import { FC } from 'react';

import { IconThermometer } from '../../../../../helpers/ucr/icons';
import { ExpandMore } from '../../../../../helpers/ucr/icons';
import { SicknessItem, useSicknessViewModel } from './useSickessViewModel';
interface IProps {
    isSick?: boolean;
    handleChange?: (val: boolean) => void;
}

const Sickness: FC<IProps> = ({ isSick = false, handleChange }) => {
    const { SicknessSelect, selected, itemRenderer, onSelect } = useSicknessViewModel({
        isSick,
        handleChange,
    });

    return (
        <SicknessSelect
            className={cn('ucr__calendar-hcp-sickness', {
                'ucr__calendar-hcp-sickness--sick': selected === SicknessItem.SICK,
            })}
            filterable={false}
            activeItem={selected}
            popoverProps={{ minimal: true }}
            items={Object.values(SicknessItem)}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={onSelect}
            itemRenderer={itemRenderer}
        >
            {/* children become the popover target; render value here */}
            <Button
                className={cn('ucr__calendar-hcp-sickness-select')}
                icon={<IconThermometer className={cn('ucr__calendar-hcp-sickness-select-icon')} />}
                text={selected}
                rightIcon={<ExpandMore className="ucr__calendar-hcp-sickness-select-icon-arrow" />}
            />
        </SicknessSelect>
    );
};

export default Sickness;
