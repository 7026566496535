/**
 * Utility to derive the organisations to show in the Org switcher of the Footer
 * @param allOrganisations list of orgs the user has access to
 * @param user the user to evaluate
 * @returns array of filtered organisations the user has access to
 */
// Has to use any type due to poor typing across Footer
export const getFilteredOrganisations = (allOrganisations: any[], user: any) => {
    // If the user is an admin or controller for an organisation, we'll allow them to access to the org
    // This excludes HCP only users, or users that are assigned to an org without permissions
    return allOrganisations.filter(
        (org) =>
            user.controllerOrganisations.includes(org.value) ||
            user.adminOrganisations.includes(org.value),
    );
};
