import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import { NavLink, Redirect, Switch } from 'react-router-dom';

import { SentryRoute } from '../../../helpers/sentryRoute';
import { PatientsContext, PatientsContextState } from '../../../hook/usePatientsContext';
import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';
import MapExperimental from '../../pages/vaccinations/MapExperimental';
import Patients from '../../pages/vaccinations/Patients';
import Routes, { TLocation } from '../../pages/vaccinations/Routes';

export default function Vaccinations() {
    const {
        RootStore: {
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    if (!isFeatureEnabled('vaccinations')) {
        return <Redirect to="/page-not-found" />;
    }

    const mapEnabled = isFeatureEnabled('vaccinationsMapExperimental');

    const renderRoutesWithContext = () => (
        <Switch>
            <Redirect exact from="/vaccinations" to="/vaccinations/patients" />
            {mapEnabled ? (
                <SentryRoute path="/vaccinations/map" component={MapExperimental} />
            ) : (
                <Redirect exact from="/vaccinations/map" to="/vaccinations" />
            )}
            <SentryRoute path="/vaccinations/patients/(add|import)?/:jobId?" component={Patients} />
            <SentryRoute path="/vaccinations/routes/(new|add-to-existing|withdraw-from-existing)?/:jobId?">
                {({ history, location, match }) => (
                    <Routes
                        history={history}
                        location={location as TLocation<{ closeModal: boolean }>}
                        routeMatch={match}
                    />
                )}
            </SentryRoute>
        </Switch>
    );

    return (
        <section className="vaccinations">
            <ul
                className={classNames('vaccinations__tabs', {
                    'vaccinations__tabs--experimental': mapEnabled,
                })}
            >
                <li>
                    <NavLink
                        className="vaccinations__tab"
                        activeClassName="vaccinations__tab--active"
                        to="/vaccinations/patients"
                    >
                        <Icon icon="people" className="vaccinations__tab-icon" /> Patients
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="vaccinations__tab"
                        activeClassName="vaccinations__tab--active"
                        to="/vaccinations/routes"
                    >
                        <Icon icon="route" className="vaccinations__tab-icon" /> Routes
                    </NavLink>
                </li>
                {mapEnabled && (
                    <li>
                        <NavLink
                            className="vaccinations__tab"
                            activeClassName="vaccinations__tab--active"
                            to="/vaccinations/map"
                        >
                            <Icon icon="map" className="vaccinations__tab-icon" /> Map
                        </NavLink>
                    </li>
                )}
            </ul>
            <PatientsContext.Provider value={PatientsContextState()}>
                {renderRoutesWithContext()}
            </PatientsContext.Provider>
        </section>
    );
}
