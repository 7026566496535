import moment from 'moment';

export interface GetStartDateForFollowUpProps {
    systemTime: Date;
    dateOfVisit?: Date;
    fromPatientListView?: boolean;
}

const getDates = ({ systemTime, dateOfVisit }: GetStartDateForFollowUpProps) => {
    const todayDate = moment().startOf('day');
    const dateOfVisitDifference = dateOfVisit
        ? moment(dateOfVisit).startOf('day').diff(todayDate, 'days')
        : 0;

    return {
        todayDate,
        dateOfVisitDifference,
        dayDifference: moment(systemTime).startOf('day').diff(todayDate, 'days'),
    };
};
export const getStartDateForDefaultVisitAndAdmin = ({
    systemTime,
    fromPatientListView,
}: GetStartDateForFollowUpProps): Date => {
    const { todayDate, dayDifference } = getDates({ systemTime });

    // if it coming from the patient view we will return today date.
    if (fromPatientListView) {
        return todayDate.toDate();
    }

    // if the day difference is greater than 0 then return today date.
    if (dayDifference > 0) {
        return moment(systemTime).startOf('day').toDate();
    }

    return todayDate.toDate();
};

export const getStartDateForFollowUp = ({
    systemTime,
    dateOfVisit,
    fromPatientListView,
}: GetStartDateForFollowUpProps): Date => {
    const { todayDate, dayDifference, dateOfVisitDifference } = getDates({
        systemTime,
        dateOfVisit,
    });

    // loaded from the patient list view we default to today date.
    if (fromPatientListView) {
        return todayDate.toDate();
    }

    // then the date is in the past for both we default for today.
    if (dayDifference <= 0 && dateOfVisitDifference <= 0) {
        return todayDate.toDate();
    }
    // if the system time and the date of visit in the future use date of visit.
    if (dayDifference > 0 && dateOfVisitDifference > 0) {
        return moment(dateOfVisit).startOf('day').toDate();
    }
    // if dayDifference is in the past and dateOfVisit in the future use date of visit.
    if (dayDifference <= 0 && dateOfVisitDifference > 0) {
        return moment(dateOfVisit).startOf('day').toDate();
    }
    // if dayDifference (system) is greater than 0 and dateOfVisit is less than 0 use system time.
    if (dayDifference > 0 && dateOfVisitDifference <= 0) {
        return moment(systemTime).startOf('day').toDate();
    }

    // last scenario will never be reached but just incase it does.
    return todayDate.toDate();
};
