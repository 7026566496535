import { Vaccination } from '@doc-abode/data-models';
import { VaccinationCategory } from '@doc-abode/data-models';

const { COVID_19 } = VaccinationCategory;

export enum FilterType {
    COVID_PLUS_CO_ADMIN = 'COVID_PLUS_CO_ADMIN',
    NON_COVID_NO_CO_ADMIN = 'NON_COVID_NO_CO_ADMIN',
    NON_COVID_PLUS_CO_ADMIN = 'NON_COVID_PLUS_CO_ADMIN',
}

export function arePatientsCompatible(patients: Vaccination[]): boolean {
    if (
        patients.every((patient) => patient.vaccinationCategory === COVID_19) ||
        patients.every(
            (patient) =>
                patient.vaccinationCategory !== COVID_19 &&
                !patient.coAdministeredWith?.includes(COVID_19),
        )
    ) {
        return true;
    }

    if (
        patients.some(
            (patient) =>
                patient.vaccinationCategory !== COVID_19 &&
                (!patient.coAdministeredWith?.includes(COVID_19) ||
                    !patients.some(
                        ({ vaccinationCategory, nhsNumber }) =>
                            patient.nhsNumber === nhsNumber && vaccinationCategory === COVID_19,
                    )),
        )
    ) {
        return false;
    }

    return true;
}

export function filterPatientSelection(
    patients: Vaccination[],
    filterType: FilterType,
): Vaccination[] {
    if (filterType === FilterType.COVID_PLUS_CO_ADMIN) {
        return patients.filter(
            (patient) =>
                patient.vaccinationCategory === COVID_19 ||
                (patient.coAdministeredWith?.includes(COVID_19) &&
                    patients.some(
                        ({ vaccinationCategory, nhsNumber, coAdministeredWith }) =>
                            patient.nhsNumber === nhsNumber &&
                            vaccinationCategory === COVID_19 &&
                            coAdministeredWith?.includes(patient.vaccinationCategory!),
                    )),
        );
    }

    if (filterType === FilterType.NON_COVID_NO_CO_ADMIN) {
        return patients.filter(
            (patient) =>
                patient.vaccinationCategory !== COVID_19 &&
                !patient.coAdministeredWith?.includes(COVID_19),
        );
    }

    if (filterType === FilterType.NON_COVID_PLUS_CO_ADMIN) {
        return patients.filter((patient) => patient.vaccinationCategory !== COVID_19);
    }

    return [];
}
