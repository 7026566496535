import { useHistory } from 'react-router';

import { useUsersForRoleViewModel } from './useUsersForRoleViewModel';

function getSortedUsers(users: any[]): any[] {
    return users.slice().sort(
        (a, b) =>
            // Sort by enabled status and then alphabetically by User ID
            b.enabled - a.enabled ||
            // b.available - a.available ||
            a.userName.localeCompare(b.userName),
    );
}

export const useUsersTableViewModel = () => {
    // Admins can see more users than a normal Controller
    const { users } = useUsersForRoleViewModel();

    const history = useHistory();

    const _handleUserClick = (userId: string) => {
        history.push(`/users/${userId}`);
    };

    return {
        users: getSortedUsers(users),
        history,
        onClickOfUser: _handleUserClick,
    };
};
