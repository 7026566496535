import { Gender, Patient } from '@doc-abode/data-models';

export interface CleanPatientVisitData {
    nhsNumber: string;
    firstName: string;
    middleName?: string | null;
    lastName: string;
    contactNumber?: string | null;
    additionalContactNumbers?: string[];
    dateOfBirth?: Date;
    gender: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    town?: string;
    postCode: string;
    languagesSpoken?: string[];
    staffPreferredGender?: string[];
}

export interface CleanReferralVisitData {
    systmOneRef?: string;
    referralDateTime?: Date;
    referrer?: string;
}

export interface CleanCareVisitData {
    referralPathway?: string;
    disposition?: string;
    careComplexity?: string | null;
    hcpId?: string;
    buddyId?: string;
    carRequired?: boolean;
    visitDate: Date;
    earliestDateOfVisit?: Date;
    availableFrom?: Date;
    availableTo?: Date;
    startTime?: Date;
    duration?: string;
    notes?: string;
}

type PatientLike = Pick<
    Patient,
    | 'nhsNumber'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'contactNumber'
    | 'additionalContactNumbers'
    | 'dateOfBirth'
    | 'gender'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressLine3'
    | 'town'
    | 'languagesSpoken'
    | 'staffPreferredGender'
    | 'systmOneRef'
    | 'referrer'
    | 'referralDateTime'
    | 'referralPathway'
    | 'disposition'
    | 'careComplexity'
    | 'carRequired'
    | 'earliestDateOfVisit'
    | 'availableFrom'
    | 'availableTo'
    | 'startDateTime'
    | 'notes'
> &
    ({ postCode: string } | { postcode: string });

export function createVisitData(patient: PatientLike): {
    patientData: CleanPatientVisitData;
    referralData: CleanReferralVisitData;
    careData: CleanCareVisitData;
} {
    const {
        nhsNumber,
        middleName,
        contactNumber,
        additionalContactNumbers,
        addressLine1,
        addressLine2,
        addressLine3,
        town,
        languagesSpoken,
        staffPreferredGender,
        systmOneRef,
        referralDateTime,
        referrer,
        referralPathway,
        disposition,
        careComplexity,
        carRequired,
        earliestDateOfVisit,
        availableFrom,
        availableTo,
        startDateTime,
        notes,
    } = patient;

    const postCode = 'postcode' in patient ? patient.postcode : '';
    const firstName = patient.firstName ? patient.firstName : '';
    const lastName = patient.lastName ? patient.lastName : '';
    const dateOfBirth = patient.dateOfBirth ? patient.dateOfBirth : '';
    const gender = patient.gender ? patient.gender : Gender.NOTPROVIDED;

    const patientData: CleanPatientVisitData = {
        nhsNumber,
        firstName,
        middleName,
        lastName,
        contactNumber,
        additionalContactNumbers,
        dateOfBirth: new Date(dateOfBirth),
        gender,
        addressLine1,
        addressLine2,
        addressLine3,
        town,
        postCode,
        languagesSpoken,
        staffPreferredGender,
    };

    const referralData: CleanReferralVisitData = {
        systmOneRef,
        referralDateTime: referralDateTime ? new Date(referralDateTime) : undefined,
        referrer,
    };

    const careData: CleanCareVisitData = {
        referralPathway,
        disposition,
        careComplexity,
        carRequired,
        visitDate: new Date(),
        earliestDateOfVisit: earliestDateOfVisit ? new Date(earliestDateOfVisit) : undefined,
        availableFrom: availableFrom ? new Date(availableFrom) : undefined,
        availableTo: availableTo ? new Date(availableTo) : undefined,
        startTime: startDateTime ? new Date(startDateTime) : undefined,
        duration: '01:00',
        notes,
    };

    return { patientData, referralData, careData };
}
