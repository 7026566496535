import { observer } from 'mobx-react';
import { FC } from 'react';

import { ViewToShow } from '../../../../../constants/mainConst';
import {
    IconCalendar,
    IconFace,
    IconList,
    IconMap,
    IconMessages,
} from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { SideButton } from '../../components/side';
import { useView } from '../../views/useView';

interface IProps {
    size: string;
}

export const Views: FC<IProps> = observer(({ size }) => {
    const { currentViewState, navigateTo } = useView();

    const {
        RootStore: {
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    return (
        <>
            <SideButton
                name="Calendar view"
                tooltipText="Calendar view"
                size={size}
                pos="top"
                Icon={IconCalendar}
                isActive={currentViewState.timeline}
                clickEvent={() => {
                    navigateTo(ViewToShow.TIMELINE);
                }}
                dataTest="icon-calendar-view"
            />
            <SideButton
                name="List view"
                tooltipText="List view"
                size={size}
                pos="top"
                Icon={IconList}
                isActive={currentViewState.visitList}
                clickEvent={() => {
                    navigateTo(ViewToShow.VISITS_LIST);
                }}
                dataTest="icon-list-view"
            />
            <SideButton
                name="Map view"
                tooltipText="Map view"
                size={size}
                pos="top"
                Icon={IconMap}
                isActive={currentViewState.map}
                clickEvent={() => {
                    navigateTo(ViewToShow.MAP);
                }}
                dataTest="icon-map-view"
            />
            <SideButton
                name="List of patients"
                tooltipText="List of patients"
                size={size}
                pos="top"
                Icon={IconFace}
                isActive={currentViewState.patientList}
                clickEvent={() => {
                    navigateTo(ViewToShow.PATIENTS_LIST);
                }}
                dataTest="icon-patient-list-view"
            />
            {isFeatureEnabled('s1Enabled') && (
                <SideButton
                    name="SystmOne message audit"
                    tooltipText="SystmOne message audit"
                    size={size}
                    pos="top"
                    Icon={IconMessages}
                    isActive={currentViewState.s1Messages}
                    clickEvent={() => {
                        navigateTo(ViewToShow.S1_MESSAGES);
                    }}
                    dataTest="icon-systmone-messages-view"
                />
            )}
        </>
    );
});
