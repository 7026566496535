import cn from 'classnames';
import { FC } from 'react';

interface IProps {
    isShow: boolean;
    clickEvent?: () => void;
}

const Overlay: FC<IProps> = ({ isShow, clickEvent = () => {} }) => {
    return (
        <div
            className={cn('v2__overlay', {
                'v2__overlay--show': isShow,
                'v2__overlay--hide': !isShow,
            })}
            onClick={clickEvent}
        />
    );
};

export default Overlay;
