import { gql } from '@apollo/client';
import {
    Gender,
    IAdmission,
    Patient as Job,
    PatientListFilters,
    PatientListSort,
} from '@doc-abode/data-models';

export type PatientListJob = Pick<
    Job,
    | 'id'
    | 'hcpId'
    | 'disposition'
    | 'referralPathway'
    | 'staffRequired'
    | 'arrivedDateTime'
    | 'buddyId'
    | 'buddyArrivedDateTime'
    | 'startDateTime'
    | 'careComplexity'
    | 'dateOfVisit'
>;

export interface PatientListPatient {
    nhsNumber: string;
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
    gender: Gender;
    postcode: string;

    contactNumber: string;
    additionalContactNumbers: string[];
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    town: string;
    languagesSpoken: any[];
    staffPreferredGender: string[];

    systmOneRef: string;
    referralDateTime: string;
    referrer: string;

    referralPathway: string;
    disposition: string;
    careComplexity: string;
    carRequired: boolean;
    earliestDateOfVisit: string;
    availableFrom: string;
    availableTo: string;
    notes: string;

    admission: IAdmission;

    lastCompletedJob: PatientListJob;
    nextUpcomingJob: PatientListJob;
}

export interface IPatientsListQueryResponse {
    getPatients: {
        patients: PatientListPatient[];
        nextPageNumber: number;
    };
}

export interface IPatientsListQueryVariables {
    pagination?: {
        pageSize?: number;
        pageNumber?: number;
    };

    sorts?: PatientListSort;
    filters?: PatientListFilters;
}

export interface IPatientUpdateResponse {
    updatePatient: {
        message: string;
    };
}

export const UPDATE_PATIENT = gql`
    mutation updatePatient($input: UpdatePatientInput!) {
        updatePatient(input: $input) {
            message
        }
    }
`;

export const QUERY_PATIENTS = gql`
    query getPatients(
        $sorts: PatientListSorts
        $filters: PatientListFilters
        $pagination: Pagination
    ) {
        getPatients(sorts: $sorts, filters: $filters, pagination: $pagination) {
            patients {
                nhsNumber
                firstName
                middleName
                lastName
                dateOfBirth
                gender
                postcode

                contactNumber
                additionalContactNumbers
                addressLine1
                addressLine2
                addressLine3
                town
                languagesSpoken
                staffPreferredGender

                systmOneRef
                referralDateTime
                referrer

                referralPathway
                disposition
                careComplexity
                earliestDateOfVisit
                availableFrom
                availableTo
                notes

                lastCompletedJob {
                    id
                    hcpId
                    disposition
                    referralPathway
                    staffRequired
                    arrivedDateTime
                    buddyId
                    buddyArrivedDateTime
                    startDateTime
                    careComplexity
                    dateOfVisit
                }

                nextUpcomingJob {
                    id
                    hcpId
                    disposition
                    referralPathway
                    staffRequired
                    arrivedDateTime
                    buddyId
                    buddyArrivedDateTime
                    startDateTime
                    careComplexity
                    dateOfVisit
                }

                admission {
                    isAdmitted
                }
            }

            nextPageNumber
        }
    }
`;
