import { Checkbox } from '@blueprintjs/core';
import { TimePicker, TimePickerProps } from '@blueprintjs/datetime';
import cn from 'classnames';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import { FC, KeyboardEvent, useEffect, useState } from 'react';

import { FormGroup } from '../FormGroup';

const availableKeys = ['Alt', 'Backspace', 'Ctrl', 'Enter', 'Shift', 'Tab', 'ArrowUp', 'ArrowDown'];

interface IProps extends TimePickerProps {
    name: string;
    label?: string;
    fullWidth?: boolean;
    required?: boolean;
    defaultEnabled?: boolean;
    checkboxPosition?: string;
    onChangeCheckbox?: (isChecked: boolean) => void;
    handleOnChange?: (value: Date) => void;
    showCheckbox?: boolean;
    checkboxTestId?: string;
    displayErrors?: string;
}

const TimeInputComponent: FC<IProps> = ({
    name,
    className,
    label,
    fullWidth,
    required,
    defaultValue,
    defaultEnabled = false,
    selectAllOnFocus = true,
    onChangeCheckbox = () => {},
    disabled,
    displayErrors,
    handleOnChange = () => {},
    checkboxTestId = 'checkboxTestId',
    checkboxPosition,
    showCheckbox,
    ...props
}) => {
    const [field, meta] = useField(name);
    const { setFieldValue } = useFormikContext();
    const [enabled, setEnabled] = useState(required || Boolean(field.value) || defaultEnabled);
    // When the field is disabled its value is set to null, so we save its previous so it can be
    // restored when the field is re-enabled
    const [savedValue, setSavedValue] = useState<Date | undefined>(
        field.value || defaultValue || undefined,
    );

    // Ensure that times are always rounded down to the nearest minute, to avoid issues with
    // time calculations
    useEffect(() => {
        if (!field.value) return;
        if (field.value.getSeconds() !== 0 || field.value.getMilliseconds() !== 0) {
            const roundedTime = new Date(field.value);
            roundedTime.setSeconds(0, 0);
            setFieldValue(name, roundedTime);
        }
    }, [field.value, name, setFieldValue]);

    useEffect(() => {
        if (!enabled) {
            setFieldValue(name, undefined);
        } else {
            setFieldValue(
                name,
                savedValue ||
                    moment()
                        .minute(Number(moment().add(5, 'minutes').format('mm')))
                        .toDate(),
            );
        }
    }, [enabled, name, setFieldValue, savedValue]);

    useEffect(() => {
        if (required) {
            setEnabled(true);
        }
    }, [required]);

    const changeTime = (time: Date) => {
        setFieldValue(name, time);
        setSavedValue(time);

        if (handleOnChange) {
            handleOnChange(time);
        }
    };

    const keyDownEvent = (e: KeyboardEvent) => {
        const matchesDigit = /\d/.test(e.key);

        if (!matchesDigit && !availableKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    const onChange = () => {
        setEnabled(!enabled);
        onChangeCheckbox(!enabled);
    };

    return (
        <FormGroup
            className={cn(className, 'v2__form-time-input-group')}
            label={label}
            labelFor={field.name}
            required={required}
            touched={meta.touched}
            error={meta.error}
            displayErrors={displayErrors}
        >
            {/* as far as I can tell this whole showCheckbox is for one use case in
             src/components/pages/ucr/forms/AddVisit/CareDetails.tsx
             if that's the case we should move the checkbox to CareDetails to make this component simpler
             and to make CareDetails esier to work with / test.
             */}
            {showCheckbox || (!required && !disabled) ? (
                <Checkbox
                    className={cn('v2__form-checkbox-input', {
                        'v2__form-checkbox-input--left': checkboxPosition === 'start',
                    })}
                    checked={enabled}
                    onChange={onChange}
                    data-testid={checkboxTestId}
                />
            ) : null}
            <TimePicker
                {...props}
                {...field}
                className={cn('v2__form-time-input', {
                    'v2__form-time-input--am-pm': props.useAmPm,
                    'v2__form-time-input--full': fullWidth,
                })}
                defaultValue={savedValue}
                onKeyDown={keyDownEvent}
                onChange={changeTime}
                disabled={!enabled || disabled}
                selectAllOnFocus={selectAllOnFocus}
            />
        </FormGroup>
    );
};

export default TimeInputComponent;
