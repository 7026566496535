import { observer } from 'mobx-react';
import { FC } from 'react';
import Select from 'react-select';

import { getHcp } from '../../../../helpers/ucr/getHcp';
import { useHcpSuggestionOptions } from '../../../../hook/useHcpSuggestionOptions';
import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';
import { compareByLabels } from '../../../modules/helpers/sortFunctions';
import Suggest from '../../../v2/form/Suggest';
import { VisitValuesType } from '../blocks/panels/VisitDetailsTypes';

interface IProps {
    patient: VisitValuesType;
    isFirstUser?: boolean;
    onHcpReassignment?: (hcpId: string) => void;
    insideForm?: boolean;
    disabled?: boolean;
    displayErrors?: string;
    includeUnavailableHcps?: boolean;
    menuPlacement?: 'auto' | 'top' | 'bottom';
    isAdmin?: boolean;
    ariaLabel?: string;
}

interface IHcpSelectOption {
    value: string;
    label: string;
    isWorking?: boolean;
    isDisabled?: boolean;
}

export const HcpSelect: FC<IProps> = ({
    patient,
    isFirstUser = true,
    onHcpReassignment = () => {},
    insideForm = true,
    disabled = false,
    includeUnavailableHcps = false,
    displayErrors,
    menuPlacement,
    isAdmin = false,
    ariaLabel,
}) => {
    const {
        RootStore: {
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { hcpId, buddyId } = patient;
    const staff = getHcp(users, isFirstUser ? hcpId : buddyId);

    const hcpOptions = useHcpSuggestionOptions(patient);

    const hcpList: IHcpSelectOption[] = [
        ...hcpOptions
            .map((option) => ({
                ...option,
                ...(!includeUnavailableHcps && { isDisabled: !option.isWorking }),
            }))
            .filter((l: any) => {
                return (
                    (includeUnavailableHcps ||
                        l.isWorking ||
                        l.value === (staff ? staff.userId : '')) &&
                    (isFirstUser ? l.value !== buddyId : l.value !== hcpId)
                );
            })
            .sort(compareByLabels),
    ];

    if (!isAdmin) {
        hcpList.unshift({ label: 'Not set', value: '' });
    }

    const selectedHcp = hcpList.find((hcp) => hcp.value === (staff ? staff.userId : ''));

    const onItemSelect = (item: IHcpSelectOption) => {
        onHcpReassignment(item.value || '');
    };

    return insideForm ? (
        <Suggest
            name={isFirstUser ? 'hcpId' : 'buddyId'}
            displayErrors={displayErrors}
            className={isFirstUser ? 'v2__form-group--pos-1-2' : 'v2__form-group--pos-2-2'}
            disabled={disabled}
            value={isFirstUser ? hcpId : buddyId}
            options={hcpList}
            menuPlacement={menuPlacement || 'top'}
            aria-label={ariaLabel}
        />
    ) : (
        <Select
            className="visit-details__select"
            classNamePrefix="visit-details__select"
            options={hcpList}
            value={selectedHcp}
            onChange={(option) => {
                onItemSelect(option as IHcpSelectOption);
            }}
        />
    );
};

export default observer(HcpSelect);
