import { Button, Tooltip } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { FC, Fragment } from 'react';

import { IconInfoRoundCirle } from '../../../../../helpers/ucr/icons';
import { Panel } from '../side';
import { FilterGroupType, useUcrFilterPanelViewModel } from './useUcrFilterPanelViewModel';

interface IFilterPanel {
    isShow: boolean;
    closePanel: () => void;
}

interface IFilterGroup {
    filterGroup: FilterGroupType;
}

const FilterGroup: FC<IFilterGroup> = observer(({ filterGroup }) => {
    return (
        <>
            <div className="ucr__filter-group">
                <span className="ucr__filter-group-title">{filterGroup.name}</span>
                <Tooltip content={filterGroup.tooltip}>
                    <IconInfoRoundCirle className="ucr__filter-group-icon"></IconInfoRoundCirle>
                </Tooltip>
            </div>

            {filterGroup.filters.map((filter) => (
                <Fragment key={filter.name}>{filter.render()}</Fragment>
            ))}
        </>
    );
});

const FiltersPanel: FC<IFilterPanel> = observer(({ isShow, closePanel }) => {
    const { filterGroups, onClearAllFilters } = useUcrFilterPanelViewModel();

    return (
        <Panel
            title="Filters"
            side="right"
            isShow={isShow}
            closePanel={closePanel}
            testId="filtersPanel"
        >
            <div className="ucr__side-panel-block ucr__side-panel-block--scrollable">
                <div className="ucr__side-panel-container">
                    {filterGroups.map((filterGroup) => (
                        <FilterGroup filterGroup={filterGroup} key={filterGroup.name} />
                    ))}

                    <Button
                        className="ucr__filters-clear-filters"
                        text="Clear filters"
                        minimal={true}
                        name="Clear filters"
                        onClick={onClearAllFilters}
                    />
                </div>
            </div>
        </Panel>
    );
});

export default FiltersPanel;
