import { S1ClientConnectionStatus } from '@doc-abode/data-models';

import { capitalizeFirstLetter } from '../../../../../helpers';
import { getHcp } from '../../../../../helpers/ucr/getHcp';
import { useUsersForRoleViewModel } from '../../../users/useUsersForRoleViewModel';

export const useConnectedClientRowViewModel = (
    clientConnectionStatus: S1ClientConnectionStatus,
) => {
    const { users } = useUsersForRoleViewModel();
    const user = getHcp(users, clientConnectionStatus.userId);

    return {
        userName: user?.userName ?? clientConnectionStatus.userId,
        clientVersion: clientConnectionStatus.clientVersion,
        lastActivity: clientConnectionStatus.lastActivity,
        // status is inferred as `disconnected` if `lastActivity` or `status` is undefined,
        // so this will always be a string
        status: capitalizeFirstLetter(clientConnectionStatus.status!),
        statusClassName: `s1-connected-clients__status s1-connected-clients__status--${clientConnectionStatus.status}`,
    };
};
