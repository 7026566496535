import { XYCoord } from 'react-dnd';

import { IHcpsPos } from '../../interfaces/ucr';
import getHcpId from './getHcpId';

interface IProps {
    delta?: XYCoord;
    pos: {
        left: number;
        top: number;
    };
    snapWidth?: number;
    snapHeight?: number;
    hcpsPos?: IHcpsPos;
    isUnassigned?: boolean;
    leftOffset?: number;
}

export const snapAssignedToGrid = ({
    delta,
    pos,
    snapWidth,
    snapHeight,
    hcpsPos,
    isUnassigned,
    leftOffset,
}: IProps): [number, number] => {
    if (!delta) return [0, 0];

    let snappedX = Math.round(delta.x / (snapWidth || 1)) * (snapWidth || 1);
    let snappedY = Math.round(delta.y / (snapHeight || 1)) * (snapHeight || 1);

    if (hcpsPos) {
        const hcpId = getHcpId({ hcpsPos, top: pos.top + delta.y });

        snappedY = hcpsPos[hcpId]?.top - pos.top;
    }

    if (isUnassigned && leftOffset) {
        snappedX = -leftOffset;
    }

    return [snappedX, snappedY];
};

export const snapUnassignedToGrid = ({
    pos,
    snapWidth,
    snapHeight,
    hcpsPos,
}: IProps): [number, number] => {
    let snappedX = Math.round(pos.left / (snapWidth || 1)) * (snapWidth || 1);
    let snappedY = Math.round(pos.top / (snapHeight || 1)) * (snapHeight || 1);

    if (hcpsPos) {
        const hcpId = getHcpId({ hcpsPos, top: pos.top });

        snappedY = hcpsPos[hcpId]?.top;
    }

    return [snappedX, snappedY];
};
