import { RoutePatientInput } from '@doc-abode/data-models';

import { baseUrl, retryable } from '../baseApi';
import {
    IConfirmRoutesResponse,
    ICreateRoutesResponse,
    IRequestRoutesResponse,
    IVaccinationHcp,
} from './types';

/**
 * API reference:
 * https://doc-abode.gitlab.io/platform/
 */

interface IRequestRoutesBody {
    hcps: IVaccinationHcp[];
    patients: RoutePatientInput[];
}
export const requestRoutes = async (
    authToken: string,
    body: IRequestRoutesBody,
): Promise<IRequestRoutesResponse> => {
    const response = await fetch(`${baseUrl}/routes`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: authToken,
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(data));
    }

    return data;
};

export const createRoutes = (
    authToken: string,
    requestId: string,
    routeType: string,
): Promise<ICreateRoutesResponse> =>
    retryable(() =>
        fetch(`${baseUrl}/routes?requestId=${requestId}&routeType=${routeType}`, {
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const confirmRoutes = (
    authToken: string,
    body: { [key: string]: any },
): Promise<IConfirmRoutesResponse> =>
    retryable(() =>
        fetch(`${baseUrl}/routes/confirm`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: authToken,
            },
        }),
    );
/**
 * We POST data to the API to update a route.
 * The updated route is not returned via this call to the api, instead we get an id and a time in seconds to make another call to the
 * API to GET the updated route (checkUpdatedRoute)
 * to calculate a route first send the route info (waypoints?) as the payload of this initial request
 * the response is:   callbackInSeconds ,
 * quick glance at https://gitlab.com/doc-abode/platform/-/blob/develop/lambdas/routes/handlers/createUpdatedRoute.ts?ref_type=heads
 * suggests not.
 * {"requestId":"<UUID string>","callbackInSeconds":3}
 * this means that the route should be calculated and ready for you in 3 seconds with the id requestId
 *
 * you then send another request using checkUpdatedRoute  which will have the route in the response.
 * from the platform docs:
 * Requests a new itinerary based on additional patients being provided.
 * https://doc-abode.gitlab.io/platform/
 * Routes
 * API docs suggest only a response code 200 is returned but experiments with the api say otherwise *
 *
 */
export const updateRoute = async (
    authToken: string,
    itineraryId: string,
    body: { [key: string]: string },
) => {
    const response = await fetch(`${baseUrl}/routes/itineraries/${itineraryId}/update-route`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: authToken,
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(data));
    }

    return data;
};

/**
 * using the response from updateRoute:
 * {"requestId":"<UUID string>","callbackInSeconds":3}
 * we GET the route data
 *
 * API documentation says:
 * Gets the route calculation result, and if it's complete, saves it to the database.
 * I think this is the database on the backend somewhere (currently a noSql db)
 *
 */
export const checkUpdatedRoute = async (
    authToken: string,
    itineraryId: string,
    requestId: string,
    startTime: any,
) => {
    const response = await fetch(
        `${baseUrl}/routes/itineraries/${itineraryId}/update-route?requestId=${requestId}&selectedStartTime=${startTime}`,
        {
            method: 'GET',
            headers: {
                Authorization: authToken,
            },
        },
    );

    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(data));
    }

    return data;
};

export const confirmUpdatedRoute = async (
    authToken: string,
    itineraryId: string | undefined,
    body: { [key: string]: any },
) => {
    const response = await fetch(
        `${baseUrl}/routes/itineraries/${itineraryId}/confirm-updated-route`,
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: authToken,
            },
        },
    );

    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(data));
    }

    return data;
};

export const reassignRoute = async (
    authToken: string,
    itineraryId: string,
    body: { [key: string]: any },
) => {
    const response = await fetch(`${baseUrl}/routes/itineraries/${itineraryId}/reassign`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: authToken,
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(data));
    }

    return data;
};

export interface IRemoveVaccinationFromRouteNoRecalculationBody {
    vaccinationId: string;
    vaccinationRouteId: string;
}
/**
 *  POST /routes/remove-vaccinations-from-route-no-recalculation
 *
 */
export async function removeVaccinationFromRouteNoRecalculation(
    authToken: string,
    body: IRemoveVaccinationFromRouteNoRecalculationBody,
) {
    const response = await fetch(
        `${baseUrl}/routes/remove-vaccination-from-route-no-recalculation`,
        {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: authToken,
            },
        },
    );

    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(data));
    }

    return data;
}
