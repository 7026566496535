import { Patient } from '@doc-abode/data-models';
import { Form } from 'formik';
import { FC } from 'react';

import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../v2/components';
import { Select, TextArea } from '../../../../v2/form';
import { ReverseJobHcpAbortDetailsSegment } from './ReverseJobHcpAbortDetailsSegment';
import { useReverseJobViewModel } from './useReverseJobViewModel';

interface IProps {
    visit: Patient;
    loading: boolean;
    onClose: () => void;
}

const ReverseJobForm: FC<IProps> = ({ visit, loading, onClose }) => {
    const {
        isDoubleUp,
        displayLabelUser1,
        abortedDetailsHcp1,
        displayLabelUser2,
        abortedDetailsHcp2,
        reverseForOption,
        reasonsForReversing,
        isControllerReverseReasonDisabled,
        isConfirmReverseVisitDisabled,
    } = useReverseJobViewModel({ loading, job: visit });

    return (
        <Form>
            <div className="v2__dialog-block">
                <p>This job has been aborted with the following details</p>
                <ReverseJobHcpAbortDetailsSegment
                    abortedDetails={abortedDetailsHcp1}
                    userDisplayLabel={displayLabelUser1}
                />
                <ReverseJobHcpAbortDetailsSegment
                    abortedDetails={abortedDetailsHcp2}
                    userDisplayLabel={displayLabelUser2}
                />
            </div>
            <ConditionalDisplay show={isDoubleUp}>
                <div className="v2__dialog-block">
                    <Select
                        label="Reverse cancellation for"
                        name="reverseForOption"
                        defaultChecked={true}
                        options={reverseForOption}
                        required
                    />
                </div>
            </ConditionalDisplay>
            <div className="v2__dialog-block">
                <Select
                    label="Reason for reverting the cancellation"
                    name="controllerReverseReason"
                    options={reasonsForReversing}
                    disabled={isControllerReverseReasonDisabled}
                    required
                />
            </div>
            <div className="v2__dialog-block">
                <TextArea label="Notes on reversal" name="controllerReverseNotes" />
            </div>
            <div className="v2__dialog-buttons v2__dialog-buttons--right">
                <Button
                    name="Cancel"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    color={ButtonColors.GREY}
                    disabled={loading}
                    clickEvent={onClose}
                />
                <Button
                    name="Confirm reversal"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    type="submit"
                    color={ButtonColors.RED}
                    disabled={isConfirmReverseVisitDisabled}
                />
            </div>
        </Form>
    );
};

export default ReverseJobForm;
