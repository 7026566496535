import { AlertStatus, PatientAlert } from '@doc-abode/data-models';

/** Sifted patient alerts based on alert status */
interface FilteredPatientAlerts {
    resolved: PatientAlert[];
    unresolved: PatientAlert[];
    hasPatientAlerts: boolean;
    hasResolvedAlerts: boolean;
    hasUnresolvedAlerts: boolean;
}

/**
 * A simple helper function to sift patient alerts based on status
 *
 * @param patientAlerts Array of patient alerts
 * @param filterId Optional: ID to filter job IDs against
 * @returns Sifted patient alerts
 */
function filterPatientAlerts(
    patientAlerts: PatientAlert[] = [],
    filterId?: string,
): FilteredPatientAlerts {
    if (filterId) {
        patientAlerts = patientAlerts.filter((alert) => alert.jobId === filterId);
    }

    const unresolved: PatientAlert[] = [];
    const resolved: PatientAlert[] = [];

    for (const alert of patientAlerts) {
        if (alert.status === AlertStatus.RESOLVED) {
            resolved.push(alert);
        } else {
            unresolved.push(alert);
        }
    }

    return {
        resolved,
        unresolved,
        hasPatientAlerts: patientAlerts.length !== 0,
        hasResolvedAlerts: resolved.length !== 0,
        hasUnresolvedAlerts: unresolved.length !== 0,
    };
}

export default filterPatientAlerts;
