import { JobStatus } from '@doc-abode/data-models';

import { IChangeStatusFormFormikValues } from '../components/pages/ucr/forms/ChangeVisitStatus/changeVisitStatusTypes';
import { IArrivedDateTimeCanHaveAValue, TJobStatusOrNot } from './patientVisitHelpersTypes';

const finishedDateTimeCanHaveAValueWhenJobStatus: TJobStatusOrNot[] = [
    JobStatus.COMPLETED,
    JobStatus.HCP_ABORTED,
    JobStatus.CONTROLLER_ABORTED,
];
export function arrivedDateTimeCanHaveAValue({ jobStatus }: IArrivedDateTimeCanHaveAValue) {
    // same as finished plus arrived
    return [...finishedDateTimeCanHaveAValueWhenJobStatus, JobStatus.ARRIVED].includes(jobStatus);
}

export function madeCurrentDateTimeCanHaveAValue({ jobStatus }: IArrivedDateTimeCanHaveAValue) {
    // same as finished plus arrived
    return [
        ...finishedDateTimeCanHaveAValueWhenJobStatus,
        JobStatus.ARRIVED,
        JobStatus.CURRENT,
    ].includes(jobStatus);
}

export interface IFinishedDateTimeCanHaveAValue {
    jobStatus: TJobStatusOrNot;
}
export function finishedDateTimeCanHaveAValue({
    jobStatus,
}: IFinishedDateTimeCanHaveAValue): boolean {
    return finishedDateTimeCanHaveAValueWhenJobStatus.includes(jobStatus);
}

export interface IPostVisitNotesCanHaveAValue {
    values: IChangeStatusFormFormikValues;
    currentJobStatus: JobStatus | undefined;
}
export function postVisitNotesCanHaveAValue({
    values,
    currentJobStatus,
}: IPostVisitNotesCanHaveAValue) {
    return (
        values.newJobStatus === JobStatus.COMPLETED ||
        (values.newJobStatus === JobStatus.ARRIVED && currentJobStatus === JobStatus.COMPLETED)
    );
}

export interface IIsPostVisitNotesValid {
    postVisitNotesMandatory: boolean;
    jobStatus: TJobStatusOrNot;
    postVisitNotes: string | undefined | null;
}
export function isPostVisitNotesValid({
    postVisitNotesMandatory,
    jobStatus,
    postVisitNotes,
}: IIsPostVisitNotesValid) {
    return !(
        postVisitNotesMandatory &&
        jobStatus === JobStatus.COMPLETED &&
        !postVisitNotes?.trim()
    );
}
