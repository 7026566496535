import { AlertStatus, IAlert, PatientAlert, StaffAlert } from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import { useState } from 'react';

import { getSortedJobsForUserInAlertState } from '../../../../../../helpers/ucr/getSortedJobsForUserInAlertState';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import AppToaster from '../../../../../modules/helpers/Toaster';

interface IProps {
    alert: IAlert;
}

export const useCommonAlertViewModel = ({ alert }: IProps) => {
    const {
        RootStore: {
            ucrStore: { clearOpenedDialog, updateAlert, jobs, setAlertAcknowledged },
            configStore: { clientKeys },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [isAlertUpdating, setIsAlertUpdating] = useState(false);

    const isAcknowledged = alert?.acknowledged;

    const alertInfoTitle =
        (alert?.resolvedBy && 'Resolved') ||
        (alert?.acknowledged && 'Acknowledged') ||
        (!alert?.resolvedBy && 'Unresolved');

    const filteredJobs = getSortedJobsForUserInAlertState(jobs, alert?.createdBy);

    const onClickCancel = () => {
        if (isAlertUpdating) {
            return;
        }

        clearOpenedDialog();
    };

    const onClickAcknowledge = () => {
        setAlertAcknowledged(alert.alertId);
        onClickCancel();
    };

    const onClickUpdateAlert = async (values: FormikValues) => {
        const { controllerResolutionReason, controllerResolutionNotes } = values;
        if (!controllerResolutionReason) {
            AppToaster.show({
                message: 'A resolution type must be set',
                intent: 'warning',
            });
            return;
        }

        const input: Partial<StaffAlert | PatientAlert> = {
            status: AlertStatus.RESOLVED,
            category: alert.category,
            resolution: controllerResolutionReason,
            resolutionNotes: controllerResolutionNotes,
        };

        try {
            setIsAlertUpdating(true);
            await updateAlert(alert.alertId, input);
            setIsAlertUpdating(false);
            clearOpenedDialog();
            AppToaster.show({
                message: 'Alert successfully resolved',
                intent: 'success',
            });
        } catch (err) {
            console.error('Error resolving alert:', err);
            setIsAlertUpdating(false);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to resolve the alert',
                intent: 'danger',
            });
        }
    };

    return {
        alertInfoTitle,
        showResolutionForm: alert?.status === AlertStatus.OPEN,
        isAlertUpdating,
        isAcknowledged,
        alert,
        onClickUpdateAlert,
        onClickAcknowledge,
        onClickCancel,
        jobs: filteredJobs,
        showAlertOnMap: clientKeys.googleMaps.length > 0,
        mapClientKeys: clientKeys.googleMaps,
    };
};
