import { Callout, Icon, Tab, Tabs } from '@blueprintjs/core';
import { Itinerary as ItineraryType, Vaccination } from '@doc-abode/data-models';
import { FC } from 'react';

import { formatDisplayDateTime, metresToMiles } from '../../../../modules/helpers/formatData';
import { Itinerary } from './Itinerary/Itinerary';
import { MapWrapper } from './MapWrapper';
import { PatientSummaryList } from './PatientSummaryList';

interface Props {
    newItinerary: ItineraryType;
    oldEndTime: string;
    oldTotalTravelDistance: number;
    oldTotalTravelTime: number | string;
    newEndTime: string;
    newTotalTravelDistance: number;
    newTotalTravelTime: number | string;
    removedByUser?: Vaccination[];
    removedByOptimisation?: Vaccination[];
    unscheduledByCapacity?: Vaccination[];
    unscheduledByOptimisation?: Vaccination[];
    patientsToAdd?: Vaccination[];
    addPatients?: boolean;
    getAuthToken?: () => Promise<string>;
    apiKey?: string;
}

export const NewItinerarySection: FC<Props> = ({
    newItinerary,
    oldEndTime,
    oldTotalTravelDistance,
    oldTotalTravelTime,
    newEndTime,
    newTotalTravelDistance,
    newTotalTravelTime,
    removedByUser = [],
    removedByOptimisation = [],
    unscheduledByCapacity = [],
    unscheduledByOptimisation = [],
    patientsToAdd = [],
    addPatients = false,
    getAuthToken = () => Promise.resolve(''),
    apiKey = '',
}) => {
    return (
        <>
            <h2 className="h2">Proposed changes</h2>
            <dl className="info">
                <dt className="info__title">Ends (estimated)</dt>
                <dd className="info__definition" data-testid="end-time-change">
                    {formatDisplayDateTime(oldEndTime)}{' '}
                    <Icon className="info__change-arrow" size={14} icon="arrow-right" />{' '}
                    {formatDisplayDateTime(newEndTime)}
                </dd>
                <dt className="info__title">Total travel distance</dt>
                <dd className="info__definition" data-testid="travel-distance-change">
                    {metresToMiles(oldTotalTravelDistance)}{' '}
                    <Icon className="info__change-arrow" size={14} icon="arrow-right" />{' '}
                    {metresToMiles(newTotalTravelDistance)}
                </dd>
                <dt className="info__title">Total travel time</dt>
                <dd className="info__definition" data-testid="travel-time-change">
                    {oldTotalTravelTime}{' '}
                    <Icon className="info__change-arrow" size={14} icon="arrow-right" />{' '}
                    {newTotalTravelTime}
                </dd>
            </dl>
            {removedByUser.length > 0 && (
                <>
                    <Callout
                        intent="warning"
                        className="create-routes__callout"
                        data-testid="removed-patients-callout"
                    >
                        The following patient(s) will be removed from the route:
                        <PatientSummaryList patients={removedByUser} />
                    </Callout>
                    {!addPatients && (
                        <Callout
                            intent="primary"
                            className="create-routes__callout"
                            data-testid="removed-patients-note-callout"
                        >
                            <strong>Please note:</strong> the patient will not be removed from the
                            route until you click 'Accept changes' below. Once the patient has been
                            removed they will return to an 'Unassigned' status. If you wish to
                            withdraw the patient from the active pool you must do so separately.
                        </Callout>
                    )}
                </>
            )}
            {removedByOptimisation.length > 0 && (
                <>
                    <Callout
                        intent="warning"
                        className="create-routes__callout"
                        data-testid="removed-optimisation-patients-callout"
                    >
                        The following patients will be removed from the route due to a more optimal
                        route:
                        <PatientSummaryList patients={removedByOptimisation} />
                    </Callout>
                </>
            )}
            {unscheduledByCapacity.length > 0 && (
                <Callout
                    intent="warning"
                    className="create-routes__callout"
                    data-testid="uncheduled-capacity-patients-callout"
                >
                    The following patients could not be accommodated as the maximum number of doses
                    is insufficient:
                    <PatientSummaryList patients={unscheduledByCapacity} />
                </Callout>
            )}
            {unscheduledByOptimisation.length > 0 && (
                <Callout
                    intent="warning"
                    className="create-routes__callout"
                    data-testid="uncheduled-optimisation-patients-callout"
                >
                    The following patients could not be accommodated due to time reasons:
                    <PatientSummaryList patients={unscheduledByOptimisation} />
                </Callout>
            )}
            <Tabs id="RouteDetails" defaultSelectedTabId="itinerary" renderActiveTabPanelOnly large>
                <Tab
                    id="itinerary"
                    title="Itinerary"
                    data-testid="itinerary-tab"
                    panel={
                        <Itinerary
                            instructions={newItinerary.instructions}
                            newItems={patientsToAdd?.map((patient) => patient.id)}
                        />
                    }
                />
                <Tab
                    id="map"
                    title="Map"
                    data-testid="map-tab"
                    panel={
                        <MapWrapper
                            itineraries={[
                                {
                                    ...newItinerary,
                                    newItems: patientsToAdd?.map((patient) => patient.id),
                                },
                            ]}
                            apiKey={apiKey}
                        />
                    }
                />
            </Tabs>
        </>
    );
};
