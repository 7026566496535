import { Patient } from '@doc-abode/data-models';

// Used to interpret the date of visit as beginning or end of date
export enum DATEMODE {
    AS_START_OF_DAY = 'SOD',
    AS_END_OF_DAY = 'EOD',
    AS_IS = 'ASIS',
}

// Used to interpret the sort order
export enum SORTORDER {
    ASC = 'ASC',
    DESC = 'DESC',
}

/**
 * Function allows the sorting of jobs by their planned start time
 * The function will use the startDateTime for comparison where available
 * or fallback to the dateOfVisit.
 *
 * @job1 the first job used for comparison
 * @job2 the second job used for comparison
 * @dateMode (optional) influences interpretation of the dateOfVisit as-is, EOD or SOD
 **/
export function sortJobsByPlannedTime(
    job1: Patient | null,
    job2: Patient | null,
    dateMode?: DATEMODE | null,
    sortMode?: SORTORDER | null,
): number {
    let returnVal = null;

    // Default to as-is
    dateMode = dateMode === null ? DATEMODE.AS_IS : dateMode;

    const comparisonDate1 = getJobStartDateTime(job1, dateMode);
    const comparisonDate2 = getJobStartDateTime(job2, dateMode);

    if (comparisonDate1 && comparisonDate2) {
        // Only sort jobs if both jobs have a date
        if (comparisonDate1 < comparisonDate2) {
            returnVal = -1;
        } else if (comparisonDate1 > comparisonDate2) {
            returnVal = 1;
        }
    } else if (comparisonDate1) {
        // If there is no job2 or no date for job2, job1 is the more recent
        returnVal = 1;
    } else if (comparisonDate2) {
        // If there is no job1 or no date for job1, job2 is the more recent
        returnVal = -1;
    }

    // Return equality if none of the previous conditions were met
    // * Either no jobs at all
    // * Neither job has any dates
    // * Jobs have dates but they are equal
    if (returnVal === null) {
        returnVal = 0;
    }

    // Inverse the sort order when sorting oldest to newest
    if (sortMode === SORTORDER.ASC) {
        returnVal = returnVal * -1;
    }

    return returnVal;
}

/**
 * Allows users to get a comparable timestamp from a job.
 * Returns the startDateTime where available or falls back to the dateOfVisit
 *
 * @job the job from which to extract a comparable date
 * @dateMode (optional) influences interpretation of the dateOfVisit as-is, EOD or SOD
 * returns startDateTime or dateOfVisit or null if the input value was null
 **/
export function getJobStartDateTime(
    job: Patient | null,
    dateMode?: DATEMODE,
): number | null | Date {
    let returnDate = null;

    if (job) {
        if (job.startDateTime) {
            returnDate = new Date(job.startDateTime);
        } else if (job.dateOfVisit) {
            returnDate = formatDateForComparison(new Date(job.dateOfVisit), dateMode);
        }
    }
    return returnDate;
}

/**
 * Utility function to modify the time of a date
 *
 * @inputDate is the date to change
 * @dateMode (optional) allows the date to be treated as-is (default), EOD or SOD
 * returns a date/number or null if the input value was null
 **/
export function formatDateForComparison(
    inputDate: Date | null,
    dateMode?: DATEMODE,
): Date | number | null {
    let returnDate = null;

    if (inputDate) {
        if (DATEMODE.AS_END_OF_DAY === dateMode) {
            returnDate = new Date(inputDate).setHours(23, 59, 59, 999);
        } else if (DATEMODE.AS_START_OF_DAY === dateMode) {
            returnDate = new Date(inputDate).setHours(0, 0, 0, 0);
        } else {
            returnDate = new Date(inputDate);
        }
    }
    return returnDate;
}
