import { FormGroup, TextArea, TextAreaProps } from '@blueprintjs/core';
import { useField } from 'formik';

interface ITextAreaInput extends TextAreaProps {
    name: string;
    label?: string;
    helperText?: string;
    labelInfo?: string;
}

const TextInput = ({ label, helperText, labelInfo, ...props }: ITextAreaInput) => {
    const [field, meta] = useField(props);

    return (
        <>
            <FormGroup
                label={label}
                labelFor={props.name}
                helperText={helperText}
                labelInfo={labelInfo}
            >
                <TextArea {...field} {...props} id={props.name} fill />
                {meta.touched && meta.error ? (
                    <div className="form-error-message">{meta.error}</div>
                ) : null}
            </FormGroup>
        </>
    );
};

export default TextInput;
