import { FC } from 'react';

import { IconAdd } from '../../../../../helpers/ucr/icons';
import { Button, ButtonElems, ButtonSizes } from '../../../../v2/components';

interface ICommonButtonInterface {
    clickEvent(): void;
    className?: string;
    btnStyle?: string;
    disabled?: boolean;
}

export const PullFromReferralBtn: FC<ICommonButtonInterface> = ({
    clickEvent,
    className = 'v2__form-group--pos-2-2 v2__form-group--align-right',
}) => (
    <div className={className}>
        <Button
            className="v2__form-submit-button"
            name="Pull from referral"
            size={ButtonSizes.SMALL}
            Icon={IconAdd}
            clickEvent={clickEvent}
            elem={ButtonElems.BUTTON}
        />
    </div>
);

export const LookupPatientlBtn: FC<ICommonButtonInterface> = ({
    clickEvent,
    className = 'v2__form-group--pos-2-2 v2__form-group--align-right',
    btnStyle,
    disabled,
}) => (
    <div className={className}>
        <Button
            className={`v2__form-submit-button ${btnStyle}`}
            name="Lookup Patient"
            ariaLabel="Lookup patient"
            size={ButtonSizes.SMALL}
            clickEvent={clickEvent}
            elem={ButtonElems.BUTTON}
            disabled={disabled}
        />
    </div>
);
