import { Checkbox } from '@blueprintjs/core';

import { ViewToShow } from '../../../../../constants/mainConst';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { MultiSelectComponent } from '../../../../v2/form/MultiSelect/MultiSelect';
import {
    availabilityOptions,
    careComplexityOptions,
    carRequiredOptions,
    genderOptions,
    jobStatusOptions,
    pathwayOptions,
    referrerOptions,
    serviceOptions,
    warningOptions,
} from './UcrFilterOptions';
import { useUcrFilterLogic } from './useUcrFilterLogic';

enum FilterGroup {
    JOB = 'Job Filters',
    HCP = 'HCP Filters',
}

export enum FilterName {
    JOB_STATUS = 'jobStatus',
    PATHWAY = 'referralPathway',
    SERVICE = 'disposition',
    REFERRER = 'referrer',
    CARE_COMPLEXITY = 'careComplexity',
    CAR_REQUIRED = 'carRequired',
    WARNINGS = 'warning',
    HCP_AVAILABILITY = 'availability',
    HCP_TYPE = 'hcpType',
    HCP_BAND = 'band',
    HCP_GENDER = 'gender',
    SHOW_ABORTED = 'showAborted',
    SHOW_UNASSIGNED = 'showUnassigned',
}

type FilterType = {
    name: string;
    group: FilterGroup;
    isActive: boolean;
    render: () => JSX.Element;
};

export type FilterGroupType = {
    name: FilterGroup;
    tooltip: string;
    filters: FilterType[];
};

function getActiveFiltersForGroup(filters: FilterType[], groupName: FilterGroup) {
    return filters.filter((filter) => filter.isActive && filter.group === groupName);
}

export function useUcrFilterPanelViewModel() {
    const {
        RootStore: {
            configStore: { bands: bandOptions, pathways },
            ucrStore: { viewToShow, hcpTypes: hcpTypeOptions },
            lovsStore,
        },
    } = useStores<{ RootStore: RootStore }>();

    const {
        jobFilters,
        hcpFilters,
        showAbortedJobs,
        setShowAbortedJobs,
        showUnassignedJobs,
        setShowUnassignedJobs,
        jobTransportOption,
        onJobTransportChange,
        onJobFilterChange,
        onHcpFilterChange,
        onClearAllFilters,
    } = useUcrFilterLogic();

    const allFiltersInPriorityOrder: FilterType[] = [
        {
            name: FilterName.PATHWAY,
            group: FilterGroup.JOB,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST].includes(viewToShow),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.PATHWAY}
                    className="ucr__filters-panel-filter"
                    optionsSelected={jobFilters.referralPathway}
                    placeholder="Pathway"
                    options={pathwayOptions(pathways)}
                    optionClassName="v2__form-multi-select-option"
                    onItemSelect={(option) => onJobFilterChange(FilterName.PATHWAY, option)}
                    onRemove={(option) => onJobFilterChange(FilterName.PATHWAY, option)}
                />
            ),
        },
        {
            name: FilterName.SERVICE,
            group: FilterGroup.JOB,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST].includes(viewToShow),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.SERVICE}
                    className="ucr__filters-panel-filter"
                    optionsSelected={jobFilters.disposition}
                    placeholder="Service"
                    options={serviceOptions(pathways)}
                    optionClassName="v2__form-multi-select-option v2__form-multi-select-option--big"
                    onItemSelect={(option) => onJobFilterChange(FilterName.SERVICE, option)}
                    onRemove={(option) => onJobFilterChange(FilterName.SERVICE, option)}
                />
            ),
        },
        {
            name: FilterName.REFERRER,
            group: FilterGroup.JOB,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST].includes(viewToShow),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.REFERRER}
                    className="ucr__filters-panel-filter"
                    optionsSelected={jobFilters.referrer}
                    placeholder="Referrer"
                    options={referrerOptions(lovsStore.referrer)}
                    optionClassName="v2__form-multi-select-option v2__form-multi-select-option--big"
                    onItemSelect={(option) => onJobFilterChange(FilterName.REFERRER, option)}
                    onRemove={(option) => onJobFilterChange(FilterName.REFERRER, option)}
                />
            ),
        },
        {
            name: FilterName.JOB_STATUS,
            group: FilterGroup.JOB,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST, ViewToShow.MAP].includes(
                viewToShow,
            ),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.JOB_STATUS}
                    className="ucr__filters-panel-filter"
                    optionsSelected={jobFilters.jobStatus}
                    placeholder="Status"
                    options={jobStatusOptions}
                    optionClassName="v2__form-multi-select-option"
                    onItemSelect={(option) => onJobFilterChange(FilterName.JOB_STATUS, option)}
                    onRemove={(option) => onJobFilterChange(FilterName.JOB_STATUS, option)}
                />
            ),
        },
        {
            name: FilterName.CARE_COMPLEXITY,
            group: FilterGroup.JOB,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST].includes(viewToShow),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.CARE_COMPLEXITY}
                    className="ucr__filters-panel-filter"
                    optionsSelected={jobFilters.careComplexity}
                    placeholder="Care complexity"
                    options={careComplexityOptions}
                    optionClassName="v2__form-multi-select-option"
                    onItemSelect={(option) =>
                        onJobFilterChange(FilterName.CARE_COMPLEXITY, option, true)
                    }
                    onRemove={(option) => onJobFilterChange(FilterName.CARE_COMPLEXITY, option)}
                />
            ),
        },
        {
            name: FilterName.CAR_REQUIRED,
            group: FilterGroup.JOB,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST].includes(viewToShow),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.CAR_REQUIRED}
                    className="ucr__filters-panel-filter"
                    optionsSelected={jobTransportOption}
                    placeholder="Transport required"
                    options={carRequiredOptions}
                    optionClassName="v2__form-multi-select-option"
                    onItemSelect={(option) => onJobTransportChange(option)}
                    onRemove={(option) => onJobTransportChange(option, true)}
                />
            ),
        },
        {
            name: FilterName.WARNINGS,
            group: FilterGroup.JOB,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST].includes(viewToShow),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.WARNINGS}
                    className={'ucr__filters-panel-filter'}
                    optionsSelected={jobFilters.warning}
                    placeholder="Warning"
                    options={warningOptions}
                    optionClassName="v2__form-multi-select-option"
                    onItemSelect={(option) => onJobFilterChange(FilterName.WARNINGS, option, true)}
                    onRemove={(option) => onJobFilterChange(FilterName.WARNINGS, option)}
                />
            ),
        },
        {
            name: FilterName.SHOW_UNASSIGNED,
            group: FilterGroup.JOB,
            isActive: [ViewToShow.MAP].includes(viewToShow),
            render: () => (
                <Checkbox
                    label="Show unassigned visits"
                    checked={showUnassignedJobs}
                    className="shift-pattern-select-all-checkbox"
                    onChange={(e) => {
                        if (setShowUnassignedJobs) {
                            setShowUnassignedJobs(!showUnassignedJobs);
                        }
                    }}
                    onClick={(event) => event.stopPropagation()}
                />
            ),
        },
        {
            name: FilterName.SHOW_ABORTED,
            group: FilterGroup.JOB,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST, ViewToShow.MAP].includes(
                viewToShow,
            ),
            render: () => (
                <Checkbox
                    label="Show aborted visits"
                    checked={showAbortedJobs}
                    className="shift-pattern-select-all-checkbox"
                    onChange={(event: any) => setShowAbortedJobs(!showAbortedJobs)}
                    onClick={(event: any) => event.stopPropagation()}
                />
            ),
        },
        {
            name: FilterName.HCP_AVAILABILITY,
            group: FilterGroup.HCP,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST].includes(viewToShow),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.HCP_AVAILABILITY}
                    className="ucr__filters-panel-filter"
                    optionsSelected={hcpFilters.availability}
                    placeholder="Availability"
                    options={availabilityOptions}
                    optionClassName="v2__form-multi-select-option"
                    onItemSelect={(option) =>
                        onHcpFilterChange(FilterName.HCP_AVAILABILITY, option)
                    }
                    onRemove={(option) => onHcpFilterChange(FilterName.HCP_AVAILABILITY, option)}
                />
            ),
        },
        {
            name: FilterName.HCP_TYPE,
            group: FilterGroup.HCP,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST, ViewToShow.MAP].includes(
                viewToShow,
            ),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.HCP_TYPE}
                    className="ucr__filters-panel-filter"
                    optionsSelected={hcpFilters.hcpType}
                    placeholder="HCP Type"
                    options={hcpTypeOptions}
                    optionClassName="v2__form-multi-select-option"
                    onItemSelect={(option) => onHcpFilterChange(FilterName.HCP_TYPE, option)}
                    onRemove={(option) => onHcpFilterChange(FilterName.HCP_TYPE, option)}
                />
            ),
        },
        {
            name: FilterName.HCP_BAND,
            group: FilterGroup.HCP,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST, ViewToShow.MAP].includes(
                viewToShow,
            ),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.HCP_BAND}
                    className="ucr__filters-panel-filter"
                    optionsSelected={hcpFilters.band}
                    placeholder="Band"
                    options={bandOptions}
                    optionClassName="v2__form-multi-select-option v2__form-multi-select-option--big"
                    onItemSelect={(option) => onHcpFilterChange(FilterName.HCP_BAND, option)}
                    onRemove={(option) => onHcpFilterChange(FilterName.HCP_BAND, option)}
                />
            ),
        },
        {
            name: FilterName.HCP_GENDER,
            group: FilterGroup.HCP,
            isActive: [ViewToShow.TIMELINE, ViewToShow.VISITS_LIST].includes(viewToShow),
            render: () => (
                <MultiSelectComponent
                    name={FilterName.HCP_GENDER}
                    className="ucr__filters-panel-filter"
                    optionsSelected={hcpFilters.gender}
                    placeholder="Gender"
                    options={genderOptions(lovsStore.gender)}
                    optionClassName="v2__form-multi-select-option v2__form-multi-select-option--big"
                    onItemSelect={(option) => onHcpFilterChange(FilterName.HCP_GENDER, option)}
                    onRemove={(option) => onHcpFilterChange(FilterName.HCP_GENDER, option)}
                />
            ),
        },
    ];

    const jobFilterGroup: FilterGroupType = {
        name: FilterGroup.JOB,
        tooltip: 'These filters restrict the visible jobs based on their attributes',
        filters: getActiveFiltersForGroup(allFiltersInPriorityOrder, FilterGroup.JOB),
    };

    const hcpFilterGroup: FilterGroupType = {
        name: FilterGroup.HCP,
        tooltip:
            'These filters restrict the visible HCPs based on their demographics and attributes',
        filters: getActiveFiltersForGroup(allFiltersInPriorityOrder, FilterGroup.HCP),
    };

    return {
        filterGroups: [jobFilterGroup, hcpFilterGroup],
        onClearAllFilters,
    };
}
