import { Job } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import { FC, useMemo } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import {
    getHcpId,
    getMomentDuration,
    getOverlappingJobs,
    snapAssignedToGrid,
} from '../../../../../helpers/ucr';
import useStores from '../../../../../hook/useStores';
import { IHcpsPos, IJobPos, IJobsPos } from '../../../../../interfaces/ucr';
import RootStore from '../../../../../stores/RootStore';
import { EnumJobContainer } from './Job/JobTypes';
import JobPreview from './JobPreview';

interface IProps {
    isSnapToGrid: boolean;
}

interface IGetNewPos {
    calendarEl?: HTMLDivElement;
    visitsEl?: HTMLDivElement;
    item?: { pos: IJobPos; job: Job; container: 'visits' | 'calendar' };
    initialOffset?: XYCoord;
    currentOffset?: XYCoord;
    isSnapToGrid: boolean;
    hcpsOffset: number;
    hcpsPos: IHcpsPos;
    jobsPos: IJobsPos;
    cellWidth: number;
    hourStart: number;
    hcpsWidth: number;
}

const getNewPos = ({
    calendarEl,
    visitsEl,
    item,
    initialOffset,
    currentOffset,
    isSnapToGrid,
    hcpsOffset,
    hcpsPos,
    jobsPos,
    hourStart,
    cellWidth,
    hcpsWidth,
}: IGetNewPos): IJobPos | undefined => {
    if (!item || !initialOffset || !currentOffset || !calendarEl || !visitsEl) return;

    const isUnassigned = item.container === 'visits';

    const hcpsTop = calendarEl.getBoundingClientRect().top + hcpsOffset + window.scrollY;
    const jobsTop = visitsEl.getBoundingClientRect().top + window.scrollY;

    const containersDiff = isUnassigned ? Math.abs(hcpsTop - jobsTop) - calendarEl.scrollTop : 0;

    const left = !isUnassigned ? item.pos.left : 0;
    const top = !isUnassigned ? item.pos.top : initialOffset.y - jobsTop;

    let { x, y } = currentOffset;
    let leftOffset;
    const actualDuration = moment.duration(
        moment(item.job?.finishedDateTime).diff(item.job.arrivedDateTime),
    );
    const duration: Moment = getMomentDuration(item.job.duration);
    const width =
        (actualDuration.hours() + actualDuration.minutes() / 60) * cellWidth ||
        (duration.hours() + duration.minutes() / 60) * cellWidth;
    // Calculate left fixed offset
    if (item.job.startDateTime) {
        const jobDateStart: Moment = moment(item.job.arrivedDateTime || item.job.startDateTime);
        const jobHourStart: number = jobDateStart.hours() + jobDateStart.minutes() / 60;

        const hcpsLeft = calendarEl.getBoundingClientRect().left;
        const jobsLeft = visitsEl.getBoundingClientRect().left;
        const baseLeftOrigin = jobsLeft - hcpsLeft + 20;
        const leftOriginOffest = (jobHourStart - hourStart) * cellWidth;

        leftOffset = baseLeftOrigin - leftOriginOffest - hcpsWidth + calendarEl.scrollLeft;
    }

    if (isSnapToGrid) {
        x -= initialOffset.x;
        y -= initialOffset.y + containersDiff;
        [x, y] = snapAssignedToGrid({
            delta: { x, y },
            pos: { left, top },
            hcpsPos,
            isUnassigned,
            leftOffset,
        });
        x += initialOffset.x;
        y += initialOffset.y + containersDiff;
    }

    const hcpId = getHcpId({
        hcpsPos,
        top: top + y - initialOffset.y - containersDiff,
    });

    const overlapping = getOverlappingJobs({
        hcpId,
        jobsPos,
        jobPos: item.pos,
        curLeft: left + x - initialOffset.x,
    });

    return {
        ...item.pos,
        hcpId,
        top: y - 9, // minus 9 is a magic fix to make the job align to swimlane
        left: x,
        height: hcpsPos[hcpId] ? hcpsPos[hcpId].height : 0,
        width,
        overlapping,
    };
};

const DragLayer: FC<IProps> = ({ isSnapToGrid }) => {
    const {
        RootStore: {
            ucrStore: {
                hcpsOffset,
                hcpsPos,
                jobsPos,
                calendarEl,
                visitsEl,
                cellWidth,
                hourStart,
                hcpsWidth,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer(
        (monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset() || undefined,
            currentOffset: monitor.getSourceClientOffset() || undefined,
            isDragging: monitor.isDragging(),
        }),
    );

    const newPos = getNewPos({
        calendarEl,
        visitsEl,
        item,
        initialOffset,
        currentOffset,
        isSnapToGrid,
        hcpsOffset,
        hcpsPos,
        jobsPos,
        cellWidth,
        hourStart,
        hcpsWidth,
    });

    const jobItem = useMemo(
        () =>
            itemType === 'box' ? (
                <JobPreview
                    pos={newPos || item.pos}
                    job={item.job}
                    container={EnumJobContainer.CALENDAR}
                />
            ) : null,
        [item, itemType, newPos],
    );

    return isDragging ? <div className="ucr__calendar-draglayer">{jobItem}</div> : null;
};
export default observer(DragLayer);
