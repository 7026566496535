import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { GOOGLE_API_LIBRARIES } from '../../../constants/googleAPIs';
import { Directions } from './Directions';
import { VaccinationRoute } from './types';

export const colors = ['#1c7cd5', '#29a634', '#d9534f', '#d9822b', '#800080'];

const markers = {
    waypoint:
        'M12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z',
    origin: 'M7.05025 4.05025C8.36301 2.7375 10.1435 2 12 2C13.8565 2 15.637 2.7375 16.9497 4.05025C18.2625 5.36301 19 7.14348 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9C5 7.14348 5.7375 5.36301 7.05025 4.05025ZM16.2 6.94118H13.512L13.32 6H9V14H9.96V10.7059H12.648L12.84 11.6471H16.2V6.94118ZM14.28 10.7059H13.32V9.76471H14.28V10.7059ZM14.28 8.82353H15.24V9.76471H14.28V8.82353ZM14.28 8.82353V7.88235H13.32V8.82353L12.84 7.88235V6.94118H11.88V7.88235H10.92V6.94118H9.96V7.88235H10.92V8.82353H9.96V9.76471H10.92V8.82353H11.88V9.76471H12.84V8.82353L13.32 9.76471V8.82353H14.28ZM12.84 8.82353H11.88V7.88235H12.84V8.82353Z',
};

export enum markerType {
    waypoint = 'waypoint',
    origin = 'origin',
}

export const icon = (fillColor: string, type: markerType = markerType.waypoint) => ({
    path: markers[type],
    fillColor,
    fillOpacity: 1,
    anchor: new window.google.maps.Point(12, 21),
    strokeWeight: 0,
    scale: 2,
    labelOrigin: new window.google.maps.Point(12, 10),
});

interface MapInterface {
    apiKey: string;
    // this has newItems being mapped which isn't apart of itinernaries. making it hard to map.
    itineraries: Record<string, any>[];
    routes?: VaccinationRoute[];
    focusedItineraryId?: string | null;
}

export default function MapView({
    itineraries = [],
    routes = [],
    focusedItineraryId = null,
    apiKey,
}: MapInterface) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries: GOOGLE_API_LIBRARIES,
    });

    const getLatLng = (lat: string, lng: string, name: string) => ({
        location: new window.google.maps.LatLng({ lat: Number(lat), lng: Number(lng) }),
        id: name,
    });

    return (
        <div className="map-container">
            {isLoaded && (
                <GoogleMap id="routes" zoom={2}>
                    {itineraries.map(({ instructions, itineraryId, newItems }, index) => (
                        <Directions
                            instructions={instructions}
                            isLoaded={isLoaded}
                            getLatLng={getLatLng}
                            newItems={newItems}
                            hidden={Boolean(
                                focusedItineraryId && focusedItineraryId !== itineraryId,
                            )}
                            key={itineraryId}
                            index={index}
                        />
                    ))}
                    {routes.map(({ itineraryId, id }, index) => (
                        <Directions
                            jobId={id}
                            isLoaded={isLoaded}
                            getLatLng={getLatLng}
                            hidden={Boolean(
                                focusedItineraryId && focusedItineraryId !== itineraryId,
                            )}
                            key={itineraryId}
                            index={itineraries.length + index}
                        />
                    ))}
                </GoogleMap>
            )}
        </div>
    );
}
