import { FC, ReactNode } from 'react';

interface IProps {
    title?: string;
    children: ReactNode;
}

const Info: FC<IProps> = ({ title, children }) => (
    <>
        {title && <h3 className="v2-info__heading">{title}</h3>}
        <dl className="v2-info">{children}</dl>
    </>
);

export default Info;
