import {
    ShinglesVaccine,
    Vaccination,
    VaccinationCategory,
    VaccineManufacturer,
} from '@doc-abode/data-models';

export default function isMultiDoseVaccine(
    vaccination:
        | Vaccination
        | { vaccinationCategory: VaccinationCategory; vaccineManufacturer: VaccineManufacturer },
): boolean {
    return (
        vaccination.vaccinationCategory === VaccinationCategory.COVID_19 ||
        (vaccination.vaccinationCategory === VaccinationCategory.SHINGLES &&
            vaccination.vaccineManufacturer === ShinglesVaccine.SHINGRIX)
    );
}
