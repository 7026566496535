import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ViewToShow } from '../../../../../constants/mainConst';
import { getHcp } from '../../../../../helpers/ucr/getHcp';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import HCP from './HCP';

const HCPs: FC = () => {
    const hcpsRef = useRef<HTMLDivElement>(null);
    const [numberAllHcpsPos, setNumberAllHcpsPos] = useState<number>(0);
    const [initalized, setInitalized] = useState(false);

    const {
        RootStore: {
            userStore: { user },
            usersStore: { users },
            ucrStore: { hcps, setHcpsOffset, viewToShow, setPinnedStaff },
            mapStore: { hcps: mapHcps, hcpsBreakdownInfo },
        },
    } = useStores<{ RootStore: RootStore }>();

    const updateAllHcpsPos = useCallback(() => {
        setNumberAllHcpsPos((numb) => numb + 1);
    }, []);

    const isMapView = viewToShow === ViewToShow.MAP;

    useEffect(() => {
        if (!hcpsRef.current) return;

        setHcpsOffset(hcpsRef.current.offsetTop);
    }, [hcpsRef, setHcpsOffset, users]);

    const hcpsList = useMemo(
        () => (isMapView ? mapHcps.filter((hcp) => hcpsBreakdownInfo[hcp.userId]) : hcps),
        [hcps, hcpsBreakdownInfo, isMapView, mapHcps],
    );

    // TODO - rewrite the entire logic around staff pins; currently needed because of the way we filter, sort and then re-render the HCP panel
    if (!initalized) {
        const userWithPins = getHcp(users, user.username);
        const userPins = userWithPins?.staffPins || {};
        setPinnedStaff(userPins);
        setInitalized(true);
    }

    return (
        <div ref={hcpsRef} className="ucr__calendar-hcps">
            {hcpsList.map((hcp) => (
                <HCP
                    key={hcp.userId}
                    hcp={hcp}
                    numberAllHcpsPos={numberAllHcpsPos}
                    updateAllHcpsPos={updateAllHcpsPos}
                />
            ))}
        </div>
    );
};

export default observer(HCPs);
