import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

import packageInfo from '../../package.json';
import { host } from '../api/baseApi';

const DEFAULT_SAMPLE_RATE = 1.0;

const getSampleRate = (envVar?: string) => {
    const parsedSampleRate = parseFloat(envVar ?? '');
    return Number.isNaN(parsedSampleRate) ? DEFAULT_SAMPLE_RATE : parsedSampleRate;
};

export const initSentry = () => {
    const {
        NODE_ENV,
        REACT_APP_ENVIRONMENT,
        REACT_APP_SENTRY_DSN,
        REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
        REACT_APP_SENTRY_PROFILES_SAMPLE_RATE,
        REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
        REACT_APP_SENTRY_REPLAYS_ERROR_SAMPLE_RATE,
    } = process.env;

    if (!REACT_APP_SENTRY_DSN || NODE_ENV !== 'production') {
        return;
    }

    const history = createBrowserHistory();

    const tracesSampleRate = getSampleRate(REACT_APP_SENTRY_TRACES_SAMPLE_RATE);
    const profilesSampleRate = getSampleRate(REACT_APP_SENTRY_PROFILES_SAMPLE_RATE);
    const replaysSessionSampleRate = getSampleRate(REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE);
    const replaysOnErrorSampleRate = getSampleRate(REACT_APP_SENTRY_REPLAYS_ERROR_SAMPLE_RATE);

    console.debug('Sentry configuration:', {
        tracesSampleRate,
        profilesSampleRate,
        replaysSessionSampleRate,
        replaysOnErrorSampleRate,
    });

    Sentry.init({
        environment: REACT_APP_ENVIRONMENT,
        release: packageInfo.version,
        dsn: REACT_APP_SENTRY_DSN,
        integrations: [
            Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
            Sentry.reactRouterV5BrowserTracingIntegration({ history }),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracePropagationTargets: [host],
        tracesSampleRate,
        profilesSampleRate,
        replaysSessionSampleRate,
        replaysOnErrorSampleRate,
    });
};
