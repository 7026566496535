import _ from 'lodash';
import moment from 'moment';

import { dateFormat } from '../../../constants/patientsConst';

export const getAPIReadyPatients = (updatedDetails) => {
    // Don't checks the required fields, because they should not be empty
    // List of required fields firstName
    // consented
    // lastName
    // nhsNumber
    // dateOfBirth
    // town
    // postCode
    // gender
    // hubId
    updatedDetails.additionalContactNumbers = _.isEmpty(updatedDetails.additionalContactNumbers)
        ? []
        : updatedDetails.additionalContactNumbers
              .split(',')
              .map((contactNumber) => contactNumber.trim());
    updatedDetails.dateOfDose1 = _.isEmpty(updatedDetails.dateOfDose1)
        ? null
        : moment.utc(updatedDetails.dateOfDose1, dateFormat).format();
    updatedDetails.dateOfDose2 = _.isEmpty(updatedDetails.dateOfDose2)
        ? null
        : moment.utc(updatedDetails.dateOfDose2, dateFormat).format();
    updatedDetails.dateOfPreviousDose = _.isEmpty(updatedDetails.dateOfPreviousDose)
        ? null
        : moment.utc(updatedDetails.dateOfPreviousDose, dateFormat).format();
    updatedDetails.dateOfBirth = _.isEmpty(updatedDetails.dateOfBirth)
        ? null
        : moment.utc(updatedDetails.dateOfBirth, dateFormat).format();
    updatedDetails.notes = _.isEmpty(updatedDetails.notes) ? '' : updatedDetails.notes;
    updatedDetails.addressLine2 = _.isEmpty(updatedDetails.addressLine2)
        ? ''
        : updatedDetails.addressLine2;
    updatedDetails.manufacturerOfDose1 = _.isEmpty(updatedDetails.manufacturerOfDose1)
        ? ''
        : updatedDetails.manufacturerOfDose1;
    updatedDetails.manufacturerOfDose2 = _.isEmpty(updatedDetails.manufacturerOfDose2)
        ? ''
        : updatedDetails.manufacturerOfDose2;
    updatedDetails.manufacturerOfPreviousDose = _.isEmpty(updatedDetails.manufacturerOfPreviousDose)
        ? ''
        : updatedDetails.manufacturerOfPreviousDose;
    updatedDetails.contactNumber = _.isEmpty(updatedDetails.contactNumber)
        ? ''
        : updatedDetails.contactNumber;
    updatedDetails.availableFrom = _.isEmpty(updatedDetails.availableFrom)
        ? ''
        : updatedDetails.availableFrom;
    updatedDetails.availableTo = _.isEmpty(updatedDetails.availableTo)
        ? ''
        : updatedDetails.availableTo;
    updatedDetails.doseNumber = _.isEmpty(updatedDetails.doseNumber)
        ? null
        : parseInt(updatedDetails.doseNumber, 10);

    // Future task to remove; solely retaining as BE still expects data
    updatedDetails.dateOfDose3 = undefined;
    updatedDetails.dateOfDose3Primary = undefined;
    updatedDetails.dateOfDose4 = undefined;
    updatedDetails.manufacturerOfDose3 = undefined;
    updatedDetails.manufacturerOfDose3Primary = undefined;
    updatedDetails.manufacturerOfDose4 = undefined;

    delete updatedDetails.itineraryId;
    return updatedDetails;
};
