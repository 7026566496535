import { JobStatus, Patient } from '@doc-abode/data-models';
import moment from 'moment';

import { getActiveJobs } from '../../../../../api/jobsApi';
import { getDateEndTime } from '../../../../../helpers';
import {
    arrivedDateTimeCanHaveAValue,
    finishedDateTimeCanHaveAValue,
    isPostVisitNotesValid,
    madeCurrentDateTimeCanHaveAValue,
} from '../../../../../models/patientVisitHelpers';
import { TJobStatusOrNot } from '../../../../../models/patientVisitHelpersTypes';
import { TFocusedUser } from '../../../../../stores/UCRStore';
import { IChangeStatusFormFormikValues } from './changeVisitStatusTypes';

// todo when time allows: NOTE: some of these helper function are just the code extracted from ChangeVisitStatusForm
// to isolate the code to make it easier to test
// some have not been refactored so there is still duplication of code logic etc.
const jobStatusAcceptedLabel = Patient.getFriendlyVisitStatus(JobStatus.ACCEPTED).toUpperCase();

export interface IGetIsUserX {
    isDoubleUp: boolean;
    focusedUser: TFocusedUser;
}

/**
 * I think getIsHcp1 is the "more correct" name for this function.
 * @param isDoubleUp
 * @param pressedRadioButtonValue
 */
export function getIsUser1({ isDoubleUp, focusedUser }: IGetIsUserX): boolean {
    // why !== 'user2'  why not == 'user1' could be that sometimes pressedRadioButtonValue is not set
    // but if that's the case why was the user2 logic also a !== (the getUser2 function is now removed, I decided its not needed)
    return !isDoubleUp || (isDoubleUp && focusedUser !== 'user2');
}

export interface IPropertyNames {
    jobStatus: 'buddyJobStatus' | 'jobStatus';
    arrivedDateTime: 'buddyArrivedDateTime' | 'arrivedDateTime';
    finishedDateTime: 'finishedDateTime' | 'buddyFinishedDateTime';
    madeCurrentDateTime: 'madeCurrentDateTime' | 'buddyMadeCurrentDateTime';
    postVisitNotes: 'postVisitNotes' | 'postVisitNotesBuddy';
}
const propertyNamesBuddy: IPropertyNames = {
    jobStatus: 'buddyJobStatus',
    arrivedDateTime: 'buddyArrivedDateTime',
    finishedDateTime: 'buddyFinishedDateTime',
    madeCurrentDateTime: 'buddyMadeCurrentDateTime',
    postVisitNotes: 'postVisitNotesBuddy',
};
const propertyNames: IPropertyNames = {
    jobStatus: 'jobStatus',
    arrivedDateTime: 'arrivedDateTime',
    finishedDateTime: 'finishedDateTime',
    madeCurrentDateTime: 'madeCurrentDateTime',
    postVisitNotes: 'postVisitNotes',
};
export interface IGetPropertyNames {
    isHcp1: boolean;
}
export function getPropertyNames({ isHcp1 }: IGetPropertyNames): IPropertyNames {
    if (isHcp1) {
        return propertyNames;
    }

    return propertyNamesBuddy;
}

export interface IHcpResult {
    current: number;
    arrived: number;
    pass: boolean;
}

export interface IHasAnotherJobInProgress {
    hcpResults: IHcpResult;
    currentJobStatus: JobStatus | undefined;
}

export function userHasAnotherJobInProgress({
    hcpResults,
    currentJobStatus,
}: IHasAnotherJobInProgress) {
    return (
        (hcpResults.current > 0 || hcpResults.arrived > 0) &&
        ![JobStatus.CURRENT, JobStatus.ARRIVED].includes(currentJobStatus as JobStatus)
    );
}
export interface IFocusedUserHasAnotherJobInProgress {
    isHcp1: boolean;
    hcpResults: IHcpResult;
    currentJobStatus: JobStatus | undefined;
    buddyResults: IHcpResult;
}
export function focusedUserHasAnotherJobInProgress({
    isHcp1,
    hcpResults,
    currentJobStatus,
    buddyResults,
}: IFocusedUserHasAnotherJobInProgress): boolean {
    let hcpResultsToUse = hcpResults;
    if (!isHcp1) {
        hcpResultsToUse = buddyResults;
    }
    return userHasAnotherJobInProgress({ hcpResults: hcpResultsToUse, currentJobStatus });
}

export interface IValidJobStatusTransitions {
    value: JobStatus;
    label: string | JobStatus;
}

export interface IGetValidJobStatusTransitionsForUser {
    currentJobStatus: JobStatus;
    hasAnotherJobInProgress: boolean;
}
export function getValidJobStatusTransitions({
    currentJobStatus,
    hasAnotherJobInProgress,
}: IGetValidJobStatusTransitionsForUser): IValidJobStatusTransitions[] {
    let validJobStatusTransitions: IValidJobStatusTransitions[] = [];
    // ??? ?? ?????????? ??? !? I think I've seen something like this elsewhere.
    // there was an object containing a series of messages that had
    // messages grouped by JobStatus
    // This looks like possible JobStatus transitions based on current job status and
    // if the user has another job in progress
    switch (currentJobStatus) {
        case JobStatus.ACCEPTED:
            validJobStatusTransitions = (
                hasAnotherJobInProgress
                    ? []
                    : [
                          {
                              value: JobStatus.CURRENT,
                              label: JobStatus.CURRENT,
                          },
                          {
                              value: JobStatus.ARRIVED,
                              label: JobStatus.ARRIVED,
                          },
                      ]
            ).concat([
                {
                    value: JobStatus.COMPLETED,
                    label: JobStatus.COMPLETED,
                },
            ]);
            break;
        case JobStatus.CURRENT:
            validJobStatusTransitions = (
                hasAnotherJobInProgress
                    ? []
                    : ([
                          {
                              value: JobStatus.ARRIVED,
                              label: JobStatus.ARRIVED,
                          },
                      ] as IValidJobStatusTransitions[])
            ).concat([
                {
                    value: JobStatus.COMPLETED,
                    label: JobStatus.COMPLETED,
                },
                {
                    value: JobStatus.ACCEPTED,
                    label: jobStatusAcceptedLabel,
                },
            ] as IValidJobStatusTransitions[]);
            break;
        case JobStatus.ARRIVED:
            validJobStatusTransitions = (
                hasAnotherJobInProgress
                    ? []
                    : ([
                          {
                              value: JobStatus.CURRENT,
                              label: JobStatus.CURRENT,
                          },
                      ] as IValidJobStatusTransitions[])
            ).concat([
                {
                    value: JobStatus.COMPLETED,
                    label: JobStatus.COMPLETED,
                },
                {
                    value: JobStatus.ACCEPTED,
                    label: jobStatusAcceptedLabel,
                },
            ]);
            break;
        case JobStatus.COMPLETED:
            validJobStatusTransitions = (
                hasAnotherJobInProgress
                    ? []
                    : [
                          {
                              value: JobStatus.CURRENT,
                              label: JobStatus.CURRENT,
                          },
                          {
                              value: JobStatus.ARRIVED,
                              label: JobStatus.ARRIVED,
                          },
                      ]
            ).concat([
                {
                    value: JobStatus.ACCEPTED,
                    label: Patient.getFriendlyVisitStatus(
                        JobStatus.ACCEPTED,
                    ).toUpperCase() as JobStatus,
                },
            ]);
            break;
        default:
            validJobStatusTransitions = [];
            break;
    }

    return validJobStatusTransitions;
}

export interface IJobStatusCanBeChange {
    jobStatus: JobStatus | undefined;
}
export function jobStatusCanBeChange({ jobStatus }: IJobStatusCanBeChange): boolean {
    return [JobStatus.CURRENT, JobStatus.ARRIVED, JobStatus.ACCEPTED, JobStatus.COMPLETED].includes(
        jobStatus as JobStatus,
    );
}

export interface IGetDefaultMadeCurrentValue {
    patientVisit: Patient | null | undefined;
    isHcp1: boolean;
    values: { [key: string]: any };
}

/**
 * https://docabode.atlassian.net/wiki/spaces/KB/pages/2141159469/VS-VISCH-15+COD+changes+job+status#Table-T1.-Defaulting-of-time
 * madeCurrent defaults to: Made current = Planned time of the job
 * Planned time of the job is
 * Patient.startDateTime
 */
export function getDefaultMadeCurrentValue({
    isHcp1,
    values,
    patientVisit,
}: IGetDefaultMadeCurrentValue): Date | undefined {
    let property = 'madeCurrentDateTime';
    if (!isHcp1) {
        property = 'buddyMadeCurrentDateTime';
    }
    let madeCurrentDefaultValue;
    let patientMadeCurrentDateTime;
    if (patientVisit) {
        patientMadeCurrentDateTime = patientVisit[property as keyof Patient];
    }
    switch (true) {
        case Boolean(values[property]):
            madeCurrentDefaultValue = values[property];
            break;
        case Boolean(patientVisit) && Boolean(patientMadeCurrentDateTime):
            madeCurrentDefaultValue = patientMadeCurrentDateTime;
            break;
        default:
            // No value for madeCurrent default to Patient.startDateTime
            madeCurrentDefaultValue = (patientVisit as Patient)?.startDateTime;
    }
    if (!madeCurrentDefaultValue) {
        return undefined;
    }

    return new Date(madeCurrentDefaultValue);
}

export interface IGetDefaultArrivedDateTime {
    isHcp1: boolean;
    // todo when we do a refactor make this just take Patient  and not null | undefined as well.
    patientVisit: Patient | null | undefined;
}

/**
 * incorporates the default values in the table:
 * https://docabode.atlassian.net/wiki/spaces/KB/pages/2141159469/VS-VISCH-15+COD+changes+job+status#Table-T1.-Defaulting-of-time
 *
 * Actual start time = Planned time of the job
 * planned start time of job === Patient.startDateTime
 * as it can be string | null,   what to do if we do not have startDateTime for now I've just returned undefined, which technically
 * is in line with the spec
 * off falling back to Patient.startDateTime.
 */
export function getDefaultArrivedDateTime({
    isHcp1,
    patientVisit,
}: IGetDefaultArrivedDateTime): Date | undefined {
    let arrivedDateTime;
    let arrivedDateTimePropertyName = 'arrivedDateTime';
    if (!isHcp1) {
        arrivedDateTimePropertyName = 'buddyArrivedDateTime';
    }
    let patientArrivedDateTime;
    const patientStartDateTime = (patientVisit as Patient)?.startDateTime;
    if (patientVisit) {
        patientArrivedDateTime = patientVisit[arrivedDateTimePropertyName as keyof Patient];
    }
    if (patientArrivedDateTime) {
        arrivedDateTime = new Date(patientArrivedDateTime as string);
    } else if (patientStartDateTime) {
        arrivedDateTime = new Date(patientStartDateTime as string);
    }

    return arrivedDateTime;
}

export interface IGetDefaultFinishedTime {
    arrivedDateTime: Date | undefined;
    isHcp1: boolean;
    patientVisit: Patient | undefined | null;
}

/**
 * Incorporates the default values in the table:
 * https://docabode.atlassian.net/wiki/spaces/KB/pages/2141159469/VS-VISCH-15+COD+changes+job+status#Table-T1.-Defaulting-of-time
 *
 * Actual end time = Planned end time of the job
 * nb separate param for arrivedDateTime as this could be the default and not the one
 * set in patientVisit
 */
export function getDefaultFinishedTime({
    arrivedDateTime,
    isHcp1,
    patientVisit,
}: IGetDefaultFinishedTime): Date | undefined {
    let finishedDateTimePropertyName = 'finishedDateTime';
    if (!isHcp1) {
        finishedDateTimePropertyName = 'buddyFinishedDateTime';
    }
    let finishedDateTimeValue;
    if (patientVisit) {
        finishedDateTimeValue = patientVisit[
            finishedDateTimePropertyName as keyof Patient
        ] as string;
    }
    switch (true) {
        case Boolean(finishedDateTimeValue):
            return new Date(finishedDateTimeValue as string);
        case Boolean(patientVisit?.endDateTime):
            return new Date(patientVisit?.endDateTime as string);
        case Boolean(arrivedDateTime) && Boolean(patientVisit?.duration):
            return getDateEndTime(arrivedDateTime, patientVisit?.duration);
    }

    return undefined;
}

export interface IIsEarlierDateAfterLaterDateWhenBothHaveAValue {
    earlierDateTime: Date | null | undefined;
    laterDateTime: Date | null | undefined;
}

/**
 * Note: to the nearest minute rounded down.
 */
export function isEarlierDateAfterLaterDateWhenBothHaveAValue({
    earlierDateTime,
    laterDateTime,
}: IIsEarlierDateAfterLaterDateWhenBothHaveAValue): boolean {
    return (
        Boolean(earlierDateTime) &&
        Boolean(laterDateTime) &&
        moment(earlierDateTime).startOf('minute').toDate() >
            moment(laterDateTime).startOf('minute').toDate()
    );
}
export interface IDateTimeValues {
    madeCurrentDateTime: Date | undefined | null;
    arrivedDateTime: Date | undefined | null;
    finishedDateTime: Date | undefined | null;
}
export interface IGetValidationWarnings {
    dateTimeValues: IDateTimeValues;
    statusHasChanged: boolean;
    newJobStatus: TJobStatusOrNot;
}
export function getValidationWarnings({
    dateTimeValues,
    statusHasChanged,
    newJobStatus,
}: IGetValidationWarnings): string[] {
    const wiggleRoomNow = moment(new Date()).add(5, 'minutes').toDate();
    let validationWarnings: string[] = [];
    // not entirely sure this is needed, but the old code had checking the status logic.
    if (!statusHasChanged) {
        return validationWarnings;
    }
    const checkFinishedDateTime = finishedDateTimeCanHaveAValue({ jobStatus: newJobStatus });
    const checkArrivedDateTime = arrivedDateTimeCanHaveAValue({ jobStatus: newJobStatus });
    const checkMadeCurrentDateTime = madeCurrentDateTimeCanHaveAValue({ jobStatus: newJobStatus });
    if (checkMadeCurrentDateTime && !dateTimeValues.madeCurrentDateTime) {
        validationWarnings.push('Time marked as current must have a value.');
    }
    if (checkArrivedDateTime && !dateTimeValues.arrivedDateTime) {
        validationWarnings.push('Actual start time must have a value.');
    }
    if (checkFinishedDateTime && !dateTimeValues.finishedDateTime) {
        validationWarnings.push('Actual end time must have a value.');
    }
    // CHECK ARRIVED V FINISH
    if (
        checkArrivedDateTime &&
        checkFinishedDateTime &&
        isEarlierDateAfterLaterDateWhenBothHaveAValue({
            earlierDateTime: dateTimeValues.arrivedDateTime,
            laterDateTime: dateTimeValues.finishedDateTime,
        })
    ) {
        validationWarnings.push('The actual start time cannot be later than the actual end time.');
    }

    // CHECK MADE CURRENT TIME IS NOT IN FUTURE
    if (
        isEarlierDateAfterLaterDateWhenBothHaveAValue({
            earlierDateTime: dateTimeValues.madeCurrentDateTime,
            laterDateTime: wiggleRoomNow,
        })
    ) {
        validationWarnings.push('The time the job was made current cannot be in the future.');
    }
    // ARRIVED TIME V MADE CURRENT TIME
    if (
        checkArrivedDateTime &&
        isEarlierDateAfterLaterDateWhenBothHaveAValue({
            earlierDateTime: dateTimeValues.madeCurrentDateTime,
            laterDateTime: dateTimeValues.arrivedDateTime,
        })
    ) {
        validationWarnings.push(
            'The time the job was made current cannot be later than the actual start time.',
        );
    }
    // CHECK ARRIVED TIME NOT IN FUTURE
    if (
        checkArrivedDateTime &&
        isEarlierDateAfterLaterDateWhenBothHaveAValue({
            earlierDateTime: dateTimeValues.arrivedDateTime,
            laterDateTime: wiggleRoomNow,
        })
    ) {
        validationWarnings.push('The arrival time cannot be in the future.');
    }
    // CHECK finish TIME NOT IN FUTURE
    if (
        checkFinishedDateTime &&
        isEarlierDateAfterLaterDateWhenBothHaveAValue({
            earlierDateTime: dateTimeValues.finishedDateTime,
            laterDateTime: wiggleRoomNow,
        })
    ) {
        validationWarnings.push('The completion time cannot be in the future.');
    }

    return validationWarnings;
}

export interface IGetHcpHasActiveJob {
    isHcp1: boolean;
    patientVisit: Patient | undefined | null;
    hcpResults: IHcpResult;
    buddyResults: IHcpResult;
}

/**
 * Seems to be the logic of if we are currently on a job (IHcpResult.arrived > 0|| IHcpResult.current  > 0)
 * and that job is active (JobStatus.CURRENT, JobStatus.ARRIVED) then we dont count as being on an active job.
 * I think this is because we are using this to determine if we can change the current job status, and if the current job IS
 * the active job then its all good.
 * maybe a beter name for this would be getHcpHasActiveJobOtherThanCurrentJob
 * used to have param apiLoaded just means that we have loaded what we need, but i would expect that the results
 * but as IHcpResult.pass can only be set to true on the api, and we need resultsToUse.pass to be true
 * we can assume  resultsToUse.pass === apiLoaded
 * @param isHcp1 determines which properties of patientVisit we test against eg buddy or "not buddy"
 * @param patientVisit Patient
 * These result are from the api and are a count of jobs marked as current or active for the HCP,
 * looks like IHcpResult.pass denotes if the HCP in question has been found or not.
 * @param hcpResults IHcpResult
 * @param buddyResults  IHcpResult
 */
export function getHcpHasActiveJob({
    isHcp1,
    patientVisit,
    hcpResults,
    buddyResults,
}: IGetHcpHasActiveJob): boolean {
    // lots of duplication of logic
    const propertyNamesToUse = getPropertyNames({ isHcp1 });

    let resultsToUse = hcpResults;
    if (!isHcp1) {
        resultsToUse = buddyResults;
    }
    let jobStatus;
    if (patientVisit) {
        jobStatus = patientVisit[propertyNamesToUse.jobStatus];
    }
    const jobStatusIsOk = !(
        [JobStatus.CURRENT, JobStatus.ARRIVED] as (undefined | JobStatus)[]
    ).includes(jobStatus);
    return Boolean(
        resultsToUse.pass &&
            (resultsToUse.current > 0 || resultsToUse.arrived > 0) &&
            jobStatusIsOk,
    );
}

export interface IGetShowTimeMarkedAsCurrent {
    changedStatus: JobStatus | undefined | null | '';
}
export function getShowTimeMarkedAsCurrent({
    changedStatus,
}: IGetShowTimeMarkedAsCurrent): boolean {
    return Boolean(changedStatus) && changedStatus !== JobStatus.ACCEPTED;
}

export interface IGetShowActualStartTime {
    newJobStatus: TJobStatusOrNot;
}
export function getShowActualStartTime({ newJobStatus }: IGetShowActualStartTime): boolean {
    return ([JobStatus.ARRIVED, JobStatus.COMPLETED] as TJobStatusOrNot[]).includes(newJobStatus);
}

export interface IGetPostVisitNotesError {
    postVisitNotesMandatory: boolean;
    jobStatus: TJobStatusOrNot;
    postVisitNotes: string | undefined | null;
}

export function getPostVisitNotesError({
    postVisitNotesMandatory,
    jobStatus,
    postVisitNotes,
}: IGetPostVisitNotesError): string | undefined {
    const addPostVisitNotesError = !isPostVisitNotesValid({
        postVisitNotesMandatory,
        jobStatus: jobStatus,
        postVisitNotes: postVisitNotes,
    });
    if (addPostVisitNotesError) {
        return 'This field is required.';
    }
    return undefined;
}

export interface IIsConfirmButtonDisabled {
    loading: boolean;
    postVisitNotesMandatory: boolean;
    values: IChangeStatusFormFormikValues;
    validationWarnings: string[];
}
export function isConfirmButtonDisabled({
    loading,
    values,
    validationWarnings,
    postVisitNotesMandatory,
}: IIsConfirmButtonDisabled): boolean {
    return (
        loading ||
        values.newJobStatus === '' ||
        validationWarnings.length > 0 ||
        !isPostVisitNotesValid({
            postVisitNotesMandatory,
            jobStatus: values.newJobStatus,
            postVisitNotes: values[
                propertyNames.postVisitNotes as keyof IChangeStatusFormFormikValues
            ] as string | undefined | null,
        })
    );
}

export interface IGetActiveJobsForHcp {
    hcpId: string | undefined | null;
    token: string;
}
export async function getActiveJobsForHcp({
    hcpId,
    token,
}: IGetActiveJobsForHcp): Promise<IHcpResult> {
    if (!hcpId) {
        return Promise.resolve({ current: 0, arrived: 0, pass: false });
    }
    const activeJobs = await getActiveJobs(token, { hcpId: hcpId });

    return activeJobs;
}
