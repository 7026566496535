import { StaffAlert } from '@doc-abode/data-models';
import { FC } from 'react';

import { getHcpName } from '../../../../../../helpers/ucr';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { Info, InfoItem } from '../../../../../v2/components';
import { AlertStatusTimesAndUsers } from '../common/AlertStatusTimesAndUsers';

interface Props {
    alert: StaffAlert;
}

export const StaffAlertDetails: FC<Props> = ({ alert }) => {
    const {
        RootStore: {
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    return (
        <div className="v2__form-section">
            <Info>
                <InfoItem label="Sender:">{getHcpName(users, alert.userId)}</InfoItem>
                <InfoItem label="Phone:">{alert.originPhoneNumber}</InfoItem>
            </Info>
            <AlertStatusTimesAndUsers alert={alert} />
        </div>
    );
};
