import { Patient } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { SummaryJobListRow } from './SummaryJobListRow';

// Passing through a list of jobs to allow for greater re-use than reading it from a store
interface JobListProps {
    jobs: Patient[];
}

export const SummaryJobList: FC<JobListProps> = observer(({ jobs }) => {
    if (jobs.length === 0) {
        return (
            <div className="v2__form-section">
                This staff member does not have any assigned jobs for today
            </div>
        );
    }

    return (
        <div className="v2__form-section">
            <table className="ucr-staff-alert-dialog__job-table">
                <thead>
                    <tr>
                        <th>Time</th>
                        <th>Pathway</th>
                        <th>Service</th>
                        <th>Patient</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {jobs.map((job, i) => (
                        <SummaryJobListRow job={job} key={`summary-job-list-row-${job.id}`} />
                    ))}
                </tbody>
            </table>
        </div>
    );
});
