import { FormGroup, Radio, RadioGroup } from '@blueprintjs/core';
import { useField } from 'formik';
import React from 'react';

const RadioInput = ({ label, options, ...props }) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });

    return (
        <FormGroup>
            <RadioGroup label={label} {...field} {...props} selectedValue={field.value} inline>
                {options.map((option) => (
                    <Radio label={option.label} value={option.value} key={option.value} />
                ))}
            </RadioGroup>
            {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </FormGroup>
    );
};

export default RadioInput;
