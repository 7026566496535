import { TimePrecision } from '@blueprintjs/datetime2';
import { FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { dateTimeFormat } from '../../../../../constants/patientsConst';
import { getMomentDateFormatter } from '../../../../../helpers/ucr/getMomentDateFormatter';
import { transformFormikValuesToPatientForWarningValidation } from '../../../../../helpers/ucr/getWarnings';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Button, ButtonSizes } from '../../../../v2/components';
import { DateInput, Select, TextInput } from '../../../../v2/form';
import { WarningBanner } from '../../../../v2/form/WarningBanner';
import { useWarningMessageViewModel } from '../../hooks/useWarningMessageViewModel';
import { FormMode, FormSteps } from '../common';
import { S1ReferralSelect } from './S1ReferralSelect';
import { useReferralDetailsViewModel } from './useReferralDetailsViewModel';

const MAXDATE = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
const MINDATE = new Date(1900, 1, 1);

type ReferralDetailsType = {
    values: FormikValues;
    loading: boolean;
    formMode: FormMode;
    setFieldValue: (field: string, value: any) => void;
    onNextStep?: (nextStep: FormSteps, prevStep: FormSteps) => void;
    onSaveForm?: (curStep: FormSteps) => void;
};

const ReferralDetails: FC<ReferralDetailsType> = ({
    loading,
    formMode,
    values,
    onNextStep = () => {},
    onSaveForm = () => {},
}) => {
    const {
        activeReferrals,
        onSyncS1Referrals,
        referrer,
        buttonName,
        onSubmit,
        referralSelectHelperText,
        s1Enabled,
    } = useReferralDetailsViewModel({
        formMode,
        values,
        onNextStep,
        onSaveForm,
    });

    // Determine warning messages to show
    const tmpJob = transformFormikValuesToPatientForWarningValidation({ values, isAdmin: false });

    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({
        job: tmpJob,
        sectionShown: FormSteps.REFERRAL,
    });

    return (
        <div className="v2__form-block">
            {s1Enabled ? (
                <S1ReferralSelect
                    loading={loading}
                    referrals={activeReferrals}
                    onSyncS1Referrals={onSyncS1Referrals}
                    referralSelectHelperText={referralSelectHelperText}
                />
            ) : (
                <TextInput
                    name="systmOneRef"
                    className="v2__form-group--pos-1-2"
                    label="Referral reference number"
                    disabled={loading}
                />
            )}
            <Select
                name="referrer"
                className="v2__form-group--pos-1-2"
                label="Referrer"
                disabled={loading}
            >
                <option value="">Not set</option>
                {referrer
                    ?.slice()
                    .sort((a: any, b: any) => a.localeCompare(b))
                    .map((option: string) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
            </Select>
            {!s1Enabled && (
                <DateInput
                    showTimezoneSelect={false}
                    name="referralDateTime"
                    className="v2__form-group--pos-1-2"
                    label="Referral date"
                    dateFormat={dateTimeFormat}
                    {...getMomentDateFormatter(dateTimeFormat)}
                    dateFnsFormat={dateTimeFormat}
                    disabled={loading}
                    timePickerProps={{
                        showArrowButtons: true,
                        precision: TimePrecision.MINUTE,
                    }}
                    maxDate={MAXDATE}
                    minDate={MINDATE}
                />
            )}
            <ConditionalDisplay show={hasWarningMessages}>
                <div className="v2__form-group--pos-1-1">
                    <WarningBanner spacerTop={true} compact={true} warnings={warningMessages} />
                </div>
            </ConditionalDisplay>
            <label className="v2__form-group">
                <Button
                    className="v2__form-submit-button"
                    name={buttonName}
                    size={ButtonSizes.MEDIUM}
                    clickEvent={onSubmit}
                />
            </label>
        </div>
    );
};

export default observer(ReferralDetails);
