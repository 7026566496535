import { Position, Toaster } from '@blueprintjs/core';

// You can add new messages into the Toaster by doing:
// AppToaster.show({ message: "Your message" });
// Alternatively, more toasters can be added for additional positions

const AppToaster = Toaster.create({
    className: 'page-toaster',
    position: Position.TOP_RIGHT,
});

export default AppToaster;
