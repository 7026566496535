import { gql } from '@apollo/client';

export const QUERY_JOBS_BY_JOB_TYPE = gql`
    query queryJobsByJobTypeIndex(
        $jobType: String!
        $filter: TableJobsFilterInput
        $search: String
        $itineraryType: String
        $v2Filter: String
    ) {
        queryJobsByJobTypeIndex(
            jobType: $jobType
            filter: $filter
            search: $search
            itineraryType: $itineraryType
            v2Filter: $v2Filter
        ) {
            items {
                id
                arrivedDateTime
                finishedDateTime
                jobStatus
                jobStatusBeforeAbort
                buddyJobStatusBeforeAbort
                activityType
                firstName
                lastName
                middleName
                hubId
                vaccinationCategory
                vaccinationType
                doseNumber
                createDateTime
                dateOfVisit
                dateOfBirth
                consented
                dateOfDose1
                dateOfDose2
                dateOfPreviousDose
                manufacturerOfDose1
                manufacturerOfDose2
                manufacturerOfPreviousDose
                vaccineManufacturer
                nhsNumber
                town
                addressLine1
                addressLine2
                addressLine3
                postCode
                county
                coAdministeredWith
                itineraryId
                availableFrom
                availableTo
                hcpId
                buddyId
                startDateTime
                earliestDateOfVisit
                duration
                disposition
                version
                staffRequired
                referralPathway
                referrer
                careComplexity
                carRequired
                initialVisit
                buddyJobStatus
                buddyArrivedDateTime
                buddyFinishedDateTime
                gender
                hcpName
                buddyName
                createdBy
                lastUpdatedDateTime
                lastUpdatedBy
                notes
                languagesSpoken
                contactNumber
                additionalContactNumbers
                gender
                staffPreferredGender
                controllerAbortedReason
                controllerAbortedNotes
                buddyControllerAbortedReason
                buddyControllerAbortedNotes
                buddyMadeCurrentDateTime
                hcpAbortedDateTime
                hcpAbortedReason
                hcpAbortedNotes
                buddyHcpAbortedDateTime
                buddyHcpAbortedReason
                buddyHcpAbortedNotes
                markedCompletedByController
                markedCompletedByControllerNotes
                latitude
                longitude
                pds {
                    versionId
                }
                postVisitNotes
                postVisitNotesBuddy
                practice
                vaccineSiteOfAdministration
                madeCurrentDateTime
                vaccineExpiryDate
                vaccineBatchNumber
                odsCode
                systmOneRef
                nominatedHcps
                routeType
                expiryDateTime
                jobType
                itinerary {
                    route {
                        startTime
                        endTime
                        totalTravelDistance
                        totalTravelTime
                        wayPoints {
                            latitude
                            longitude
                        }
                    }
                    instructions {
                        duration
                        endTime
                        startTime
                        instructionType
                        distance
                        itineraryItem {
                            closingTime
                            openingTime
                            priority
                            dwellTime
                            odsCode
                            location {
                                latitude
                                longitude
                            }
                            name
                            dropOffFrom
                            quantity
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ROUTE_BY_ID = gql`
    query getJob($id: String!) {
        getJob(id: $id) {
            id
            itineraryId
            jobStatus
            jobStatusBeforeAbort
            jobType
            createDateTime
            createdBy
            dateOfVisit
            lastUpdatedBy
            version
            vaccinationCategory
            vaccinationType
            hubId
            priceInPence
            arrivedDateTime
            controllerAbortedReason
            controllerAbortedNotes
            withdrawnReason
            withdrawnNotes
            finishedDateTime
            hcpName
            hcpId
            hcpAbortedReason
            abortedFollowUpAction
            hcpAbortedNotes
            notes
            startDateTime
            lastUpdatedDateTime
            controllerReverseNotes
            controllerReverseReason
            hcpReverseNotes
            hcpReverseReason
            abortedBy
            postVisitNotes
            hcpAbortedDateTime
            markedCompletedByController
            markedCompletedByControllerNotes
            madeCurrentDateTime
            nominatedHcps
            organisation
            pds {
                versionId
            }
            routeType
            expiryDateTime
            itinerary {
                itineraryId
                agent {
                    capacity
                    name
                    price {
                        fixedPrice
                        pricePerHour
                        pricePerKM
                    }
                    shifts {
                        break
                        endLocation {
                            latitude
                            longitude
                        }
                        startLocation {
                            latitude
                            longitude
                        }
                        endTime
                        startTime
                    }
                }
                route {
                    endLocation {
                        latitude
                        longitude
                    }
                    startLocation {
                        latitude
                        longitude
                    }
                    endTime
                    startTime
                    totalTravelDistance
                    totalTravelTime
                    wayPoints {
                        latitude
                        longitude
                    }
                }
                removedPatients {
                    id
                    reason
                    timestamp
                }
                unscheduledItems {
                    id
                    reason
                    timestamp
                }
                instructions {
                    duration
                    endTime
                    startTime
                    instructionType
                    distance
                    itineraryItem {
                        closingTime
                        openingTime
                        priority
                        dwellTime
                        odsCode
                        location {
                            latitude
                            longitude
                        }
                        name
                        dropOffFrom
                        quantity
                    }
                }
            }
        }
    }
`;

export const GET_JOB_BY_ID = gql`
    query getJob($id: String!) {
        getJob(id: $id) {
            id
            itineraryId
            jobStatus
            jobStatusBeforeAbort
            buddyJobStatusBeforeAbort
            jobType
            practice
            firstName
            middleName
            lastName
            activityType
            manufacturerOfDose1
            manufacturerOfDose2
            manufacturerOfPreviousDose
            contactNumber
            consented
            createDateTime
            createdBy
            nhsNumber
            town
            addressLine1
            addressLine2
            addressLine3
            postCode
            county
            dateOfVisit
            dateOfBirth
            additionalContactNumbers
            lastUpdatedBy
            version
            flags
            vaccinationCategory
            vaccinationType
            hubId
            gender
            doseNumber
            priceInPence
            arrivedDateTime
            availableFrom
            availableTo
            controllerAbortedReason
            controllerAbortedNotes
            buddyControllerAbortedReason
            buddyControllerAbortedNotes
            dateOfDose1
            dateOfDose2
            dateOfPreviousDose
            coAdministeredWith
            withdrawnReason
            withdrawnNotes
            vaccineBatchNumber
            vaccineManufacturer
            vaccineExpiryDate
            vaccineSiteOfAdministration
            finishedDateTime
            hcpName
            buddyName
            hcpId
            hcpAbortedReason
            abortedFollowUpAction
            hcpAbortedNotes
            buddyHcpAbortedReason
            buddyHcpAbortedNotes
            notes
            startDateTime
            earliestDateOfVisit
            duration
            referrer
            referralDateTime
            referralPathway
            disposition
            careComplexity
            carRequired
            buddyId
            languagesSpoken
            systmOneRef
            staffRequired
            initialVisit
            buddyJobStatus
            buddyArrivedDateTime
            buddyFinishedDateTime
            lastUpdatedDateTime
            buddyHcpAbortedReason
            buddyHcpAbortedNotes
            staffPreferredGender
            controllerReverseNotes
            controllerReverseReason
            hcpReverseNotes
            hcpReverseReason
            buddyControllerReverseNotes
            buddyControllerReverseReason
            buddyHcpReverseNotes
            buddyHcpReverseReason
            buddyMadeCurrentDateTime
            abortedBy
            buddyAbortedBy
            postVisitNotes
            postVisitNotesBuddy
            hcpAbortedDateTime
            buddyHcpAbortedDateTime
            markedCompletedByController
            markedCompletedByControllerNotes
            madeCurrentDateTime
            latitude
            longitude
            pds {
                versionId
            }
            odsCode
        }
    }
`;

export const UPDATE_JOB = gql`
    mutation updateJob($input: UpdateJobInput!, $action: String) {
        updateJob(input: $input, action: $action) {
            id
            itineraryId
            jobStatus
            jobStatusBeforeAbort
            buddyJobStatusBeforeAbort
            jobType
            activityType
            practice
            firstName
            middleName
            lastName
            manufacturerOfDose1
            manufacturerOfDose2
            manufacturerOfDose3
            manufacturerOfDose3Primary
            manufacturerOfDose4
            manufacturerOfPreviousDose
            contactNumber
            consented
            createDateTime
            createdBy
            nhsNumber
            town
            addressLine1
            addressLine2
            addressLine3
            postCode
            county
            dateOfVisit
            dateOfBirth
            additionalContactNumbers
            lastUpdatedBy
            version
            flags
            vaccinationCategory
            vaccinationType
            hubId
            gender
            doseNumber
            priceInPence
            arrivedDateTime
            availableFrom
            availableTo
            controllerAbortedReason
            controllerAbortedNotes
            buddyControllerAbortedReason
            buddyControllerAbortedNotes
            dateOfDose1
            dateOfDose2
            dateOfDose3
            dateOfDose3Primary
            dateOfDose4
            dateOfPreviousDose
            coAdministeredWith
            withdrawnReason
            withdrawnNotes
            vaccineBatchNumber
            vaccineManufacturer
            vaccineExpiryDate
            vaccineSiteOfAdministration
            finishedDateTime
            hcpName
            hcpId
            hcpAbortedReason
            hcpAbortedNotes
            buddyHcpAbortedReason
            buddyHcpAbortedNotes
            notes
            startDateTime
            earliestDateOfVisit
            duration
            referrer
            referralDateTime
            referralPathway
            disposition
            careComplexity
            carRequired
            buddyId
            languagesSpoken
            staffRequired
            initialVisit
            buddyJobStatus
            buddyArrivedDateTime
            buddyFinishedDateTime
            staffPreferredGender
            controllerReverseNotes
            controllerReverseReason
            hcpReverseNotes
            hcpReverseReason
            buddyControllerReverseNotes
            buddyControllerReverseReason
            buddyHcpReverseNotes
            buddyHcpReverseReason
            buddyMadeCurrentDateTime
            abortedBy
            buddyAbortedBy
            postVisitNotes
            postVisitNotesBuddy
            markedCompletedByController
            markedCompletedByControllerNotes
            madeCurrentDateTime
            pds {
                versionId
            }
            odsCode
        }
    }
`;

export const CREATE_JOB = gql`
    mutation createJob($input: CreateJobInput!) {
        createJob(input: $input) {
            id
        }
    }
`;

export const GET_WARNINGS = gql`
    query getWarnings($warning: TableJobsWarningInput) {
        getWarnings(warning: $warning) {
            warnings {
                category
                message
                data {
                    startDateTime
                    duration
                    hcpId
                    nhsNumber
                }
            }
        }
    }
`;

export const GET_ALL_WARNINGS = gql`
    query getWarnings($warning: TableJobsWarningInput) {
        getWarnings(warning: $warning) {
            allWarnings {
                id
                warnings {
                    category
                    message
                    data {
                        startDateTime
                        duration
                        hcpId
                        nhsNumber
                    }
                }
            }
        }
    }
`;
