type stringNullOrUndefined = string | undefined | null;

/**
 * Joins the values of an array to a concatenated string
 * @param arrayToTransform array of values
 * @param separator optional separator to use; if none provided the function will use ", " (= comma followed by space)
 * @returns string of concatenated values or zero length string
 */
export function convertArrayToConcatenatedString(
    arrayToTransform?: stringNullOrUndefined[],
    separator?: string,
) {
    if (!arrayToTransform) {
        return '';
    }

    return arrayToTransform.filter((value) => value).join(separator || ', ');
}
