import { Checkbox } from '@blueprintjs/core';
import React from 'react';

import { capitalizeFirstLetter } from '../../../helpers';
import useHeaderSorting from '../../../hook/useHeaderSorting';
import { Checkbox as CheckboxV2, Text } from '../../v2/form';
import SortedHeader from '../sorting/SortedHeader';

export interface HeaderColumnType {
    key: string;
    label?: string;
    sortable?: boolean;
    render?: Function;
    sortKey?: string;
    hasSelectAll?: boolean;
    onSelect?: (checked: boolean) => void;
    isSelected?: boolean;
}

interface Props {
    checked?: boolean;
    isFrontendSort?: boolean;
    onSort?: () => void;
    onToggleSelectAll?: (checked: boolean) => {};
    selectable?: boolean;
    columns: HeaderColumnType[];
    allSelected?: boolean;
}

const TableHeader = ({
    onSort = () => {},
    isFrontendSort = true,
    onToggleSelectAll,
    checked,
    selectable = false,
    columns,
    allSelected = false,
}: Props) => {
    const { sort, sortData } = useHeaderSorting((s: any) => s, onSort, isFrontendSort);
    return (
        <thead>
            <tr className="patient-data-table__row">
                {selectable && (
                    <th className="patient-data-table__header">
                        <Checkbox
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                onToggleSelectAll ? onToggleSelectAll(evt.target.checked) : null
                            }
                            checked={allSelected}
                        />
                    </th>
                )}
                {columns.map(
                    ({
                        sortable = true,
                        key,
                        label = capitalizeFirstLetter(key),
                        sortKey,
                        hasSelectAll,
                        onSelect,
                        isSelected,
                    }: HeaderColumnType) =>
                        sortable ? (
                            <SortedHeader
                                key={key}
                                sort={sort}
                                sortBy={sortKey || key}
                                text={label}
                                sortData={sortData}
                            />
                        ) : (
                            <th key={key} className="patient-data-table__header">
                                {hasSelectAll ? (
                                    <CheckboxV2
                                        name={label}
                                        className="bp5-control-indicator--small"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            onSelect?.(e.target.checked);
                                        }}
                                        checked={isSelected}
                                    >
                                        <Text
                                            name={label}
                                            description={label}
                                            descriptionClassName="v2__form-section-radio-description"
                                        />
                                    </CheckboxV2>
                                ) : (
                                    label
                                )}
                            </th>
                        ),
                )}
            </tr>
        </thead>
    );
};

export default TableHeader;
