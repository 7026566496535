import cn from 'classnames';
import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import Select, { MenuPlacement } from 'react-select';

import { FormGroup } from '../FormGroup';

export interface ISuggestOption {
    value: string;
    label: string;
    isWorking?: boolean;
    highlighted?: boolean;
}

interface IProps {
    name: string;
    options: ISuggestOption[];
    className?: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    displayErrors?: string | undefined;
    value?: string | null;
    styles?: any;
    isContinueBtnClicked?: boolean;
    menuPlacement?: MenuPlacement;
}

const SuggestComponent: FC<IProps> = ({
    className,
    label,
    required,
    disabled = false,
    options,
    displayErrors,
    value,
    isContinueBtnClicked,
    menuPlacement,
    ...props
}) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (!options.find((option) => option.value === field.value)) {
            setFieldValue(props.name, options[0].value);
        }
    }, [field.value, options, props.name, setFieldValue]);

    useEffect(() => {
        if (value) {
            setFieldValue(props.name, value);
        }
    }, [value, props.name, setFieldValue]);

    const selectedOption = value
        ? options.find((option) => option.value === value)
        : options.find((option) => option.value === field.value);

    const optionStyles = {
        option: (styles: any, { data }: { data: ISuggestOption }) => {
            return {
                ...styles,
                fontWeight: data.highlighted ? 'bold' : 'inherit',
            };
        },
    };

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props.name}
            required={required}
            touched={meta.touched || isContinueBtnClicked}
            error={meta.error}
            displayErrors={displayErrors}
        >
            <Select
                {...props}
                {...field}
                menuPlacement={menuPlacement || 'bottom'}
                options={options}
                value={selectedOption}
                className={cn('v2__form-suggest-input', {
                    'v2__form-suggest-input--disabled': disabled,
                })}
                classNamePrefix="react-select"
                onChange={(option: any) => {
                    if (option) setFieldValue(props.name, option.value);
                }}
                isDisabled={disabled}
                styles={optionStyles}
            />
        </FormGroup>
    );
};

export default SuggestComponent;
