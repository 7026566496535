import { Patient } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { IconArrowDropDown, IconArrowDropUp } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import EmptyListView from './EmptyListView';
import { emptyStateMessage, headerProperties } from './ListViewConst';
import { ListViewRow } from './ListViewRow';
import { IListGenerate, useListViewViewModel } from './useListViewViewModel';

interface IProps {}

export interface IPatientExtended extends Patient {
    hasStaffAlert?: boolean | null;
    hasPatientAlert?: boolean | null;
}

const ListView: FC<IProps> = () => {
    const { listOfJobs, getClassNamesFor, listOfDoubleUps, requestSort } = useListViewViewModel();

    // generates the list view per row.
    const GenerateList: FC<IListGenerate> = ({ jobList, doubleUps }): JSX.Element => {
        let jsxArr = jobList.map((job: IPatientExtended, index: number) => {
            return <ListViewRow job={job} index={index} isDoubleUp={doubleUps.includes(job)} />;
        });
        return <>{jsxArr}</>;
    };

    // generates the header if adding or removing a remove please add/remove grid-template-columns on listview.scss
    const GenerateHeader: FC<IProps> = (): JSX.Element => {
        const {
            RootStore: {
                configStore: { isFeatureEnabled },
            },
        } = useStores<{ RootStore: RootStore }>();

        const jsxArr = headerProperties.map((value, index) => {
            if (!value.featureFlag || isFeatureEnabled(value.featureFlag)) {
                return (
                    <th
                        className="ucr-listview__header"
                        key={'listview-header-' + index}
                        onClick={() => requestSort(value.columnName)}
                    >
                        <div className="patient-list__header-flex">
                            <div
                                className={`patient-list__header-text ${getClassNamesFor(
                                    value.columnName,
                                )}`}
                            >
                                {value.headerName}
                            </div>
                            {value.sortable && (
                                <span className={getClassNamesFor(value.columnName)}>
                                    <IconArrowDropUp
                                        className={'patient-list__header-sort-icon icon-ascending'}
                                    />
                                    <IconArrowDropDown
                                        className={`patient-list__header-sort-icon icon-descending`}
                                    />
                                </span>
                            )}
                        </div>
                    </th>
                );
            } else {
                return <></>;
            }
        });
        return <>{jsxArr}</>;
    };

    return (
        <div className="ucr-listview__main">
            <table className="ucr-listview__containerWidth">
                <thead>
                    <tr className="ucr-listview__list-row ucr-listview__list-row--sticky">
                        <GenerateHeader />
                    </tr>
                </thead>
                {listOfJobs.length > 0 && (
                    <tbody>
                        <GenerateList jobList={listOfJobs} doubleUps={listOfDoubleUps} />
                    </tbody>
                )}
            </table>
            {listOfJobs.length === 0 && <EmptyListView message={emptyStateMessage} />}
        </div>
    );
};

export default observer(ListView);
