export enum ViewToShow {
    TIMELINE = 'timeline',
    VISITS_LIST = 'listview',
    MAP = 'mapview',
    PATIENTS_LIST = 'patients',
    S1_MESSAGES = 'systmone-messages',
    VISIT_DETAILS = 'visit-details',
    JOB_SUMMARY_HEADER = 'job-summary-header',
}

export const statusTags: { [key: string]: string } = {
    PENDING: 'available',
    AVAILABLE: 'available',
    ACCEPTED: 'accepted',
    COMPLETED: 'completed',
    CURRENT: 'current',
    ARRIVED: 'arrived',
    HCP_ABORTED: 'aborted',
    WITHDRAWN: 'aborted',
    CONTROLLER_ABORTED: 'aborted',
};

export const genderMapping: { [key: string]: string } = {
    female: 'Female',
    male: 'Male',
    non_binary: 'Non-binary',
    transgender: 'Transgender',
    intersex: 'Intersex',
    non_listed: 'Other non-listed',
    not_provided: 'Not provided',
    nopreference: 'No preference',
};

export const getDisplayBand = (bandLabel: string) => bandLabel;
