import moment, { Moment } from 'moment';

export const getMomentDuration = (duration?: string): Moment => {
    /*
    duration is passed around as a datetime/moment where 01:30am is 1.5 hours

    Selecting a random date which is not a clock change as it can result in timing errors, 
    as the start of the day on a clock change day is not 0:00.
    */
    let momentDuration = moment('1970-01-01').startOf('day');

    if (!duration) {
        return momentDuration;
    }

    // Duration is in format HH:mm
    const hours = duration.substring(0, 2);
    const minutes = duration.substring(3);

    return momentDuration.hours(Number(hours)).minutes(Number(minutes));
};

export const getDateDuration = (duration?: string): Date => {
    return getMomentDuration(duration).toDate();
};

export const getDurationInMinutes = (duration?: string): number => {
    const [hours, minutes] = (duration || '').split(':');
    return Number.parseInt(hours) * 60 + Number.parseInt(minutes);
};
