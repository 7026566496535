import { Button, Callout, Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Markdown from 'react-markdown';
import Select from 'react-select';

import packageInfo from '../../../package.json';
import changelogMd from '../../CHANGELOG.md';
import { getFilteredOrganisations } from '../../helpers/getFilteredOrganisations';

const version = packageInfo.version;
const DIALOGS = {
    CHANGELOG: 'CHANGELOG',
    SWITCH_ORGANISATION: 'SWITCH_ORGANISATION',
    SWITCH_PORTAL_MODE: 'SWITCH_PORTAL_MODE',
};

const Footer = inject('RootStore')(
    observer(
        class Footer extends Component {
            state = {
                openDialog: null,
                changelogMd: null,
            };

            _handleOpenDialog = (dialog) => {
                this.setState({ openDialog: dialog });
            };

            _handleCloseDialog = () => {
                this.setState({ openDialog: null });
            };

            _handleSwitchOrganisation = ({ value }) => {
                this._handleCloseDialog();
                const { org, switchOrganisation } = this.props.RootStore.configStore;
                if (value !== org) {
                    switchOrganisation(value);
                }
            };

            componentWillMount() {
                fetch(changelogMd)
                    .then((response) => response.text())
                    .then((text) => {
                        this.setState({ changelogMd: text });
                    });
            }
            render() {
                const { openDialog } = this.state;
                const {
                    configStore: { name, org, isFeatureEnabled },
                    userStore: { organisationsInfo, user },
                } = this.props.RootStore;

                const allOrganisations = [...organisationsInfo]
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name));
                const defaultOrgName = allOrganisations.find(
                    (organisation) => organisation.value === org,
                )?.name;

                const filteredOrganisations = getFilteredOrganisations(allOrganisations, user);

                return (
                    <div className="info-bar info-bar--bottom">
                        <Callout
                            className={classNames('footer', {
                                'with-beamer': isFeatureEnabled('beamer'),
                            })}
                        >
                            <span className="footer__info">
                                {filteredOrganisations.length > 1 ? (
                                    <Button
                                        onClick={() =>
                                            this._handleOpenDialog(DIALOGS.SWITCH_ORGANISATION)
                                        }
                                        text={name}
                                        outlined
                                        icon="office"
                                        rightIcon="expand-all"
                                    />
                                ) : (
                                    name
                                )}{' '}
                                v{version} |
                                <button
                                    className="minimal-button"
                                    onClick={() => this._handleOpenDialog(DIALOGS.CHANGELOG)}
                                >
                                    Changelog
                                </button>
                                {isFeatureEnabled('beamer') && user.username && (
                                    <Helmet>
                                        {/* see public.html for the beamer_config*/}
                                        <script
                                            defer="defer"
                                            src="https://app.getbeamer.com/js/beamer-embed.js"
                                        ></script>
                                    </Helmet>
                                )}
                            </span>
                        </Callout>
                        <Dialog
                            isOpen={openDialog === DIALOGS.CHANGELOG}
                            onClose={this._handleCloseDialog}
                            title="Changelog"
                            icon="updated"
                            className="dialog--wide"
                        >
                            <div className="markdown">
                                <Markdown>{this.state.changelogMd}</Markdown>
                            </div>
                        </Dialog>
                        <Dialog
                            isOpen={openDialog === DIALOGS.SWITCH_ORGANISATION}
                            onClose={this._handleCloseDialog}
                            title="Organisation"
                            icon="office"
                        >
                            <div className="footer__dialog">
                                <Select
                                    options={filteredOrganisations.map(({ value, name }) => ({
                                        label: name,
                                        value,
                                    }))}
                                    defaultValue={{
                                        label: defaultOrgName,
                                        value: org,
                                    }}
                                    onChange={this._handleSwitchOrganisation}
                                    placeholder="Select..."
                                />
                            </div>
                        </Dialog>
                    </div>
                );
            }
        },
    ),
);

export default Footer;
