import { JobStatus, Patient } from '@doc-abode/data-models';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { getSortedAbortReasons } from '../../../../../helpers/getSortedAbortReasons';
import { isAbortNoteRequired } from '../../../../../helpers/isAbortNoteRequired';
import { isValidStringValue } from '../../../../../helpers/isValidStringValue';
import { isAborted, isCompleted } from '../../../../../helpers/statusCheckHelper';
import { getFormattedHcpUserForRadioLabelChooser } from '../../../../../helpers/ucr/getFormattedHcpUserForRadioLabelChooser';
import { getHcp } from '../../../../../helpers/ucr/getHcp';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

interface Props {
    visit: Patient;
    loading: boolean;
}

export const defaultOption = {
    label: 'Select option',
    value: '',
    disabled: true,
};

/**
 * Utility to evaluate whether the job part for an HCP is in an abortable state
 * @param jobStatus the status for the HCP
 * @returns boolean true if the part for the HCP can be aborted, false if it cannot
 */
export function isHcpInAbortableState(jobStatus: JobStatus | undefined): boolean {
    return !isCompleted({ jobStatus }) && !isAborted({ jobStatus });
}

/**
 * Utility to determine whether the Confirm abort button is active
 * @param loading indiactor for any load operation such as an update
 * @param cancelationOption the selected cancellation reason
 * @param controllerAbortedReason the selected abort reason
 * @param areAbortNotesMandatory indicator whether the abort notes are mandatory
 * @param controllerAbortedNotes the entered abort notes
 * @returns boolean returns true if an update is in progress or mandatory details are missing
 */
export function isCancellationButtonDisabled(
    loading: boolean,
    cancelationOption: string,
    controllerAbortedReason: string,
    areAbortNotesMandatory: boolean,
    controllerAbortedNotes: string,
): boolean {
    return (
        loading ||
        cancelationOption === '' ||
        controllerAbortedReason === '' ||
        !isValidStringValue(areAbortNotesMandatory, controllerAbortedNotes)
    );
}

export function getAbortReasonsForDropdown(
    isDoubleUp: boolean,
    cancelationOption: string,
    isHcp1InAbortableState: boolean,
    isHcp2InAbortableState: boolean,
    controllerSingleVisitAbortReason: any,
    controllerAbortReason: any,
) {
    // If we're not dealing with a double-up, we return all abort reasons
    if (!isDoubleUp) {
        return controllerAbortReason;
    }

    // At this point we are dealing with a dbl-up
    // If the user selected HCP1 but HCP2 can still be aborted, we return the reasons for individual aborts
    if (cancelationOption === 'user1' && isHcp2InAbortableState) {
        return controllerSingleVisitAbortReason;
    }

    // If the user selected HCP2 but HCP1 can still be aborted, we return the reasons for individual aborts
    if (cancelationOption === 'user2' && isHcp1InAbortableState) {
        return controllerSingleVisitAbortReason;
    }

    // At this stage we can safely return all reasons
    return controllerAbortReason;
}

export const useAbortDoubleVisitViewModel = ({ visit, loading }: Props) => {
    const {
        RootStore: {
            lovsStore: { controllerAbortReason, controllerSingleVisitAbortReason },
            configStore: { abortReasons },
            ucrStore: { hcps },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { values, setFieldValue }: FormikContextType<FormikValues> = useFormikContext();
    const isDoubleUp: boolean = isMultiAssigneeJob(visit);

    // The mask opens with the first aborted reason found. This reason my require mandatory notes
    const [areAbortNotesMandatory, setAreAbortNotesMandatory] = useState(false);

    const isHcp1InAbortableState: boolean = isHcpInAbortableState(visit.jobStatus);
    const isHcp2InAbortableState: boolean = isHcpInAbortableState(visit.buddyJobStatus);

    // Get a list of sorted abort reasons which are still active;
    const reasonsForAborting = [
        defaultOption,
        ...getAbortReasonsForDropdown(
            isDoubleUp,
            values.cancelationOption,
            isHcp1InAbortableState,
            isHcp2InAbortableState,
            controllerSingleVisitAbortReason,
            controllerAbortReason,
        ),
    ];

    // Following a change in selection of whose part to abort, we need to reset the already selected controller abort reason
    useEffect(() => {
        setFieldValue('controllerAbortedReason', '');
    }, [values.cancelationOption, setFieldValue]);

    // Following a change in selected abort reason, we need to determine whether the notes have become mandatory
    useEffect(() => {
        setAreAbortNotesMandatory(
            isAbortNoteRequired(
                getSortedAbortReasons(abortReasons, true, false),
                values.controllerAbortedReason,
            ),
        );
    }, [values.controllerAbortedReason, abortReasons, setAreAbortNotesMandatory]);

    const isControllerAbortedReasonDisabled = values.cancelationOption === '';

    const isConfirmAbortVisitDisabled = isCancellationButtonDisabled(
        loading,
        values.cancelationOption,
        values.controllerAbortedReason,
        areAbortNotesMandatory,
        values.controllerAbortedNotes,
    );

    const user1 = getHcp(hcps, visit.hcpId);
    const user2 = getHcp(hcps, visit.buddyId);

    const labelUser1 = getFormattedHcpUserForRadioLabelChooser(visit, user1, 1);
    const labelUser2 = getFormattedHcpUserForRadioLabelChooser(visit, user2, 2);

    return {
        isDoubleUp,
        labelUser1,
        labelUser2,
        isHcp1InAbortableState,
        isHcp2InAbortableState,
        reasonsForAborting,
        areAbortNotesMandatory,
        isControllerAbortedReasonDisabled,
        isConfirmAbortVisitDisabled,
    };
};
