import { Patient } from '@doc-abode/data-models';

export interface IIsValidRouteForm {
    multipleHubWarning: boolean;
    selectedUsers: any[]; // I think this is an array of userId strings - the hcps in this case.
    jobs: Patient[];
    selectedDate: Date | null | undefined;
}
export function isValidRouteForm({
    multipleHubWarning,
    selectedUsers,
    jobs,
    selectedDate,
}: IIsValidRouteForm) {
    return !(
        multipleHubWarning ||
        selectedUsers.length === 0 ||
        jobs.length === 0 ||
        !selectedDate
    );
}
