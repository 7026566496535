import { InputGroup, InputGroupProps2 } from '@blueprintjs/core';
import { useField } from 'formik';
import { FC } from 'react';

import { FormGroup } from '../FormGroup';

interface IProps extends InputGroupProps2 {
    name: string;
    className?: string;
    label?: string;
    required?: boolean;
    displayErrors?: string;
    isContinueBtnClicked?: boolean;
}
interface IFieldProps extends IProps {
    field?: { [key: string]: any };
    meta?: { [key: string]: any };
    isContinueBtnClicked?: boolean;
}

export const TextInputComponent: FC<IFieldProps> = ({
    className,
    label,
    required,
    field = {},
    meta = {},
    displayErrors,
    isContinueBtnClicked,
    ...props
}) => {
    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props.name}
            required={required}
            touched={meta.touched || isContinueBtnClicked}
            error={meta.error}
            displayErrors={displayErrors}
        >
            <InputGroup {...field} {...props} id={props.name} className="v2__form-text-input" />
        </FormGroup>
    );
};

const TextInputFormComponent: FC<IProps> = ({
    className,
    label,
    required,
    isContinueBtnClicked,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <TextInputComponent
            className={className}
            label={label}
            required={required}
            field={field}
            meta={meta}
            isContinueBtnClicked={isContinueBtnClicked}
            {...props}
        />
    );
};

export default TextInputFormComponent;
