import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import {
    Button,
    Callout,
    Card,
    Checkbox,
    Collapse,
    FormGroup,
    HTMLSelect,
    Icon,
    NumericInput,
} from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import { DateInput3 } from '@blueprintjs/datetime2';
import { HCPType, Vaccination } from '@doc-abode/data-models';
import moment from 'moment';
import React from 'react';

import { IHcp } from '../../../../../interfaces';
import {
    formatDisplayDate,
    formatDisplayDateTime,
    formatDisplayTime,
    metresToMiles,
    parseDateStringToJSDate,
} from '../../../../modules/helpers/formatData';
import Loader from '../../../../modules/helpers/Loader';
import Modal from '../../../../modules/modal/Modal';
import { Itinerary, ItineraryItemSummary, MapWrapper, Warnings } from '../../components';
import { colors } from '../../MapView';
import { friendlyRouteTypes } from '../../utils';
import useCreateRoutesViewController from './useCreateRoutesViewController';

interface ICreateRoutesFooterProps {
    onSubmit: () => Promise<void>;
    disabled: boolean;
}
const CreateRoutesFooter: React.FC<ICreateRoutesFooterProps> = ({ onSubmit, disabled }) => (
    <Button intent="success" large onClick={onSubmit} icon="play" disabled={disabled}>
        Generate routes
    </Button>
);

interface IConfirmRoutesFooterProps {
    onConfirm: () => void;
}
const ConfirmRoutesFooter: React.FC<IConfirmRoutesFooterProps> = ({ onConfirm }) => (
    <Button intent="success" large onClick={onConfirm} icon="tick">
        Accept routes
    </Button>
);

interface IConfirmedRoutesFooterProps {
    onClose: () => void;
}
const ConfirmedRoutesFooter: React.FC<IConfirmedRoutesFooterProps> = ({ onClose }) => (
    <Button large onClick={onClose} icon="cross">
        Close
    </Button>
);
interface ICreateRoutesProps {
    onClose: () => void;
    jobs: Vaccination[];
    users: IHcp[];
    getUserSession: () => Promise<any>;
    getAuthToken: () => Promise<string>;
    refetchRoutes: (
        variables?: Partial<OperationVariables> | undefined,
    ) => Promise<ApolloQueryResult<any>>;
    deselect: (id: string) => void;
    setVaccinations: (vaccinations: any) => void;
    hubs: any;
    getUserHcpTypes: (userId: string) => string[];
    apiKey: string;
    vaccinationDuration: Record<string, string>;
    vaccinationDetails: any;
    hcpType: HCPType[];
    isSuperuser: boolean;
    doseInterval: Record<string, Record<string, number>>;
}

const CreateRoutes: React.FC<ICreateRoutesProps> = ({
    onClose,
    jobs,
    users,
    getUserSession,
    getAuthToken,
    refetchRoutes,
    deselect,
    setVaccinations,
    hubs,
    getUserHcpTypes,
    apiKey,
    vaccinationDuration,
    vaccinationDetails,
    hcpType,
    isSuperuser,
    doseInterval,
}) => {
    const {
        startTime,
        endTime,
        selectedUsers,
        requestingRoutes,
        confirmingRoutes,
        confirmedRoutes,
        routes,
        capacity,
        hub,
        vaccinationsWithWarnings,
        didError,
        actualErrorForDidError,
        smsResults,
        selectedRoute,
        setSelectedRoute,
        showPatientList,
        setShowPatientList,
        loadingWarnings,
        selectedDateErrorMessage,
        onConfirm,
        onStartTimeChange,
        onSubmit,
        disableSubmit,
        routeType,
        onRemoveWarningPatients,
        multipleHubWarning,
        onEndTimeChange,
        isCovidRoute,
        onChangeHub,
        onCapacityChange,
        hubOptions,
        getUserSchedules,
        rangeIntersect,
        onToggleUser,
        selectedDateTimeWarnings,
        newSelectedDate,
        onNewSelectedDateChange,
        routeForItinerary,
    } = useCreateRoutesViewController({
        doseInterval,
        vaccinationDetails,
        vaccinationDuration,
        hubs,
        getUserSession,
        setVaccinations,
        refetchRoutes,
        jobs,
        deselect,
        hcpType,
    });

    return (
        <Modal
            title="Create routes"
            onClose={onClose}
            footer={
                requestingRoutes || confirmingRoutes ? null : confirmedRoutes ? (
                    <ConfirmedRoutesFooter onClose={onClose} />
                ) : routes.length > 0 ? (
                    <ConfirmRoutesFooter onConfirm={onConfirm} />
                ) : (
                    <CreateRoutesFooter onSubmit={onSubmit} disabled={disableSubmit} />
                )
            }
            shadow
        >
            <div className="create-routes">
                {loadingWarnings ? (
                    <Loader fullscreen={false} />
                ) : confirmedRoutes ? (
                    <>
                        <Callout
                            intent="success"
                            icon="tick-circle"
                            className="create-routes__callout"
                        >
                            Routes confirmed.
                        </Callout>
                        {smsResults &&
                            (smsResults.successes?.length > 0 ||
                                smsResults.failures?.length > 0) && (
                                <>
                                    <h3 className="h3">SMS messages sent to patients</h3>
                                    <table className="bp5-html-table">
                                        <thead>
                                            <tr>
                                                <th>NHS number</th>
                                                <th>Name</th>
                                                <th>Contact number</th>
                                                <th>Success</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {smsResults.successes.map(
                                                ({
                                                    contactNumber,
                                                    firstName,
                                                    lastName,
                                                    nhsNumber,
                                                }) => {
                                                    return (
                                                        <tr key={nhsNumber}>
                                                            <td>{nhsNumber}</td>
                                                            <td>
                                                                {firstName} {lastName}
                                                            </td>
                                                            <td>{contactNumber}</td>
                                                            <td className="create-routes__sms--sent">
                                                                <Icon icon="tick-circle" /> Sent
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                            {smsResults.failures.map(({ contactNumber }) => {
                                                const job = jobs.find(
                                                    (job) => job.contactNumber === contactNumber,
                                                );
                                                if (!job) return null;
                                                const { nhsNumber, firstName, lastName } = job;
                                                return (
                                                    <tr key={nhsNumber}>
                                                        <td>{nhsNumber}</td>
                                                        <td>
                                                            {firstName} {lastName}
                                                        </td>
                                                        <td>{contactNumber}</td>
                                                        <td className="create-routes__sms--not-sent">
                                                            <Icon icon="ban-circle" /> Not sent
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            )}
                    </>
                ) : confirmingRoutes ? (
                    <div className="vaccinations__loading">
                        <Loader fullscreen={false} />
                        <p className="create-routes__please-wait">Confirming routes...</p>
                    </div>
                ) : routes.length > 0 ? (
                    <>
                        <Callout intent="success" icon="tick-circle">
                            Generated {routes.length} routes covering{' '}
                            {routes.reduce(
                                (total, route) =>
                                    (total += route.itinerary.instructions.filter(
                                        ({ instructionType }) =>
                                            instructionType === 'VisitLocation',
                                    ).length),
                                0,
                            )}{' '}
                            patients.
                        </Callout>
                        <div className="create-routes__summaries">
                            {routes.map(
                                (
                                    {
                                        id,
                                        nominatedHcps,
                                        itinerary: {
                                            instructions,
                                            route: {
                                                totalTravelDistance,
                                                totalTravelTime,
                                                startTime,
                                                endTime,
                                            },
                                        },
                                    },
                                    index,
                                ) => {
                                    const selected = id === selectedRoute;
                                    return (
                                        <Card
                                            key={id}
                                            className="create-routes__summary"
                                            style={{ borderBottomColor: colors[index] }}
                                        >
                                            <p>
                                                <strong>HCP:</strong> {nominatedHcps[0]}
                                            </p>
                                            <p>
                                                <strong>Patients:</strong>{' '}
                                                {
                                                    instructions.filter(
                                                        ({ instructionType }) =>
                                                            instructionType === 'VisitLocation',
                                                    ).length
                                                }
                                            </p>
                                            <p>
                                                <strong>Start:</strong>{' '}
                                                {formatDisplayDateTime(startTime)}
                                            </p>
                                            <p>
                                                <strong>End:</strong>{' '}
                                                {formatDisplayDateTime(endTime)}
                                            </p>
                                            <p>
                                                <strong>Total travel time:</strong>{' '}
                                                {totalTravelTime}
                                            </p>
                                            <p>
                                                <strong>Total travel distance:</strong>{' '}
                                                {metresToMiles(totalTravelDistance)}
                                            </p>
                                            <Button
                                                text={
                                                    selected ? 'Hide itinerary' : 'Show itinerary'
                                                }
                                                icon={selected ? 'cross' : 'properties'}
                                                className="create-routes__summary-btn"
                                                onClick={() =>
                                                    setSelectedRoute(selected ? null : id)
                                                }
                                            />
                                        </Card>
                                    );
                                },
                            )}
                        </div>
                        {selectedRoute ? (
                            routeForItinerary ? (
                                <Itinerary
                                    hub={hub}
                                    instructions={routeForItinerary.itinerary.instructions}
                                    openInNewWindow
                                />
                            ) : null
                        ) : (
                            <MapWrapper
                                itineraries={routes.map(({ itinerary }) => itinerary)}
                                apiKey={apiKey}
                            />
                        )}
                    </>
                ) : requestingRoutes ? (
                    <div className="vaccinations__loading">
                        <Loader fullscreen={false} />
                        <p className="create-routes__please-wait">
                            Calculating route(s). Please wait, this could take several minutes...
                        </p>
                    </div>
                ) : (
                    <>
                        {didError && actualErrorForDidError && (
                            <Callout intent="danger" className="create-routes__callout">
                                Route generation was unsuccessful. Reported error:{' '}
                                {actualErrorForDidError.message}
                                {actualErrorForDidError.details?.map((detail, idx) => (
                                    <div key={`error_detail_${idx}`}>{detail}</div>
                                ))}
                            </Callout>
                        )}
                        {jobs.length > 0 ? (
                            <div className="create-routes__patients-selected">
                                <p>
                                    <strong>Route type:</strong>{' '}
                                    {
                                        friendlyRouteTypes[
                                            routeType as keyof typeof friendlyRouteTypes
                                        ]
                                    }
                                </p>
                                <span className="create-routes__patients-selected-summary">
                                    <strong>{jobs.length} patients selected.</strong>
                                    <Button
                                        className="create-routes__patients-selected-button"
                                        onClick={() => setShowPatientList(!showPatientList)}
                                        icon={showPatientList ? 'eye-off' : 'eye-open'}
                                    >
                                        {showPatientList ? 'Hide' : 'Show'}
                                    </Button>
                                </span>
                                <Collapse isOpen={showPatientList}>
                                    <ul>
                                        {jobs.map((job) => (
                                            <li key={job.id}>
                                                <ItineraryItemSummary job={job} openInNewWindow />
                                            </li>
                                        ))}
                                    </ul>
                                </Collapse>
                            </div>
                        ) : (
                            <Callout intent="danger" className="create-routes__callout">
                                There are no patients selected, please select at least one patient
                                and try again.
                            </Callout>
                        )}
                        <h3 className="h3">Select route options</h3>
                        {vaccinationsWithWarnings.length > 0 && (
                            <Callout intent="danger" className="create-routes__callout">
                                The following patients have validation warnings, please see below
                                for details.
                                <Warnings warnings={vaccinationsWithWarnings} />
                                <Button
                                    text="Remove affected patient(s) from selection"
                                    onClick={onRemoveWarningPatients}
                                    outlined
                                />
                            </Callout>
                        )}
                        {multipleHubWarning && (
                            <Callout intent="danger" className="create-routes__callout">
                                The selected patients belong multiple hubs that cannot be combined
                                into a single route. Please refine your selection and try again.
                            </Callout>
                        )}
                        <div className="create-routes__select-date-time">
                            <div className="create-routes__date-time-controls">
                                <FormGroup label="Date" labelFor="date">
                                    <DateInput3
                                        inputProps={{ id: 'date' }}
                                        formatDate={formatDisplayDate}
                                        parseDate={parseDateStringToJSDate}
                                        onChange={(date) => {
                                            date && onNewSelectedDateChange(new Date(date));
                                        }}
                                        minDate={isSuperuser ? undefined : new Date()}
                                        maxDate={moment().add(1, 'year').toDate()}
                                        value={newSelectedDate?.toISOString()}
                                    />
                                </FormGroup>
                                <FormGroup label="Start time">
                                    <TimePicker
                                        onChange={onStartTimeChange}
                                        value={startTime}
                                        selectAllOnFocus
                                        maxTime={endTime}
                                    />
                                </FormGroup>
                                <FormGroup label="End time">
                                    <TimePicker
                                        onChange={onEndTimeChange}
                                        value={endTime}
                                        minTime={startTime}
                                        selectAllOnFocus
                                    />
                                </FormGroup>
                                {isCovidRoute && (
                                    <FormGroup label="Max. doses *">
                                        <NumericInput
                                            className="create-routes__numeric-input"
                                            onValueChange={onCapacityChange}
                                            value={capacity}
                                            min={1}
                                            selectAllOnFocus
                                            large
                                        />
                                    </FormGroup>
                                )}
                                <FormGroup label="Start/end location">
                                    <HTMLSelect
                                        onChange={onChangeHub}
                                        value={hub!.id}
                                        large
                                        options={hubOptions}
                                        disabled={multipleHubWarning}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-error-message">{selectedDateErrorMessage}</div>
                            {isCovidRoute && (
                                <span>
                                    * Applies only to COVID-19 vaccines, this is usually based on
                                    vial size
                                </span>
                            )}
                        </div>
                        <h3 className="h3">Select HCPs</h3>
                        <table className="bp5-html-table users-table bp5-interactive">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>User ID</th>
                                    <th>HCP Type(s)</th>
                                    <th>Availability</th>
                                    <th>Shifts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users
                                    .filter((user) => user.enabled && user.roles?.includes('hcp'))
                                    .map(({ userId, userName }) => {
                                        const schedulesOnSelectedDate = getUserSchedules(userId);

                                        if (schedulesOnSelectedDate.length === 0) return null;

                                        const shifts = schedulesOnSelectedDate
                                            .map(
                                                (schedule) =>
                                                    `${formatDisplayTime(
                                                        schedule.startDateTime,
                                                    )} to ${formatDisplayTime(
                                                        schedule.endDateTime,
                                                    )}`,
                                            )
                                            .join(', ');

                                        const availabilityMilliseconds =
                                            schedulesOnSelectedDate.reduce((mins, schedule) => {
                                                const range = rangeIntersect(schedule);
                                                if (!range) return mins;
                                                return (mins += range.valueOf());
                                            }, 0);

                                        const availabilityMins = moment
                                            .duration(availabilityMilliseconds)
                                            .asMinutes();

                                        const hours = Math.floor(availabilityMins / 60);
                                        const mins = Math.floor(availabilityMins % 60);

                                        const selected = selectedUsers.includes(userId);
                                        const toggleSelected = (
                                            event:
                                                | React.ChangeEvent<HTMLInputElement>
                                                | React.MouseEvent<HTMLElement>,
                                        ) => onToggleUser(userId, !selected, event);

                                        const formattedHcpTypes = getUserHcpTypes(userId);

                                        return (
                                            <tr
                                                key={userId}
                                                className={`users-table__row ${
                                                    selected ? 'users-table__row--selected' : ''
                                                }`}
                                                onClick={toggleSelected}
                                            >
                                                <td>
                                                    <Checkbox
                                                        checked={selected}
                                                        onChange={toggleSelected}
                                                    />
                                                </td>
                                                <td>{userName}</td>
                                                <td>{userId}</td>
                                                <td>
                                                    {formattedHcpTypes.length > 0
                                                        ? formattedHcpTypes.join(', ')
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {hours} {hours === 1 ? 'hour' : 'hours'}
                                                    {mins > 0 && <>, {mins} mins</>}
                                                </td>
                                                <td>{shifts}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {selectedDateTimeWarnings.length > 0 && (
                            <Callout intent="warning" className="create-routes__callout">
                                {selectedDateTimeWarnings.map((warning) => (
                                    <li key={warning}>{warning}</li>
                                ))}
                            </Callout>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default CreateRoutes;
