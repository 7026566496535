import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

import { AutocompletePlacesInputProps } from './AutocompletePlacesInput';

export const generateAddressFromPlace = (place: google.maps.places.PlaceResult) => {
    let addressLine1: string | undefined;
    let addressLine2: string | undefined;
    let addressLine3: string | undefined;
    let city: string | undefined;
    let postcode: string | undefined;
    let premise: string | undefined;
    let route: string | undefined;

    for (const addressComponent of place.address_components || []) {
        if (addressComponent.types.includes('premise')) {
            premise = addressComponent.long_name;
        }
        if (addressComponent.types.includes('street_number')) {
            addressLine1 = addressComponent.long_name;
        }
        if (addressComponent.types.includes('route')) {
            route = addressComponent.long_name;
        }
        if (addressComponent.types.includes('postal_town')) {
            city = addressComponent.long_name;
        }
        if (addressComponent.types.includes('postal_code')) {
            postcode = addressComponent.long_name;
        }
    }

    if (route && !premise) {
        addressLine1 = addressLine1 ? `${addressLine1} ${route}` : route;
    } else if (route && premise) {
        addressLine2 = addressLine1 ? `${addressLine1} ${route}` : route;
        addressLine1 = premise;
    } else if (premise) {
        addressLine1 = addressLine1 ? `${addressLine1} ${premise}` : premise;
    }

    if (place.types?.includes('establishment') && place.name && place.name !== '') {
        if (addressLine2) {
            addressLine3 = addressLine2;
        }
        addressLine2 = addressLine1;
        addressLine1 = place.name ?? '';
    }
    return { addressLine1, addressLine2, addressLine3, city, postcode };
};

export const useAutocompletePlacesInputViewModel = ({
    className,
    label,
    required,
    isContinueBtnClicked,
    displayErrors,
    onPlaceSelected,
    apiKey,
    ...props
}: AutocompletePlacesInputProps) => {
    const { ref } = usePlacesWidget<HTMLInputElement>({
        apiKey,
        onPlaceSelected: (place) => {
            onPlaceSelected(generateAddressFromPlace(place));
        },
        options: {
            types: [],
            fields: ['address_components', 'name', 'type'],
            componentRestrictions: { country: 'uk' },
        },
    });

    const [inputRef, setInputRef] = useState<React.RefObject<HTMLInputElement> | null>(ref);

    useEffect(() => {
        if (ref && ref.current instanceof HTMLInputElement) {
            setInputRef(ref);
        } else {
            setInputRef(null);
        }
    }, [ref]);

    const [field, meta] = useField({ ...props });

    return {
        field,
        meta,
        ref,
        inputRef,
    };
};
