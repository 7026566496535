import { Tooltip } from '@blueprintjs/core';
import { Patient } from '@doc-abode/data-models';
import { FC, Fragment } from 'react';

import { EnumJobContainer } from './JobTypes';
import { useWarningIconsViewModel } from './useWarningIconsViewModel';

type IWarningIcons = {
    container: EnumJobContainer;
    job: Patient;
    isBuddy: boolean;
    showTooltip?: boolean;
};

const WarningIcons: FC<IWarningIcons> = (props) => {
    const { container, job, isBuddy, showTooltip } = props;

    const { warningIcons } = useWarningIconsViewModel({
        container,
        job,
        isBuddy,
    });

    return (
        <div className="ucr__calendar-job-icons-container">
            {warningIcons.map((i) => (
                <Fragment key={job.id + i.name}>
                    {showTooltip ? (
                        <Tooltip
                            data-testid={`tooltip-${i.name}`}
                            popoverClassName={'ucr-listview__popover'}
                            key={job.id + 'has-warnings'}
                            content={i.tooltip}
                        >
                            {i.render()}
                        </Tooltip>
                    ) : (
                        i.render()
                    )}
                </Fragment>
            ))}
        </div>
    );
};

export default WarningIcons;
