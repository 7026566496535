const formatValue = (value: any) => {
    if (typeof value === 'object') {
        return value;
    }

    return {
        eq: value,
    };
};

const formatOutput = (key: string, values: any[] | { spread: any[] }) => {
    if (!Array.isArray(values)) {
        return values.spread;
    }

    return values.map((value) => {
        return {
            [key]: formatValue(value),
        };
    });
};

export default function filterBuilder(input: Record<string, any>) {
    const output: Record<string, any> = {};

    Object.entries(input).forEach(([key, values]: [string, any[] | { spread: any[] }]) => {
        if (Array.isArray(values) && values.length === 1) {
            output[key] = formatValue(values[0]);
        } else if ('or' in output) {
            output.and = [
                ...(output.and || []),
                {
                    or: formatOutput(key, values),
                },
            ];
        } else {
            output.or = formatOutput(key, values);
        }
    });

    return output;
}
