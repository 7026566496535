import { baseUrl, orgUrl, retryable } from './baseApi';

export const getVisitTimings = (authToken: string, from: string, to: string): Promise<any> => {
    return fetch(`${baseUrl}/reports/ucr/from/${from}/to/${to}`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
    });
};

export const getFileUploadParams = (authToken: string): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/jobs/upload`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const checkJobsUpload = (authToken: string, uploadId: string): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/jobs/upload/${uploadId}/confirm`, {
            method: 'GET',
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const confirmJobsUpload = (
    authToken: string,
    uploadId: string,
    body: { [key: string]: any },
): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/jobs/upload/${uploadId}/confirm`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const getActiveJobs = (authToken: string, data: { [key: string]: string }): Promise<any> =>
    retryable(() =>
        fetch(`${orgUrl}/activejobs?${new URLSearchParams(data)}`, {
            method: 'GET',
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const uploadFile = (
    key: string,
    file: string | Blob,
    uploadParams: { [key: string]: any },
): Promise<any> => {
    const formData = new FormData();

    Object.keys(uploadParams.fields).forEach((param) => {
        formData.append(param, uploadParams.fields[param]);
    });

    formData.append('key', key);
    formData.append('file', file);

    return fetch(uploadParams.url, {
        method: 'POST',
        body: formData,
    });
};
