import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'mobx-react';

import { useServiceWorker } from '../hook/useServiceWorker';
import RootStore from '../stores/RootStore';
import App from './App';
import Authenticator from './auth/Authenticator';
import AppShellErrorBoundary from './common/ErrorBoundaries/AppShellBoundary';
import Footer from './layout/Footer';
import { EnvironmentInfo } from './modules/callout/EnvironmentInfo';
import { ServiceWorkerUpdate } from './modules/callout/ServiceWorkerUpdate';

const rootStore = new RootStore();

const queryClient = new QueryClient();

export const AppShell = () => {
    const { isUpdateReady, applyUpdate } = useServiceWorker();

    const callouts = [];

    if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
        callouts.push(<EnvironmentInfo key="environment-info" />);
    }

    if (isUpdateReady) {
        callouts.push(
            <ServiceWorkerUpdate applyUpdate={applyUpdate} key="service-worker-update" />,
        );
    }

    return (
        <>
            <div className={`container container--callout-x${callouts.length}`}>
                {callouts}
                <Provider RootStore={rootStore}>
                    <ErrorBoundary fallback={AppShellErrorBoundary}>
                        <Authenticator RootStore={rootStore}>
                            <QueryClientProvider client={queryClient}>
                                <App />
                            </QueryClientProvider>
                        </Authenticator>
                    </ErrorBoundary>
                    <Footer />
                </Provider>
            </div>
            <div id="custom-prompt"></div>
        </>
    );
};
