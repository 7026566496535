import { inject, observer } from 'mobx-react';
import React, { Component, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import Controller from './controller/Main';

const RedirectToHome = ({ setRedirectToHome }) => {
    useEffect(() => {
        setRedirectToHome(false);
    }, [setRedirectToHome]);

    return <Redirect to="/" />;
};

const Layout = inject('RootStore')(
    observer(
        class Layout extends Component {
            render() {
                const {
                    userStore: { redirectToHome, setRedirectToHome },
                } = this.props.RootStore;

                if (redirectToHome) {
                    return <RedirectToHome setRedirectToHome={setRedirectToHome} />;
                }

                return <Controller {...this.props} />;
            }
        },
    ),
);

export default Layout;
