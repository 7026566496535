import { FC, memo } from 'react';

import transformHour from '../../../../../helpers/transformHour';

interface IProps {
    hour: number;
    cellWidth: number;
}

const Hour: FC<IProps> = ({ hour, cellWidth }) => {
    return (
        <div className="ucr__calendar-hour-item" style={{ width: cellWidth + 'px' }}>
            <span className="ucr__calendar-hour-text">{transformHour(hour)}</span>
        </div>
    );
};

export default memo(Hour);
