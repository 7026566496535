import { FC, ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface IProps {
    children: ReactNode;
}

const Provider: FC<IProps> = ({ children }) => {
    return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
};

export default Provider;
