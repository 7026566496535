export const compareByLabels = (a: any, b: any) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
    }
    return 0;
};

export const compareByName = (a: any, b: any) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    return 0;
};

export const compareByValues = (a: any, b: any) => {
    if (a.value < b.value) {
        return -1;
    }
    if (a.value > b.value) {
        return 1;
    }
    return 0;
};
