import { JobStatus, Patient } from '@doc-abode/data-models';

import { IHcp } from '../../interfaces';

export function getFormattedHcpUserForRadioLabelChooser(
    job: Patient,
    user: IHcp | undefined,
    userNumber: 1 | 2,
): string {
    // Defaulting to unassigned to keep logic simple
    let userName = 'Unassigned';
    if (user) {
        userName = user.userName;
    }

    // We'll want to show a job status even if the job is unassigned, e.g. an aborted part for an unassigned user
    let jobStatusLabel: string;
    if (userNumber === 1) {
        jobStatusLabel = job.friendlyVisitStatus;
    } else {
        jobStatusLabel = `${Patient.getFriendlyVisitStatus(job.buddyJobStatus as JobStatus)}`;
    }

    if (jobStatusLabel) {
        jobStatusLabel = jobStatusLabel.toUpperCase();
    }
    const label = `#${userNumber} - ${userName} - ${jobStatusLabel}`;

    return label;
}
