import { Patient } from '@doc-abode/data-models';
import { Form } from 'formik';
import { FC } from 'react';

import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../v2/components';
import { Select, TextArea } from '../../../../v2/form';
import { defaultOption, useAbortDoubleVisitViewModel } from './useAbortDoubleVisitViewModel';

interface IProps {
    visit: Patient;
    loading: boolean;
    onClose: () => void;
}

const AbortDoubleVisitForm: FC<IProps> = ({ visit, loading, onClose }) => {
    const {
        isDoubleUp,
        labelUser1,
        labelUser2,
        isHcp1InAbortableState,
        isHcp2InAbortableState,
        reasonsForAborting,
        areAbortNotesMandatory,
        isControllerAbortedReasonDisabled,
        isConfirmAbortVisitDisabled,
    } = useAbortDoubleVisitViewModel({ visit, loading });

    return (
        <Form>
            <ConditionalDisplay show={isDoubleUp}>
                <div className="v2__dialog-block">
                    <p>This is a double-up visit. Please select cancelation option</p>
                    <p>{labelUser1}</p>
                    <p>{labelUser2}</p>
                </div>
                <div className="v2__dialog-block">
                    <Select
                        label="Cancelation option"
                        name="cancelationOption"
                        options={[
                            defaultOption,
                            {
                                label: labelUser1,
                                value: 'user1',
                                disabled: !isHcp1InAbortableState,
                            },
                            {
                                label: labelUser2,
                                value: 'user2',
                                disabled: !isHcp2InAbortableState,
                            },
                            {
                                label: 'All jobs',
                                value: 'all',
                                disabled: !isHcp1InAbortableState || !isHcp2InAbortableState,
                            },
                        ]}
                        required
                    />
                </div>
            </ConditionalDisplay>
            <div className="v2__dialog-block">
                <Select
                    label="Reason for aborting"
                    name="controllerAbortedReason"
                    options={reasonsForAborting}
                    disabled={isControllerAbortedReasonDisabled}
                    required
                />
            </div>
            <div className="v2__dialog-block">
                <TextArea
                    label="Notes"
                    name="controllerAbortedNotes"
                    className="v2__form-group--pos-1-1"
                    required={areAbortNotesMandatory}
                />
            </div>
            <div className="v2__dialog-buttons v2__dialog-buttons--left">
                <Button
                    name="Cancel"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    color={ButtonColors.GREY}
                    disabled={loading}
                    clickEvent={onClose}
                />
                <Button
                    name="Confirm abort job"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    type="submit"
                    color={ButtonColors.RED}
                    disabled={isConfirmAbortVisitDisabled}
                />
            </div>
        </Form>
    );
};

export default AbortDoubleVisitForm;
