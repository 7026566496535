import { Alert, Button, Intent, Tooltip } from '@blueprintjs/core';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Redirect } from 'react-router-dom';

import useStores from '../../../../hook/useStores';
import { TooltipsAccessRights } from '../../../../interfaces/admin';
import RootStore from '../../../../stores/RootStore';
import { genderOptions } from '../../../pages/ucr/components/filters/UcrFilterOptions';
import { Checkbox, MultiSelect, PhoneInput, Select, TextInput } from '../../../v2/form';
import { FormGroup } from '../../../v2/form/FormGroup';
import Loader from '../../helpers/Loader';
import AppToaster from '../../helpers/Toaster';
import Validation from './Validation';

const s1AlertText =
    'Because you have not provided a SystmOne username, Doc Abode will not be able to manage diary entries on SystmOne on behalf of the health care professional.';

const AddUser = () => {
    const {
        RootStore: {
            userStore: { isComplianceOfficer },
            usersStore: { addUser },
            lovsStore: { gender, hcpType: hcpOptions, languagesSpoken, speciality },
            configStore: { confirmRegisterHCPText, isFeatureEnabled, bands: bandOptions },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { validationSchema, initialValues, getAPIReadyUserData } = Validation();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [values, setValues] = useState(initialValues);

    const handleSubmit = (values: typeof initialValues) => {
        setValues(values);
        setIsOpenConfirm(true);
    };

    const handleConfirm = async () => {
        setIsOpenConfirm(false);
        setIsLoading(true);
        const userData = getAPIReadyUserData(values, validationSchema);
        const apiResponseStatus = await addUser(userData);

        if (apiResponseStatus.response.status === 200) {
            AppToaster.show({
                message: 'User was added successfully!',
                intent: 'success',
            });
        } else if (apiResponseStatus.response.status === 409) {
            AppToaster.show({
                message: 'User already exists for provided email address or phone number.',
                intent: 'danger',
            });
        } else {
            AppToaster.show({
                message:
                    'User was not added. Please check the details you have added and try again.',
                intent: 'danger',
            });
        }
        setIsLoading(false);
    };

    if (!isFeatureEnabled('addUser') || isComplianceOfficer) {
        return <Redirect to="/page-not-found" />;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={validationSchema}
        >
            {({ setFieldValue }) => {
                return (
                    <div className="page">
                        {isLoading && <Loader />}
                        <h1 className="h1">Add User</h1>
                        <Form className="add-user-form">
                            <Row>
                                <Col md={6}>
                                    <TextInput label="First Name" name="firstName" required />

                                    <TextInput label="Last Name" name="lastName" required />

                                    <TextInput label="Email Address" name="email" required />

                                    <PhoneInput label="Phone number" name="phoneNumber" required />

                                    <Select
                                        label="Gender"
                                        name="gender"
                                        options={genderOptions(gender)}
                                        required
                                    />

                                    <Select
                                        label="Band"
                                        name="band"
                                        options={bandOptions}
                                        withEmptyOption
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormGroup
                                        label="Role(s)"
                                        labelFor="roles"
                                        className="v2__form-group"
                                    >
                                        <Tooltip
                                            popoverClassName={'ucr-listview__popover'}
                                            content={TooltipsAccessRights.HCP_MOBILE}
                                        >
                                            <Checkbox
                                                label="Mobile"
                                                name="roles"
                                                value="hcp"
                                                inline
                                                data-test="role-hcp"
                                                withFormGroup={false}
                                                title=""
                                            />
                                        </Tooltip>
                                        <Tooltip
                                            popoverClassName={'ucr-listview__popover'}
                                            content={TooltipsAccessRights.CONTROLLER}
                                        >
                                            <Checkbox
                                                label="Controller"
                                                name="roles"
                                                value="controller"
                                                inline
                                                data-test="role-controller"
                                                withFormGroup={false}
                                            />
                                        </Tooltip>
                                        <Tooltip
                                            popoverClassName={'ucr-listview__popover'}
                                            content={TooltipsAccessRights.ADMIN}
                                        >
                                            <Checkbox
                                                label="Admin"
                                                name="roles"
                                                value="org_admin"
                                                inline
                                                data-test="role-org_admin"
                                                withFormGroup={false}
                                            />
                                        </Tooltip>
                                    </FormGroup>

                                    <MultiSelect
                                        label="HCP Type(s)"
                                        name="hcpTypes"
                                        options={hcpOptions}
                                        required
                                        inputId="hcpTypes"
                                    />

                                    <MultiSelect
                                        label="Languages"
                                        name="languages"
                                        options={languagesSpoken}
                                        inputId="languages"
                                    />

                                    <MultiSelect
                                        label="Speciality"
                                        name="specialities"
                                        options={speciality}
                                        inputId="specialities"
                                    />
                                    {isFeatureEnabled('s1Enabled') && (
                                        <TextInput label="SystmOne Username" name="s1Username" />
                                    )}
                                </Col>
                            </Row>

                            {/* SUBMIT BUTTON */}
                            <footer className="form-footer">
                                <Button intent="success" large type="submit" icon="tick">
                                    Add user
                                </Button>
                            </footer>

                            <Alert
                                isOpen={isOpenConfirm}
                                onConfirm={handleConfirm}
                                onCancel={() => setIsOpenConfirm(false)}
                                cancelButtonText="Cancel"
                                confirmButtonText="Confirm"
                                icon="warning-sign"
                                intent={Intent.PRIMARY}
                                className="dialog--wide"
                            >
                                <h2 className="h2">Important Note</h2>
                                <p>{confirmRegisterHCPText}</p>
                                {isFeatureEnabled('s1Enabled') && values.s1Username === '' && (
                                    <p>{s1AlertText}</p>
                                )}
                            </Alert>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
};

export default observer(AddUser);
