import {
    AlertCategory,
    AlertStatus,
    IAlert,
    PatientAlert,
    StaffAlert,
} from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import Info from '../../../../../v2/components/Info';
import InfoItem from '../../../../../v2/components/InfoItem';
import { AlertStatusTimeAndUser } from './AlertStatusTimeAndUser';

interface IProps {
    alert: IAlert;
}

export const AlertStatusTimesAndUsers: FC<IProps> = observer(({ alert }) => {
    // TODO - Fix data model in a future iteration, for some odd reason we added the resolution to each model rather than the parent model
    let alertResolutionType;

    switch (alert.category) {
        case AlertCategory.PATIENT:
            alertResolutionType = (alert as PatientAlert).resolution;
            break;
        case AlertCategory.STAFF:
            alertResolutionType = (alert as StaffAlert).resolution;
            break;
        default:
            break;
    }

    return (
        <Info>
            <AlertStatusTimeAndUser
                label="Raised:"
                entryAt={alert.createdAt}
                entryBy={alert.createdBy}
            />
            <ConditionalDisplay show={alert.acknowledged}>
                <AlertStatusTimeAndUser
                    label="Acknowledged:"
                    entryAt={alert.acknowledgedAt}
                    entryBy={alert.acknowledgedBy}
                />
            </ConditionalDisplay>
            <ConditionalDisplay show={alert.status === AlertStatus.RESOLVED}>
                <AlertStatusTimeAndUser
                    label="Resolved:"
                    entryAt={alert.resolvedAt}
                    entryBy={alert.resolvedBy}
                />
                <ConditionalDisplay show={!!alertResolutionType}>
                    <InfoItem label="Resolution type:">{alertResolutionType}</InfoItem>
                </ConditionalDisplay>
                <ConditionalDisplay show={!!alert.resolutionNotes}>
                    <InfoItem label="Resolution notes:">{alert.resolutionNotes}</InfoItem>
                </ConditionalDisplay>
            </ConditionalDisplay>
        </Info>
    );
});
