import { FormikValues } from 'formik';
import { observer } from 'mobx-react';

import { Text } from '../../../../v2/form';
import {
    ReferralDetailsVMInterface,
    useReferralDetailsViewModel,
} from './useReferralDetailsViewModel';

export const ReferralDetailsReview = observer(({ values }: { values: FormikValues }) => {
    const { systmOneRef, systmOneRefLabel, referralDate, referralTime, s1Enabled } =
        useReferralDetailsViewModel({
            values,
        } as ReferralDetailsVMInterface);

    // The referrer need to be conditionally placed depending on the S1 feature flag
    const maxPosition = s1Enabled ? '2' : '4';

    return (
        <div className="v2__form-block">
            <Text
                name="systmOneRef"
                title={systmOneRefLabel}
                description={systmOneRef}
                className={`v2__form-group--pos-1-${maxPosition}`}
            />
            <Text
                name="referrer"
                title="Referrer"
                description={values.referrer}
                className={`v2__form-group--pos-2-${maxPosition}`}
            />
            {!s1Enabled && (
                <>
                    <Text
                        name="referralDateTime"
                        title="Referral date"
                        description={referralDate}
                        className="v2__form-group--pos-3-4"
                    />
                    <Text
                        name="referralDateTime"
                        title="Referral time"
                        description={referralTime}
                        className="v2__form-group--pos-4-4"
                    />
                </>
            )}
        </div>
    );
});
