import { TextArea, TextAreaProps } from '@blueprintjs/core';
import { useField } from 'formik';
import { FC } from 'react';

import { FormGroup } from '../FormGroup';

interface IProps extends TextAreaProps {
    name: string;
    className?: string;
    label?: string;
    required?: boolean;
}

const TextAreaComponent: FC<IProps> = ({ className, label, required, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props.name}
            required={required}
            touched={meta.touched}
            error={meta.error}
        >
            <TextArea
                {...field}
                {...props}
                id={props.name}
                className="v2__form-textarea-input"
                fill
            />
        </FormGroup>
    );
};

export default TextAreaComponent;
