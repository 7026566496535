import { InputGroup, InputGroupProps2 } from '@blueprintjs/core';
import { FC } from 'react';

import FormGroup from '../FormGroup';
import { useAutocompletePlacesInputViewModel } from './useAutocompletePlacesInputViewModel';

export interface Place {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    postcode?: string;
}

export interface AutocompletePlacesInputProps extends InputGroupProps2 {
    name: string;
    className?: string;
    label?: string;
    required?: boolean;
    displayErrors?: string;
    isContinueBtnClicked?: boolean;
    onPlaceSelected: (place: Place) => void;
    apiKey: string;
}

const AutocompletePlacesInput: FC<AutocompletePlacesInputProps> = ({
    className,
    label,
    required,
    isContinueBtnClicked,
    displayErrors,
    onPlaceSelected,
    apiKey,
    ...props
}) => {
    const { field, inputRef, meta } = useAutocompletePlacesInputViewModel({
        className,
        label,
        required,
        isContinueBtnClicked,
        displayErrors,
        onPlaceSelected,
        apiKey,
        ...props,
    });

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props.name}
            required={required}
            touched={meta.touched}
            error={meta.error}
            displayErrors={displayErrors}
        >
            <InputGroup
                inputRef={inputRef}
                {...field}
                {...props}
                id={props.name}
                className="v2__form-text-input"
            />
        </FormGroup>
    );
};

export default AutocompletePlacesInput;
