import { getRoutes } from '../controller/getRoutes';
import { getTabId } from './getTabId';
import { getTabTarget } from './getTabTarget';

export function getHomepageTab(
    isController: boolean,
    isAdmin: boolean,
    isSuperuser: boolean,
    isComplianceOfficer: boolean,
    isFeatureEnabled: (feature: string) => boolean,
): string | undefined {
    let routes = getRoutes(
        isController,
        isAdmin,
        isSuperuser,
        isComplianceOfficer,
        isFeatureEnabled,
    );

    if (routes.length === 0) {
        return undefined;
    }

    return getTabTarget(routes[0]);
}

export function reverseLookupTab(
    path: string,
    isController: boolean,
    isAdmin: boolean,
    isSuperuser: boolean,
    isComplianceOfficer: boolean,
    isFeatureEnabled: (feature: string) => boolean,
): string | undefined {
    let routes = getRoutes(
        isController,
        isAdmin,
        isSuperuser,
        isComplianceOfficer,
        isFeatureEnabled,
    );

    if (routes.length === 0) {
        return undefined;
    }

    const reverseMappedRoute = routes.find((route) => path.startsWith(getTabTarget(route)));

    if (!reverseMappedRoute) {
        return undefined;
    }

    return getTabId(reverseMappedRoute);
}
