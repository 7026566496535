import { observer } from 'mobx-react';
import { FC } from 'react';

import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Dialog } from '../../../../v2/overlays';
import { SummaryJobList } from '../../components/summaryJobList/SummaryJobList';
import { ResolveStaffAlert } from './staffAlert/ResolveStaffAlert';
import { StaffAlertDetails } from './staffAlert/StaffAlertDetails';
import { StaffAlertMap } from './staffAlert/StaffAlertMap';
import { StaffAlertMessages } from './staffAlert/StaffAlertMessages';
import { useStaffAlertViewModel } from './staffAlert/useStaffAlertViewModel';

const StaffAlertDialog: FC = () => {
    const {
        showDialog,
        showResolutionForm,
        isAlertUpdating,
        alert,
        jobs,
        onClickUpdateAlert,
        onClickCancel,
        showAlertOnMap,
        mapClientKeys,
        resolutionReasons,
    } = useStaffAlertViewModel();

    // Bail here if there is no alert
    if (!alert) {
        return <></>;
    }

    return (
        <Dialog isShow={showDialog} closeEvent={onClickCancel} title="Staff Alert">
            <StaffAlertDetails alert={alert} />
            <ConditionalDisplay show={showResolutionForm}>
                <ResolveStaffAlert
                    onSubmit={onClickUpdateAlert}
                    onCancel={onClickCancel}
                    areButtonsDisabled={isAlertUpdating}
                    resolutionReasons={resolutionReasons}
                />
            </ConditionalDisplay>
            <StaffAlertMessages messages={alert?.messages} />
            <ConditionalDisplay show={showAlertOnMap}>
                <StaffAlertMap alert={alert} apiKey={mapClientKeys} />
            </ConditionalDisplay>
            <SummaryJobList jobs={jobs} />
        </Dialog>
    );
};

export default observer(StaffAlertDialog);
