import ConfigStore from './ConfigStore';
import JobsStore from './JobsStore';
import LovsStore from './LovsStore';
import MapStore from './MapStore';
import PatientListStore from './PatientListStore';
import S1Store from './S1Store';
import SchedulesStore from './SchedulesStore';
import UCRStore from './UCRStore';
import UsersStore from './UsersStore';
import UserStore from './UserStore';

class RootStore {
    userStore: UserStore;
    jobsStore: JobsStore;
    vaccinationsStore: JobsStore;
    routesStore: JobsStore;
    lovsStore: LovsStore;
    usersStore: UsersStore;
    configStore: ConfigStore;
    schedulesStore: SchedulesStore;
    ucrStore: UCRStore;
    patientListStore: PatientListStore;
    mapStore: MapStore;
    s1Store: S1Store;

    constructor() {
        this.userStore = new UserStore(this);
        this.jobsStore = new JobsStore(this);
        this.vaccinationsStore = new JobsStore(this, 'vaccination');
        this.routesStore = new JobsStore(this, 'route');
        this.lovsStore = new LovsStore(this);
        this.usersStore = new UsersStore(this);
        this.configStore = new ConfigStore(this);
        this.schedulesStore = new SchedulesStore(this);
        this.ucrStore = new UCRStore(this);
        this.patientListStore = new PatientListStore();
        this.mapStore = new MapStore(this);
        this.s1Store = new S1Store(this);
    }
}

export default RootStore;
