import { Patient } from '@doc-abode/data-models';
import { formatNameLastMiddleFirst } from '@doc-abode/helpers';

import { getPathwayServiceOrAdminTypeLabels } from '../../../../../helpers/ucr/getPathwayServiceOrAdminTypeLabels';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';

interface IProps {
    job: Patient;
}

export const useSummaryJobListRowViewModel = ({ job }: IProps) => {
    const {
        RootStore: {
            configStore: { pathways, adminTypes },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { pathwayDisplayLabel, serviceOrAdminTypeDisplayLabel } =
        getPathwayServiceOrAdminTypeLabels(job, pathways, adminTypes);

    return {
        startTimeDisplayLabel: job.startDateTime
            ? formatDisplayDateTime(job.startDateTime)
            : 'No start time',
        pathwayDisplayLabel,
        serviceOrAdminTypeDisplayLabel,
        patientName:
            formatNameLastMiddleFirst({
                firstName: job.firstName,
                middleName: job.middleName,
                lastName: job.lastName,
            }) || 'N/A',
        statusLabel: Patient.getFriendlyVisitStatus(job.jobStatus),
    };
};
