import React, { Component } from 'react';

export default class Error extends Component {
    render() {
        return (
            <div className="form-error-wrapper">
                {this.props.errors.map((error, index) => (
                    <div className="form-error-message" key={`${index}-error`}>
                        {error}
                    </div>
                ))}
            </div>
        );
    }
}
