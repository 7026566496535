import { DispositionType, JobStatus, Patient } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, useEffect } from 'react';

import { getHcpName } from '../../../../../../helpers';
import { shouldShowPostJobNotes } from '../../../../../../helpers/shouldShowPostJobNotes';
import { getPathwayServiceOrAdminTypeLabels } from '../../../../../../helpers/ucr/getPathwayServiceOrAdminTypeLabels';
import { isMultiAssigneeJob } from '../../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { Info, InfoItem } from '../../../../../v2/components';
import { HcpSelect } from '../../../components/HcpSelect';
import { friendlyCareComplexity } from '../../../forms/common/helpers';
import { VisitValuesType } from '../VisitDetailsTypes';

type IProps = {
    job: Patient;
    isFirstUser: boolean;
    isStaffMemberEditable: boolean;
    onHcpReassignment: (userId: string) => void;
};

const InfoPanelJobDetailsSection: FC<IProps> = ({
    job,
    isFirstUser,
    isStaffMemberEditable,
    onHcpReassignment,
}) => {
    const {
        RootStore: {
            usersStore: { users },
            configStore: { pathways, adminTypes },
            schedulesStore: { getSchedulesByDate },
        },
    } = useStores<{ RootStore: RootStore }>();

    const {
        notes,
        postVisitNotes,
        postVisitNotesBuddy,
        referrer,
        careComplexity,
        carRequired,
        staffRequired,
        hcpId,
        buddyId,
        dateOfVisit,
    } = job;

    const isDoubleUp = isMultiAssigneeJob(job);

    const isAdmin = DispositionType.ADMIN === job.disposition;

    const userId = isFirstUser ? hcpId : buddyId;

    const staffName = getHcpName(users, userId, false);

    let jobState: JobStatus | undefined = job?.jobStatus;

    let postJobNotesLabel = 'Post-job notes';
    let postJobNotesDisplayVal = postVisitNotes;

    if (isDoubleUp) {
        if (isFirstUser) {
            postJobNotesLabel = postJobNotesLabel.concat(' #1');
        } else {
            jobState = job?.buddyJobStatus;
            postJobNotesLabel = postJobNotesLabel.concat(' #2');
            postJobNotesDisplayVal = postVisitNotesBuddy;
        }
    }
    const showPostJobNotes = shouldShowPostJobNotes({ jobStatus: jobState });

    useEffect(() => {
        // Only load schedules if the staff assignment dropdown is available
        if (isStaffMemberEditable) {
            getSchedulesByDate(moment(dateOfVisit).format('YYYY-MM-DD'));
        }
    }, [dateOfVisit, getSchedulesByDate, isStaffMemberEditable]);

    const { pathwayDisplayLabel, serviceOrAdminTypeDisplayLabel } =
        getPathwayServiceOrAdminTypeLabels(job, pathways, adminTypes);

    const staffNumberSuffix = isFirstUser ? '#1' : '#2';

    return (
        <div className="visit-details__visit">
            <Info>
                <ConditionalDisplay show={!isAdmin}>
                    <InfoItem label="# Staff required">{staffRequired || 1}</InfoItem>
                </ConditionalDisplay>
                <InfoItem label={`Staff member ${isDoubleUp ? staffNumberSuffix : ''}`}>
                    {isStaffMemberEditable ? (
                        <HcpSelect
                            patient={job as VisitValuesType}
                            isFirstUser={isFirstUser}
                            onHcpReassignment={onHcpReassignment}
                            insideForm={false}
                            includeUnavailableHcps={false}
                            isAdmin={isAdmin}
                        />
                    ) : (
                        staffName
                    )}
                </InfoItem>
                <ConditionalDisplay show={!isAdmin}>
                    <InfoItem label="Referrer">{referrer}</InfoItem>
                    <InfoItem label="Pathway">{pathwayDisplayLabel}</InfoItem>
                    <InfoItem label="Service">{serviceOrAdminTypeDisplayLabel}</InfoItem>
                    <InfoItem label="Complexity">
                        {careComplexity ? friendlyCareComplexity[careComplexity] : null}
                    </InfoItem>
                    <InfoItem label="Car required">{carRequired ? 'Yes' : 'No'}</InfoItem>
                </ConditionalDisplay>
                <ConditionalDisplay show={isAdmin}>
                    <InfoItem label="Activity type">{serviceOrAdminTypeDisplayLabel}</InfoItem>
                </ConditionalDisplay>
                <InfoItem label="Pre-job notes" alignSelf="start">
                    {notes}
                </InfoItem>
                <ConditionalDisplay show={showPostJobNotes}>
                    <InfoItem label={postJobNotesLabel} alignSelf="start">
                        {postJobNotesDisplayVal}
                    </InfoItem>
                </ConditionalDisplay>
            </Info>
        </div>
    );
};

export default observer(InfoPanelJobDetailsSection);
