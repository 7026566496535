import cn from 'classnames';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';

import { ViewToShow } from '../../../../constants/mainConst';
import { IconFilterAlt, IconLegend, IconVisits } from '../../../../helpers/ucr/icons';
import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';
import { Visits } from '../components';
import FiltersPanel from '../components/filters/FiltersPanel';
import Legend from '../components/Legend';
import PatientFiltersPanel from '../components/patientView/FiltersPanel';
import { Arrow, SideButton } from '../components/side';

interface IProps {}

interface PanelInfo {
    id: string;
    name: string;
    pos: string;
    text?: string;
    badgeContent?: string;
    textAction?: () => void;
    Icon: FC;
    dataTest: string;
    Panel: FC<{ closePanel(): void; isShow: boolean }>;
    fullSizeIcon?: boolean;
}

const defaultPanels: PanelInfo[] = [
    {
        id: 'unassigned-visits',
        name: 'Unassigned visits',
        Icon: IconVisits,
        dataTest: 'button-unassigned-visits',
        Panel: Visits,
        pos: 'top',
    },
    {
        id: 'legend',
        name: 'Legend Panel',
        Icon: IconLegend,
        dataTest: 'button-legend-panel',
        Panel: Legend,
        pos: 'top',
    },
];

function useGetPanels(): PanelInfo[] | undefined {
    const {
        RootStore: {
            ucrStore: { viewToShow, clearAllFilters, appliedFilters },
            mapStore: { appliedFilters: mapAppliedFilters, clearAllFilters: mapClearAllFilters },
            patientListStore: {
                appliedFilters: patientAppliedFilters,
                clearAllFilters: patientClearAllFilters,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    switch (viewToShow) {
        case ViewToShow.PATIENTS_LIST:
            return [
                defaultPanels[1],
                {
                    id: 'filters',
                    name: 'Filters Panel',
                    text: patientAppliedFilters ? 'Clear' : undefined,
                    badgeContent: patientAppliedFilters ? `${patientAppliedFilters}` : undefined,
                    textAction: patientClearAllFilters,
                    Icon: IconFilterAlt,
                    dataTest: 'button-filter-panel',
                    Panel: PatientFiltersPanel,
                    pos: '',
                    fullSizeIcon: true,
                },
            ];
        case ViewToShow.MAP:
            return [
                ...defaultPanels,
                {
                    id: 'filters',
                    name: 'Filters Panel',
                    text: mapAppliedFilters ? 'Clear' : undefined,
                    badgeContent: mapAppliedFilters ? `${mapAppliedFilters}` : undefined,
                    textAction: mapClearAllFilters,
                    Icon: IconFilterAlt,
                    dataTest: 'button-filter-panel',
                    Panel: FiltersPanel,
                    pos: '',
                    fullSizeIcon: true,
                },
            ];
        case ViewToShow.TIMELINE:
        case ViewToShow.VISITS_LIST:
            return [
                ...defaultPanels,
                {
                    id: 'filters',
                    name: 'Filters Panel',
                    text: appliedFilters ? 'Clear' : undefined,
                    badgeContent: appliedFilters ? `${appliedFilters}` : undefined,
                    textAction: clearAllFilters,
                    Icon: IconFilterAlt,
                    dataTest: 'button-filter-panel',
                    Panel: FiltersPanel,
                    pos: '',
                    fullSizeIcon: true,
                },
            ];
    }
}

const Right: FC<IProps> = () => {
    const {
        RootStore: {
            ucrStore: { viewToShow },
        },
    } = useStores<{ RootStore: RootStore }>();
    const sidePanels = useGetPanels() || [];

    const [openedPanel, setOpenedPanel] = useState<PanelInfo | undefined>();

    const closePanel = () => setOpenedPanel(undefined);

    useEffect(() => {
        // When the views change automatically close the panel
        if (viewToShow) {
            closePanel();
        }
    }, [viewToShow]);

    return (
        <aside
            className={cn('ucr__side', 'ucr__side--right', 'ucr__side--small', {
                'ucr__side--panel': openedPanel,
            })}
        >
            <div
                className={cn(
                    'ucr__side-wrapper',
                    'ucr__side-wrapper--right',
                    'ucr__side-wrapper--small',
                )}
            >
                <div className="ucr__side-content">
                    {sidePanels.map((panel) => (
                        <SideButton
                            key={`side-button-${panel.id}`}
                            size="small"
                            isActive={panel.id === openedPanel?.id}
                            clickEvent={() => setOpenedPanel(panel)}
                            {...panel}
                        />
                    ))}
                </div>

                <Arrow
                    panel="right"
                    isReverse={!!openedPanel}
                    isDisabled={!openedPanel}
                    clickEvent={closePanel}
                />
            </div>

            {/* We need to render all panels because Calendar DnD depends on it */}
            {sidePanels.map((panel, i) => {
                const { Panel } = panel;
                return (
                    <Panel
                        key={panel.id}
                        isShow={panel.id === openedPanel?.id}
                        closePanel={closePanel}
                    />
                );
            })}
        </aside>
    );
};

export default observer(Right);
