import { Tooltip } from '@blueprintjs/core';
import { JobStatus } from '@doc-abode/data-models';
import cn from 'classnames';
import { FC } from 'react';

import { AddressWarningIcon } from '../../../../../helpers/ucr/icons';
import { IHCPBreakdown } from '../../../../../stores/MapStoreTypes';
interface IProps {
    index: number;
    breakdown: IHCPBreakdown;
    mapViewBreakDown: IHCPBreakdown[];
    setBoundedArea?: (breakdown: IHCPBreakdown) => void;
    hasAddressWarning?: boolean;
}

interface IBreakdown {
    breakdown: IHCPBreakdown;
    className?: string;
}

const RenderDurationOrTime: FC<IBreakdown> = ({ breakdown }) => {
    if (JobStatus.HCP_ABORTED === breakdown.status) {
        return (
            <div className="ucr__hcp-mapview-breakdown-duration">
                HCP Aborted at {breakdown.abortedDateTime}
            </div>
        );
    }

    if (JobStatus.CONTROLLER_ABORTED === breakdown.status) {
        return (
            <div className="ucr__hcp-mapview-breakdown-duration">
                Aborted at {breakdown.finishedDateTime}
            </div>
        );
    }

    if (JobStatus.COMPLETED === breakdown.status) {
        return (
            <div className="ucr__hcp-mapview-breakdown-duration">
                Completed at {breakdown.finishedDateTime}
            </div>
        );
    }

    return <div className="ucr__hcp-mapview-breakdown-duration">{breakdown.duration}</div>;
};

const RenderTimeBreakDown: FC<IBreakdown> = ({ breakdown, className }) => {
    if (
        JobStatus.HCP_ABORTED === breakdown.status ||
        JobStatus.CONTROLLER_ABORTED === breakdown.status ||
        JobStatus.COMPLETED === breakdown.status
    ) {
        return (
            <div>
                <span className={`ucr__hcp-mapview-breakdown-strikethrough ${className}`}>
                    {breakdown.expectedDateTime}
                </span>
                <span
                    className={`${
                        !!breakdown.arrivedDateTime ? 'ucr__hcp-mapview-breakdown-arrivedTime' : ''
                    }`}
                >
                    {breakdown.arrivedDateTime}
                </span>
            </div>
        );
    }

    if (JobStatus.ARRIVED === breakdown.status || JobStatus.CURRENT === breakdown.status) {
        const time = JobStatus.ARRIVED ? breakdown.arrivedDateTime : breakdown.madeCurrentDateTime;

        return (
            <div>
                <span
                    className={`${
                        !!breakdown.madeCurrentDateTime
                            ? 'ucr__hcp-mapview-breakdown-arrivedTime'
                            : ''
                    }`}
                >
                    {time}
                    {breakdown.status === JobStatus.CURRENT ? ' (Current)' : ' (Arrived)'}
                </span>
            </div>
        );
    }

    return <div>{breakdown.expectedDateTime}</div>;
};

const HCPBreakdown: FC<IProps> = ({
    breakdown,
    index,
    mapViewBreakDown,
    setBoundedArea,
    hasAddressWarning,
}) => {
    return (
        <>
            <div
                className={`ucr__hcp-mapview-breakdown-container--child ${
                    breakdown.patientAlert ? 'ucr__hcp-mapview-breakdown-container--alert' : ''
                }`}
                onClick={() => {
                    if (setBoundedArea) setBoundedArea(breakdown);
                }}
            >
                {Boolean(
                    breakdown.status === JobStatus.COMPLETED ||
                        breakdown.status === JobStatus.HCP_ABORTED ||
                        breakdown.status === JobStatus.CONTROLLER_ABORTED,
                ) ? (
                    <div
                        className={`ucr__hcp-mapview-breakdown--statuscircle ucr__hcp-mapview-breakdown--statuscircle--${breakdown.status}`}
                    ></div>
                ) : (
                    <div
                        className={`ucr__hcp-mapview-breakdown--timecircle ucr__hcp-mapview-breakdown--timecircle--${
                            breakdown.status || JobStatus.PENDING
                        } ${
                            hasAddressWarning
                                ? 'ucr__hcp-mapview-breakdown--timecircle--warning'
                                : ''
                        }`}
                    >
                        <span>{breakdown.position}</span>
                    </div>
                )}
                <div className="ucr__hcp-mapview-breakdown-name-and-postcode">
                    <div
                        className={cn('', {
                            'ucr-mapview__breakdown-icon-wrap': hasAddressWarning,
                        })}
                    >
                        {hasAddressWarning && (
                            <Tooltip
                                popoverClassName={'ucr-listview__popover'}
                                content="Address details could not be verified"
                            >
                                <AddressWarningIcon className="ucr-mapview__breakdown-icon" />
                            </Tooltip>
                        )}
                        {breakdown.name}
                    </div>
                    <div
                        className={cn('ucr__hcp-mapview-breakdown-postcode', {
                            'ucr__hcp-mapview-breakdown-postcode--pl': hasAddressWarning,
                        })}
                    >
                        {breakdown.postcode}{' '}
                    </div>
                </div>
                <div
                    className={cn('ucr__hcp-mapview-breakdown-time-and-duration', {
                        'ucr__hcp-mapview-breakdown-time-and-duration--warning': hasAddressWarning,
                    })}
                >
                    <RenderTimeBreakDown breakdown={breakdown} />
                    <RenderDurationOrTime breakdown={breakdown} />
                </div>
            </div>

            {index + 1 !== mapViewBreakDown.length && (
                <div className="ucr__hcp-mapview-dot-container">
                    <div className="ucr__hcp-mapview-dot"></div>
                    <div className="ucr__hcp-mapview-dot"></div>
                    <div className="ucr__hcp-mapview-dot"></div>
                </div>
            )}
        </>
    );
};

export { RenderDurationOrTime };
export { RenderTimeBreakDown };
export { HCPBreakdown };
export default HCPBreakdown;
