import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

export const useCollapseAndExpandButtonsViewModel = () => {
    const {
        RootStore: {
            ucrStore: { setAllExpandedState },
        },
    } = useStores<{ RootStore: RootStore }>();

    const collapseAll = () => {
        setAllExpandedState(false);
    };

    const expandAll = () => {
        setAllExpandedState(true);
    };

    return {
        expandAll,
        collapseAll,
    };
};
