import { FC } from 'react';

interface IProps {
    message: String;
}

const EmptyListView: FC<IProps> = ({ message }) => {
    return <div className="ucr_listview__empty">{message}</div>;
};

export default EmptyListView;
