import { Button } from '@blueprintjs/core';
import { JobStatus } from '@doc-abode/data-models';
import React, { FC } from 'react';

interface Props {
    setShowAbortPrompt: (show: boolean) => void;
    aborting: boolean;
    jobStatus: JobStatus;
    setEditing: (editing: boolean) => void;
    editing: boolean;
    onSave: () => Promise<void>;
    hasChanges: boolean;
    hasErrors: boolean;
    onResubmit: () => Promise<void>;
    isSuperuser: boolean;
}

export const RouteDetailsFooter: FC<Props> = ({
    setShowAbortPrompt,
    aborting,
    jobStatus,
    setEditing,
    editing,
    onSave,
    hasChanges,
    hasErrors,
    onResubmit,
    isSuperuser,
}) => {
    const showEditButton =
        isSuperuser ||
        (!isSuperuser &&
            [
                JobStatus.AVAILABLE,
                JobStatus.ACCEPTED,
                JobStatus.HCP_ABORTED,
                JobStatus.COMPLETED,
            ].includes(jobStatus));
    if (editing) {
        return (
            <>
                <Button large onClick={() => setEditing(false)} icon="cross">
                    Cancel
                </Button>
                <Button
                    intent="success"
                    large
                    onClick={onSave}
                    icon="tick"
                    disabled={!hasChanges || hasErrors}
                >
                    Submit changes
                </Button>
            </>
        );
    }

    return (
        <>
            {jobStatus === 'CONTROLLER_ABORTED' ? (
                <>
                    <span className="modal__footer-text">
                        Note: when resubmitting a route, only patients in an 'Unassigned' state will
                        be carried over to the new route.
                    </span>
                    <Button intent="primary" large onClick={onResubmit} icon="redo">
                        Resubmit route
                    </Button>
                </>
            ) : (
                <Button
                    intent="danger"
                    large
                    onClick={() => setShowAbortPrompt(true)}
                    loading={aborting}
                    icon="ban-circle"
                >
                    Abort route
                </Button>
            )}
            {showEditButton && (
                <Button intent="primary" large onClick={() => setEditing(true)} icon="edit">
                    Edit route
                </Button>
            )}
        </>
    );
};
