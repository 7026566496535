import { ISuggestOption } from '../../components/v2/form';

export function isHcpWorking(selectableHcpOptions: ISuggestOption[], selectedHcp: string): boolean {
    // If an empty string is provided, the HCP cannot be deemed as working
    if (selectedHcp === '') {
        return false;
    }

    // If no values have been provided for comparison, the HCP cannot be deemed as working
    if (selectableHcpOptions.length === 0) {
        return false;
    }

    // The HCP is working if they appear in the selectable options and is explicitly marked as "working"
    if (selectableHcpOptions.find((sug) => sug.value === selectedHcp)?.isWorking) {
        return true;
    }

    // The HCP did not appear in the selectable options or was marked as not working
    return false;
}
