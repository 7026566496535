import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';

import { PatientListPatient, UPDATE_PATIENT } from '../../../../../graphql/queries/patients';
import { IconAdd } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { Dialogs } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import { ButtonColors, ButtonSizes } from '../../../../v2/components';
import { createVisitData } from '../../forms/AddVisit/createVisitData';
import { IQuickActionsButton, QuickActionsDropDown } from '../calendar/QuickActionsDropDown';

export const PatientListQuickActions: FC<{ patient: PatientListPatient }> = observer(
    ({ patient }) => {
        const {
            RootStore: {
                userStore: { user },
                ucrStore: { setFollowUpVisitData, setFollowUpAdminTimeData, setOpenedDialog },
                patientListStore: { setAdmissionPatient, updatePatientAdmission },
            },
        } = useStores<{ RootStore: RootStore }>();

        const [updatePatient] = useMutation(UPDATE_PATIENT);

        const addVisit = useCallback(() => {
            const { patientData, referralData, careData } = createVisitData(patient);

            setFollowUpVisitData({ ...patientData, ...referralData, ...careData } as any);
            setOpenedDialog(Dialogs.ADD_NEW_VISIT);
        }, [setFollowUpVisitData, setOpenedDialog, patient]);

        const addAdminTime = useCallback(() => {
            const { patientData, careData } = createVisitData(patient);

            setFollowUpAdminTimeData({ ...patientData, ...careData } as any);
            setOpenedDialog(Dialogs.ADMINISTRATIVE_TIME);
        }, [setFollowUpAdminTimeData, setOpenedDialog, patient]);

        const performAdmission = useCallback(async () => {
            try {
                await updatePatient({
                    variables: {
                        input: {
                            admission: {
                                nhsNumber: `${patient.nhsNumber}`,
                                isAdmitted: true,
                                admittedBy: user.username,
                            },
                        },
                    },
                });
                updatePatientAdmission(patient, true);
                AppToaster.show({
                    message: `${patient.firstName || ''} ${
                        patient.lastName || ''
                    } has been admitted.`,
                    intent: 'success',
                });
            } catch (err) {
                console.error('Error updating patient', err);
            }
        }, [patient, user, updatePatient, updatePatientAdmission]);

        const showDischargeDialog = useCallback(async () => {
            setAdmissionPatient(patient);
            setOpenedDialog(Dialogs.DISCHARGE_PATIENT);
        }, [setOpenedDialog, setAdmissionPatient, patient]);

        const buttons = useMemo<IQuickActionsButton[]>(
            () => [
                {
                    Icon: IconAdd,
                    name: 'Add visit',
                    size: ButtonSizes.SMALL,
                    clickEvent: addVisit,
                },
                {
                    Icon: IconAdd,
                    name: 'Add admin',
                    size: ButtonSizes.SMALL,
                    clickEvent: addAdminTime,
                },
                {
                    Icon: IconAdd,
                    name: `${patient.admission.isAdmitted ? 'Discharge' : 'Admit'} patient`,
                    size: ButtonSizes.SMALL,
                    clickEvent: patient.admission.isAdmitted
                        ? showDischargeDialog
                        : performAdmission,
                },
            ],
            [
                addAdminTime,
                addVisit,
                showDischargeDialog,
                performAdmission,
                patient.admission.isAdmitted,
            ],
        );

        return (
            <QuickActionsDropDown
                text="Quick actions"
                buttons={buttons}
                size={ButtonSizes.SMALL}
                color={ButtonColors.GREY}
                dropDownButtonSize={ButtonSizes.SMALL}
            />
        );
    },
);
