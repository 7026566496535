import { inject, observer } from 'mobx-react';
import React, { Component, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import CustomPrompt from './common/CustomPrompt';
import Layout from './layout/Layout';

const Router = ({ props }) => {
    const userConfirmation = useCallback((message, callback) => {
        const node = document.getElementById('custom-prompt');

        const cleanUp = (answer) => {
            ReactDOM.unmountComponentAtNode(node);
            callback(answer);
        };

        ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node);
    }, []);

    return (
        <BrowserRouter getUserConfirmation={userConfirmation}>
            <Switch>
                <Redirect exact from="/logout" to="/" />
                <Layout props={props} />
            </Switch>
        </BrowserRouter>
    );
};

const App = inject('RootStore')(
    observer(
        class App extends Component {
            async componentDidMount() {
                const { configStore, userStore, lovsStore, usersStore } = this.props.RootStore;

                await userStore.authorize();

                lovsStore.setLovs();
                await configStore.getConfig();
                await configStore.getClientKeys();
                usersStore.startTimer();
            }

            componentWillUnmount() {
                const { usersStore } = this.props.RootStore;

                usersStore.dispose();
            }

            render() {
                return <Router props={this.props} />;
            }
        },
    ),
);

export default App;
