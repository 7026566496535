import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { Dialogs } from '../../../../../../stores/UCRStore';
import { useCommonAlertViewModel } from '../common/useCommonAlertViewModel';

export const useStaffAlertViewModel = () => {
    const {
        RootStore: {
            lovsStore: { controllerStaffAlertResolutionReason: resolutionReasons },
            ucrStore: { openedDialog, openedStaffAlertToResolve: alert },
        },
    } = useStores<{ RootStore: RootStore }>();

    const showDialog = openedDialog === Dialogs.STAFF_ALERT_INFO;

    const {
        alertInfoTitle,
        showResolutionForm,
        isAlertUpdating,
        onClickUpdateAlert,
        onClickCancel,
        jobs,
        showAlertOnMap,
        mapClientKeys,
    } = useCommonAlertViewModel({ alert: alert! });

    return {
        alertInfoTitle,
        showDialog,
        showResolutionForm,
        isAlertUpdating,
        alert,
        onClickUpdateAlert,
        onClickCancel,
        jobs,
        showAlertOnMap,
        mapClientKeys,
        resolutionReasons,
    };
};
