import { orgUrl, request } from './baseApi';

export interface SecureCommsCredentials {
    operatorId: string;
    password: string;
}

export const decodeToken = (
    token: string,
    retry: boolean = false,
): Promise<SecureCommsCredentials> => {
    return request(
        {
            url: `${orgUrl}/comms/decode-token`,
            method: 'POST',
            data: token,
        },
        retry,
    );
};

export const getCredentials = (retry: boolean = false): Promise<SecureCommsCredentials> => {
    return request(
        {
            url: `${orgUrl}/comms/get-credentials`,
            method: 'POST',
        },
        retry,
    );
};
