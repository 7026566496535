import { Patient } from '@doc-abode/data-models';
import { FC } from 'react';

import { ViewToShow } from '../../../../../../constants/mainConst';
import { IconS1Referral } from '../../../../../../helpers';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { useJobS1SyncStatusViewModel } from '../../../components/useJobS1SyncStatusViewModel';

interface IProps {
    patient: Patient;
    isBuddy: boolean;
    label: string;
    view: ViewToShow;
    includeReferral?: boolean;
}

const S1SyncStatusLabel: FC<IProps> = ({ patient, isBuddy, label, view, includeReferral }) => {
    const {
        isS1SyncEnabled,
        showS1SyncStatus,
        getS1SyncStatusClassname,
        getS1ReferralStatusClass,
    } = useJobS1SyncStatusViewModel(patient);

    return (
        <ConditionalDisplay show={isS1SyncEnabled}>
            <span>
                <span
                    className={getS1SyncStatusClassname(view, isBuddy)}
                    role={'status'}
                    data-testid="s1-sync-status"
                >
                    {label}
                </span>
                <ConditionalDisplay show={includeReferral === true && showS1SyncStatus}>
                    <span data-testid="s1-referral-icon">
                        <IconS1Referral className={getS1ReferralStatusClass()} />
                    </span>
                </ConditionalDisplay>
            </span>
        </ConditionalDisplay>
    );
};

export default S1SyncStatusLabel;
