import { Schedule } from '@doc-abode/data-models';

import { formatTime } from '../../components/modules/helpers/formatData';

export function formatScheduleStartAndEndForDisplayLabel(schedule?: Schedule) {
    if (!schedule) {
        return '';
    }

    const formattedStartDateTime = formatTime(schedule.startDateTime);
    const formattedEndDateTime = formatTime(schedule.endDateTime);

    return `${formattedStartDateTime}-${formattedEndDateTime}`;
}
