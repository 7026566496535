import { PatientAlert } from '@doc-abode/data-models';
import { useEffect } from 'react';

import filterPatientAlerts from '../../../../../../helpers/ucr/filterPatientAlerts';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';

export const useInfoPanelAlertsSectionViewModel = () => {
    const {
        RootStore: {
            ucrStore: {
                setOpenedPatientAlertInfo,
                patientAlerts,
                jobAlerts,
                fetchJobAlerts,
                focusedJobId,
            },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    // Current alerts are available in the list of all patient alerts
    const { unresolved, hasUnresolvedAlerts } = filterPatientAlerts(patientAlerts, focusedJobId);

    // Load all alerts for this job
    useEffect(() => {
        fetchJobAlerts();
    }, [fetchJobAlerts]);

    // Historic alerts need to be explicitly fetched
    const { resolved, hasResolvedAlerts } = filterPatientAlerts(jobAlerts, focusedJobId);

    const showWarnings = hasUnresolvedAlerts || hasResolvedAlerts;

    const onViewDetailsClick = (alert: PatientAlert) => {
        setOpenedPatientAlertInfo(alert);
    };

    return {
        setOpenedPatientAlertInfo,
        onViewDetailsClick,
        users,
        showWarnings,
        unresolved,
        hasUnresolvedAlerts,
        resolved,
        hasResolvedAlerts,
    };
};
