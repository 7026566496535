import React, { FC } from 'react';

import MapView from '../../MapView';

interface Props {
    itineraries: Record<string, any>[];
    apiKey: string;
}

export const MapWrapper: FC<Props> = ({ itineraries, apiKey }) => (
    <div style={{ height: '500px' }}>
        <MapView itineraries={itineraries} apiKey={apiKey} />
    </div>
);
