import { AbortReason } from '@doc-abode/data-models';

/**
 * Utility to get a sorted list of abort reasons for a job cancellation.
 * The utility allows users to restrict the list to enabled types only, as well as only return those
 * types that are relevant for a part cancellation of a job that has multiple assignees
 * @param abortReasons list of all potential abort reasons
 * @param enabledOptionsOnly restricts results to active options only
 * @param appliesToIndividualJobAssignmentOnly if set to true, restricts results to abort reasons that can be used for a part cancellations. To cancel all (remaining) job parts the flag must be sent as false
 * @returns a sorted AbortReason[] whose values match the input criteria
 */
export function getSortedAbortReasons(
    abortReasons: AbortReason[],
    enabledOptionsOnly: boolean,
    appliesToIndividualJobAssignmentOnly: boolean,
): AbortReason[] {
    return abortReasons
        .slice()
        .sort((a: AbortReason, b: AbortReason) => a.label.localeCompare(b.label))
        .filter((abortReason: AbortReason) => {
            if (enabledOptionsOnly && !abortReason.enabled) {
                // The caller requests active (i.e. enabled) options only
                // If an option is disabled, it must not be returned
                return false;
            }

            if (
                // The caller requests options that apply to partial cancellations only, i.e. individual job parts of a double-up/multi-HCP job
                // If an option does not apply to individual job parts, it must not be returned
                appliesToIndividualJobAssignmentOnly &&
                !abortReason.appliesToIndividualJobAssignment
            ) {
                return false;
            }

            return true;
        });
}
