import { JobStatus, Patient } from '@doc-abode/data-models';

import { isPermittedJobStatus } from '../isPermittedJobStatus';
import { getJobsForUser } from './getJobsForUser';

const permittedStates = [
    JobStatus.AVAILABLE,
    JobStatus.ACCEPTED,
    JobStatus.CURRENT,
    JobStatus.ARRIVED,
];

// TODO - Fix in the future, needs to take dbl-up status into account as well
const jobFilter = (job: Patient) => isPermittedJobStatus(permittedStates, job.jobStatus);

export function getSortedJobsForUserInAlertState(jobs: Patient[], hcpId?: string | null) {
    return getJobsForUser(jobs, hcpId)
        .filter(jobFilter)
        .sort((a: any, b: any) => {
            if (a.startDateTime && b.startDateTime) {
                return new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime();
            }

            return a.startDateTime ? 1 : -1;
        });
}
