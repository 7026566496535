import { AbortReason } from '@doc-abode/data-models';

/**
 * Utility to determine whether a selected abort reason requires mandatory notes
 * @param abortReasons list of potential abort reasons to check against
 * @param selectedReason the name of the selected reason for which to check
 * @returns a boolean to indicate whether the selected type required mandatory notes
 */
export function isAbortNoteRequired(abortReasons: AbortReason[], selectedReason: string): boolean {
    let notesAreRequired = false;

    const abortReasonFound: AbortReason | undefined = abortReasons.find(
        (abortReason: AbortReason) => abortReason.label === selectedReason,
    );
    if (abortReasonFound) {
        notesAreRequired = abortReasonFound.notesRequired;
    }

    return notesAreRequired;
}
