import { Button, Callout, Checkbox, Icon } from '@blueprintjs/core';
import { Vaccination } from '@doc-abode/data-models';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router';

import {
    checkJobsUpload,
    confirmJobsUpload,
    getFileUploadParams,
    uploadFile,
} from '../../../../api/jobsApi';
import useStores from '../../../../hook/useStores';
import {
    VaccinationImportPatientError,
    VaccinationImportPatientWarning,
} from '../../../../interfaces';
import RootStore from '../../../../stores/RootStore';
import Modal from '../../../modules/modal/Modal';
import { ImportErrorsTable } from './ImportErrorsTable';
import { ImportWarningsTable } from './ImportWarningsTable';

interface IImportPatientsFooter {
    resetAll: () => void;
    onConfirmUpload: () => void;
    onClose: () => void;
    uploadSaved: boolean;
    showConflicts: boolean;
    showWarnings: boolean;
}

const ImportPatientsFooter = ({
    showConflicts,
    showWarnings,
    resetAll,
    onConfirmUpload,
    uploadSaved,
    onClose,
}: IImportPatientsFooter) => {
    if (showConflicts) {
        return <Button onClick={onConfirmUpload} text="Confirm" intent="primary" large outlined />;
    }

    return (
        <>
            {uploadSaved && <Button onClick={onClose} text="Close" icon="cross" large outlined />}
            <Button onClick={resetAll} text="Reset" icon="reset" intent="primary" large outlined />
            {showWarnings && (
                <Button onClick={onConfirmUpload} text="Confirm" intent="primary" large outlined />
            )}
        </>
    );
};

interface IImportPatients {
    refetch: () => void;
}

export default function ImportPatients({ refetch }: IImportPatients) {
    const [exist, setExist] = useState([]);
    const [existWithConflicts, setExistWithConflicts] = useState([]);
    const [jobsCount, setJobsCount] = useState(0);
    const [uploadId, setUploadId] = useState('');
    const [overwrite, setOverwrite] = useState<string[]>([]);
    const [fileSelected, setFileSelected] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [uploadChecked, setUploadChecked] = useState(false);
    const [uploadConfirmed, setUploadConfirmed] = useState(false);
    const [uploadSaved, setUploadSaved] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [validationErrors, setValidationErrors] = useState<VaccinationImportPatientError[]>([]);
    const [fileFormatError, setFileFormatError] = useState(false);
    const [validationWarnings, setValidationWarnings] = useState<VaccinationImportPatientWarning[]>(
        [],
    );

    const history = useHistory();

    const {
        RootStore: {
            userStore: { getUserSession },
            configStore: { org, patientUploadAdapter },
        },
    } = useStores<{ RootStore: RootStore }>();

    const reset = () => {
        setUploadId('');
        setFileSelected(false);
        setUploadComplete(false);
        setUploadChecked(false);
        setUploadConfirmed(false);
        setUploadSaved(false);
        setJobsCount(0);
        setExist([]);
        setExistWithConflicts([]);
        setOverwrite([]);
        setFileFormatError(false);
        setValidationWarnings([]);
    };

    const resetAll = () => {
        reset();
        setUploadError(null);
        setValidationErrors([]);
    };

    const onConfirmUpload = useCallback(
        async (uploadId: string) => {
            setUploadConfirmed(true);
            try {
                const { tokens } = await getUserSession();
                const { jobsCount } = await confirmJobsUpload(tokens.id, uploadId, {
                    overwrite: overwrite.map((item: string) => {
                        const [id, key] = item.split('_');
                        return { id, key };
                    }),
                });
                setJobsCount(jobsCount);
                setUploadSaved(true);
            } catch (err: any) {
                setUploadError(err?.message || 'Import Patient:No Error message available');
                reset();
            }
        },
        [getUserSession, overwrite],
    );

    const onDrop = useCallback(
        async ([file]: File[]) => {
            setUploadError(null);
            setFileFormatError(false);

            if (!file.name.endsWith('.csv')) {
                setFileFormatError(true);
                return;
            }

            setFileSelected(true);
            let checkResponse;
            let uploadParams;

            try {
                const { tokens } = await getUserSession();
                uploadParams = await getFileUploadParams(tokens.id);
                setUploadId(uploadParams.uploadId);

                const key = `${org}/${uploadParams.uploadId}.csv`;
                await uploadFile(key, file, uploadParams);
                setUploadComplete(true);

                checkResponse = await checkJobsUpload(tokens.id, uploadParams.uploadId);
                setUploadChecked(true);
            } catch (err: any) {
                setUploadError(err?.message);
                if (err.data?.error?.validationErrors) {
                    setValidationErrors(err.data.error.validationErrors);
                }
                reset();
                return;
            }

            const { exist, existWithConflicts, jobsCount, warnings } = checkResponse;

            setJobsCount(jobsCount);
            setExist(exist);

            if (existWithConflicts.length === 0 && warnings.length === 0) {
                onConfirmUpload(uploadParams.uploadId);
            } else {
                setExistWithConflicts(existWithConflicts);
                setValidationWarnings(warnings);
            }
        },
        [getUserSession, onConfirmUpload, org],
    );

    const toggleOverwrite = (event: React.FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: { id, checked },
        } = event;

        if (checked) {
            setOverwrite([...overwrite, id]);
        } else {
            setOverwrite(overwrite.filter((item) => item !== id));
        }
    };

    const overwiteAll = () => {
        setOverwrite(existWithConflicts.map(({ id, key }) => `${id}_${key}`));
    };

    const overwiteNone = () => {
        setOverwrite([]);
    };

    const onClose = () => {
        refetch();
        history.push('/vaccinations/patients');
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const showConflicts = existWithConflicts.length > 0 && !uploadConfirmed;
    const showWarnings = validationWarnings.length > 0 && !uploadConfirmed;

    return (
        <Modal
            title="Import patients"
            onClose={onClose}
            shadow
            footer={
                (showConflicts || uploadError || uploadSaved || showWarnings) && (
                    <ImportPatientsFooter
                        resetAll={resetAll}
                        showConflicts={showConflicts}
                        showWarnings={showWarnings}
                        onConfirmUpload={() => onConfirmUpload(uploadId)}
                        uploadSaved={uploadSaved}
                        onClose={onClose}
                    />
                )
            }
        >
            <div className="file-upload">
                {uploadError && (
                    <>
                        {validationErrors.length > 0 ? (
                            <ImportErrorsTable
                                patientUploadAdapter={patientUploadAdapter}
                                validationErrors={validationErrors}
                            />
                        ) : uploadError === 'CSV file contains duplicate records' ? (
                            <Callout intent="danger" className="file-upload__error">
                                The patients file contains duplicate records (based on NHS number
                                and vaccination type/dose number). Please check the file and try
                                again.
                            </Callout>
                        ) : (
                            <Callout intent="danger" className="file-upload__error">
                                Sorry, we encountered an error uploading the patients file and it
                                could not be processed. Please check the file and try again. If the
                                problem persists, please try refreshing the page.
                            </Callout>
                        )}
                    </>
                )}
                {!uploadError && !fileSelected && (
                    <>
                        <label htmlFor="fileUpload">
                            <div {...getRootProps()} className="file-upload__input">
                                <input name="fileUpload" {...getInputProps()} multiple={false} />
                                <span className="opacity-75">
                                    <Icon icon="upload" className="file-upload__icon" />
                                    {isDragActive
                                        ? 'Drop the file here...'
                                        : 'To upload a new file, drag and drop it here, or click to choose...'}
                                </span>
                            </div>
                        </label>
                        <p>
                            You can use the upload function above to import patients. Files must be
                            in .csv format. Please see{' '}
                            <a
                                href={`/example-patient-upload-${
                                    patientUploadAdapter?.toLowerCase() || 'generic'
                                }.csv`}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                this example file
                            </a>{' '}
                            for the required structure.
                        </p>
                        {fileFormatError && (
                            <Callout intent="danger">
                                Invalid file format, only .csv files are allowed.
                            </Callout>
                        )}
                    </>
                )}
                {fileSelected && !uploadComplete && (
                    <div className="file-upload__input file-upload__input--uploading">
                        <Icon icon="cloud-upload" className="file-upload__icon" />
                        Uploading...
                    </div>
                )}
                {uploadComplete && !uploadChecked && (
                    <div className="file-upload__input file-upload__input--uploading">
                        <Icon icon="search" className="file-upload__icon" />
                        Checking uploaded file...
                    </div>
                )}
                {showConflicts && (
                    <>
                        <div className="file-upload__input file-upload__input--conflict">
                            <Icon icon="warning-sign" className="file-upload__icon" />
                            <div>
                                Found <strong>{jobsCount}</strong>{' '}
                                {jobsCount === 1 ? 'patient' : 'patients'} with{' '}
                                <strong>{existWithConflicts.length}</strong>{' '}
                                {existWithConflicts.length === 1 ? 'conflict' : 'conflicts'}. Please
                                select which conflicts to overwite below.
                            </div>
                        </div>
                        <h2 className="h2">Details of conflicts</h2>
                        <Button text="Select all" onClick={overwiteAll} outlined />{' '}
                        <Button text="Select none" onClick={overwiteNone} outlined />
                        <table className="bp5-html-table file-upload__table">
                            <thead>
                                <tr>
                                    <th>Overwrite?</th>
                                    <th>NHS number</th>
                                    <th>Vaccination type</th>
                                    <th>Dose number</th>
                                    <th>Conflicting field</th>
                                    <th>Existing value</th>
                                    <th>New value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {existWithConflicts.map(
                                    ({
                                        nhsNumber,
                                        key,
                                        doseNumber,
                                        old,
                                        new: newValue,
                                        vaccinationCategory,
                                        id,
                                    }) => {
                                        const itemKey = `${id}_${key}`;
                                        const selected = overwrite.includes(itemKey);

                                        return (
                                            <tr
                                                className={`file-upload__table-row ${
                                                    selected
                                                        ? 'file-upload__table-row--selected'
                                                        : ''
                                                }`}
                                                key={itemKey}
                                            >
                                                <td>
                                                    <Checkbox
                                                        id={itemKey}
                                                        checked={selected}
                                                        onChange={toggleOverwrite}
                                                    />
                                                </td>
                                                <td>{nhsNumber}</td>
                                                <td>
                                                    {Vaccination.getFriendlyVaccinationCategory(
                                                        vaccinationCategory,
                                                    )}
                                                </td>
                                                <td>{doseNumber}</td>
                                                <td>{key}</td>
                                                <td>{old}</td>
                                                <td>{newValue}</td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </>
                )}
                {showWarnings && <ImportWarningsTable validationWarnings={validationWarnings} />}
                {uploadConfirmed && !uploadSaved && !uploadError && (
                    <div className="file-upload__input file-upload__input--uploading">
                        <Icon icon="floppy-disk" className="file-upload__icon" />
                        Saving patients...
                    </div>
                )}
                {uploadSaved && (
                    <div className="file-upload__input file-upload__input--success">
                        <Icon icon="tick-circle" className="file-upload__icon" />
                        <div>
                            Imported <strong>{jobsCount}</strong>{' '}
                            {jobsCount === 1 ? 'patient' : 'patients'}.
                            {exist.length > 0 && (
                                <>
                                    <br />
                                    <strong>{exist.length}</strong> already existed without
                                    conflicts so {exist.length === 1 ? 'was' : 'were'} ignored.
                                </>
                            )}
                            {existWithConflicts.length > 0 && (
                                <>
                                    <br />
                                    <strong>{existWithConflicts.length}</strong> conficts were
                                    found.
                                    <br />
                                    <strong>{overwrite.length}</strong>{' '}
                                    {overwrite.length === 1 ? 'was' : 'were'} updated.
                                    <br />
                                    <strong>
                                        {existWithConflicts.length - overwrite.length}
                                    </strong>{' '}
                                    {existWithConflicts.length - overwrite.length === 1
                                        ? 'was'
                                        : 'were'}{' '}
                                    ignored.
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}
