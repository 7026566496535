import { Job, JobStatus } from '@doc-abode/data-models';

import { FormMode } from '../../components/pages/ucr/forms/common';

const qualifyingStates = [
    JobStatus.CURRENT,
    JobStatus.ARRIVED,
    JobStatus.COMPLETED,
    JobStatus.HCP_ABORTED,
    JobStatus.CONTROLLER_ABORTED,
];

export function getChangeConfirmationDialogMessage(jobStatus?: JobStatus): string {
    if (!jobStatus) {
        return 'Are you sure you want to change this job?';
    }

    return `The job is ${Job.getFriendlyStatus(
        jobStatus,
    ).toUpperCase()}. Are you sure you want to change it?`;
}

/**
 * Helper function to determine whether a job is in a state which should trigger a change confirmation dialog, e.g. when dragging and dropping an in progress job, when editing a completed job, etc.
 * @param jobStatus the status the job is in
 * @param formMode the mode of the form, e.g. addVisit, editVist etc.
 * @returns boolean - true = job should trigger a confirmation dialog, false = job changes do not require confirmation by user
 */
export function isQualifyingStateForChangeConfirmationDialog(
    jobStatus?: JobStatus,
    formMode?: FormMode,
): boolean {
    if (!jobStatus) {
        return false;
    }

    // warning should only be triggered if editing a visit
    if (formMode) {
        const isEdit = formMode === FormMode.EDIT_VISIT;
        if (!isEdit) {
            return false;
        }
    }

    return qualifyingStates.includes(jobStatus);
}
