import cn from 'classnames';
import { createContext, CSSProperties, FC, ReactNode, useCallback, useReducer } from 'react';

interface IProps {
    allowMultipleTabs?: boolean;
    allowCloseLastTab?: boolean;
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
}

interface IAccordionReducer {
    type: string;
    name: string;
}
interface IAccordionToggleTab {
    name: string;
    isOpen: boolean;
    isClickable: boolean;
}
interface IAccordionOpenTab {
    name: string;
    open: boolean;
}

interface IAccordionContext {
    allowMultipleTabs: boolean;
    allowCloseLastTab: boolean;
    openTabs: string[];
    toggleTabEvent: (arg0: IAccordionToggleTab) => void;
    mountTabEvent: (arg0: IAccordionOpenTab) => void;
}

const openTabsReducer = (state: string[], action: IAccordionReducer) => {
    switch (action.type) {
        case 'open':
            return [...state, action.name];
        case 'close':
            return state.filter((name: string): boolean => name !== action.name);
        case 'toggle':
            return [action.name];
        default:
            return state;
    }
};

export const AccordionContext = createContext<any>(null);

const Accordion: FC<IProps> = ({
    allowMultipleTabs = false, // Allow to open multiple tabs
    allowCloseLastTab = false, // Allow to close the last tab
    children,
    className,
    style,
}) => {
    const [openTabs, setOpenTabs] = useReducer(openTabsReducer, []);

    const toggleTabEvent = useCallback(
        ({ name, isOpen, isClickable }: IAccordionToggleTab): void => {
            if (!isClickable) return;

            const type = !isOpen ? (allowMultipleTabs ? 'open' : 'toggle') : 'close';
            setOpenTabs({ type, name });
        },
        [allowMultipleTabs],
    );

    const mountTabEvent = useCallback(({ name, open }: IAccordionOpenTab): void => {
        const type = open ? 'open' : 'close';
        setOpenTabs({ type, name });
    }, []);

    const context: IAccordionContext = {
        allowMultipleTabs,
        allowCloseLastTab,
        openTabs,
        toggleTabEvent,
        mountTabEvent,
    };

    return (
        <AccordionContext.Provider value={context}>
            <div className={cn('v2__accordion', className)} style={style}>
                {children}
            </div>
        </AccordionContext.Provider>
    );
};

export default Accordion;
