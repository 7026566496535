import React, { ReactNode, useEffect, useState } from 'react';

import { Accordion, AccordionColors, AccordionTab } from '../../../../v2/components/Accordion';
import { IJob } from './ContainerTypes';

const UnassignedVisits = ({
    title,
    content,
    isCheckedDate,
}: {
    title: string;
    content: IJob[];
    isCheckedDate: boolean;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(isCheckedDate);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        setIsOpen(isCheckedDate);
    }, [isCheckedDate]);

    return (
        <>
            <Accordion>
                <AccordionTab
                    name="Unassigned Visits"
                    title={title}
                    color={AccordionColors.DARK_BLUE}
                    open={isOpen}
                    onClick={() => handleClick()}
                    className="v2__accordion-tab--unassigned-visits"
                >
                    <div>{content as ReactNode}</div>
                </AccordionTab>
            </Accordion>
        </>
    );
};

export default UnassignedVisits;
