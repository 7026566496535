import { GraphQLErrors } from '@apollo/client/errors';

import AppToaster from '../../../modules/helpers/Toaster';

export const graphqlErrorHandler = ({ graphQLErrors }: { graphQLErrors: GraphQLErrors }) => {
    let message = 'Please check your network connection!';
    if (graphQLErrors && graphQLErrors.length > 0) {
        message = graphQLErrors[0].message;
    }
    AppToaster.show({
        message,
        intent: 'danger',
        timeout: 0,
    });
};
