/**
 * Utility function to check whether a string is deemed valid.
 * The input is always deemed valid if its optional; if mandatory the input must not be empty or consist of whitespaces only
 * @param isStringMandatory controls whether the input is optional - false = optional; true = input must be provided
 * @param stringToValidate string value to check
 * @returns boolean indicating whether the input is acceptable
 */
export function isValidStringValue(isStringMandatory: boolean, stringToValidate: string): boolean {
    if (!isStringMandatory) {
        // If the string is optional, we do not need to validate the content
        return true;
    }

    // At this point, the string is mandatory
    if (!stringToValidate) {
        // If there is no defined string, the mandatory string is obviously invalid
        return false;
    }

    if (stringToValidate.trim().length === 0) {
        // If the string that has been passed is empty, the string is invalid
        return false;
    }

    return true;
}
