import { FC } from 'react';

import Loader from '../../../../../modules/helpers/Loader';

interface IProps {}

const PanelLoader: FC<IProps> = () => {
    return (
        <div className="visit-details__loading">
            <Loader fullscreen={false} />
        </div>
    );
};

export default PanelLoader;
