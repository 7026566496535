import { Callout, Intent, Tag } from '@blueprintjs/core';
import { GetPatientRecordResponse, S1Message, S1Status } from '@doc-abode/data-models';
import { capitalize } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';

import Loader from '../../../../modules/helpers/Loader';
import { Button } from '../../../../v2/components';
import { Dialog, Overlay } from '../../../../v2/overlays';
import { useS1MessagesViewModel } from './useS1MessagesViewModel';

const statusIntentMappings: Record<S1Status, Intent> = {
    [S1Status.SUCCESS]: Intent.SUCCESS,
    [S1Status.ERROR]: Intent.DANGER,
    [S1Status.PENDING]: Intent.WARNING,
};

const S1MessageRow: React.FC<{
    message: S1Message;
    setSelectedMessage: (message: S1Message) => void;
}> = ({ message, setSelectedMessage }) => {
    const { createdAt, eventName, eventType, status, requestBody, s1LoggedInUser } = message;

    const requestBodyWithNhsNumber = requestBody?.FunctionParameters as GetPatientRecordResponse;
    const nhsNumber = requestBodyWithNhsNumber?.Identity?.NhsNumber;

    const onClick = () => setSelectedMessage(message);

    return (
        <tr className="ucr-listview__list-row ucr-listview__list-row--content" onClick={onClick}>
            <td>{moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
            <td>{nhsNumber}</td>
            <td>{capitalize(eventType)}</td>
            <td>
                <Tag minimal>{capitalize(eventName)}</Tag>
            </td>
            <td>
                <Tag minimal intent={statusIntentMappings[status]}>
                    {capitalize(status)}
                </Tag>
            </td>
            <td>{s1LoggedInUser}</td>
        </tr>
    );
};

const S1Messages = () => {
    const {
        s1Messages,
        s1MessagesLoading,
        s1MessagesPaginationToken,
        s1MessagesError,
        fetchS1Messages,
        selectedMessage,
        setSelectedMessage,
    } = useS1MessagesViewModel();

    return (
        <section className="ucr__section">
            <div className="ucr__main">
                <div className="ucr-listview__main ucr-listview__main--min-height">
                    {s1MessagesLoading && <Loader fullscreen={false} />}
                    {s1MessagesError && (
                        <Callout className="callout" intent="danger">
                            {s1MessagesError}
                        </Callout>
                    )}
                    <table className="ucr-listview__table">
                        <thead>
                            <tr className="ucr-listview__list-row">
                                <th className="ucr-listview__header">Timestamp</th>
                                <th className="ucr-listview__header">NHS number</th>
                                <th className="ucr-listview__header">Event type</th>
                                <th className="ucr-listview__header">Event name</th>
                                <th className="ucr-listview__header">Status</th>
                                <th className="ucr-listview__header">SystmOne user</th>
                            </tr>
                        </thead>
                        <tbody>
                            {s1Messages.map((message) => (
                                <S1MessageRow
                                    key={message.requestId}
                                    message={message}
                                    setSelectedMessage={setSelectedMessage}
                                />
                            ))}
                            {s1Messages.length === 0 && (
                                <tr className="ucr-listview__list-row ucr-listview__list-row--center">
                                    <td colSpan={6}>
                                        There are no messages for your filter criteria
                                    </td>
                                </tr>
                            )}
                            {s1MessagesPaginationToken && (
                                <tr className="ucr-listview__list-row ucr-listview__list-row--center">
                                    <td colSpan={6}>
                                        <Button
                                            name="Load More"
                                            clickEvent={fetchS1Messages}
                                            disabled={s1MessagesLoading}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Overlay isShow={selectedMessage !== null} />
            <Dialog
                isShow={selectedMessage !== null}
                closeEvent={() => setSelectedMessage(null)}
                title="Message details"
            >
                {selectedMessage && (
                    <div className="s1-message">
                        <div className="s1-message__column">
                            <h4 className="s1-message__column-heading">Request body</h4>
                            <div className="s1-message__code">
                                <code>
                                    <pre>
                                        {JSON.stringify(selectedMessage.requestBody, null, 2)}
                                    </pre>
                                </code>
                            </div>
                        </div>
                        <div className="s1-message__column">
                            <h4 className="s1-message__column-heading">Response body</h4>
                            <div className="s1-message__code">
                                <code>
                                    <pre>
                                        {JSON.stringify(selectedMessage.responseBody, null, 2)}
                                    </pre>
                                </code>
                            </div>
                        </div>
                    </div>
                )}
            </Dialog>
        </section>
    );
};

export default observer(S1Messages);
