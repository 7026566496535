import { JobStatus, Patient } from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { isDisableFormBtn } from '../../../../../helpers';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';
import { getDateEndTime } from '../../../../../helpers/ucr';
import {
    getChangeConfirmationDialogMessage,
    isQualifyingStateForChangeConfirmationDialog,
} from '../../../../../helpers/ucr/changeConfirmationDialogHelper';
import { getHcp } from '../../../../../helpers/ucr/getHcp';
import { transformFormikValuesToPatientForWarningValidation } from '../../../../../helpers/ucr/getWarnings';
import { useHcpSuggestionOptions } from '../../../../../hook/useHcpSuggestionOptions';
import useStores from '../../../../../hook/useStores';
import useVisitWarnings from '../../../../../hook/useVisitWarnings';
import { IHcp } from '../../../../../interfaces/ucr';
import RootStore from '../../../../../stores/RootStore';
import { compareByLabels } from '../../../../modules/helpers/sortFunctions';
import { ISuggestOption } from '../../../../v2/form';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';
import { useWarningMessageViewModel } from '../../hooks/useWarningMessageViewModel';
import { FormMode, FormSteps, getButtonName, warningHighlight } from '../common';
import { ActivityDetailsType } from './ActivityDetails';

const requiredFields: Array<keyof FormikValues> = [
    'activityType',
    'hcpId',
    'visitDate',
    'startTime',
    'duration',
];

export const useActivityDetailsViewModel = ({
    values,
    loading,
    onNextStep,
    setFieldValue,
    formMode,
}: ActivityDetailsType) => {
    const {
        RootStore: {
            usersStore: { users },
            ucrStore: { jobs, hourStart, focusedJobId, openAlert, closeAlert, updateWarnings },
            schedulesStore: { getSchedulesByDate },
            configStore: { adminTypes },
        },
    } = useStores<{ RootStore: RootStore }>();
    const [isContinueBtnClicked, setIsContinueBtnClicked] = useState(false);
    const [startTimeState, setStartTimeState] = useState(values.startTime);
    const [showUnavailableHcp, setShowUnavailableHcp] = useState(false);

    const staffStyles: {
        option?: any;
    } = {};

    useEffect(() => {
        getSchedulesByDate(moment(values.visitDate).format('YYYY-MM-DD'));
    }, [values.visitDate, getSchedulesByDate]);

    useEffect(() => {
        const endTime = values.startTime
            ? getDateEndTime(values.startTime, values.duration)
            : moment().startOf('day').toDate();

        setFieldValue('endTime', endTime);
    }, [values.startTime, values.duration, setFieldValue]);

    // we need to update the startTime with the date field date but copy the hour and minutes.

    useEffect(() => {
        if (!moment(values.startTime).isSame(startTimeState)) {
            setStartTimeState(values.startTime);
        }
    }, [values.startTime, startTimeState]);

    useEffect(() => {
        if (values.visitDate && !moment(values.startTime).isSame(startTimeState)) {
            const startTimeDate = moment(values.startTime);
            const date = moment(values.visitDate)
                .set('hours', startTimeDate.hour())
                .set('minutes', startTimeDate.minute())
                .set('seconds', 0)
                .set('milliseconds', 0);

            setFieldValue('startTime', date.toDate());
        }
    }, [values.visitDate, values.startTime, setFieldValue, setStartTimeState, startTimeState]);

    const hcpSuggestionOptions = useHcpSuggestionOptions(values as VisitValuesType);

    const hcpList: ISuggestOption[] = useMemo(() => {
        return [
            { label: 'Not set', value: '' },
            ...hcpSuggestionOptions
                .filter((l: ISuggestOption) => {
                    return showUnavailableHcp || values.hcpId === l.value ? l : l.isWorking;
                })
                .sort(compareByLabels)
                .sort((a: { value: string }, b: { value: string }) => {
                    return a.value === values.previouslyHcpId ||
                        a.value === values.previouslyBuddyId
                        ? -1
                        : b.value === values.previouslyHcpId || b.value === values.previouslyBuddyId
                          ? 1
                          : 0;
                }),
        ];
    }, [
        hcpSuggestionOptions,
        showUnavailableHcp,
        values.hcpId,
        values.previouslyHcpId,
        values.previouslyBuddyId,
    ]);

    if (formMode === FormMode.FOLLOW_UP) {
        if (values.hcpIdTemp) {
            const user = getHcp(users, values.hcpIdTemp) as IHcp;
            hcpList.splice(
                hcpList.findIndex(({ value }) => user.userId === value),
                1,
            );

            hcpList.splice(1, 0, {
                label: user.userName,
                value: user.userId,
            });
        }
        staffStyles.option = (provided: any, state: any) => ({
            ...provided,
            fontWeight: state.data.value === values?.hcpIdTemp ? 'bold' : 'normal',
        });
    }

    const isErrors = isDisableFormBtn(values, requiredFields);
    const buttonName = getButtonName(formMode, FormSteps.ACTIVITY);
    const [highlightedWarnings, setHighlightedWarnings] = useState<any>({});

    const onSubmit = () => {
        if (!isErrors) {
            onNextStep(FormSteps.REVIEW, FormSteps.ACTIVITY);
        } else {
            setIsContinueBtnClicked(true);
        }
    };
    const MAXDATE = moment().add(1, 'year').toDate();
    const MINTIME = moment().startOf('day').hour(hourStart).toDate();
    const defaultStartTime = moment().startOf('minutes').toDate();

    useEffect(() => {
        if (values.hcpId && !hcpList.find((hcp) => hcp.value === values.hcpId)?.isWorking) {
            setShowUnavailableHcp(true);
        }
    }, [hcpList, values.hcpId]);

    const currentJob = jobs.find((job: Patient) => job.id === focusedJobId);

    const onSaveClick = () => {
        if (isQualifyingStateForChangeConfirmationDialog(currentJob?.jobStatus, formMode)) {
            openAlert({
                message: getChangeConfirmationDialogMessage(currentJob?.jobStatus),
                isOpen: true,
                onConfirm: () => {
                    onSubmit();
                    closeAlert();
                },
            });
            return;
        }
        onSubmit();
    };

    const options = Object.values(adminTypes)
        .filter((adminType: any) => {
            return adminType.enabled;
        })
        .sort((a: any, b: any) => {
            return a.label.localeCompare(b.label);
        })
        .map((adminType: any) => {
            return <option value={adminType.value}>{adminType.label}</option>;
        });

    const finalOptions = [<option value="">Select...</option>, ...options];

    const jobStatus: JobStatus = values.jobStatus;
    const showPatientNotes = shouldShowPostJobNotes({ jobStatus });

    // Update (BE) warnings for the current job
    const visitWarnings = useVisitWarnings(values as VisitValuesType);

    useEffect(() => {
        updateWarnings(values.id, visitWarnings || []);
    }, [
        values.hcpId,
        values.buddyId,
        values.id,
        values.visitDate,
        values.startTime,
        values.duration,
        visitWarnings,
        updateWarnings,
    ]);

    // Determine warning messages to show
    const tmpJob = transformFormikValuesToPatientForWarningValidation({ values, isAdmin: true });

    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({
        job: tmpJob,
        sectionShown: FormSteps.ACTIVITY,
    });

    useEffect(() => {
        setHighlightedWarnings(
            warningHighlight({
                warnings: warningMessages,
                values: values,
            }),
        );
    }, [values, warningMessages]);

    return {
        setShowUnavailableHcp,
        onSaveClick,
        buttonName,
        defaultStartTime,
        finalOptions,
        hasWarningMessages,
        highlightedWarnings,
        isContinueBtnClicked,
        MAXDATE,
        MINTIME,
        showPatientNotes,
        showUnavailableHcp,
        warningMessages,
    };
};
