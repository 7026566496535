import { Patient } from '@doc-abode/data-models';
import { formatNameFirstMiddleLast, formatNameLastMiddleFirst } from '@doc-abode/helpers';

import getHcpName from '../../../../../helpers/ucr/getHcpName';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import { IHcp, IHcpExtended } from '../../../../../interfaces/ucr';
import { formatDateWithTime, formatTime } from '../../../../modules/helpers/formatData';

export function getHcpTooltip(pin: IHcpExtended): string {
    const hcpName = formatNameFirstMiddleLast(pin);
    const formattedLocationTime = formatDateWithTime(pin.lastKnownLocationTime);

    // Output includes linebreak for better readability, see
    // https://docabode.atlassian.net/wiki/spaces/KB/pages/2533720065/VS-MAP-13+Simple+tooltip+for+jobs+and+Live+locations
    return `HCP - ${hcpName}\nLast updated - ${formattedLocationTime}`;
}

export function getVisitTooltip(job: Patient, users: IHcp[]): string {
    // Format the name of the patient
    const patientName = formatNameLastMiddleFirst(job);

    // Extract the HCPs and format their names
    const isDoubleUp = isMultiAssigneeJob(job);
    const hcp1Name = job.hcpId ? getHcpName(users, job.hcpId) : 'Unassigned';
    const hcp2Name = isDoubleUp && job.buddyId ? getHcpName(users, job.buddyId) : 'Unassigned';
    const assignees = isDoubleUp ? `${hcp1Name} / ${hcp2Name}` : `${hcp1Name}`;

    // Format the planned job time
    const plannedTime = job.startDateTime ? formatTime(job.startDateTime) : 'Not set';

    // Output includes linebreak for better readability, see
    // https://docabode.atlassian.net/wiki/spaces/KB/pages/2533720065/VS-MAP-13+Simple+tooltip+for+jobs+and+Live+locations
    return `Patient - ${patientName}\nHCP - ${assignees}\nPlanned - ${plannedTime}`;
}
