import cn from 'classnames';
import { createElement, CSSProperties, FC, memo, ReactNode } from 'react';

export enum ButtonElems {
    DIV = 'div',
    BUTTON = 'button',
}

export enum ButtonSizes {
    SMALL = 'small',
    MEDIUM = 'medium',
    // LARGE = 'large',
    VERTICAL = 'vertical',
}

export enum ButtonColors {
    WHITE = 'white',
    BLUE = 'blue',
    BLUE_DARK = 'blue-dark',
    GREY = 'grey',
    PINK = 'pink',
    AMBER = 'amber',
    RED = 'red',
    RED_LIGHT = 'red-light',
}

interface IProps {
    name?: string | React.ReactElement;
    size?: ButtonSizes;
    elem?: ButtonElems;
    color?: ButtonColors;
    isFullWidth?: boolean;
    Icon?: FC;
    IconRight?: FC;
    clickEvent?: () => void;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    type?: 'button' | 'submit';
    ariaLabel?: string;
}

interface IWrapperProps {
    children: ReactNode[];
    elem: string;
    onClick?: () => void;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    type?: 'button' | 'submit';
}

const ButtonWrapper: FC<IWrapperProps> = ({ elem, children, ...rest }) => {
    return createElement(elem, rest, children);
};

const Button: FC<IProps> = ({
    name,
    size = ButtonSizes.MEDIUM,
    elem = ButtonElems.DIV,
    color = ButtonColors.BLUE,
    isFullWidth,
    Icon,
    IconRight,
    clickEvent,
    className,
    style,
    disabled,
    type = 'button',
    ariaLabel,
}) => {
    return (
        <ButtonWrapper
            elem={elem}
            className={cn('v2__button', `v2__button--${size}`, `v2__button--${color}`, className, {
                'v2__button--only-icon': !name && (Icon || IconRight),
                'v2__button--name-icon': name && (Icon || IconRight),
                'v2__button--only-name': name && !(Icon || IconRight),
                'v2__button--full-width': isFullWidth,
                'v2__button--disabled': disabled,
            })}
            style={style}
            onClick={clickEvent}
            disabled={disabled}
            type={type}
            aria-label={ariaLabel}
        >
            {Icon && (
                <span className="v2__button-icon">
                    <Icon />
                </span>
            )}

            <span className="v2__button-name">{name}</span>

            {IconRight && (
                <span className="v2__button-icon-right">
                    <IconRight />
                </span>
            )}
        </ButtonWrapper>
    );
};

export default memo(Button);
