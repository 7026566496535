import { IJobPos, IJobsPos, IJobsPosArr } from '../../interfaces/ucr';

interface IProps {
    hcpId: string;
    jobsPos: IJobsPos;

    jobPos: IJobPos;
    curLeft: number;
}

interface IPropsArr {
    hcpId: string;
    jobsPosArr: IJobsPosArr;
    jobPos: IJobPos;
    curLeft: number;
}

const getOverlappingJobs = ({ hcpId, jobsPos, jobPos, curLeft }: IProps): string[] => {
    return Object.keys(jobsPos).filter(
        (id) =>
            // Only the jobs of this HCP
            jobsPos[id].hcpId === hcpId &&
            // Not current job
            id !== jobPos.jobId &&
            // Overlap on the left side
            ((curLeft > jobsPos[id].left && curLeft < jobsPos[id].left + jobsPos[id].width) ||
                // Overlap on the right side
                (curLeft + jobPos.width > jobsPos[id].left &&
                    curLeft + jobPos.width < jobsPos[id].left + jobsPos[id].width)),
    );
};

export const getOverlappingJobsArr = ({
    hcpId,
    jobsPosArr,
    jobPos,
    curLeft,
}: IPropsArr): string[] => {
    return Object.keys(jobsPosArr).filter(
        (id) =>
            jobsPosArr[id] &&
            jobsPosArr[id].length > 0 &&
            // Only the jobs of this HCP
            jobsPosArr[id][0].hcpId === hcpId &&
            // Not current job
            id !== jobPos.jobId &&
            // Overlap on the left side
            ((curLeft > jobsPosArr[id][0].left &&
                curLeft < jobsPosArr[id][0].left + jobsPosArr[id][0].width) ||
                // Overlap on the right side
                (curLeft + jobPos.width > jobsPosArr[id][0].left &&
                    curLeft + jobPos.width < jobsPosArr[id][0].left + jobsPosArr[id][0].width)),
    );
};

export default getOverlappingJobs;
