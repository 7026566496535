import { Patient, PatientAddress } from '@doc-abode/data-models';
import moment from 'moment';

import { IJobPos } from '../../interfaces/ucr';
import { isMultiAssigneeJob } from './isMultiAssigneeJob';

type FormikValues = {
    [key: string]: any;
};

export function isDisableFormBtn(
    values: FormikValues,
    requiredFields: Array<keyof FormikValues>,
): boolean {
    let isDisabled = false;

    requiredFields.forEach((field) => {
        if (values[field] === null || values[field] === undefined || values[field] === '') {
            isDisabled = true;
            return;
        }
    });

    return isDisabled;
}

export const isSecondDoubleUpHcp = (job: Patient, position: IJobPos): boolean => {
    return isMultiAssigneeJob(job) && Boolean(position?.isSecondDoubleUpHcp);
};

export const getValidityPeriod = (period?: { start?: string; end?: string }) => {
    const validFrom = period?.start ? moment(period.start).format('DD.MM.YYYY') : null;
    const validTo = period?.end ? moment(period.end).format('DD.MM.YYYY') : null;

    const validToText = validTo ? 'to ' + validTo : '';
    const description = validFrom ? `Valid from ${validFrom} ${validToText}` : '';

    return description;
};

export const formatAddressString = ({
    addressLine1,
    addressLine2,
    addressLine3,
    town,
    postCode,
}: PatientAddress) =>
    [addressLine1, addressLine2, addressLine3, town, postCode].filter((_) => _).join(', ');
