import { Schedule } from '@doc-abode/data-models';
import { Formik, FormikErrors, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import { IconWarning } from '../../../../../../helpers';
import useStores from '../../../../../../hook/useStores';
import { IHcp } from '../../../../../../interfaces/ucr';
import RootStore from '../../../../../../stores/RootStore';
import Loader from '../../../../../modules/helpers/Loader';
import { Button, ButtonSizes, Callout } from '../../../../../v2/components';
import { Select, Text, TimeInput } from '../../../../../v2/form';
import { WarningBanner } from '../../../../../v2/form/WarningBanner';
import { FetchJobsContext } from '../../../blocks/MainConst';
import { EnumOptionPattern, IAvailabilityTime } from './HcpAvailabilityFormTypes';
import { reverseLookupScheduleToPattern } from './reverseLookupScheduleToPattern';

type Props = {
    hcpHcpAvailableHours?: Schedule | null;
    pattern: string;
    hcp: IHcp;
    onSubmit: () => void;
};

export const Availability: FC<Props> = ({ hcpHcpAvailableHours, pattern, hcp, onSubmit }) => {
    const {
        RootStore: {
            schedulesStore: { setSchedules },
            ucrStore: { getCurrentJobsByUser, selectedDate },
        },
    } = useStores<{ RootStore: RootStore }>();

    const fetchJobs = useContext(FetchJobsContext);

    const initialStartTime = hcpHcpAvailableHours
        ? moment(hcpHcpAvailableHours.startDateTime).toDate()
        : moment().hour(7).minute(0).toDate();
    const initialEndTime = hcpHcpAvailableHours
        ? moment(hcpHcpAvailableHours.endDateTime).toDate()
        : moment().hour(20).minute(0).toDate();

    const hcpJobs = getCurrentJobsByUser(hcp.userId);

    return (
        <div>
            <Formik
                initialValues={{
                    arrivedDateTime: initialStartTime,
                    finishedDateTime: initialEndTime,
                    pattern: pattern,
                }}
                onSubmit={async (val) => {
                    const data = {
                        organisation: hcp.organisation,
                        userId: hcp.userId,
                        date: moment(selectedDate).format('YYYY-MM-DD'),
                        startTime:
                            val.pattern === EnumOptionPattern.NONE
                                ? null
                                : moment(val.arrivedDateTime).format('HH:mm'),
                        endTime:
                            val.pattern === EnumOptionPattern.NONE
                                ? null
                                : moment(val.finishedDateTime).format('HH:mm'),
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    };
                    onSubmit();
                    await setSchedules(data as any);
                    await fetchJobs.fetchSchedulesAndWarnings();
                }}
                enableReinitialize={false}
            >
                {({ values, errors, handleSubmit, setFieldValue, setErrors }) => (
                    <AvailabilityForm
                        hcp={hcp}
                        hcpJobs={hcpJobs}
                        values={values}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        setErrors={setErrors}
                    />
                )}
            </Formik>
        </div>
    );
};

const AvailabilityForm: FC<{
    hcp: IHcp;
    values: FormikValues;
    hcpJobs: any;
    errors: FormikErrors<FormikValues>;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    setFieldValue: (name: string, value: Moment | Date | string) => void;
    setErrors: (errors: FormikErrors<FormikValues>) => void;
}> = observer(({ hcp, values, hcpJobs, errors, handleSubmit, setFieldValue, setErrors }) => {
    const {
        RootStore: {
            ucrStore: { getSelectedDateMoment },
            lovsStore: { availabilityTimeSettings },
            schedulesStore: {
                validatingAvailability,
                validateNewAvailability,
                availabilityCheckResult,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [canSubmit, setCanSubmit] = useState(true);
    const [validateErrors, setValidateErrors] = useState({});

    const checkAvailability = (start: Moment, end: Moment) => {
        validateNewAvailability({
            userId: hcp.userId,
            startTime: moment(start).format('HH:mm'),
            endTime: moment(end).format('HH:mm'),
            date: moment(getSelectedDateMoment).format('DD/MM/YYYY'),
        });
    };

    useEffect(() => {
        checkAvailability(values.arrivedDateTime, values.finishedDateTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCanSubmit(!errors?.arrivedDateTime && !availabilityCheckResult?.error);
    }, [availabilityCheckResult?.error, errors?.arrivedDateTime]);

    useEffect(() => {
        // Checking Errors

        const validateErrorList: Record<string, string> = {};
        const newTimes = {
            arrivedDateTime: values.arrivedDateTime,
            finishedDateTime: values.finishedDateTime,
        };

        const hasSchedulingConflict = getSchedulingConflict(hcpJobs, newTimes);

        const isNonePattern =
            moment(values.arrivedDateTime).format('HH:mm') === '00:00' &&
            moment(values.finishedDateTime).format('HH:mm') === '00:00';

        if ((hcpJobs?.length && isNonePattern) || (hcpJobs?.length && hasSchedulingConflict)) {
            validateErrorList['availability'] = 'The HCP has admin and/or visits assigned';
        }

        setValidateErrors(validateErrorList);
    }, [values.arrivedDateTime, values.finishedDateTime, hcpJobs, setErrors]);

    const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const pattern: IAvailabilityTime = availabilityTimeSettings.find(
            (availabilityTime: IAvailabilityTime): boolean => {
                return availabilityTime.value === e.target.value;
            },
        ) || { value: 'none', label: 'None', startTime: '00:00', endTime: '00:00', enabled: true };
        setFieldValue('pattern', pattern.value);

        const times = getHcpTime({
            pattern,
            arrivedDateTime: values.arrivedDateTime,
            finishedDateTime: values.finishedDateTime,
            referenceDate: getSelectedDateMoment,
            availabilityTimeSettings,
        });

        if (!times) return;

        const [startTime, endTime] = times;
        const formattedStartTime = startTime.format('DD MM YYYY HH:mm');
        const formattedEndTime = endTime.format('DD MM YYYY HH:mm');

        if (moment(values.arrivedDateTime).format('DD MM YYYY HH:mm') !== formattedStartTime) {
            setFieldValue('arrivedDateTime', moment(startTime).toDate());
        }
        if (moment(values.finishedDateTime).format('DD MM YYYY HH:mm') !== formattedEndTime) {
            setFieldValue('finishedDateTime', moment(endTime).toDate());
        }

        checkAvailability(startTime, endTime);
    };

    const onChangeTime = ({
        arrivedDateTime,
        finishedDateTime,
    }: {
        arrivedDateTime: Moment;
        finishedDateTime: Moment;
    }) => {
        const pattern = values.pattern;

        const newPattern = reverseLookupScheduleToPattern(
            {
                startDateTime: arrivedDateTime.toISOString(),
                endDateTime: finishedDateTime.toISOString(),
            } as Schedule,
            availabilityTimeSettings as IAvailabilityTime[],
            false,
        );

        if (pattern !== newPattern.value) {
            setFieldValue('pattern', newPattern.value);
        }

        checkAvailability(arrivedDateTime, finishedDateTime);
    };

    return (
        <form className="ucr__calendar-hcp-form" onSubmit={canSubmit ? handleSubmit : () => {}}>
            <div className="ucr__calendar-hcp-formRow">
                <Text name="arrivedDateTime" description="Pattern" />
                <Select name="pattern" onChange={onChangeSelect}>
                    <option value="">Not Set</option>
                    {availabilityTimeSettings
                        .filter((availabilityTime: IAvailabilityTime) => {
                            return availabilityTime.enabled;
                        })
                        .map((availabilityTime: IAvailabilityTime) => {
                            return (
                                <option value={availabilityTime.value} key={availabilityTime.value}>
                                    {availabilityTime.label}
                                </option>
                            );
                        })}
                </Select>
            </div>

            <div className="ucr__calendar-hcp-formRow">
                <Text name="arrivedDateTime" description="Start time" />
                <TimeInput
                    name="arrivedDateTime"
                    selectAllOnFocus
                    required
                    value={values.arrivedDateTime}
                    handleOnChange={(date: Date) =>
                        onChangeTime({
                            arrivedDateTime: moment(date),
                            finishedDateTime: moment(values.finishedDateTime),
                        })
                    }
                />
            </div>
            <div className="ucr__calendar-hcp-formRow">
                <Text name="arrivedDateTime" description="End time" />
                <TimeInput
                    name="finishedDateTime"
                    selectAllOnFocus
                    required
                    value={values.finishedDateTime}
                    handleOnChange={(date: Date) =>
                        onChangeTime({
                            finishedDateTime: moment(date),
                            arrivedDateTime: moment(values.arrivedDateTime),
                        })
                    }
                />
            </div>

            <WarningBanner
                spacerTop={true}
                compact={true}
                simpleWarnings={Object.values(validateErrors)}
            />

            {!!availabilityCheckResult?.error && (
                <Callout intent="warning" Icon={IconWarning} spacerTop>
                    <p className="warning-block">{availabilityCheckResult.error}</p>
                </Callout>
            )}

            {validatingAvailability ? (
                <Loader fullscreen={false} size={30} />
            ) : (
                <div className="ucr__calendar-hcp-formRow button-container">
                    <Button
                        name="Apply"
                        size={ButtonSizes.SMALL}
                        clickEvent={canSubmit ? handleSubmit : () => {}}
                        type="submit"
                        disabled={!canSubmit}
                    />
                </div>
            )}
        </form>
    );
});

function getHcpTime({
    pattern,
    arrivedDateTime,
    finishedDateTime,
    referenceDate,
    availabilityTimeSettings,
}: {
    pattern: IAvailabilityTime;
    arrivedDateTime: Moment;
    finishedDateTime: Moment;
    referenceDate: Moment;
    availabilityTimeSettings: IAvailabilityTime[];
}): [Moment, Moment] {
    const timeSetting = availabilityTimeSettings.find((availableTime) => {
        return availableTime.value === pattern.value;
    });

    if (timeSetting?.value === 'custom') {
        return [moment(arrivedDateTime), moment(finishedDateTime)];
    }

    if (timeSetting) {
        const timesStart = timeSetting.startTime.split(':');
        const timesEnd = timeSetting.endTime.split(':');
        const start = moment(referenceDate)
            .hour(parseInt(timesStart[0]))
            .minute(parseInt(timesStart[1]))
            .millisecond(0);
        const end = moment(referenceDate)
            .hour(parseInt(timesEnd[0]))
            .minute(parseInt(timesEnd[1]))
            .millisecond(0);
        return [start, end];
    } else {
        return [
            moment(referenceDate).hour(0).minute(0).millisecond(0),
            moment(referenceDate).hour(0).minute(0).millisecond(0),
        ];
    }
}

function getSchedulingConflict(
    hcpJobs: any,
    {
        arrivedDateTime,
        finishedDateTime,
    }: {
        arrivedDateTime: Date | Moment;
        finishedDateTime: Date | Moment;
    },
): boolean {
    if (!hcpJobs?.length) return false;

    let hasSchedulingConflict = false;

    hcpJobs.forEach((j: any) => {
        const [durationHour, durationMinute] = j.duration?.split(':');
        const endDateTime = moment(j.startDateTime)
            .add(durationHour, 'hour')
            .add(durationMinute, 'minute');

        if (
            !moment(j.startDateTime).isAfter(arrivedDateTime) ||
            !moment(endDateTime).isBefore(finishedDateTime)
        ) {
            hasSchedulingConflict = true;
        }
    });

    return hasSchedulingConflict;
}
