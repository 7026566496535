import { observer } from 'mobx-react';
import { FC } from 'react';

import getHcpName from '../../../../../../helpers/ucr/getHcpName';
import useStores from '../../../../../../hook/useStores';
import { IHcp } from '../../../../../../interfaces/ucr';
import RootStore from '../../../../../../stores/RootStore';
import { formatDisplayDateTime } from '../../../../../modules/helpers/formatData';
import InfoItem from '../../../../../v2/components/InfoItem';

interface IGetFormattedData {
    entryAt?: string | null;
    entryBy?: string | null;
    users: IHcp[];
}

export function getFormattedData({ entryAt, entryBy, users }: IGetFormattedData) {
    return {
        formattedDateTime: entryAt ? formatDisplayDateTime(entryAt) : undefined,
        formattedUser: entryBy ? getHcpName(users, entryBy) : undefined,
    };
}

interface IProps {
    label: string;
    entryAt?: string | null;
    entryBy?: string | null;
}

export const AlertStatusTimeAndUser: FC<IProps> = observer(({ label, entryAt, entryBy }) => {
    const {
        RootStore: {
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { formattedDateTime, formattedUser } = getFormattedData({ entryAt, entryBy, users });

    return (
        <InfoItem label={label}>
            {formattedDateTime ?? ''}
            {formattedUser ? ` by ${formattedUser}` : ''}
        </InfoItem>
    );
});
