import { Intent } from '@blueprintjs/core';

export const statusMappings = {
    PENDING: 'Unassigned',
    AVAILABLE: 'Assigned',
    ACCEPTED: 'Accepted',
    CURRENT: 'Current',
    ARRIVED: 'Arrived',
    COMPLETED: 'Completed',
    HCP_ABORTED: 'Aborted by HCP',
    WITHDRAWN: 'Withdrawn',
    CONTROLLER_ABORTED: 'Archived',
    REMOVED: 'Removed',
};

export const statusTags = {
    PENDING: Intent.NONE,
    AVAILABLE: Intent.SUCCESS,
    ACCEPTED: Intent.SUCCESS,
    CURRENT: Intent.PRIMARY,
    ARRIVED: Intent.PRIMARY,
    HCP_ABORTED: Intent.DANGER,
    WITHDRAWN: Intent.WARNING,
    CONTROLLER_ABORTED: Intent.DANGER,
    REMOVED: Intent.DANGER,
};

export const withdrawnOptions = [
    {
        label: 'Select...',
        value: '',
    },
    {
        label: 'Unable to contact patient',
        value: 'Unable to contact patient',
    },
    {
        label: 'Patient unable to receive vaccination at present',
        value: 'Patient unable to receive vaccination at present',
    },
    {
        label: 'Other reason',
        value: 'Other reason',
    },
];

export const dateFormat = 'DD/MM/YYYY';
export const dateTimeFormat = 'DD/MM/YYYY HH:mm';

export const flagsIcons = {
    'Care Home': 'office',
    Carer: 'person',
    Staff: 'briefcase',
    'Sheltered Housing': 'home',
    'Supported Living': 'lifesaver',
    Refugee: 'symbol-cross',
    Test: 'lab-test',
    'Learning difficulty': 'learning',
    NOK: 'person',
    Housebound: 'home',
};
