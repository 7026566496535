import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { Button, Callout } from '@blueprintjs/core';
import { Hub, Vaccination } from '@doc-abode/data-models';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import Loader from '../../../../../modules/helpers/Loader';
import Modal from '../../../../../modules/modal/Modal';
import { VaccinationRoute } from '../../../types';
import { NewItinerarySection } from '../NewItinerarySection';
import { PickupRecalculationOptionsDialog } from './PickupRecalculationOptionsDialog';
import { UpdateRouteAddPatients } from './UpdateRouteAddPatients';
import { ConfirmUpdateRouteFooter, PatientToRemoveLink } from './updateRouteHelpers';
import useUpdateRouteModel from './useUpdateRouteModel';

interface Props {
    patientsToAdd: Vaccination[];
    onClose: () => void;
    deselect: (id: string) => void;
    setVaccinations: () => void;
    allPatients: Vaccination[];
    addPatients: boolean;
    recalculateRoute: boolean;
    onSort: (sortFunc: any) => void;
    refetchRoutes: (
        variables?: Partial<OperationVariables> | undefined,
    ) => Promise<ApolloQueryResult<any>>;
}

/**
 * When this component first renders we are in a state where we are getting data from the server so the spinner shows.
 * This is part of the UI that initiates calls to the back end for route related stuff
 * Is this just VaccineRoute?
 * Will it always be VaccineRoute? or is it correct to leave it generic even though its in the Vaccinations react-router-dom section
 * This seems to handled lots of route related stuff, edit existing route  multiple ways, but not create a new route
 * (that's done in CreateRoutes)
 *
 * we can:
 *   /recalculate
 *   /withdraw-from-existing
 *   /add-to-existing
 *
 *   the path is checked in the parent component (Routes.js  ~ line 1017) which decides if this component is rendered
 *   what does this component do if its /withdraw-from-existing
 *
 *   if seems to be that if it's not a recalculate and it's not an addPatients then it must be a /withdraw-from-existing
 *   see useEffect from ~line 268 onwards
 *   which ultimately triggers a set of actions that calls:
 *   routesApi.confirmUpdatedRoute
 *
 *   Looks like we check if this component is being used for
 *   recalculation or adding a patient , and the component behaves accordingly,  if it's not recalculation or adding a patient then the component
 *   is for withdraw patient.  this seems a little woolly, and we have to do this check in several places so its error-prone
 *
 * @constructor
 */
export const UpdateRoute: FC<Props> = ({
    patientsToAdd,
    onClose,
    deselect,
    setVaccinations,
    allPatients,
    addPatients,
    onSort,
    recalculateRoute,
    refetchRoutes,
}) => {
    const {
        clientKeys,
        multipleHubWarning,
        didError,
        unscheduledByCapacity,
        emptyItinerary,
        cannotRecalculate,
        showPickupRecalculationOptionsDialog,
        showVaccinationRemovedSuccessfully,
        onRemoveWarningPatients,
        filteredRoutes,
        handleOnClickUpdateRouteAddPatients,
        oldRoute,
        newRoute,
        handleConfirmUpdateRoute,
        handleClosePickupRecalculationOptionsDialog,
        title,
        showFooter,
        patientsToAddPickupRecalculationOptionsDialog,
        loaderSpinnerShow,
        loaderSpinnerMessage,
        removedPatients,
        removedByUser,
        newItinerary,
        removedByOptimisation,
        unscheduledByOptimisation,
        getAuthToken,
        hubs,
        patientsWithWarnings,
        selectedRoute,
        setShowPickupRecalculationOptionsDialog,
        onRequestUpdatedRoute,
    } = useUpdateRouteModel({
        deselect,
        recalculateRoute,
        addPatients,
        allPatients,
        patientsToAdd,
        setVaccinations,
        refetchRoutes,
    });

    const history = useHistory();
    const { state } = useLocation<{
        routeType: string;
        hub: Hub;
        selectedStartTime: string;
        userId: string;
        patient: Vaccination;
        route: VaccinationRoute;
    }>();

    return (
        <Modal
            title={title}
            onClose={onClose}
            footer={
                <ConditionalDisplay show={showFooter}>
                    <ConfirmUpdateRouteFooter onConfirm={handleConfirmUpdateRoute} />
                </ConditionalDisplay>
            }
            shadow
        >
            <ConditionalDisplay show={loaderSpinnerShow}>
                {/* LOADING SPINNER AND MESSAGE */}
                <div className="vaccinations__loading">
                    <Loader fullscreen={false} />
                    <p className="create-routes__please-wait">{loaderSpinnerMessage}</p>
                </div>
            </ConditionalDisplay>
            <ConditionalDisplay show={showVaccinationRemovedSuccessfully}>
                <Callout intent="success" icon="tick-circle" className="create-routes__callout">
                    Patient successfully removed from route.
                </Callout>
            </ConditionalDisplay>
            <ConditionalDisplay show={!loaderSpinnerShow && !showVaccinationRemovedSuccessfully}>
                {/* a whole mass of nested ternaries.  Not ideal -  nb the show logic for this conditional display
                is there because the proceeding 2 ConditionalDisplay used to be part of this ternary fest.*/}
                {multipleHubWarning ? (
                    <>
                        <Callout intent="danger" className="create-routes__callout">
                            The selected patients belong multiple hubs that cannot be combined into
                            a single route. Please refine your selection and try again.
                        </Callout>
                        <div>
                            <Button
                                icon="circle-arrow-left"
                                onClick={() => history.push('/vaccinations/patients')}
                                text="Go back"
                            />
                        </div>
                    </>
                ) : emptyItinerary && removedPatients?.length > 0 ? (
                    <Callout intent="warning" className="create-routes__callout">
                        The route could not be recalculated because it would result in an itinerary
                        with no patients. However,{' '}
                        <PatientToRemoveLink removedByUser={removedByUser} /> has been reset to an
                        'Unassigned' status so can now be withdrawn or added to a new route as
                        required.
                    </Callout>
                ) : cannotRecalculate && removedPatients?.length > 0 ? (
                    <Callout intent="warning" className="create-routes__callout">
                        The route could not be recalculated in its current state.{' '}
                        {!addPatients && !recalculateRoute && (
                            <>
                                However, <PatientToRemoveLink removedByUser={removedByUser} /> has
                                been reset to an 'Unassigned' status so can now be withdrawn or
                                added to a new route as required.
                            </>
                        )}
                    </Callout>
                ) : newItinerary ? (
                    <NewItinerarySection
                        newItinerary={newItinerary}
                        oldEndTime={oldRoute!.endTime}
                        oldTotalTravelDistance={oldRoute!.totalTravelDistance}
                        oldTotalTravelTime={oldRoute!.totalTravelTime}
                        newEndTime={newRoute!.endTime}
                        newTotalTravelDistance={newRoute!.totalTravelDistance}
                        newTotalTravelTime={newRoute!.totalTravelTime}
                        removedByUser={removedByUser}
                        removedByOptimisation={removedByOptimisation}
                        unscheduledByCapacity={unscheduledByCapacity}
                        unscheduledByOptimisation={unscheduledByOptimisation}
                        patientsToAdd={patientsToAdd}
                        addPatients={addPatients}
                        getAuthToken={getAuthToken}
                        apiKey={clientKeys.googleMaps}
                    />
                ) : addPatients ? (
                    <UpdateRouteAddPatients
                        didError={didError}
                        filteredRoutes={filteredRoutes}
                        hubs={hubs}
                        onClick={handleOnClickUpdateRouteAddPatients}
                        onRemoveWarningPatients={onRemoveWarningPatients}
                        onSort={onSort}
                        patientsToAdd={patientsToAdd}
                        patientsWithWarnings={patientsWithWarnings}
                    />
                ) : recalculateRoute ? (
                    <>
                        {didError && (
                            <Callout intent="danger" className="create-routes__callout">
                                Route generation was unsuccessful. Reported error:{' '}
                                {didError.message}
                                {didError.details?.map((detail) => (
                                    <div key={detail}>{detail}</div>
                                ))}
                            </Callout>
                        )}
                    </>
                ) : null}
            </ConditionalDisplay>
            <PickupRecalculationOptionsDialog
                patientsToAdd={patientsToAddPickupRecalculationOptionsDialog}
                vaccinationToRemove={state?.patient}
                route={selectedRoute}
                selectedStartTime={state?.selectedStartTime}
                hub={state?.hub}
                isOpen={showPickupRecalculationOptionsDialog}
                handleClose={handleClosePickupRecalculationOptionsDialog}
                setIsOpen={setShowPickupRecalculationOptionsDialog}
                onRequestUpdatedRoute={onRequestUpdatedRoute}
                recalculateRoute={recalculateRoute}
            />
        </Modal>
    );
};
