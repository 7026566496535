import { getHcpTypesDisplayLabel } from '../../../helpers/getHcpTypeDisplayLabel';
import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';
import {
    getS1DisplayInfo,
    getUserAvailabilityDisplayInfo,
    getUserMaxDriveDisplayLabel,
    getUserStatusDisplayInfo,
} from './getStatusDisplayLabels';

export interface IUseUserRowViewModel {
    user: any;
}

export const useUserRowViewModel = (props: IUseUserRowViewModel) => {
    const {
        RootStore: {
            usersStore: { getUserHcpTypes },
        },
    } = useStores<{ RootStore: RootStore }>();

    return {
        userId: props.user.userId,
        userName: props.user.userName,
        hcpTypesDisplayLabel: getHcpTypesDisplayLabel(getUserHcpTypes(props.user.userId)),
        userStatusInfo: getUserStatusDisplayInfo(props.user.enabled),
        availableStatusInfo: getUserAvailabilityDisplayInfo(props.user.available),
        s1StatusInfo: getS1DisplayInfo(props.user.s1Username),
        maxDriveTimeMinutesLabel: getUserMaxDriveDisplayLabel(props.user.maxDriveTimeMinutes),
    };
};
