import { Vaccination } from '@doc-abode/data-models';
import { formatNameFirstMiddleLast } from '@doc-abode/helpers';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { convertArrayToConcatenatedString } from '../../../../../helpers/convertArrayToConcatenatedString';
import { formatDisplayDate, formatPractice } from '../../../../modules/helpers/formatData';

interface Props {
    job: Vaccination;
    openInNewWindow?: boolean;
    modalRef?: any;
}

export function getPatientDisplayStringForItinerary(job: Vaccination) {
    const displayPartName = formatNameFirstMiddleLast({
        firstName: job.firstName,
        middleName: job.middleName,
        lastName: job.lastName,
    });
    const displayPartDob = formatDisplayDate(job.dateOfBirth);
    const displayPartPhone = job.contactNumber
        ? `, phone: ${convertArrayToConcatenatedString([job.contactNumber, ...(job.additionalContactNumbers || [])], ', ')}`
        : '';

    return `${displayPartName} (${job.nhsNumber}, ${displayPartDob}${displayPartPhone})`;
}

export function getVaccinationDisplayStringForItinerary(job: Vaccination) {
    const displayPartCategory = Vaccination.getFriendlyVaccinationCategory(job.vaccinationCategory);
    const displayPartManufacturer = job.vaccineManufacturer
        ? ` (${Vaccination.getFriendlyManufacturer(job.vaccineManufacturer)})`
        : '';

    return `${displayPartCategory}${displayPartManufacturer}`;
}

export const ItineraryItemSummary: FC<Props> = ({ job, openInNewWindow, modalRef }) => {
    const history = useHistory();

    return (
        <>
            {`${getPatientDisplayStringForItinerary(job)} for `}
            <Link
                to={() => ({
                    pathname: `/vaccinations/patients/${job.id}`,
                    state: {
                        closeModal: true,
                        previousLocation: history.location.pathname,
                        scrollTop: modalRef?.current?.scrollTop,
                    },
                })}
                target={openInNewWindow ? '_blank' : '_self'}
                data-testid={`instruction-patient-link-${job.id}`}
            >
                {getVaccinationDisplayStringForItinerary(job)}
            </Link>{' '}
            - {formatPractice(job.practice, job.odsCode)}
        </>
    );
};
