import { observer } from 'mobx-react';
import { FC } from 'react';

import { ViewToShow } from '../../../../constants/mainConst';
import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';
import { JobViewControls } from './viewComponents/ViewJobView';
import { MapViewControls } from './viewComponents/ViewMap';
import { PatientListViewControls } from './viewComponents/ViewPatientList';
import { S1MessagesViewControls } from './viewComponents/ViewS1Messages';

const UcrFilters: FC<{ refreshJobs(): void }> = ({ refreshJobs }) => {
    const {
        RootStore: {
            ucrStore: { viewToShow },
        },
    } = useStores<{ RootStore: RootStore }>();

    switch (viewToShow) {
        case ViewToShow.MAP:
            return <MapViewControls />;
        case ViewToShow.PATIENTS_LIST:
            return <PatientListViewControls refreshJobs={refreshJobs} />;
        case ViewToShow.TIMELINE:
        case ViewToShow.VISITS_LIST:
            return <JobViewControls refreshJobs={refreshJobs} />;
        case ViewToShow.S1_MESSAGES:
            return <S1MessagesViewControls />;
        case ViewToShow.VISIT_DETAILS:
        default:
            return null;
    }
};

export default observer(UcrFilters);
