import { useMutation, useQuery } from '@apollo/client';
import { IPatient, JobStatus, Patient } from '@doc-abode/data-models';
import { Formik, FormikValues } from 'formik';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import { FC, useContext, useEffect, useState } from 'react';

import { GET_JOB_BY_ID, UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import useStores from '../../../../../hook/useStores';
import { IHcp } from '../../../../../interfaces/ucr';
import { JobsContext } from '../../../../../providers';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts } from '../../../../../stores/UCRStore';
import { getMidnightDayString } from '../../../../modules/helpers/formatData';
import AppToaster from '../../../../modules/helpers/Toaster';
import { useView } from '../../views/useView';
import ReviewForm from '../AddVisit/ReviewForm';
import validationSchema from '../AddVisit/validation';
import { FormMode, FormSteps } from '../common';
import EditVisitForm from './EditVisitForm';
// todo type VisitData also exists in AddVisitTypes
export type VisitData = IPatient & {
    createDateTime: string;
    startDateTime: string;
    earliestDateOfVisit?: string;
    duration: string;
    availableFrom?: Moment;
    availableTo?: Moment;
    arrivedDateTime?: string;
    finishedDateTime?: string;
    dateOfBirth: string;
    jobType: string;
    visitDate: Moment;
    startTime: Moment;
    hcpId?: string;
    buddyId?: string;
    staffRequired?: string;
};
// todo type APIVisitData also exists in AddVisitTypes
export type APIVisitData = IPatient & {
    createDateTime: Date;
    startDateTime: Date;
    earliestDateOfVisit?: Date;
    duration: string;
    dateOfBirth: Date;
    jobType: string;
    hcpId?: string;
    hcpName?: string;
    buddyName?: string;
    buddyId?: string;
    staffRequired?: string;
    visitDate?: Moment;
    startTime?: Moment;
    endTime?: Moment;
    arrivedDateTime?: string;
    finishedDateTime?: string;
    buddyArrivedDateTime?: string;
    buddyFinishedDateTime?: string;
    latitude?: number;
    longitude?: number;
};

const formatTooApiDate = (
    values: VisitData,
    org: string,
    username: string,
    focusedJobId: string,
    jobs: Patient[],
    users?: IHcp[],
) => {
    const visitDate = moment(values.visitDate).seconds(0);
    const startTime = moment(values.startTime).seconds(0);

    const getAvailableFromHours = () => {
        if (!values.availableFrom) return null;

        const availableFromHours = moment(values.availableFrom).get('hours');
        const availableFromMinutes = moment(values.availableFrom).get('minutes');

        return visitDate
            .clone()
            .hour(availableFromHours)
            .minute(availableFromMinutes)
            .toISOString();
    };

    const getAvailableToHours = () => {
        if (!values.availableTo) return null;

        const availableToHours = moment(values.availableTo).get('hours');
        const availableToMinutes = moment(values.availableTo).get('minutes');

        return visitDate
            .clone()
            .hour(availableToHours)
            .minute(availableToMinutes)
            .seconds(0)
            .toISOString();
    };

    let jobStatus = values.jobStatus;

    if (values.hcpId && values.jobStatus === JobStatus.PENDING) {
        jobStatus = JobStatus.ACCEPTED;
    } else if (!values.hcpId) {
        jobStatus = JobStatus.PENDING;
    }

    const isJobArrived = values.jobStatus === JobStatus.ARRIVED;
    const isJobCurrent = values.jobStatus === JobStatus.CURRENT;
    const currentJobHcp = jobs.find((job: Patient) => job.id === focusedJobId)?.hcpId;
    const isJobArrivedOrCurrentChange =
        (isJobArrived || isJobCurrent) && currentJobHcp !== values.hcpId;

    const data = {
        ...cloneDeep(values as APIVisitData),
        createDateTime: moment(values.createDateTime).toISOString(),
        startDateTime: values.startTime
            ? visitDate
                  .clone()
                  .hour(startTime.hour())
                  .minute(startTime.minute())
                  .seconds(0)
                  .toISOString()
            : null,
        dateOfVisit: getMidnightDayString(visitDate),
        earliestDateOfVisit: values.earliestDateOfVisit
            ? moment(values.earliestDateOfVisit).clone().seconds(0).toISOString()
            : null,
        availableFrom: getAvailableFromHours(),
        availableTo: getAvailableToHours(),
        dateOfBirth: moment(values.dateOfBirth).toISOString(),
        jobStatus: isJobArrivedOrCurrentChange ? JobStatus.ACCEPTED : jobStatus,
        buddyId: values.buddyId || null,
        buddyName:
            values.buddyId && users?.find((user: any) => values.buddyId === user.userId)?.userName,
        jobType: 'ucr',
        lastUpdatedBy: username,
        lastUpdatedDateTime: moment().toISOString(),
        organisation: org,
        version: values.version + 1,
        hcpName: users?.find((user: any) => values.hcpId === user.userId)?.userName,
        hcpId: values.hcpId || null,
        additionalContactNumbers:
            values.additionalContactNumbers?.filter((str: string) => str) || [],
    };

    if (values.referralDateTime) {
        data.referralDateTime = moment(values.referralDateTime).seconds(0).toISOString();
    }

    // so if the values are set we will allow them to be sent to the api but if they aren't defined we won't send anything.
    if (values.arrivedDateTime) {
        data.arrivedDateTime = values.arrivedDateTime;

        // If modifying the arrivedDateTime, ensure it is not earlier that the madeCurrentDateTime
        if (moment(values.madeCurrentDateTime).isAfter(moment(values.arrivedDateTime))) {
            data.madeCurrentDateTime = values.arrivedDateTime;
        }
    } else {
        delete data.arrivedDateTime;
    }
    if (values.finishedDateTime) {
        data.finishedDateTime = values.finishedDateTime;
    } else {
        delete data.finishedDateTime;
    }
    if (values.buddyArrivedDateTime) {
        data.buddyArrivedDateTime = values.buddyArrivedDateTime;
    } else {
        delete data.buddyArrivedDateTime;
    }
    if (values.buddyFinishedDateTime) {
        data.buddyFinishedDateTime = values.buddyFinishedDateTime;
    } else {
        delete data.buddyFinishedDateTime;
    }
    if (values.staffRequired === 2) {
        if (values.buddyId && values.buddyJobStatus === JobStatus.PENDING) {
            data.buddyJobStatus = JobStatus.ACCEPTED;
        } else if (!values.buddyId) {
            data.buddyJobStatus = JobStatus.PENDING;
        }
    }

    if (values.pds) {
        data.pds = {
            versionId: values.pds?.versionId,
        };
    }

    delete data.visitDate;
    delete data.startTime;
    delete data.endTime;

    delete data.latitude;
    delete data.longitude;

    return data;
};

const getNewFormValues = (values: FormikValues): VisitData => {
    const newValues = {
        ...(cloneDeep(values) as VisitData),
        contactNumber: values.contactNumber,
    };

    return newValues;
};

const EditVisit: FC = () => {
    const {
        RootStore: {
            configStore: { org },
            userStore: {
                user: { username },
            },
            usersStore: { users },
            ucrStore: { setOpenedDialogAlert, focusedJobId, jobs, selectedDate },
        },
    } = useStores<{ RootStore: RootStore }>();

    const jobsContext = useContext(JobsContext);
    const { currentViewState } = useView();

    const getVisit = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
        pollInterval: 60000,
    });

    const [formData, setFormData] = useState<{
        step: FormSteps;
        values?: VisitData | null;
        initialValues?: FormikValues | null;
        formMode: FormMode;
    }>({
        step: FormSteps.REVIEW,
        formMode: FormMode.EDIT_VISIT,
    });

    const [updateJob, { loading, error }] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            getVisit?.refetch?.();
            if (!currentViewState.patientList) {
                const isUnassigned =
                    (formData.values?.staffRequired !== 2 && !formData.values?.hcpId) ||
                    (formData.values?.staffRequired === 2 &&
                        !formData.values?.hcpId &&
                        !formData.values?.buddyId);

                if (
                    isUnassigned ||
                    moment(formData.values?.visitDate).isSame(moment(selectedDate), 'day')
                ) {
                    jobsContext.setRefreshAssignedJobs(true);
                }
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });

    const [initialised, setInitialised] = useState(false);

    const onSubmit = async (values: FormikValues) => {
        const newValues = getNewFormValues(values);

        setFormData((data) => ({
            ...data,
            values: newValues,
        }));
    };

    const onSave = async (values: FormikValues) => {
        const newValues = getNewFormValues(values);
        const formattedData = formatTooApiDate(newValues, org, username, focusedJobId, jobs, users);

        setFormData((data) => ({
            ...data,
            values: newValues,
            initialValues: newValues,
        }));

        try {
            await updateJob({ variables: { input: formattedData } });
        } catch (err) {
            console.log('Error updating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unabled to update the visit',
                intent: 'danger',
            });
            return;
        }

        AppToaster.show({
            message: 'Visit updated successfully!',
            intent: 'success',
        });
    };

    useEffect(() => {
        const job = getVisit.data?.getJob;

        const newValues = {
            ...job,
            contactNumber: job.contactNumber || '',
            additionalContactNumbers:
                job.additionalContactNumbers?.filter((str: string) => str) || [],
            middleName: job.middleName || '',
            addressLine2: job.addressLine2 || '',
            addressLine3: job.addressLine3 || '',
            dateOfBirth: new Date(job.dateOfBirth),
            referralDateTime: job.referralDateTime ? new Date(job.referralDateTime) : undefined,
            visitDate: new Date(job.startDateTime || job.dateOfVisit),
            availableFrom: job.availableFrom ? new Date(job.availableFrom) : undefined,
            availableTo: job.availableTo ? new Date(job.availableTo) : undefined,
            arrivedDateTime: job.arrivedDateTime ? new Date(job.arrivedDateTime) : undefined,
            finishedDateTime: job.finishedDateTime ? new Date(job.finishedDateTime) : undefined,
            buddyArrivedDateTime: job.buddyArrivedDateTime
                ? new Date(job.buddyArrivedDateTime)
                : undefined,
            buddyFinishedDateTime: job.buddyFinishedDateTime
                ? new Date(job.buddyFinishedDateTime)
                : undefined,
            startTime: job.startDateTime ? new Date(job.startDateTime) : undefined,
            earliestDateOfVisit: job.earliestDateOfVisit ? new Date(job.earliestDateOfVisit) : null,
            staffPreferredGender: job.staffPreferredGender || [],
            languagesSpoken: job.languagesSpoken || [],
            buddyJobStatus: job.buddyJobStatus,
        };

        delete newValues.__typename;

        if (!initialised) {
            setFormData((data) => ({
                ...data,
                values: newValues,
                initialValues: newValues,
            }));
            setInitialised(true);
        }
    }, [getVisit, initialised]);

    const onEditReviewForm = (step: FormSteps) => {
        setOpenedDialogAlert(DialogAlerts.SAVE_VISIT);

        setFormData((data) => {
            return {
                ...data,
                step,
                values: null,
            };
        });
    };

    useEffect(() => {
        setOpenedDialogAlert(!formData.values ? DialogAlerts.SAVE_VISIT : DialogAlerts.NONE);
    }, [formData.values, setOpenedDialogAlert]);

    return (
        <Formik
            initialValues={{
                ...formData.initialValues,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {formData.values ? (
                <ReviewForm
                    values={formData.values}
                    patient={getVisit.data?.getJob}
                    formMode={formData.formMode}
                    onEdit={onEditReviewForm}
                    isJobCreated={true}
                />
            ) : (
                initialised && (
                    <EditVisitForm
                        step={formData.step}
                        loading={loading}
                        error={error}
                        formMode={formData.formMode}
                        onSave={onSave}
                    />
                )
            )}
        </Formik>
    );
};

export default EditVisit;
