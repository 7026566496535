import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageNotFound extends Component {
    render() {
        return (
            <div className="page">
                <p>
                    Page not found, please return to the <Link to="/">home page</Link>.
                </p>
            </div>
        );
    }
}

export default PageNotFound;
