import { useEffect } from 'react';

/**
 * Hook that is only called once when unmounted
 * @param didUnmount Effect callback that will only be called once
 */
export const useComponentDidUmount = (
    // Type taken directly from
    didUnmount: () => void,
) => {
    useEffect(
        () => didUnmount,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
};
