import { Formik } from 'formik';
import { observer } from 'mobx-react';

import { TextInput } from '../../../../v2/form';
import { CommonControls } from '../CommonControls';
import { useViewS1MessagesViewModel } from './useViewS1MessagesViewModel';

export const S1MessagesViewControls = observer(() => {
    const { refresh, setFilter, nhsNumber, setNhsNumber } = useViewS1MessagesViewModel();

    return (
        <div className="ucr__main-filters">
            <CommonControls
                disableDatePicker
                disableAddVisit
                disableAddAdmin
                refresh={refresh}
                additionalLeft={
                    <Formik initialValues={{}} onSubmit={() => {}}>
                        <TextInput
                            name="nhsNumber"
                            className="ucr__filters-filter-input ucr__filters-filter-first"
                            placeholder="NHS number"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setFilter(nhsNumber);
                                }
                            }}
                            onChange={(event) => {
                                setNhsNumber(event.target.value);
                            }}
                        />
                    </Formik>
                }
            />
        </div>
    );
});
