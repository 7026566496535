import { Button } from '@blueprintjs/core';
import { Form, Formik } from 'formik';
import React from 'react';

import Input from '../common/formik/TextInput';

const ForgotPassword = ({ didError, onBackToSignIn, onForgotPassword }) => {
    const initialValues = {
        userName: '',
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onForgotPassword}>
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <h2 className="h2">Forgotten password</h2>

                        <p>
                            Please enter your username, email address or phone number below and
                            we'll send you a code to reset your password.
                        </p>

                        <Input
                            name="userName"
                            label="Username, email address or phone number †"
                            placeholder="e.g. jane.smith"
                            disabled={isSubmitting}
                        />

                        <Button
                            intent="success"
                            large
                            loading={isSubmitting}
                            text="Send verification code"
                            icon="send-message"
                            type="submit"
                            outlined
                            fill
                        />

                        <p className="auth__small-print">
                            † You may only use an email address or phone number to reset your
                            password if they have been verified, therwise you must use your
                            username.
                        </p>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ForgotPassword;
