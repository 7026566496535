import { Checkbox } from '@blueprintjs/core';
import { useField } from 'formik';
import React from 'react';

const CheckboxInput = ({ label, ...props }) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });

    return (
        <>
            <Checkbox label={label} {...field} {...props} />
            {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </>
    );
};

export default CheckboxInput;
