import { Vaccination } from '@doc-abode/data-models';
import { formatNameFirstMiddleLast } from '@doc-abode/helpers';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { formatDisplayDate } from '../../../../modules/helpers/formatData';

interface Props {
    patients: Vaccination[];
}

export const PatientSummaryList: FC<Props> = ({ patients }) => (
    <ul>
        {patients.map(
            ({
                id,
                firstName,
                middleName,
                lastName,
                nhsNumber,
                dateOfBirth,
                doseNumber,
                vaccinationCategory,
                vaccinationType,
            }) => (
                <li key={id} data-testid={id}>
                    <Link to={`/vaccinations/patients/${id}`}>
                        {formatNameFirstMiddleLast({ firstName, middleName, lastName })} (
                        {nhsNumber}, {formatDisplayDate(dateOfBirth)}) for{' '}
                        {Vaccination.getFriendlyVaccinationCategory(vaccinationCategory)}{' '}
                        vaccination{' '}
                        {doseNumber
                            ? `(dose ${doseNumber}${
                                  vaccinationType ? ` ${vaccinationType.toLowerCase()}` : null
                              })`
                            : null}
                    </Link>
                </li>
            ),
        )}
    </ul>
);
