import { S1ClientConnectionStatus } from '@doc-abode/data-models';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getS1ClientConnectionStatuses } from './s1Api';

export const useGetS1ClientConnectionStatuses = (displayIntervalMinutes: number = 24 * 60) => {
    const queryKey = ['s1-client-statuses', displayIntervalMinutes];
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: queryKey,
        queryFn: () => getS1ClientConnectionStatuses(displayIntervalMinutes),
        retry: 1,
        refetchInterval: 60_000,
        staleTime: 30_000,
        initialData: () => {
            const cachedData = queryClient.getQueryData<S1ClientConnectionStatus[]>(queryKey);
            return cachedData;
        },
    });
};
