import { EffectCallback, useEffect } from 'react';

/**
 * Hook that is only called once when mounted
 * @param didUpdate Effect callback that will only be called once
 */
export const useComponentDidMount = (didUpdate: EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        didUpdate,
        //
        [],
    );
};
