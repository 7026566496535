import { FC, memo } from 'react';

interface IProps {}

const Empty: FC<IProps> = () => {
    return (
        <div className="ucr__calendar-visit-empty">
            <span>No visits available for assignment</span>
        </div>
    );
};

export default memo(Empty);
