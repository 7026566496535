import { Popover } from '@blueprintjs/core';
import { AlertCategory, PatientAlert, StaffAlert } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import { FC, useMemo, useState } from 'react';

import { getHcpName } from '../../../../../helpers';
import { IconBell, IconBellActive } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';
import { Button, ButtonColors, ButtonElems } from '../../../../v2/components';
import { SideButton } from '../../components/side';

interface IProps {
    size: string;
}

const filterSort = <Alert extends StaffAlert | PatientAlert>(alerts: Alert[]) =>
    alerts.slice().sort((a, b) => {
        const A = new Date(a.createdAt).getTime();
        const B = new Date(b.createdAt).getTime();

        if (A === B) {
            return 0;
        }

        return A < B ? 1 : -1;
    });

export const UrgentWarningsButton: FC<IProps> = observer(({ size }) => {
    const {
        RootStore: {
            ucrStore: {
                patientAlerts,
                staffAlerts,
                setOpenedStaffAlertInfo,
                setOpenedPatientAlertInfo,
            },
        },
    } = useStores<{ RootStore: RootStore }>();
    const [showPopover, setShowPopover] = useState(false);

    const alerts = useMemo(
        () => [...filterSort(staffAlerts), ...filterSort(patientAlerts)],
        [patientAlerts, staffAlerts],
    );

    const acknowledgedAlerts = alerts.reduce((total, alert) => {
        return total + (alert.acknowledged ? 1 : 0);
    }, 0);

    const unresolvedAlerts = alerts.length;
    const isAlerting = alerts.length !== 0 && alerts.length !== acknowledgedAlerts;

    const buttonColor = unresolvedAlerts > 0 ? ButtonColors.RED_LIGHT : ButtonColors.WHITE;

    const iconClass =
        unresolvedAlerts > 0 ? 'ucr-urgent-warning-notify' : 'ucr-urgent-warning-base';

    const AlertEntries = (
        <div className="ucr-urgent-warning-container">
            {alerts.map((alert, i) => {
                return (
                    <UrgetAlertSelectorButton
                        key={alert.alertId}
                        alert={alert}
                        clickEvent={() => dropDownClick(alert)}
                    />
                );
            })}
        </div>
    );

    const dropDownClick = (alert: StaffAlert | PatientAlert) => {
        switch (alert.category) {
            case AlertCategory.PATIENT:
                setOpenedPatientAlertInfo(alert as PatientAlert);
                break;
            case AlertCategory.STAFF:
                setOpenedStaffAlertInfo(alert as StaffAlert);
                break;
            default:
                console.error(new TypeError(`Unrecognised alert type: ${alert.category}`));
        }

        setShowPopover(false);
    };

    return (
        <Popover
            popoverClassName="v2-popover"
            placement="right-start"
            content={AlertEntries}
            isOpen={showPopover}
            transitionDuration={100}
            onClose={() => setShowPopover(false)}
            minimal
        >
            <SideButton
                name="Urgent Warnings"
                tooltipText="Urgent Warnings"
                size={size}
                pos="top"
                Icon={isAlerting ? IconBellActive : IconBell}
                isActive={false}
                clickEvent={() => {
                    if (unresolvedAlerts > 0) {
                        setShowPopover(!showPopover);
                    }
                }}
                dataTest="icon-urgent-warnings"
                badgeContent={unresolvedAlerts > 0 ? unresolvedAlerts.toString() : undefined}
                color={isAlerting ? ButtonColors.RED : buttonColor}
                iconClass={isAlerting ? 'ucr-urgent-warning-alert' : iconClass}
                fullSizeIcon
            />
        </Popover>
    );
});

const UrgetAlertSelectorButton: FC<{
    alert: PatientAlert | StaffAlert;
    clickEvent(): void;
}> = observer(({ alert, clickEvent }) => {
    return (
        <Button
            className="ucr-urgent-warning-button__drop-down-button"
            elem={ButtonElems.BUTTON}
            color={alert.category === AlertCategory.STAFF ? ButtonColors.RED : ButtonColors.AMBER}
            clickEvent={clickEvent}
            name={<ButtonInner alert={alert} />}
        />
    );
});

const ButtonInner: FC<{ alert: StaffAlert | PatientAlert }> = ({ alert }) => {
    const {
        RootStore: {
            ucrStore: { hcps, jobs },
        },
    } = useStores<{ RootStore: RootStore }>();

    switch (alert.category) {
        case AlertCategory.PATIENT:
            const job = jobs.find((job: any) => (alert as PatientAlert).jobId === job.id);
            return (
                <>
                    <div className="ucr-urgent-warning-button__type">Patient Alert:</div>
                    <dl className="ucr-urgent-warning-button__content">
                        <dt className="ucr-urgent-warning-button__dt">Staff Member:</dt>
                        <dd className="ucr-urgent-warning-button__dd">
                            {getHcpName(hcps, (alert as PatientAlert).createdBy)}
                        </dd>
                        <dt className="ucr-urgent-warning-button__dt">Patient:</dt>
                        <dd className="ucr-urgent-warning-button__dd">
                            {job?.lastName}, {job?.firstName} {job?.middleName}
                        </dd>
                        <dt className="ucr-urgent-warning-button__dt">Time:</dt>
                        <dd className="ucr-urgent-warning-button__dd">
                            {alert.createdAt ? formatDisplayDateTime(alert.createdAt) : ''}
                        </dd>
                    </dl>
                </>
            );
        case AlertCategory.STAFF:
            return (
                <>
                    <div className="ucr-urgent-warning-button__type">Staff Alert:</div>
                    <dl className="ucr-urgent-warning-button__content">
                        <dt className="ucr-urgent-warning-button__dt">Staff Member:</dt>
                        <dd className="ucr-urgent-warning-button__dd">
                            {getHcpName(hcps, (alert as StaffAlert).userId)}
                        </dd>
                        <dt className="ucr-urgent-warning-button__dt">Time:</dt>
                        <dd className="ucr-urgent-warning-button__dd">
                            {alert.createdAt ? formatDisplayDateTime(alert.createdAt) : ''}
                        </dd>
                    </dl>
                </>
            );
        default:
            console.error(new TypeError(`Unrecognised alert type: ${alert.category}`));
            return <>Unrecognised alert; Details unavailable</>;
    }
};
