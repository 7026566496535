import { RouteInformation } from '../controller/getRoutes';

export function getTabId(route: RouteInformation): string {
    if (route.tabId) {
        return route.tabId;
    }

    if (route.tabTarget) {
        return route.tabTarget.replaceAll('/', '');
    }

    return route.path.replaceAll('/', '');
}
