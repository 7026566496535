import { Patient } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC } from 'react';

import { PatientListJob } from '../../../../../graphql/queries/patients';
import { getPathwayServiceOrAdminTypeLabels } from '../../../../../helpers/ucr/getPathwayServiceOrAdminTypeLabels';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { useView } from '../../views/useView';
import { NotSet } from './NotSet';

export const PatientListJobDetails: FC<{
    job?: PatientListJob;
    date?: string | null;
    hcp?: string | null;
    hideHour?: boolean;
}> = observer(({ job, date, hcp, hideHour }) => {
    const {
        RootStore: {
            configStore: { pathways, adminTypes },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { openDeepLink } = useView();

    if (!job) {
        return <td>-</td>;
    }

    const { pathwayDisplayLabel, serviceOrAdminTypeDisplayLabel } =
        getPathwayServiceOrAdminTypeLabels(job as Patient, pathways, adminTypes);

    return (
        <td
            className="patient-list__job-cell"
            onClick={() => {
                if (job) {
                    openDeepLink(job.id, hcp === job.hcpId ? 'user1' : 'user2');
                }
            }}
        >
            {date ? moment(date).format(hideHour ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm') : <NotSet />}
            <div className="patient-list__job-cell-details">
                {pathwayDisplayLabel}
                {', '}
                {serviceOrAdminTypeDisplayLabel}
            </div>
        </td>
    );
});
