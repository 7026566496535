import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    Alert,
    Callout,
    FormGroup,
    HTMLSelect,
    Icon,
    IconName,
    Intent,
    Tag,
    TextArea,
} from '@blueprintjs/core';
import { DateInput3 } from '@blueprintjs/datetime2';
import {
    Flags,
    friendlyConsentStatuses,
    friendlyCovid19Manufacturers,
    JobStatus,
    JobType,
    Vaccination,
    VaccinationCategory,
    VaccinationType,
} from '@doc-abode/data-models';
import { Form, Formik, FormikValues } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { OptionTypeBase } from 'react-select';

import { JobFilter } from '../../../../__generated__/v2';
import {
    dateFormat,
    dateTimeFormat,
    flagsIcons,
    withdrawnOptions,
} from '../../../../constants/patientsConst';
import { routeStatusTags } from '../../../../constants/routesConst';
import {
    GET_JOB_BY_ID,
    QUERY_JOBS_BY_JOB_TYPE,
    UPDATE_JOB,
} from '../../../../graphql/queries/jobs';
import { capitalizeFirstLetter, ellipsis } from '../../../../helpers';
import { getAbortedDetails } from '../../../../helpers/getAbortedDetails';
import isMultiDoseVaccine from '../../../../helpers/isMultiDoseVaccine';
import { getMomentDateFormatter } from '../../../../helpers/ucr';
import useStores from '../../../../hook/useStores';
import { IHcp } from '../../../../interfaces';
import { IAbortAlertDialogLabels } from '../../../../interfaces/AlertDialogLabels';
import RootStore from '../../../../stores/RootStore';
import CheckboxInput from '../../../common/formik/CheckboxInput';
import SelectInput from '../../../common/formik/SelectInput';
import TextAreaInput from '../../../common/formik/TextAreaInput';
import TextInput from '../../../common/formik/TextInput';
import { PracticeSelect } from '../../../common/PracticeSelect';
import Error from '../../../modules/forms/Error';
import {
    formatDisplayDate,
    formatDisplayDateTime,
    formatPractice,
    formatPractices,
    getAgeFromDate,
} from '../../../modules/helpers/formatData';
import { splitPracticeDetails } from '../../../modules/helpers/formatDataTyped';
import Loader from '../../../modules/helpers/Loader';
import Modal from '../../../modules/modal/Modal';
import { Accordion, AccordionColors, AccordionTab } from '../../../v2/components/Accordion';
import { DateInput } from '../../../v2/form';
import { graphqlErrorHandler } from '../../common/errorHandling/graphqlErrorHandler';
import { AbortJobDialog } from '../../common/forms/abortAction/AbortJobDialog';
import { getAPIReadyPatients } from '../helpers';
import markAsCompletedValidationSchema from './markAsCompletedValidation';
import PatientDetailsFooter from './PatientDetialsFooter';
import StatusTag from './StatusTag';
import validationSchema from './validation';

export interface IFixBluePrintJsDateChangeValue {
    dateString: string | null;
}
export function fixBluePrintJsDateChangeValue({
    dateString,
}: IFixBluePrintJsDateChangeValue): Date | null {
    let date: Date | null = null;
    if (dateString) {
        date = new Date(dateString);
    }

    return date;
}
interface IPatientDetails {
    id: string;
}

const sanitiseData = (data: Record<string, any>) => {
    const output: any = {};
    Object.entries(data).forEach(([key, value]) => {
        if (value !== null) {
            output[key] = value;
        }
    });
    return output;
};

const POLL_INTERVAL = 60000;

export default function PatientDetails({ id }: IPatientDetails) {
    const [routes, setRoutes] = useState<any[]>([]);
    const [editing, setEditing] = useState(false);
    const [saveHandler, setSaveHandler] = useState<AsyncGenerator>();
    const [showEditDateOfPreviousDosePrompt, setShowEditDateOfPreviousDosePrompt] = useState(false);
    const [showWithdrawPrompt, setShowWithdrawPrompt] = useState(false);
    const [showAbortPrompt, setShowAbortPrompt] = useState(false);
    const [showUnassignPrompt, setShowUnassignPrompt] = useState(false);
    const [showCompletePrompt, setShowCompletePrompt] = useState(false);
    const [withdrawingReason, setWithdrawingReason] = useState('');
    const [withdrawingNotes, setWithdrawingNotes] = useState('');
    const [withdrawingErrors, setWithdrawingErrors] = useState<any[]>([]);
    const [markedCompletedErrors, setMarkedCompletedErrors] = useState<Record<string, string[]>>(
        {},
    );
    const [isRouteFetched, setIsRouteFetched] = useState(false);
    const [isAssignedToRoute, setIsAssignedToRoute] = useState<boolean>();

    const {
        RootStore: {
            userStore: {
                isSuperuser,
                user: { username },
            },
            usersStore: { hcpUsers },
            configStore: { hubs, getHubName, vaccinationDuration },
        },
    } = useStores<{ RootStore: RootStore }>();

    const location = useLocation<{
        closeModal?: boolean;
        scrollTop?: number;
        previousLocation: string;
    }>();
    const history = useHistory();

    const {
        loading: loadingJob,
        error: errorJob,
        data: dataJob,
    } = useQuery(GET_JOB_BY_ID, {
        variables: {
            id,
        },
        pollInterval: POLL_INTERVAL,
        onError: ({ graphQLErrors }) => graphqlErrorHandler({ graphQLErrors }),
    });

    // These labels will be used in the dialog to set archiving reasons
    const abortAlertLabels: IAbortAlertDialogLabels = {
        header: 'Are you sure you want to archive this patient?',
        abortReason: 'Archive reason',
        abortNotes: 'Archive notes',
        explanatoryText:
            'You will no longer be able to edit or assign them for a vaccination, and they will need to be re-imported into the system if that is required.',
        callouts: 'You must also ensure that any external case details are updated',
        confirmActionButton: 'Confirm',
        cancelActionButton: 'Cancel',
    };

    const [updateJob, updateJobState] = useMutation(UPDATE_JOB);

    const patient = useMemo(() => new Vaccination(sanitiseData(dataJob?.getJob || {})), [dataJob]);

    const {
        firstName,
        lastName,
        nhsNumber,
        flags = [],
        dateOfBirth,
        contactNumber,
        additionalContactNumbers,
        gender,
        addressLine1,
        addressLine2,
        town,
        postCode,
        practice,
        doseNumber,
        dateOfDose1,
        dateOfDose2,
        dateOfPreviousDose,
        jobStatus,
        hubId,
        consented,
        createDateTime,
        acceptedDateTime,
        madeCurrentDateTime,
        arrivedDateTime,
        finishedDateTime,
        hcpId,
        hcpName,
        notes,
        itineraryId,
        manufacturerOfDose1,
        manufacturerOfDose2,
        manufacturerOfPreviousDose,
        availableFrom,
        availableTo,
        vaccinationCategory,
        friendlyVaccinationCategory,
        friendlyCoAdministeredWith,
        friendlyManufacturer,
        friendlyManufacturerOfDose1,
        friendlyManufacturerOfDose2,
        friendlyManufacturerOfPreviousDose,
        withdrawnReason,
        withdrawnNotes,
        vaccineBatchNumber,
        vaccineManufacturer,
        vaccineExpiryDate,
        vaccineSiteOfAdministration,
        vaccinationType,
        markedCompletedByController,
        markedCompletedByControllerNotes,
        odsCode,
    } = patient;

    const v2Filter: JobFilter = {
        dynamoDBIndexName: 'jobType-index',
        jobType: {
            equals: JobType.ROUTE,
        },
        itineraryId: {
            equals: itineraryId,
        },
    };

    const [fetchRoutes, { loading: loadingRoutes, data: dataRoutes }] = useLazyQuery(
        QUERY_JOBS_BY_JOB_TYPE,
        {
            variables: {
                jobType: JobType.ROUTE,
                filter: { itineraryId: { eq: itineraryId } },
                v2Filter: JSON.stringify(v2Filter),
                itineraryType: 'full',
            },
            fetchPolicy: 'network-only',
            pollInterval: POLL_INTERVAL,
            onError: ({ graphQLErrors }) => graphqlErrorHandler({ graphQLErrors }),
        },
    );

    // Using the utility makes the rendering of aborted details simpler
    const abortedData = getAbortedDetails(patient, false);
    let abortLabel = 'Reason for aborting';
    if (abortedData && abortedData.abortedStatus === JobStatus.CONTROLLER_ABORTED) {
        abortLabel = 'Reason for archiving';

        // Suppress the follow-up action which would have been set by the HCP when rendering abort data for controllers
        if (abortedData.abortedFollowUpAction) {
            abortedData.abortedFollowUpAction = undefined;
        }
    }

    const [markedCompletedHcp, setMarkedCompletedHcp] = useState<IHcp | undefined>(
        routes.length > 0
            ? hcpUsers.find((user: IHcp) => user.userId === routes[0].nominatedHcps[0])
            : undefined,
    );
    const [markedArrivedDateTime, setMarkedArrivedDateTime] = useState<Date | null>(
        arrivedDateTime ? new Date(arrivedDateTime) : new Date(),
    );
    const [markedCurrentDateTime, setMarkedCurrentDateTime] = useState<Date | null>(
        madeCurrentDateTime ? new Date(madeCurrentDateTime) : new Date(),
    );
    const [markedCompletedFinishedDateTime, setMarkedCompletedFinishedDateTime] =
        useState<Date | null>(finishedDateTime ? new Date(finishedDateTime) : new Date());
    const [vaccineBatchNumberGiven, setVaccineBatchNumberGiven] = useState(vaccineBatchNumber);
    const [vaccineExpiryDateTime, setVaccineExpiryDateTime] = useState<Date | null>(
        vaccineExpiryDate ? new Date(vaccineExpiryDate) : new Date(),
    );
    const [siteOfAdministration, setSiteOfAdministration] = useState(vaccineSiteOfAdministration);
    const [markedCompletedNotes, setMarkedCompletedNotes] = useState(
        markedCompletedByControllerNotes,
    );

    const [selectedPractice, setSelectedPractice] = useState<OptionTypeBase | null | undefined>(
        // Lookup of practices is executed against the ODS API
        // The reverse lookup when editing patients needs to ensure the PracticeSelect has the currently set value available, otherwise the field is blanl
        practice ? formatPractices([{ name: practice, odsCode: odsCode }])[0] : null,
    );

    useEffect(() => {
        // Lookup of practices is executed against the ODS API
        // The reverse lookup when editing patients needs to ensure the PracticeSelect has the currently set value available, otherwise the field is blanl
        if (practice && editing) {
            setSelectedPractice(formatPractices([{ name: practice, odsCode: odsCode }])[0]);
        }
    }, [practice, editing, odsCode]);

    useEffect(() => {
        if (!loadingRoutes && dataRoutes) {
            const routes = dataRoutes.queryJobsByJobTypeIndex.items;
            setRoutes(routes);
            setIsRouteFetched(true);
            if (routes.length > 0) {
                const assginedHcpId = routes[0].hcpId || routes[0].nominatedHcps[0];
                setIsAssignedToRoute(true);
                setMarkedCompletedHcp(hcpUsers.find((user: IHcp) => user.userId === assginedHcpId));
            } else {
                setIsAssignedToRoute(false);
            }
        }
    }, [dataRoutes, hcpUsers, loadingRoutes]);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        if (itineraryId) {
            if (!isRouteFetched) {
                fetchRoutes();
            }
        } else {
            setIsAssignedToRoute(false);
        }
    }, [fetchRoutes, isRouteFetched, itineraryId]);

    const onClose = () => {
        const { closeModal, previousLocation, scrollTop } = location.state || {};
        if (closeModal && previousLocation) {
            return history.push(previousLocation, { scrollTop });
        }
        history.push('/vaccinations/patients');
    };

    const dateOfDose1Changed = (values: FormikValues) =>
        values.dateOfDose1 && dateOfDose1 !== moment.utc(values.dateOfDose1, dateFormat).format();
    const dateOfDose2Changed = (values: FormikValues) =>
        values.dateOfDose2 && dateOfDose2 !== moment.utc(values.dateOfDose2, dateFormat).format();
    const dateOfPreviousDoseChanged = (values: FormikValues) =>
        values.dateOfPreviousDose &&
        dateOfPreviousDose !== moment.utc(values.dateOfPreviousDose, dateFormat).format();
    const dateOfBirthChanged = (values: FormikValues) =>
        values.dateOfBirth && dateOfBirth !== moment.utc(values.dateOfBirth, dateFormat).format();
    const nhsNumberChanged = (values: FormikValues) =>
        values.nhsNumber && nhsNumber !== values.nhsNumber;
    const doseNumberChanged = (values: FormikValues) =>
        values.doseNumber && String(doseNumber) !== values.doseNumber;
    const madeCurrentDateTimeChanged = (values: FormikValues) =>
        madeCurrentDateTime !== values.madeCurrentDateTime;
    const arrivedDateTimeChanged = (values: FormikValues) =>
        arrivedDateTime !== values.arrivedDateTime;
    const finishedDateTimeChanged = (values: FormikValues) =>
        finishedDateTime !== values.finishedDateTime;

    async function* save(values: FormikValues, setSubmitting: (submitting: boolean) => void) {
        if (
            dateOfDose1Changed(values) ||
            dateOfDose2Changed(values) ||
            dateOfBirthChanged(values) ||
            nhsNumberChanged(values) ||
            doseNumberChanged(values) ||
            madeCurrentDateTimeChanged(values) ||
            arrivedDateTimeChanged(values) ||
            finishedDateTimeChanged(values) ||
            dateOfPreviousDoseChanged(values)
        ) {
            setShowEditDateOfPreviousDosePrompt(true);
            yield;
        }

        const updatedDetails = getAPIReadyPatients({ ...values });

        // TODO: not sure what was happening here, looks like selectedPractice could sometimes be an object but the useState typeing says string | null
        // super hack fix for now.
        const selectedPracticeString = (selectedPractice as any)?.value;
        const { name, odsCode } = splitPracticeDetails(selectedPracticeString || '');

        try {
            await updateJob({
                variables: {
                    input: {
                        ...patient,
                        ...updatedDetails,
                        practice: name,
                        odsCode: odsCode,
                        lastUpdatedBy: username,
                        version: patient.version + 1,
                        referralDateTime: undefined,
                        buddyId: undefined,
                        itineraryId: undefined,
                        __typename: undefined,
                    },
                },
            });
            setEditing(false);
        } catch (err) {
            console.error(err);
        }

        setSubmitting(false);
        setShowEditDateOfPreviousDosePrompt(false);
    }

    const onSave = (values: FormikValues, { setSubmitting }: any) => {
        const saveHandler = save(omit(values, Vaccination.IMMUTABLE_PROPERTIES), setSubmitting);
        setSaveHandler(saveHandler);
        saveHandler.next();
    };

    const onWithdraw = async () => {
        if (withdrawingReason === 'Other reason' && !withdrawingNotes) {
            setWithdrawingErrors(['Please add a reason for withdrawing the patient']);
            return;
        }
        setShowWithdrawPrompt(false);
        try {
            await updateJob({
                variables: {
                    input: {
                        id,
                        lastUpdatedBy: username,
                        withdrawnReason: withdrawingReason,
                        withdrawnNotes: withdrawingNotes,
                        jobStatus: JobStatus.WITHDRAWN,
                        version: patient.version + 1,
                    },
                },
            });
        } catch (err) {
            console.error(err);
        }
    };

    const handleWithdrawingNoteInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setWithdrawingNotes(e.target.value);
        if (withdrawingErrors.length) {
            setWithdrawingErrors([]);
        }
    };

    const onUnassign = async () => {
        setShowUnassignPrompt(false);
        try {
            await updateJob({
                variables: {
                    input: {
                        id,
                        lastUpdatedBy: username,
                        jobStatus: isAssignedToRoute ? JobStatus.AVAILABLE : JobStatus.PENDING,
                        hcpId: undefined,
                        hcpName: isAssignedToRoute ? undefined : null,
                        hcpSub: isAssignedToRoute ? undefined : null,
                        arrivedDateTime: null,
                        madeCurrentDateTime: null,
                        finishedDateTime: null,
                        vaccineExpiryDate: null,
                        vaccineBatchNumber: null,
                        itineraryId: undefined,
                        version: patient.version + 1,
                        vaccineSiteOfAdministration: null,
                        markedCompletedByControllerNotes: null,
                    },
                },
            });
        } catch (err) {
            console.error(err);
        }
    };

    const onComplete = async () => {
        if (!(await verifyMarkedAsCompletedData())) {
            return;
        }
        setShowCompletePrompt(false);
        try {
            const finishedDateTime = moment.utc(markedCompletedFinishedDateTime).format();
            const madeCurrentDateTime = moment.utc(markedCurrentDateTime).format();
            const arrivedDateTime = moment.utc(markedArrivedDateTime).format();
            const vaccineExpiryDate = moment.utc(vaccineExpiryDateTime).format();
            await updateJob({
                variables: {
                    input: {
                        id,
                        lastUpdatedBy: username,
                        jobStatus: JobStatus.COMPLETED,
                        hcpId: markedCompletedHcp?.userId,
                        hcpName: markedCompletedHcp?.userName,
                        markedCompletedByController: true,
                        markedCompletedByControllerNotes: markedCompletedNotes,
                        version: patient.version + 1,
                        arrivedDateTime,
                        madeCurrentDateTime,
                        finishedDateTime,
                        vaccineExpiryDate,
                        vaccineBatchNumber: vaccineBatchNumberGiven,
                        vaccineSiteOfAdministration: siteOfAdministration,
                    },
                },
            });
        } catch (err) {
            console.error(err);
        }
    };

    const onResubmit = async () => {
        try {
            await updateJob({
                variables: {
                    input: {
                        id,
                        lastUpdatedBy: username,
                        jobStatus: JobStatus.PENDING,
                        version: patient.version + 1,
                    },
                },
            });
        } catch (err) {
            console.error(err);
        }
    };

    const onRevertingAbort = async () => {
        try {
            await updateJob({
                variables: {
                    input: {
                        id,
                        lastUpdatedBy: username,
                        jobStatus: JobStatus.AVAILABLE,
                        hcpAbortedReason: null,
                        hcpAbortedNotes: null,
                        hcpAbortedDateTime: null,
                        madeCurrentDateTime: null,
                        arrivedDateTime: null,
                        version: patient.version + 1,
                    },
                },
            });
        } catch (err) {
            console.error(err);
        }
    };

    const title = (
        <>
            <span className="modal__heading-text">{`${firstName} ${lastName}`}</span>
            <StatusTag jobStatus={jobStatus} large isPatient />
        </>
    );

    if (loadingJob) {
        return (
            <Modal title="Loading patient details..." shadow onClose={onClose}>
                <div className="vaccinations__loading">
                    <Loader fullscreen={false} />
                </div>
            </Modal>
        );
    }

    const verifyMarkedAsCompletedData = async () => {
        const errors: Record<string, string[]> = {};

        try {
            await markAsCompletedValidationSchema().validate(
                {
                    markedCompletedFinishedDateTime,
                    markedCurrentDateTime,
                    markedArrivedDateTime,
                    vaccineExpiryDateTime,
                    vaccineBatchNumberGiven,
                    siteOfAdministration,
                    markedCompletedNotes,
                    assignedHcp: markedCompletedHcp ? markedCompletedHcp.userId : '',
                },
                { abortEarly: false },
            );
        } catch (err: any) {
            if (err && !err?.errors) {
                return false;
            }
            for (const error of err?.errors || []) {
                const [key, ...rest] = error.split(' ');
                if (errors[key]) {
                    errors[key].push(rest.join(' '));
                } else {
                    errors[key] = [rest.join(' ')];
                }
            }
            setMarkedCompletedErrors(errors);
            return false;
        }
        return true;
    };

    const verifyMarkAsCompleteField = (field: string, value: string | Date | null) => {
        const errors: Record<string, string[]> = {};
        if (!value) {
            errors[field] = ['This field is required'];
        }
        setMarkedCompletedErrors(errors);
    };

    const mainHandlerForDateInput = (
        dateString: string | null,
        markAsCompleteField: string,
        setter: (date: Date | null) => void,
    ) => {
        const date = fixBluePrintJsDateChangeValue({ dateString });
        setter(date);
        verifyMarkAsCompleteField(markAsCompleteField, date);
    };
    const handleChangeVaccineExpiryDate = (dateString: string | null) => {
        mainHandlerForDateInput(dateString, 'vaccineExpiryDate', setVaccineExpiryDateTime);
    };
    const handleChangeMadeCurrentDateTime = (dateString: string | null) => {
        mainHandlerForDateInput(dateString, 'madeCurrentDateTime', setMarkedCurrentDateTime);
    };
    const handleChangeArrivedDateTime = (dateString: string | null) => {
        mainHandlerForDateInput(dateString, 'arrivedDateTime', setMarkedArrivedDateTime);
    };

    const handleChangeFinishedDateTime = (dateString: string | null) => {
        mainHandlerForDateInput(dateString, 'finishedDateTime', setMarkedCompletedFinishedDateTime);
    };

    const getRouteOptions = () => {
        let routeStartTime, visitStartTime;
        if (routes.length > 0) {
            if (routes[0].itinerary && routes[0].itinerary.route) {
                routeStartTime = routes[0].itinerary.route.startTime;
            }
            if (routes[0].itinerary && routes[0].itinerary.instructions?.length > 0) {
                const currentVisit = routes[0].itinerary.instructions.find(
                    (instruction: Record<string, any>) =>
                        instruction.instructionType === 'VisitLocation'
                            ? instruction.itineraryItem.name === id
                            : false,
                );
                visitStartTime = currentVisit?.startTime;
            }
        }
        return {
            routeStartTime,
            visitStartTime,
        };
    };

    const getArrivedDateTime = (visitStartTime: string) => {
        if (arrivedDateTime) {
            return new Date(arrivedDateTime);
        }
        return visitStartTime ? new Date(visitStartTime) : new Date();
    };

    const getRouteStartTime = (routeStartTime: string) => {
        if (madeCurrentDateTime) {
            return new Date(madeCurrentDateTime);
        }
        return routeStartTime ? new Date(routeStartTime) : new Date();
    };

    const onShowCompletePrompt = () => {
        const { routeStartTime, visitStartTime } = getRouteOptions();
        const autoPopulatedArrivedDateTime = getArrivedDateTime(visitStartTime);
        const autoPopulatedMadeCurrentDateTime = getRouteStartTime(routeStartTime);
        const autoPopulatedMarkedCompletedDateTime = moment(autoPopulatedArrivedDateTime)
            .add(vaccinationDuration[vaccinationCategory] || 20, 'minutes')
            .toDate();
        setMarkedArrivedDateTime(autoPopulatedArrivedDateTime);
        setMarkedCurrentDateTime(autoPopulatedMadeCurrentDateTime);
        setMarkedCompletedFinishedDateTime(autoPopulatedMarkedCompletedDateTime);
        setMarkedCompletedHcp(
            routes.length > 0
                ? hcpUsers.find((user: IHcp) => user.userId === routes[0].nominatedHcps[0])
                : undefined,
        );
        setMarkedCompletedNotes(markedCompletedByControllerNotes);
        setVaccineBatchNumberGiven(vaccineBatchNumber);
        setVaccineExpiryDateTime(vaccineExpiryDate ? new Date(vaccineExpiryDate) : new Date());
        setSiteOfAdministration(vaccineSiteOfAdministration);
        setShowCompletePrompt(true);
    };

    return (
        <Formik
            initialValues={{
                ...Vaccination.INITIAL_VALUES,
                madeCurrentDateTime,
                arrivedDateTime,
                finishedDateTime,
                vaccineExpiryDate,
            }}
            onSubmit={onSave}
            validationSchema={validationSchema({
                includeDateOfBirth: true,
            })}
        >
            {({ values, setFieldValue, isSubmitting, resetForm, setSubmitting, errors }) => (
                <Form className="vaccinations__form">
                    <Modal
                        footer={
                            <PatientDetailsFooter
                                setEditing={setEditing}
                                editing={editing}
                                resetForm={resetForm}
                                patient={patient}
                                jobStatus={jobStatus}
                                setShowWithdrawPrompt={setShowWithdrawPrompt}
                                setShowAbortPrompt={setShowAbortPrompt}
                                setShowUnassignPrompt={setShowUnassignPrompt}
                                onShowCompletePrompt={onShowCompletePrompt}
                                onResubmit={onResubmit}
                                onRevertingAbort={onRevertingAbort}
                                isAssignedToRoute={isAssignedToRoute}
                                route={routes[0]}
                                isSaving={isSubmitting}
                                isSuperuser={isSuperuser}
                            />
                        }
                        title={title}
                        onClose={onClose}
                        shadow
                    >
                        {errorJob && (
                            <Callout className="callout" intent="danger">
                                {errorJob.message}
                            </Callout>
                        )}
                        {updateJobState.error && (
                            <Callout className="callout" intent="danger">
                                {updateJobState.error.message}
                            </Callout>
                        )}
                        {updateJobState.data && (
                            <Callout className="callout" intent="success">
                                Patient successfully updated
                            </Callout>
                        )}
                        {routes.length > 0 &&
                            routes.map(
                                ({
                                    id,
                                    hcpId,
                                    nominatedHcps,
                                    jobStatus,
                                    itinerary: {
                                        route: { startTime },
                                    },
                                }) => {
                                    const assignedHcp = hcpId || nominatedHcps[0];
                                    return (
                                        <Callout
                                            intent={routeStatusTags[jobStatus] as Intent}
                                            key={id}
                                            className="callout"
                                            icon="route"
                                        >
                                            <div className="callout__content">
                                                <span>
                                                    Assigned to route with{' '}
                                                    <Link to={`/users/${assignedHcp}`}>
                                                        {assignedHcp}
                                                    </Link>{' '}
                                                    on{' '}
                                                    <Link to={`/vaccinations/routes/${id}`}>
                                                        {formatDisplayDate(startTime)}
                                                    </Link>
                                                </span>
                                                <StatusTag
                                                    jobStatus={jobStatus}
                                                    className="callout__tag"
                                                />
                                            </div>
                                        </Callout>
                                    );
                                },
                            )}
                        <dl className="info">
                            <dt className="info__title">Vaccine</dt>
                            <dd className="info__definition">{friendlyVaccinationCategory}</dd>
                            {vaccinationCategory === VaccinationCategory.COVID_19 && (
                                <>
                                    <dt className="info__title">Vaccination type</dt>
                                    <dd className="info__definition">
                                        {isSuperuser && editing ? (
                                            <SelectInput name="vaccinationType">
                                                <option value="">Select...</option>
                                                {Object.values(VaccinationType).map((type) => (
                                                    <option value={type} key={type}>
                                                        {capitalizeFirstLetter(type.toLowerCase())}
                                                    </option>
                                                ))}
                                            </SelectInput>
                                        ) : vaccinationType ? (
                                            capitalizeFirstLetter(vaccinationType.toLowerCase())
                                        ) : (
                                            'Not set'
                                        )}
                                    </dd>
                                </>
                            )}
                            <dt className="info__title">Manufacturer</dt>
                            <dd className="info__definition">
                                {editing ? (
                                    <SelectInput name="vaccineManufacturer">
                                        <option value="">Select...</option>
                                        {Object.entries(
                                            Vaccination.manufacturerList(
                                                values.vaccinationCategory as VaccinationCategory,
                                            ) || [],
                                        ).map(([value, name]) => (
                                            <option value={value} key={value}>
                                                {name}
                                            </option>
                                        ))}
                                    </SelectInput>
                                ) : vaccineManufacturer ? (
                                    friendlyManufacturer
                                ) : (
                                    'Not set'
                                )}
                            </dd>
                            {isMultiDoseVaccine(patient) && (
                                <>
                                    <dt className="info__title">Dose number</dt>
                                    <dd className="info__definition">
                                        {isSuperuser && editing ? (
                                            <SelectInput name="doseNumber">
                                                <option value="">Not set...</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                            </SelectInput>
                                        ) : (
                                            doseNumber || 'Not set'
                                        )}
                                    </dd>
                                    <div className="accordion-patient-details">
                                        <Accordion>
                                            <AccordionTab
                                                name="Vaccination History"
                                                title={'Vaccination History'}
                                                color={AccordionColors.GREY}
                                                open={isOpen}
                                                onClick={() => handleClick()}
                                                className="v2__accordion-tab--patient-details"
                                            >
                                                <dt className="info__title">Previous dose date</dt>
                                                <dd className="info__definition">
                                                    {editing ? (
                                                        <TextInput
                                                            name="dateOfPreviousDose"
                                                            placeholder="DD/MM/YYYY"
                                                        />
                                                    ) : dateOfPreviousDose ? (
                                                        formatDisplayDate(dateOfPreviousDose)
                                                    ) : (
                                                        'Not set'
                                                    )}
                                                </dd>
                                                <dt className="info__title">
                                                    Previous dose manufacturer
                                                </dt>
                                                <dd className="info__definition">
                                                    {editing ? (
                                                        <SelectInput name="manufacturerOfPreviousDose">
                                                            <option value="">Select...</option>
                                                            {Object.entries(
                                                                friendlyCovid19Manufacturers,
                                                            ).map(([value, name]) => (
                                                                <option value={value} key={value}>
                                                                    {name}
                                                                </option>
                                                            ))}
                                                        </SelectInput>
                                                    ) : manufacturerOfPreviousDose ? (
                                                        friendlyManufacturerOfPreviousDose
                                                    ) : (
                                                        'Not set'
                                                    )}
                                                </dd>

                                                {friendlyVaccinationCategory ===
                                                    VaccinationCategory.COVID_19 && (
                                                    <>
                                                        <dt className="info__title">
                                                            Date of second dose
                                                        </dt>
                                                        <dd className="info__definition">
                                                            {editing ? (
                                                                <TextInput
                                                                    name="dateOfDose2"
                                                                    placeholder="DD/MM/YYYY"
                                                                />
                                                            ) : dateOfDose2 ? (
                                                                formatDisplayDate(dateOfDose2)
                                                            ) : (
                                                                'Not set'
                                                            )}
                                                        </dd>
                                                        <dt className="info__title">
                                                            Manufacturer of second dose
                                                        </dt>
                                                        <dd className="info__definition">
                                                            {editing ? (
                                                                <SelectInput name="manufacturerOfDose2">
                                                                    <option value="">
                                                                        Select...
                                                                    </option>
                                                                    {Object.entries(
                                                                        friendlyCovid19Manufacturers,
                                                                    ).map(([value, name]) => (
                                                                        <option
                                                                            value={value}
                                                                            key={value}
                                                                        >
                                                                            {name}
                                                                        </option>
                                                                    ))}
                                                                </SelectInput>
                                                            ) : manufacturerOfDose2 ? (
                                                                friendlyManufacturerOfDose2
                                                            ) : (
                                                                'Not set'
                                                            )}
                                                        </dd>

                                                        <dt className="info__title">
                                                            Date of first dose
                                                        </dt>
                                                        <dd className="info__definition">
                                                            {editing ? (
                                                                <TextInput
                                                                    name="dateOfDose1"
                                                                    placeholder="DD/MM/YYYY"
                                                                />
                                                            ) : dateOfDose1 ? (
                                                                formatDisplayDate(dateOfDose1)
                                                            ) : (
                                                                'Not set'
                                                            )}
                                                        </dd>
                                                        <dt className="info__title">
                                                            Manufacturer of first dose
                                                        </dt>
                                                        <dd className="info__definition">
                                                            {editing ? (
                                                                <SelectInput name="manufacturerOfDose1">
                                                                    <option value="">
                                                                        Select...
                                                                    </option>
                                                                    {Object.entries(
                                                                        friendlyCovid19Manufacturers,
                                                                    ).map(([value, name]) => (
                                                                        <option
                                                                            value={value}
                                                                            key={value}
                                                                        >
                                                                            {name}
                                                                        </option>
                                                                    ))}
                                                                </SelectInput>
                                                            ) : manufacturerOfDose1 ? (
                                                                friendlyManufacturerOfDose1
                                                            ) : (
                                                                'Not set'
                                                            )}
                                                        </dd>
                                                    </>
                                                )}
                                            </AccordionTab>
                                        </Accordion>
                                    </div>
                                </>
                            )}
                            <dt className="info__title">Can be co-administered with</dt>
                            <dd className="info__definition">
                                {editing
                                    ? Object.values(VaccinationCategory)
                                          .filter((category) => category !== vaccinationCategory)
                                          .map((category) => (
                                              <CheckboxInput
                                                  name="coAdministeredWith"
                                                  label={Vaccination.getFriendlyVaccinationCategory(
                                                      category,
                                                  )}
                                                  value={category}
                                                  disabled={loadingJob}
                                                  key={category}
                                              />
                                          ))
                                    : friendlyCoAdministeredWith.map((category) => (
                                          <Tag className="info__tag" key={category} minimal>
                                              {category}
                                          </Tag>
                                      ))}
                            </dd>
                        </dl>
                        <dl className="info">
                            <dt className="info__title">First name</dt>
                            <dd className="info__definition">
                                {editing ? <TextInput name="firstName" /> : firstName}
                            </dd>
                            <dt className="info__title">Last name</dt>
                            <dd className="info__definition">
                                {editing ? <TextInput name="lastName" /> : lastName}
                            </dd>
                            <dt className="info__title">NHS number</dt>
                            <dd className="info__definition">
                                {isSuperuser && editing ? (
                                    <TextInput name="nhsNumber" />
                                ) : (
                                    nhsNumber
                                )}
                            </dd>
                            <dt className="info__title">Date of birth</dt>
                            <dd className="info__definition">
                                {isSuperuser && editing ? (
                                    <TextInput name="dateOfBirth" placeholder="DD/MM/YYYY" />
                                ) : (
                                    formatDisplayDate(dateOfBirth)
                                )}
                            </dd>
                            <dt className="info__title">Age</dt>
                            <dd className="info__definition">{getAgeFromDate(dateOfBirth)}</dd>
                            <dt className="info__title">Sex</dt>
                            <dd className="info__definition">
                                {editing ? (
                                    <SelectInput name="gender">
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </SelectInput>
                                ) : gender ? (
                                    capitalizeFirstLetter(gender.toLowerCase())
                                ) : (
                                    ''
                                )}
                            </dd>
                            <dt className="info__title">Address</dt>
                            <dd className="info__definition">
                                {editing ? (
                                    <>
                                        <TextInput name="addressLine1" label="Address line 1" />
                                        <TextInput name="addressLine2" label="Address line 2" />
                                        <TextInput name="town" label="Town" />
                                        <TextInput name="postCode" label="Postcode" />
                                    </>
                                ) : (
                                    [addressLine1, addressLine2, town, postCode]
                                        .filter((_) => _)
                                        .join(', ')
                                )}
                            </dd>
                            <dt className="info__title">Primary contact number</dt>
                            <dd className="info__definition">
                                {editing ? <TextInput name="contactNumber" /> : contactNumber}
                            </dd>
                            <dt className="info__title">Additional contact numbers</dt>
                            <dd className="info__definition">
                                {editing ? (
                                    <TextInput
                                        name="additionalContactNumbers"
                                        helperText="You can enter multiple numbers separated by a comma."
                                    />
                                ) : Array.isArray(additionalContactNumbers) ? (
                                    additionalContactNumbers.join(', ')
                                ) : (
                                    additionalContactNumbers
                                )}
                            </dd>
                            <dt className="info__title">Registered GP practice</dt>
                            <dd className="info__definition">
                                {editing ? (
                                    <PracticeSelect
                                        name="practice"
                                        value={selectedPractice}
                                        isClearable={true}
                                        onChange={(value: any) => {
                                            setSelectedPractice(value);
                                        }}
                                    />
                                ) : (
                                    formatPractice(practice, odsCode)
                                )}
                            </dd>
                            <dt className="info__title">Hub</dt>
                            <dd className="info__definition">
                                {editing ? (
                                    <SelectInput name="hubId">
                                        {hubs.map((hub: { id: string; name: string }) => (
                                            <option value={hub.id} key={hub.id}>
                                                {hub.name}
                                            </option>
                                        ))}
                                    </SelectInput>
                                ) : (
                                    getHubName(hubId)
                                )}
                            </dd>
                            <dt className="info__title">Consented to vaccination</dt>
                            <dd className="info__definition">
                                {editing ? (
                                    <SelectInput name="consented">
                                        <option value="">Not set</option>
                                        {Object.entries(friendlyConsentStatuses).map(
                                            ([value, name]) => (
                                                <option value={value} key={value}>
                                                    {name}
                                                </option>
                                            ),
                                        )}
                                    </SelectInput>
                                ) : consented ? (
                                    friendlyConsentStatuses[consented]
                                ) : null}
                            </dd>
                            <dt className="info__title">Notes</dt>
                            <dd className="info__definition">
                                {editing ? <TextAreaInput name="notes" /> : notes}
                            </dd>
                            <dt className="info__title">Available from (HH:MM)</dt>
                            <dd className="info__definition">
                                {editing ? <TextInput name="availableFrom" /> : availableFrom}
                            </dd>
                            <dt className="info__title">Available to (HH:MM)</dt>
                            <dd className="info__definition">
                                {editing ? <TextInput name="availableTo" /> : availableTo}
                            </dd>
                            {(flags.length > 0 || editing) && (
                                <>
                                    <dt className="info__title">Flags</dt>
                                    <dd className="info__definition">
                                        {editing
                                            ? Object.values(Flags).map((flag) => (
                                                  <CheckboxInput
                                                      name="flags"
                                                      label={flag}
                                                      value={flag}
                                                      key={flag}
                                                  />
                                              ))
                                            : flags.map((flag) => (
                                                  <Tag
                                                      className="info__tag"
                                                      key={flag}
                                                      minimal
                                                      large
                                                  >
                                                      <Icon
                                                          className="info__icon"
                                                          icon={flagsIcons[flag] as IconName}
                                                      />
                                                      {flag}
                                                  </Tag>
                                              ))}
                                    </dd>
                                </>
                            )}
                        </dl>
                        <dl className="info">
                            <dt className="info__title">Patient added to Doc Abode</dt>
                            <dd className="info__definition">
                                {formatDisplayDateTime(createDateTime)}
                            </dd>
                            {acceptedDateTime && (
                                <>
                                    <dt className="info__title">HCP accepted vaccination</dt>
                                    <dd className="info__definition">
                                        {formatDisplayDateTime(acceptedDateTime)}
                                    </dd>
                                </>
                            )}
                            {madeCurrentDateTime && (
                                <>
                                    <dt className="info__title">Vaccination marked as current</dt>
                                    <dd className="info__definition">
                                        {isSuperuser && editing ? (
                                            <DateInput
                                                name="madeCurrentDateTime"
                                                {...getMomentDateFormatter(dateTimeFormat)}
                                                value={moment(
                                                    values.madeCurrentDateTime,
                                                ).toISOString()}
                                                timePrecision="minute"
                                                inputClassName=""
                                            />
                                        ) : (
                                            formatDisplayDateTime(madeCurrentDateTime)
                                        )}
                                    </dd>
                                </>
                            )}
                            {arrivedDateTime && (
                                <>
                                    <dt className="info__title">HCP arrived at patient</dt>
                                    <dd className="info__definition">
                                        {isSuperuser && editing ? (
                                            <DateInput
                                                name="arrivedDateTime"
                                                {...getMomentDateFormatter(dateTimeFormat)}
                                                value={values.arrivedDateTime}
                                                timePrecision="minute"
                                                inputClassName=""
                                            />
                                        ) : (
                                            formatDisplayDateTime(arrivedDateTime)
                                        )}
                                    </dd>
                                </>
                            )}
                            {finishedDateTime && (
                                <>
                                    <dt className="info__title">
                                        {jobStatus === 'COMPLETED'
                                            ? 'Vaccination completed'
                                            : jobStatus === 'CONTROLLER_ABORTED'
                                              ? 'Patient archived'
                                              : 'Vaccination aborted'}
                                    </dt>
                                    <dd className="info__definition">
                                        {isSuperuser && editing && jobStatus === 'COMPLETED' ? (
                                            <DateInput
                                                name="finishedDateTime"
                                                {...getMomentDateFormatter(dateTimeFormat)}
                                                value={values.finishedDateTime}
                                                timePrecision="minute"
                                                placeholder="DD/MM/YYYY HH:MM"
                                                inputClassName=""
                                            />
                                        ) : (
                                            formatDisplayDateTime(finishedDateTime)
                                        )}
                                    </dd>
                                </>
                            )}
                            {jobStatus === JobStatus.COMPLETED && (
                                <>
                                    <dt className="info__title">Batch number of vaccine given</dt>
                                    <dd className="info__definition">
                                        {isSuperuser && editing ? (
                                            <TextInput name="vaccineBatchNumber" />
                                        ) : (
                                            vaccineBatchNumber || 'Not set'
                                        )}
                                    </dd>
                                </>
                            )}
                            {jobStatus === JobStatus.COMPLETED && (
                                <>
                                    <dt className="info__title">Expiry date of vaccine given</dt>
                                    <dd className="info__definition">
                                        {isSuperuser && editing ? (
                                            <DateInput
                                                name="vaccineExpiryDate"
                                                {...getMomentDateFormatter(dateTimeFormat)}
                                                value={values.vaccineExpiryDate}
                                                timePrecision="minute"
                                                placeholder="DD/MM/YYYY HH:MM"
                                                inputClassName=""
                                                maxDate={moment().add(5, 'year').toDate()}
                                            />
                                        ) : vaccineExpiryDate ? (
                                            formatDisplayDate(vaccineExpiryDate)
                                        ) : (
                                            'Not set'
                                        )}
                                    </dd>
                                </>
                            )}
                            {jobStatus === JobStatus.COMPLETED && (
                                <>
                                    <dt className="info__title">Site of administration</dt>
                                    <dd className="info__definition">
                                        {isSuperuser && editing ? (
                                            <SelectInput name="vaccineSiteOfAdministration">
                                                <option value="">Select...</option>
                                                {[
                                                    'Left deltoid',
                                                    'Right deltoid',
                                                    'Left thigh',
                                                    'Right thigh',
                                                ].map((site) => (
                                                    <option value={site} key={site}>
                                                        {site}
                                                    </option>
                                                ))}
                                            </SelectInput>
                                        ) : (
                                            vaccineSiteOfAdministration || 'Not set'
                                        )}
                                    </dd>
                                </>
                            )}
                            {hcpId && hcpName && (
                                <>
                                    <dt className="info__title">Assigned HCP</dt>
                                    <dd className="info__definition">
                                        <Link to={`/users/${hcpId}`}>{hcpName}</Link>
                                    </dd>
                                </>
                            )}
                            {jobStatus === JobStatus.WITHDRAWN && (
                                <>
                                    <dt className="info__title info__title--warning">
                                        Reason for withdrawing
                                    </dt>
                                    <dd className="info__definition info__definition--warning">
                                        {withdrawnReason || 'No reason provided'}
                                    </dd>
                                    {withdrawnReason === 'Other reason' && (
                                        <>
                                            <dt className="info__title info__title--warning">
                                                Note for withdrawing
                                            </dt>
                                            <dd className="info__definition info__definition--warning">
                                                {withdrawnNotes || 'No other reason provided'}
                                            </dd>
                                        </>
                                    )}
                                </>
                            )}
                            {!!abortedData && (
                                <>
                                    <dt className="info__title info__title--danger">
                                        {abortLabel}
                                    </dt>
                                    <dd className="info__definition info__definition--danger">
                                        {abortedData.abortedReason}
                                        {abortedData.abortedFollowUpAction && (
                                            <>
                                                <br />
                                                {abortedData.abortedFollowUpAction}
                                            </>
                                        )}
                                        {abortedData.abortedNotes && (
                                            <>
                                                <br />
                                                {abortedData.abortedNotes}
                                            </>
                                        )}
                                    </dd>
                                </>
                            )}
                            {jobStatus === JobStatus.COMPLETED && markedCompletedByController && (
                                <>
                                    <dt className="info__title info__title--warning">
                                        Marked completed by controller
                                    </dt>
                                    <dd className="info__definition info__definition--warning">
                                        Yes
                                        {markedCompletedByControllerNotes && (
                                            <>
                                                <br />
                                                {markedCompletedByControllerNotes}
                                            </>
                                        )}
                                    </dd>
                                </>
                            )}
                        </dl>
                        <Alert
                            isOpen={showWithdrawPrompt}
                            onConfirm={onWithdraw}
                            onCancel={() => setShowWithdrawPrompt(false)}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="warning"
                        >
                            <p>
                                <strong>Are you sure you want to withdraw this patient?</strong>
                            </p>
                            <p>
                                Note: the patient will need to be resubmitted before they can be
                                assigned to a new route.
                            </p>
                            <FormGroup label="Reason for withdrawing" labelFor="withdrawingReason">
                                <HTMLSelect
                                    options={withdrawnOptions}
                                    id="withdrawingReason"
                                    onChange={(event) =>
                                        setWithdrawingReason(event.currentTarget.value)
                                    }
                                    value={withdrawingReason}
                                    minimal
                                    fill
                                />
                            </FormGroup>
                            {withdrawingReason === 'Other reason' && (
                                <FormGroup labelFor="withdrawingNotes">
                                    <TextArea
                                        id="withdrawingNotes"
                                        onChange={handleWithdrawingNoteInput}
                                        fill
                                        value={withdrawingNotes}
                                    />
                                    <Error errors={withdrawingErrors} />
                                </FormGroup>
                            )}
                        </Alert>
                        <Alert
                            isOpen={showUnassignPrompt}
                            onConfirm={onUnassign}
                            onCancel={() => setShowUnassignPrompt(false)}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="warning"
                        >
                            <p>
                                <strong>
                                    Are you sure you want to mark the vaccination as incomplete?
                                </strong>
                            </p>
                        </Alert>
                        <Alert
                            isOpen={showCompletePrompt}
                            onConfirm={onComplete}
                            onCancel={() => setShowCompletePrompt(false)}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            className="v2__alert-dialog"
                            intent="success"
                        >
                            <dl className="alert__title">
                                <span className="alert__heading-text">Mark as completed</span>
                            </dl>
                            <dl className="info">
                                <dt className="info__title">Assigned HCP</dt>
                                <dd className="info__definition">
                                    <HTMLSelect
                                        options={[
                                            {
                                                label: 'Select HCP',
                                                value: '',
                                            },
                                            ...hcpUsers
                                                .filter((user: IHcp) => user.enabled)
                                                .sort((a: IHcp, b: IHcp) =>
                                                    a.userName
                                                        .toLowerCase()
                                                        .localeCompare(b.userName.toLowerCase()),
                                                )
                                                .map((user: IHcp) => ({
                                                    label: ellipsis(
                                                        `${user.userName} (${user.userId})`,
                                                        30,
                                                        '...',
                                                    ),
                                                    value: user.userId,
                                                    title: `${user.userName} (${user.userId})`,
                                                })),
                                        ]}
                                        id="assignedHcp"
                                        onChange={(event) => {
                                            setMarkedCompletedHcp(
                                                hcpUsers.find(
                                                    (user: IHcp) =>
                                                        user.userId === event.currentTarget.value,
                                                ),
                                            );
                                            verifyMarkAsCompleteField(
                                                'assignedHcp',
                                                event.currentTarget.value,
                                            );
                                        }}
                                        value={markedCompletedHcp?.userId}
                                        minimal
                                        fill
                                        disabled={jobStatus !== JobStatus.PENDING}
                                        large={true}
                                    />
                                    <Error errors={markedCompletedErrors.assignedHcp || []} />
                                </dd>
                            </dl>
                            <dl className="info">
                                <dt className="info__title">Vaccination marked as current</dt>
                                <dd className="info__definition">
                                    <DateInput3
                                        showTimezoneSelect={false}
                                        formatDate={(date: Date) =>
                                            moment(date).format('DD/MM/YYYY HH:mm')
                                        }
                                        onChange={handleChangeMadeCurrentDateTime}
                                        parseDate={(str: string) =>
                                            moment(str, 'DD/MM/YYYY HH:mm').toDate()
                                        }
                                        placeholder="DD/MM/YYYY HH:MM"
                                        value={markedCurrentDateTime?.toISOString()}
                                        timePrecision="minute"
                                        className="v2__date-input "
                                        popoverProps={{ usePortal: false }}
                                    />
                                    <Error
                                        errors={markedCompletedErrors.madeCurrentDateTime || []}
                                    />
                                </dd>
                            </dl>
                            <dl className="info">
                                <dt className="info__title">HCP arrived at patient</dt>
                                <dd className="info__definition">
                                    <DateInput3
                                        showTimezoneSelect={false}
                                        formatDate={(date: Date) =>
                                            moment(date).format('DD/MM/YYYY HH:mm')
                                        }
                                        onChange={handleChangeArrivedDateTime}
                                        parseDate={(str: string) =>
                                            moment(str, 'DD/MM/YYYY HH:mm').toDate()
                                        }
                                        placeholder="DD/MM/YYYY HH:MM"
                                        value={markedArrivedDateTime?.toISOString()}
                                        timePrecision="minute"
                                        popoverProps={{ usePortal: false }}
                                    />
                                    <Error errors={markedCompletedErrors.arrivedDateTime || []} />
                                </dd>
                            </dl>
                            <dl className="info">
                                <dt className="info__title">Vaccination completed</dt>
                                <dd className="info__definition">
                                    <DateInput3
                                        showTimezoneSelect={false}
                                        formatDate={(date: Date) =>
                                            moment(date).format('DD/MM/YYYY HH:mm')
                                        }
                                        onChange={handleChangeFinishedDateTime}
                                        parseDate={(str: string) =>
                                            moment(str, 'DD/MM/YYYY HH:mm').toDate()
                                        }
                                        placeholder="DD/MM/YYYY HH:MM"
                                        value={markedCompletedFinishedDateTime?.toISOString()}
                                        timePrecision="minute"
                                        popoverProps={{ usePortal: false }}
                                    />
                                    <Error errors={markedCompletedErrors.finishedDateTime || []} />
                                </dd>
                            </dl>
                            <FormGroup
                                label="Reason for marking as completed"
                                labelInfo=""
                                labelFor="markedCompletedNotes"
                            >
                                <TextArea
                                    id="markedCompletedNotes"
                                    onChange={(event) => {
                                        setMarkedCompletedNotes(event.target.value);
                                    }}
                                    fill
                                    value={markedCompletedNotes}
                                    onBlur={(event) =>
                                        verifyMarkAsCompleteField(
                                            'markedCompletedNotes',
                                            event.target.value,
                                        )
                                    }
                                />
                                <Error errors={markedCompletedErrors.markedCompletedNotes || []} />
                            </FormGroup>
                            <dl className="info">
                                <dt className="info__title">Batch number of vaccine given</dt>
                                <dd className="info__definition">
                                    <TextArea
                                        id="markedCompletedNotes"
                                        onChange={(event) => {
                                            setVaccineBatchNumberGiven(event.target.value);
                                        }}
                                        fill
                                        value={vaccineBatchNumberGiven}
                                        onBlur={(event) =>
                                            verifyMarkAsCompleteField(
                                                'vaccineBatchNumber',
                                                event.target.value,
                                            )
                                        }
                                    />
                                    <Error
                                        errors={markedCompletedErrors.vaccineBatchNumber || []}
                                    />
                                </dd>
                            </dl>
                            <dl className="info">
                                <dt className="info__title">Expiry date of vaccine given</dt>
                                <dd className="info__definition">
                                    <DateInput3
                                        showTimezoneSelect={false}
                                        formatDate={(date: Date) =>
                                            moment(date).format('DD/MM/YYYY')
                                        }
                                        onChange={handleChangeVaccineExpiryDate}
                                        parseDate={(str: string) =>
                                            moment(str, 'DD/MM/YYYY').toDate()
                                        }
                                        placeholder="DD/MM/YYYY"
                                        /* TODO local date? */
                                        value={vaccineExpiryDateTime?.toISOString()}
                                        maxDate={moment().endOf('year').add(5, 'years').toDate()}
                                        popoverProps={{ usePortal: false }}
                                    />
                                    <Error errors={markedCompletedErrors.vaccineExpiryDate || []} />
                                </dd>
                            </dl>
                            <dl className="info">
                                <dt className="info__title">Site of administration</dt>
                                <dd className="info__definition">
                                    <HTMLSelect
                                        options={[
                                            '',
                                            'Left deltoid',
                                            'Right deltoid',
                                            'Left thigh',
                                            'Right thigh',
                                        ].map((site) => ({
                                            label: site || 'Select...',
                                            value: site,
                                        }))}
                                        id="assignedHcp"
                                        onChange={(event) => {
                                            setSiteOfAdministration(event.currentTarget.value);
                                            verifyMarkAsCompleteField(
                                                'vaccineSiteOfAdministration',
                                                event.currentTarget.value,
                                            );
                                        }}
                                        value={siteOfAdministration}
                                        minimal
                                        fill
                                        large={true}
                                    />
                                    <Error
                                        errors={
                                            markedCompletedErrors.vaccineSiteOfAdministration || []
                                        }
                                    />
                                </dd>
                            </dl>
                        </Alert>
                        <AbortJobDialog
                            showAbortPrompt={showAbortPrompt}
                            setShowAbortPrompt={setShowAbortPrompt}
                            job={patient}
                            abortAlertLabels={abortAlertLabels}
                        />
                        <Alert
                            isOpen={showEditDateOfPreviousDosePrompt}
                            onConfirm={() => saveHandler?.next()}
                            onCancel={() => {
                                setShowEditDateOfPreviousDosePrompt(false);
                                setSubmitting(false);
                            }}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="warning"
                        >
                            <>
                                {dateOfDose1Changed(values) && (
                                    <p>
                                        <strong>
                                            {`Are you sure you want to update this patient’s date of dose 1 to ${formatDisplayDate(
                                                moment(values.dateOfDose1, dateFormat),
                                            )}?`}
                                        </strong>
                                    </p>
                                )}
                                {dateOfDose2Changed(values) && (
                                    <p>
                                        <strong>
                                            {`Are you sure you want to update this patient’s date of dose 2 to ${formatDisplayDate(
                                                moment(values.dateOfDose2, dateFormat),
                                            )}?`}
                                        </strong>
                                    </p>
                                )}
                                {dateOfBirthChanged(values) && (
                                    <p>
                                        <strong>
                                            {`Are you sure you want to update this patient’s date of birth to ${formatDisplayDate(
                                                moment(values.dateOfBirth, dateFormat),
                                            )}?`}
                                        </strong>
                                    </p>
                                )}
                                {nhsNumberChanged(values) && (
                                    <p>
                                        <strong>
                                            Are you sure you want to update this patient’s NHS
                                            number to {values.nhsNumber}?
                                        </strong>
                                    </p>
                                )}
                                {doseNumberChanged(values) && (
                                    <p>
                                        <strong>
                                            Are you sure you want to update this patient’s dose
                                            number to {values.doseNumber}?
                                        </strong>
                                    </p>
                                )}
                                {madeCurrentDateTimeChanged(values) && (
                                    <p>
                                        <strong>
                                            {`Are you sure you want to update the time the visit was marked as en-route to ${formatDisplayDateTime(
                                                moment(values.madeCurrentDateTime, dateFormat),
                                            )}?`}
                                        </strong>
                                    </p>
                                )}
                                {arrivedDateTimeChanged(values) && (
                                    <p>
                                        <strong>
                                            {`Are you sure you want to update the time the visit was marked as arrived to ${formatDisplayDateTime(
                                                moment(values.arrivedDateTime, dateFormat),
                                            )}?`}
                                        </strong>
                                    </p>
                                )}
                                {finishedDateTimeChanged(values) && (
                                    <p>
                                        <strong>
                                            {`Are you sure you want to update the time the visit was marked as completed to ${formatDisplayDateTime(
                                                moment(values.finishedDateTime, dateFormat),
                                            )}?`}
                                        </strong>
                                    </p>
                                )}
                                {dateOfPreviousDoseChanged(values) && (
                                    <p>
                                        <strong>
                                            {`Are you sure you want to update this patient’s date of previous dose to ${formatDisplayDateTime(
                                                moment(values.dateOfPreviousDose, dateFormat),
                                            )}?`}
                                        </strong>
                                    </p>
                                )}
                            </>
                        </Alert>
                    </Modal>
                </Form>
            )}
        </Formik>
    );
}
