import { Instruction, Location, Vaccination, VaccinationDetails } from '@doc-abode/data-models';

import { statusTags } from '../../../../../../constants/patientsConst';

interface IGetVaccinationIdToUse {
    instruction: Instruction;
    nextInstruction: Instruction | undefined | null;
}
export function getVaccinationIdToUse({ instruction, nextInstruction }: IGetVaccinationIdToUse) {
    if (
        instruction.instructionType === 'TravelBetweenLocations' &&
        nextInstruction?.itineraryItem?.name &&
        nextInstruction?.instructionType === 'VisitLocation'
    ) {
        return nextInstruction.itineraryItem?.name;
    }

    return instruction.itineraryItem?.name;
}

export interface INextLocationIsSameAsPreviousLocation {
    currentInstruction: Instruction;
    previousInstruction: Instruction | undefined | null;
    nextInstruction: Instruction | undefined | null;
}
/* There may be some duplication of logic between this code and PickupSummary todo look to consolidate */
/**
 * If the next location to travel to is to the same location we don't need to repeat the instruction.
 */
export function nextLocationIsSameAsPreviousLocation({
    currentInstruction,
    previousInstruction,
    nextInstruction,
}: INextLocationIsSameAsPreviousLocation): boolean {
    if (
        !previousInstruction?.itineraryItem?.location ||
        !nextInstruction?.itineraryItem?.location
    ) {
        // we have no location information
        return false;
    }
    // cast to location as the original code assumed that it was a location, typing shows as Location | string
    // not sure which is correct.
    const prevLocation = previousInstruction.itineraryItem.location as unknown as Location;
    const nextLocation = nextInstruction.itineraryItem.location as unknown as Location;

    if (
        currentInstruction.instructionType === 'TravelBetweenLocations' &&
        prevLocation.latitude === nextLocation?.latitude &&
        prevLocation?.longitude === nextLocation?.longitude
    ) {
        return true;
    }
    return false;
}

export function getNameOfPlaceWeTravelToNextIfItsAPickup(instruction: Instruction | undefined) {
    if (instruction && instruction?.instructionType === 'Pickup') {
        return instruction.itineraryItem?.name;
    }
}

export interface IVaccinationIsRemovedFromRoute {
    routeItineraryId: string | undefined | null;
    vaccination: Vaccination | undefined | null;
}
export function vaccinationIsNotPartOfRoute({
    routeItineraryId,
    vaccination,
}: IVaccinationIsRemovedFromRoute) {
    if (routeItineraryId && vaccination?.itineraryId !== routeItineraryId) {
        return true;
    }

    return false;
}

interface IInstructionJobStatusCountsAsRemoved {
    routeItineraryId: string | undefined | null;
    routeStatus: string | undefined | null; // if Itinerary.routeStatus?: string should be JobStatus then this should be as well
    vaccination: Vaccination | undefined | null;
}

/**
 * REMOVED is a sort of "phantom" Vaccination.obStatus.
 * It's not the real status.
 * Its when the Vaccination has been removed from a route,
 * but the route itinerary.instructions still has the Vaccination listed as part of the route
 * Generally this is when the Vaccination has been removed from the route
 * and there is no recalculation of the route, or the route has been change by someone else
 * and all the data has not caught up (this scenario is transitory)
 */
export function vaccinationJobStatusCountsAsRemoved({
    routeStatus,
    routeItineraryId,
    vaccination,
}: IInstructionJobStatusCountsAsRemoved): boolean {
    const removed = vaccinationIsNotPartOfRoute({ routeItineraryId, vaccination });

    if (removed && routeStatus !== 'CONTROLLER_ABORTED') {
        return true;
    }

    return false;
}

export interface IVaccinationGetOdsCodesNeedingPickup {
    routeStatus: string;
    routeItineraryId: string;
    vaccinations: Vaccination[];
    vaccinationDetailsForOrganisation: VaccinationDetails;
}
export function getVaccinationOdsCodesNeedingPickup({
    routeStatus,
    routeItineraryId,
    vaccinations,
    vaccinationDetailsForOrganisation,
}: IVaccinationGetOdsCodesNeedingPickup) {
    const vaccinationsRequiringPickup = vaccinations
        .filter(
            (vaccination: Vaccination) =>
                vaccinationDetailsForOrganisation[vaccination.vaccinationCategory],
        )
        .filter((vaccination) => {
            return (
                !vaccinationJobStatusCountsAsRemoved({
                    routeStatus,
                    routeItineraryId,
                    vaccination,
                }) && vaccination.odsCode
            );
        });
    const odsCodeNeedingPickup: string[] = vaccinationsRequiringPickup.map(
        (vaccination) => vaccination.odsCode as string,
    );

    return Array.from(new Set(odsCodeNeedingPickup));
}

/**
 * Pickup is required if the pickup location odsCode is one of the odsCodes needing a pickup
 */
export interface IPickupIsStillRequired {
    odsCodeOfPickupInstruction: string | undefined;
    odsCodesNeedingPickup: string[];
}
export function pickupIsStillRequired({
    odsCodeOfPickupInstruction,
    odsCodesNeedingPickup,
}: IPickupIsStillRequired): boolean {
    // no odsCode we cant pick anything up we have no idea where it is.
    if (!odsCodeOfPickupInstruction) {
        return false;
    }

    return odsCodesNeedingPickup.includes(odsCodeOfPickupInstruction);
}

/**
 * We need to show that Vaccination.jobStatus as
 * "REMOVED"  for routes it used to belong to.
 * IF the vaccination is still part of the route we use its current jobStatus value.
 *
 * When a Vaccination has been Removed from the route, the Vaccination.jobStatus is
 * the Vaccination.jobStatus as it is "now" and so could be miss-leading if we used that jobStatus.
 */
export function getVaccinationJobStatusTag({
    routeItineraryId,
    vaccination,
    routeStatus,
}: IInstructionJobStatusCountsAsRemoved): keyof typeof statusTags {
    if (
        vaccinationJobStatusCountsAsRemoved({
            routeItineraryId,
            vaccination,
            routeStatus,
        })
    ) {
        return 'REMOVED';
    }

    return vaccination?.jobStatus as keyof typeof statusTags;
}

interface IShouldStrikeThroughTheInstruction {
    instruction: Instruction;
    odsCodesNeedingPickup: string[];
    routeItineraryId: string;
    vaccination: Vaccination | undefined | null;
    vaccinationsOnRouteLoaded: boolean;
}

/**
 * Should we display this Instruction as struck through
 * If we have a vaccination and its removed we show the instruction as struck through
 * if the instruction is a pickup and we no long have any pickups from that location
 * (f.ex because the vaccinations the used to need to be picked up from there have been removed from the route)
 * we strike through the instruction
 */
export function shouldStrikeThroughTheInstruction({
    instruction,
    odsCodesNeedingPickup,
    routeItineraryId,
    vaccination,
    vaccinationsOnRouteLoaded,
}: IShouldStrikeThroughTheInstruction): boolean {
    if (!vaccinationsOnRouteLoaded) {
        return false;
    }
    // visit location that has been removed = strike through
    if (vaccination && instruction.instructionType === 'VisitLocation') {
        return vaccinationIsNotPartOfRoute({ routeItineraryId, vaccination });
    }
    // pickup that is no longer required = strike through
    if (
        instruction.instructionType === 'Pickup' &&
        !pickupIsStillRequired({
            odsCodeOfPickupInstruction: instruction.itineraryItem?.odsCode,
            odsCodesNeedingPickup,
        })
    ) {
        return true;
    }

    return false;
}
