import { Callout } from '@blueprintjs/core';
import React, { FC } from 'react';

import { VaccinationImportPatientError } from '../../../../interfaces';

interface Props {
    patientUploadAdapter: string;
    validationErrors: VaccinationImportPatientError[];
}

const formatError = (error: string) => {
    const [key, message] = error.split(/\s(.+)/);
    return (
        <>
            <strong>{key}</strong> {message}
        </>
    );
};

export const ImportErrorsTable: FC<Props> = ({ patientUploadAdapter, validationErrors }) => {
    return (
        <>
            <Callout
                intent="danger"
                className="file-upload__error"
                data-testid="validation-errors-banner"
            >
                The uploaded file contains validation errors, please see below for details.
            </Callout>
            <table className="bp5-html-table bp5-html-table-striped">
                <thead>
                    <tr data-testid="validation-errors-table-header">
                        {patientUploadAdapter === 'CNWL' ? (
                            <>
                                <th>NHS number</th>
                                <th>Vaccine</th>
                                <th>Dose number</th>
                            </>
                        ) : (
                            <>
                                <th>Line number</th>
                                <th>NHS number</th>
                                <th>Vaccination type</th>
                            </>
                        )}
                        <th>Column</th>
                        <th>Error</th>
                        <th>Value received</th>
                    </tr>
                </thead>
                <tbody data-testid="validation-errors-table-body">
                    {validationErrors.map(
                        ({
                            line,
                            errors,
                            nhsNumber,
                            vaccinationCategory,
                            doseNumber,
                            lineInFile,
                        }) => (
                            <>
                                {errors.map(({ key, value, error }, index) => (
                                    <tr key={line} data-testid={`${nhsNumber}-${line}-${index}`}>
                                        {patientUploadAdapter === 'CNWL' ? (
                                            <>
                                                <td>{nhsNumber || 'No NHS number'}</td>
                                                <td>{vaccinationCategory}</td>
                                                <td>{doseNumber}</td>
                                            </>
                                        ) : (
                                            <>
                                                <td>{lineInFile}</td>
                                                <td>{nhsNumber || 'No NHS number'}</td>
                                                <td>{vaccinationCategory}</td>
                                            </>
                                        )}
                                        <td>{key}</td>
                                        <td>{formatError(error)}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                            </>
                        ),
                    )}
                </tbody>
            </table>
        </>
    );
};
