import { IHcpsPos } from '../../interfaces/ucr';

interface IProps {
    top: number;
    hcpsPos: IHcpsPos;
}

const getHcpId = ({ hcpsPos, top }: IProps): string => {
    // Sort list of HCPs by 'top' value, to allow next function to work correctly
    const hcpIds = Object.entries(hcpsPos)
        .sort((a, b) => a[1].top - b[1].top)
        .map(([hcpId]) => hcpId);

    // check to see if it defined as an array before trying to reduce.
    if (hcpIds && hcpIds.length === 0) {
        return '';
    }
    return hcpIds.reduce((prev, curr) =>
        top < hcpsPos[prev]?.top + hcpsPos[prev]?.height / 2 || 0 ? prev : curr,
    );
};

export default getHcpId;
