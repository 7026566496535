// purpose of this file is to unify the start/end times based of what fields we have available and the current status are
// admin times will always send isFirstUser as true as it cannot have a doubleUp at the moment.

import { JobStatus, Patient } from '@doc-abode/data-models';
import moment from 'moment';

interface TimeInterface {
    patient: Patient;
    isFirstUser?: Boolean;
}

export const defaultMessage: string = 'Not yet recorded';

export const TimeHelperActualStart = ({ patient, isFirstUser }: TimeInterface): string => {
    const buddyStatus = isFirstUser ? 'jobStatus' : 'buddyJobStatus';
    let startTime: string = defaultMessage;
    if (!patient) {
        return startTime;
    }
    if (
        patient[buddyStatus] !== JobStatus.CURRENT &&
        patient[buddyStatus] !== JobStatus.PENDING &&
        patient[buddyStatus] !== JobStatus.ACCEPTED &&
        patient[buddyStatus] !== null
    ) {
        if (isFirstUser && patient.arrivedDateTime) {
            startTime = moment(patient.arrivedDateTime).format('HH:mm a');
        }

        if (!isFirstUser && patient.buddyArrivedDateTime) {
            startTime = moment(patient.buddyArrivedDateTime).format('HH:mm a');
        }
    }

    return startTime;
};

export const TimeHelperActualEnd = ({ patient, isFirstUser = true }: TimeInterface): string => {
    const buddyStatus = isFirstUser ? 'jobStatus' : 'buddyJobStatus';
    let endTime: string = defaultMessage;
    if (!patient) {
        return endTime;
    }
    if (
        patient[buddyStatus] === JobStatus.ARRIVED ||
        patient[buddyStatus] === JobStatus.COMPLETED ||
        patient[buddyStatus] === JobStatus.CONTROLLER_ABORTED
    ) {
        // controller aborted users finishedDateTime
        // we check to see if it an admin time with a finished date or if is the first user.
        if (patient.finishedDateTime && isFirstUser) {
            endTime = moment(patient.finishedDateTime).format('HH:mm a');
        }
        if (patient.buddyFinishedDateTime && !isFirstUser) {
            endTime = moment(patient.buddyFinishedDateTime).format('HH:mm a');
        }
    }

    if (patient[buddyStatus] === JobStatus.HCP_ABORTED) {
        if (patient.hcpAbortedDateTime) {
            endTime = moment(patient.hcpAbortedDateTime).format('HH:mm a');
        }
    }

    return endTime;
};
