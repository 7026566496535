import { PatientListFilters, PatientListSort, SortOrder } from '@doc-abode/data-models';
import { action, makeObservable, observable } from 'mobx';

import { PatientListPatient } from '../graphql/queries/patients';

export enum SORT_COLUMN {
    NHS_NUMBER = 'sortByNHSNumber',
    NAME = 'sortByName',
    DOB = 'sortByDob',
    GENDER = 'sortByGender',
    POSTCODE = 'sortByPostcode',
    LAST_COMPLETED_VISIT = 'sortByLastCompletedJob',
    UPCOMING_VISIT = 'sortByNextUpcomingJob',
    ADMISSIONS = 'sortByAdmissions',
}

export enum LIST_STATE {
    // Random text to avoid falsey ... that looks sort of errory for end user
    PATIENTS_TO_LIST = 'This is not intentionally blank',
    NO_PATIENTS = 'There are no patient records yet',
    NO_PATIENTS_IN_FILTER = 'There are no patient records matching your criteria',
    LOADING = 'Loading patient records',
}

class PatientListStore {
    constructor() {
        makeObservable(this, {
            sorts: observable,
            filters: observable,
            state: observable,
            patients: observable,
            admissionPatient: observable,
            appliedFilters: observable,
            showDischarged: observable,

            setAdmissionPatient: action,
            setSort: action,
            setFilters: action,
            setPatients: action,
            reset: action,
            updatePatientAdmission: action,
            clearAllFilters: action,
            setShowDischarged: action,
            setState: action,
        });
    }

    sorts: PatientListSort = {};
    filters: PatientListFilters = {};
    state = LIST_STATE.LOADING;
    showDischarged: boolean = false;

    patients: PatientListPatient[] = [];
    admissionPatient: PatientListPatient | null = null;
    appliedFilters = 0;
    /**
     * Set the sort criteria for the table
     * @param sort the column to sort
     */
    setSort = (sort: SORT_COLUMN) => {
        const { [sort]: oldOrder } = this.sorts;
        let newOrder: SortOrder | undefined;

        if (!oldOrder) {
            newOrder = SortOrder.ASC;
        } else if (oldOrder === SortOrder.ASC) {
            newOrder = SortOrder.DESC;
        } else {
            newOrder = undefined;
        }

        this.sorts = {
            [sort]: newOrder,
        };
    };

    setFilters = <K extends keyof PatientListFilters>(key: K, value: PatientListFilters[K]) => {
        if (typeof value === 'undefined') {
            delete this.filters[key];
        } else {
            this.filters[key] = value;
        }
    };

    /**
     * Set Patients to be displayed to the user
     * @param patients Array of patients to use
     */
    setPatients = (patients: PatientListPatient[]) => {
        this.patients = patients;

        if (patients.length === 0) {
            this.state = LIST_STATE.NO_PATIENTS;
        } else {
            this.state = LIST_STATE.PATIENTS_TO_LIST;
        }
    };

    /**
     * Sets default values to store
     */
    reset = () => {
        this.sorts = {};
        this.patients = [];
        this.state = LIST_STATE.LOADING;
    };

    /**
     * Set the state of the list
     */
    setState = (state: LIST_STATE) => {
        this.state = state;
    };

    /**
     * Set admissionPatient to a patient so we can either discharge or admit them.
     */

    setAdmissionPatient = (patient: PatientListPatient) => {
        this.admissionPatient = patient;
    };

    updatePatientAdmission = (patient: PatientListPatient, status: boolean) => {
        const foundPatient = this.patients.find((val) => val.nhsNumber === patient.nhsNumber);
        if (foundPatient?.admission) {
            foundPatient.admission.isAdmitted = status;
        }
    };

    clearAllFilters = () => {
        this.showDischarged = false;
        this.filters.showDischargedPatients = false;
        this.appliedFilters = 0;
    };

    setShowDischarged = (value: boolean) => {
        this.showDischarged = value;
        this.filters.showDischargedPatients = value;
        this.appliedFilters = value ? 1 : 0;
    };
}

export default PatientListStore;
