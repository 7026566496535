/**
 * Standardises an input string to undefined if
 * * the input is undefined
 * * the input is null
 * * the input - after trimming - is zero length
 * @param input
 * @returns input string as-was passed in or undefined
 */
export function standardiseStringToUndefined(input: string | undefined | null): string | undefined {
    if (!input) {
        return undefined;
    }

    if (input.trim() === '') {
        return undefined;
    }

    return input;
}
