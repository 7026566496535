interface IConditionalDisplayProps {
    show: boolean;
    children: null | JSX.Element | string | JSX.Element[];
}

export function ConditionalDisplay({
    show,
    children,
}: IConditionalDisplayProps): null | JSX.Element {
    if (show) {
        return <>{children}</>;
    }
    return null;
}

interface IConditionalDisplayIfThenElseProps {
    if: boolean;
    then: null | JSX.Element | string;
    else: null | JSX.Element | string;
}

export function ConditionalDisplayIfThenElse(
    props: IConditionalDisplayIfThenElseProps,
): null | JSX.Element {
    const { if: _if, then: _then, else: _else } = props;
    if (_if) {
        return <>{_then}</>;
    }

    return <>{_else}</>;
}
