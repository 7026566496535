import { ODSRecord } from '@doc-abode/data-models';

type ODSSearchParams = {
    'name:contains'?: string;
    'ods-org-role'?: string;
    active?: string;
};

type ODSSearchResponse = {
    entry: { resource: ODSRecord }[];
};

export const odsFhirRequest = <T>(callback: () => Promise<Response>): Promise<T> =>
    new Promise((resolve, reject) => {
        let attempt = 1;

        const run = async () => {
            try {
                const response = await callback();

                // The ODS API has a rate limit of 5 requests per second. If we exceed this limit,
                // back off and try again.
                if (response.status === 429) {
                    if (attempt > 5) {
                        reject(new Error('Rate limit exceeded'));
                    }
                    setTimeout(() => run(), 1000 * attempt);
                    attempt += 1;
                    return;
                }

                if (!response.ok) {
                    reject(new Error(response.statusText));
                }

                resolve(response.json() as Promise<T>);
            } catch (err) {
                if (err instanceof Error) {
                    reject(err);
                } else {
                    reject(new Error('An error with odsFhirRequest occurred'));
                }
            }
        };

        run();
    });

export const getOrganisation = async (odsCode: string): Promise<ODSRecord> =>
    odsFhirRequest(() =>
        fetch(`https://directory.spineservices.nhs.uk/STU3/Organization/${odsCode}`),
    );

export const searchOrganisations = async (query: ODSSearchParams): Promise<ODSSearchResponse> => {
    const params = new URLSearchParams(query);
    return odsFhirRequest(() =>
        fetch(`https://directory.spineservices.nhs.uk/STU3/Organization?${params}`),
    );
};
