import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { decodeToken, getCredentials, SecureCommsCredentials } from '../../../api/commsApi';
import { Loader } from '../ucr/blocks/panels';

const SecureVideoCall = () => {
    const [operatorId, setOperatorId] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [didError, setDidError] = useState(false);
    const { token } = useParams<{ org: string; token: string }>();

    // Migration was done without token support, Web always gets the latest token
    useEffect(() => {
        const loadCredentials = async () => {
            let credentials: SecureCommsCredentials;

            try {
                if (token) {
                    credentials = await decodeToken(token);
                } else {
                    credentials = await getCredentials();
                }

                setOperatorId(credentials.operatorId);
                setPassword(credentials.password);

                document.querySelector('form')?.submit();
            } catch (err) {
                console.error(err);
                setDidError(true);
            } finally {
                setLoading(false);
            }
        };

        loadCredentials();
    }, [token]);

    // TODO - Find a way to open this in a new tab once this becomes production-ready. Code was more of less just lifted and shifted from Onboarding
    return (
        <div>
            {loading && <Loader />}
            {didError && token && (
                <p>
                    Sorry, we were unable to transfer you. Your link may have expired, please try
                    generating another one from the mobile app.
                </p>
            )}
            {didError && !token && <p>Sorry, we were unable to transfer you.</p>}
            <form action="https://docabode.call-vision.com/app_launch.asp" method="POST">
                <input type="hidden" name="u" value={operatorId} />
                <input type="hidden" name="p" value={password} />
            </form>
        </div>
    );
};

export default SecureVideoCall;
