import { observer } from 'mobx-react';
import { FC } from 'react';

import { Arrow } from '../side';

interface IProps {
    clickEvent(): void;
    reverse: boolean;
}

const ExpandFrame: FC<IProps> = ({ clickEvent, reverse }) => {
    return (
        <div className="ucr-expandFrameContainer">
            <Arrow panel="right" isReverse={reverse} clickEvent={clickEvent} />
        </div>
    );
};

export default observer(ExpandFrame);
