// Adapted from https://medium.com/toplyne-engineering/pwa-update-notifications-in-a-react-application-f5680d51bb2

import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';

import { ServiceWorkerHandler } from '../serviceWorkerHandler';
import { useInterval } from './useInterval';

const FIVE_MINUTES = 1000 * 60 * 5;

export const useServiceWorker = () => {
    const [isUpdateReady, setIsUpdateReady] = useState(false);
    const [serviceWorkerHandler, setServiceWorkerHandler] = useState<ServiceWorkerHandler | null>(
        null,
    );

    const checkForWaitingUpdates = useCallback(
        (registration?: ServiceWorkerRegistration | null) => {
            if (registration?.waiting && !isUpdateReady) {
                Sentry.metrics.increment('useServiceWorker.intervalCheck');
                setIsUpdateReady(true);
            }
        },
        [isUpdateReady],
    );

    useInterval(() => {
        // Periodically check for a waiting service worker.and trigger the update prompt if it is
        // not already visibile.
        // This is a fallback in case the service worker registration fails to trigger the update,
        // as has been reported in https://docabode.atlassian.net/browse/VSU-2996
        // If the cause for that can be determined and fixed, this interval check can be removed.
        checkForWaitingUpdates(serviceWorkerHandler?.registration);
    }, FIVE_MINUTES);

    const onUpdateReady = useCallback(() => {
        Sentry.metrics.increment('useServiceWorker.onUpdateReady');
        setIsUpdateReady(true);
    }, [setIsUpdateReady]);

    const applyUpdate = () => {
        Sentry.metrics.increment('useServiceWorker.applyUpdate');
        const waitingWorkerState = serviceWorkerHandler?.registration?.waiting?.state;

        if (waitingWorkerState === 'installed') {
            // If the service worker is installed then the user has already been prompted to update
            // and the service worker is waiting to be activated.
            serviceWorkerHandler?.setIsUserInitiatedUpdate();
            serviceWorkerHandler?.registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
        } else if (waitingWorkerState === 'activated') {
            // If the service worker is activated then the user has already been prompted to update
            // and the service worker is already serving the updated content, we just need to reload
            // the page.
            window.location.reload();
        }
    };

    useEffect(() => {
        if (serviceWorkerHandler) {
            return;
        }

        const handler = new ServiceWorkerHandler();
        setServiceWorkerHandler(handler);

        const registerServiceWorker = async () => {
            const registration = await handler.register(onUpdateReady);
            checkForWaitingUpdates(registration);
        };

        registerServiceWorker();
    }, [onUpdateReady, serviceWorkerHandler, setServiceWorkerHandler, checkForWaitingUpdates]);

    return { isUpdateReady, applyUpdate };
};
