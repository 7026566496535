import { Patient } from '@doc-abode/data-models';

export function isNonEnglishSpeaker(job?: Patient) {
    if (!job) {
        return false;
    }

    if (!job.languagesSpoken) {
        return false;
    }

    return job.languagesSpoken.length !== 0;
}
