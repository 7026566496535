import { Icon } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { ConditionalDisplay } from '../../CondtionalDisplay';
import { useUserRowViewModel } from './useUserRowViewModel';
import { useUsersTableColumnViewModel } from './useUsersTableColumnViewModel';

export type UserRowType = {
    user: any;
    onClick: (userId: string) => void;
};

const UserRow = ({ user, onClick }: UserRowType) => {
    const {
        userId,
        userName,
        hcpTypesDisplayLabel,
        userStatusInfo,
        availableStatusInfo,
        s1StatusInfo,
        maxDriveTimeMinutesLabel,
    } = useUserRowViewModel({ user });

    const { showS1Info, showUserStatusInfo } = useUsersTableColumnViewModel();

    return (
        <tr onClick={(event: React.MouseEvent) => onClick(user.userId)}>
            <td>{userName}</td>
            <td>{userId}</td>
            <ConditionalDisplay show={showS1Info}>
                <td data-testid="s1username">{s1StatusInfo.label}</td>
            </ConditionalDisplay>
            <td>{hcpTypesDisplayLabel}</td>
            <td>{maxDriveTimeMinutesLabel}</td>
            <ConditionalDisplay show={showUserStatusInfo}>
                <td>
                    <span
                        className={`users-list__status users-list__status--${userStatusInfo.status}`}
                    >
                        <Icon icon={userStatusInfo.icon} />
                        <span className="users-list__status-label">{userStatusInfo.label}</span>
                    </span>
                </td>
            </ConditionalDisplay>
            <td>
                <span
                    className={`users-list__status users-list__status--${availableStatusInfo.status}`}
                >
                    <Icon icon={availableStatusInfo.icon} />
                    <span className="users-list__status-label">{availableStatusInfo.label}</span>
                </span>
            </td>
        </tr>
    );
};

export default observer(UserRow);
