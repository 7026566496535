import { Alert, Callout, FormGroup, HTMLSelect, TextArea } from '@blueprintjs/core';
import { AbortReason, Patient } from '@doc-abode/data-models';
import { FC } from 'react';

import { IAbortAlertDialogLabels } from '../../../../../interfaces/AlertDialogLabels';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import Error from '../../../../modules/forms/Error';
import { useAbortJobDialogViewModel } from './useAbortJobDialogViewModel';

export type AbortJobDialogType = {
    showAbortPrompt: boolean;
    setShowAbortPrompt: (value: boolean) => void;
    job: Patient;
    abortAlertLabels: IAbortAlertDialogLabels;
    onConfirmRedirectAction?: () => void;
};

export const AbortJobDialog: FC<AbortJobDialogType> = ({
    showAbortPrompt,
    setShowAbortPrompt,
    job,
    abortAlertLabels,
    onConfirmRedirectAction,
}) => {
    const {
        onCancel,
        onConfirm,
        handleChangeOfAbortNotes,
        handleChangeOfAbortReason,
        sortedAbortReasons,
        abortReason,
        abortNotes,
        areAbortNotesMandatory,
        abortErrors,
    } = useAbortJobDialogViewModel({
        showAbortPrompt,
        setShowAbortPrompt,
        job,
        onConfirmRedirectAction,
    });

    return (
        <Alert
            isOpen={showAbortPrompt}
            onConfirm={() => onConfirm()}
            onCancel={() => onCancel()}
            cancelButtonText={abortAlertLabels.cancelActionButton}
            confirmButtonText={abortAlertLabels.confirmActionButton}
            icon="warning-sign"
            intent="danger"
            className="alert"
        >
            <div className="alert__body">
                <div className="alert__contents">
                    <p data-testid="dialogHeader" aria-label="Dialog header">
                        <strong>{abortAlertLabels.header}</strong>
                    </p>
                    <ConditionalDisplay show={!!abortAlertLabels.explanatoryText}>
                        <p data-testid="explanatoryText" aria-label="Explanatory text">
                            {abortAlertLabels.explanatoryText
                                ? abortAlertLabels.explanatoryText
                                : ''}
                        </p>
                    </ConditionalDisplay>
                    <FormGroup
                        label={abortAlertLabels.abortReason}
                        labelFor="abortReason"
                        labelInfo="(required)"
                    >
                        <HTMLSelect
                            options={sortedAbortReasons.map((abortReason: AbortReason) => {
                                return { label: abortReason.label, value: abortReason.label };
                            })}
                            id="abortReason"
                            onChange={(event) =>
                                handleChangeOfAbortReason(event.currentTarget.value)
                            }
                            value={abortReason}
                            minimal
                            fill
                        />
                    </FormGroup>
                    <FormGroup
                        label={abortAlertLabels.abortNotes}
                        labelFor="abortNotes"
                        labelInfo={areAbortNotesMandatory ? '(required)' : '(optional)'}
                    >
                        <TextArea
                            id="abortNotes"
                            onChange={(event) => handleChangeOfAbortNotes(event.target.value)}
                            fill
                            value={abortNotes}
                        />
                        <Error errors={abortErrors} />
                    </FormGroup>
                    <ConditionalDisplay show={!!abortAlertLabels.callouts}>
                        <Callout intent="danger">
                            <p data-testid="callouts">
                                {abortAlertLabels.callouts ? abortAlertLabels.callouts : ''}
                            </p>
                        </Callout>
                    </ConditionalDisplay>
                </div>
            </div>
        </Alert>
    );
};
