import { JobStatus } from '@doc-abode/data-models';

export enum FormMode {
    ADD_VISIT = 'addVisit',
    EDIT_VISIT = 'editVisit',
    FOLLOW_UP = 'followUp',
    DEFAULT = 'default',
}

export enum FormSteps {
    PATIENT = 'patient',
    ACTIVITY = 'activity',
    REVIEW = 'review',
    REFERRAL = 'referral',
    CARE = 'care',
}

export enum ButtonNames {
    ADD_VISIT = 'Save',
    EDIT_VISIT = 'Save',
    DEFAULT_LAST = 'Review',
    DEFAULT = 'Continue',
}

type FriendlyVisitDetails = {
    [key: string]: string;
};

export const getButtonName = (formMode: FormMode, step?: FormSteps): ButtonNames => {
    if (formMode === FormMode.ADD_VISIT) {
        return ButtonNames.ADD_VISIT;
    } else if (formMode === FormMode.EDIT_VISIT) {
        return ButtonNames.EDIT_VISIT;
    } else if (step && [FormSteps.ACTIVITY, FormSteps.CARE].includes(step)) {
        return ButtonNames.DEFAULT_LAST;
    } else {
        return ButtonNames.DEFAULT;
    }
};

export const friendlyCareComplexity: FriendlyVisitDetails = {
    nonComplex: 'Non-complex',
    complex: 'Complex',
};
export const abortStatusCheck = (jobStatus: JobStatus) =>
    jobStatus === JobStatus.CONTROLLER_ABORTED || jobStatus === JobStatus.HCP_ABORTED;
