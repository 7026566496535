import { Formik, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../../v2/components';
import { Form, Select, TextArea } from '../../../../../v2/form';

interface IProps {
    resolutionReasons: any[];
    areButtonsDisabled: boolean;
    onSubmit: (values: FormikValues) => void;
    onCancel: () => void;
}

export const ResolveStaffAlert: FC<IProps> = observer(
    ({ resolutionReasons, areButtonsDisabled, onSubmit, onCancel }) => {
        return (
            <div className="v2__form-section">
                <Formik
                    initialValues={{
                        controllerResolutionReason: resolutionReasons[0].value,
                        controllerResolutionNotes: '',
                    }}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    <Form>
                        <p className="v2__form-group--pos-1-1">
                            Resolving a staff alert will remove the alert from the "Urgent Warnings"
                            and remove all visual cues of the alert on the dashboard.
                        </p>

                        <p className="v2__form-group--pos-1-1">
                            Only resolve a staff alert if your staff is safe or the emergency has
                            otherwise been dealt with as per your standard operating procedures.
                        </p>
                        <p>
                            <Select
                                label="Resolution to alert"
                                name="controllerResolutionReason"
                                className="v2__form-group--pos-1-1"
                                options={resolutionReasons}
                            />
                        </p>
                        <p>
                            <TextArea
                                label="Notes"
                                name="controllerResolutionNotes"
                                className="v2__form-group--pos-1-1"
                            />
                        </p>
                        <div className="v2__form-group v2__form-group--pos-1-1">
                            <Button
                                name="Close"
                                elem={ButtonElems.BUTTON}
                                size={ButtonSizes.MEDIUM}
                                className="v2__form-submit-button"
                                color={ButtonColors.GREY}
                                disabled={areButtonsDisabled}
                                clickEvent={onCancel}
                            />
                            <Button
                                name="Resolve alert"
                                elem={ButtonElems.BUTTON}
                                size={ButtonSizes.MEDIUM}
                                className="v2__form-submit-button"
                                type="submit"
                                color={ButtonColors.RED}
                                disabled={areButtonsDisabled}
                            />
                        </div>
                    </Form>
                </Formik>
            </div>
        );
    },
);
