import { observer } from 'mobx-react';
import { FC } from 'react';

import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Dialog } from '../../../../v2/overlays';
import { SummaryJobList } from '../../components/summaryJobList/SummaryJobList';
import { PatientAlertDetails } from './patientAlert/PatientAlertDetails';
import { ResolvePatientAlert } from './patientAlert/ResolvePatientAlert';
import { usePatientAlertViewModel } from './patientAlert/usePatientAlertViewModel';

const PatientAlertDialog: FC = () => {
    const {
        showDialog,
        alertInfoTitle,
        showResolutionForm,
        isAlertUpdating,
        isAcknowledged,
        alert,
        alertedJob,
        jobs,
        onClickUpdateAlert,
        onClickAcknowledge,
        onClickCancel,
        resolutionReasons,
    } = usePatientAlertViewModel();

    // Bail here if there is no alert
    if (!alert || !alertedJob) {
        return <></>;
    }

    return (
        <Dialog
            isShow={showDialog}
            closeEvent={onClickCancel}
            title={`${alertInfoTitle} Patient Alert`}
        >
            <PatientAlertDetails alert={alert} alertedJob={alertedJob} />
            <ConditionalDisplay show={showResolutionForm}>
                <ResolvePatientAlert
                    onSubmit={onClickUpdateAlert}
                    onAcknowledge={onClickAcknowledge}
                    onCancel={onClickCancel}
                    isAcknowledged={isAcknowledged}
                    areButtonsDisabled={isAlertUpdating}
                    resolutionReasons={resolutionReasons}
                />
            </ConditionalDisplay>
            <SummaryJobList jobs={jobs} />
        </Dialog>
    );
};

export default observer(PatientAlertDialog);
