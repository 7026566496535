import { Patient } from '@doc-abode/data-models';

export const getJobsForLinkedVisits = (jobs: Patient[]) =>
    jobs.flatMap((job: Patient) => {
        const { hcpId, buddyId, version, id } = job;
        const result = [];

        if (hcpId) {
            result.push({
                jobId: id,
                personJobId: '0',
                version,
            });
        }

        if (buddyId) {
            result.push({
                jobId: id,
                personJobId: '1',
                version,
            });
        }

        return result;
    });
