import { Button } from '@blueprintjs/core';
import { Component } from 'react';

class Modal extends Component {
    render() {
        const {
            lightbox,
            shadow,
            title,
            onClose,
            children,
            footer,
            showPrintButton,
            forwardedRef,
            testId,
        } = this.props;

        return (
            <div
                data-testid={testId ?? ''}
                className={`modal ${shadow ? 'modal--shadow' : ''}`}
                ref={forwardedRef}
            >
                {lightbox && <div className="modal__lightbox"></div>}
                <div className="modal__wrapper">
                    <div className="modal__title">
                        <h2 className="h2 modal__heading">{title}</h2>
                        <div className="modal__header-btn">
                            {showPrintButton && (
                                <Button onClick={() => window.print()} minimal icon="print" />
                            )}
                            {onClose ? <Button onClick={onClose} minimal icon="cross" /> : ''}
                        </div>
                    </div>
                    <div className="modal__content">{children}</div>
                    {footer && <div className="modal__footer">{footer}</div>}
                </div>
            </div>
        );
    }
}

export default Modal;
