import { IHcp } from '../../interfaces/ucr';
import { getHcp } from './getHcp';

/**
 * A simple helper to find the name of a HCP staff memeber based on the ID
 *
 * @param users Array of users
 * @param hcpId ID of user to find
 * @param returnPlaceholder Indicates whether to return a placeholder string when no user is passed
 * @returns Full name of staff member, or their ID if nothing is found
 */
const getHcpName = (
    users: IHcp[],
    hcpId?: string | null,
    returnPlaceholder?: boolean | undefined,
): string => {
    let returnVal = '';

    if (!hcpId) {
        // If no HCP has been passed, return an empty string or a placeholder
        // For backwards compatibility with existing use cases, a missing param is
        // treated as if it was set to true
        if (returnPlaceholder === undefined || returnPlaceholder) {
            returnVal = 'Unrecognised HCP ID';
        }
    } else {
        // If an HCP ID has been passed, try to resolve it
        // return the HCP ID if the name cannot be resolved
        returnVal = hcpId;

        if (users.length !== 0) {
            const hcp = getHcp(users, hcpId);

            if (hcp) {
                returnVal = hcp.userName;
            }
        }
    }

    return returnVal;
};

export default getHcpName;
