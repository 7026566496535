import { Formik, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../../v2/components';
import { Form, Select, TextArea } from '../../../../../v2/form';

interface IProps {
    resolutionReasons: any[];
    isAcknowledged: boolean;
    areButtonsDisabled: boolean;
    onSubmit: (values: FormikValues) => void;
    onAcknowledge: () => void;
    onCancel: () => void;
}

export const ResolvePatientAlert: FC<IProps> = observer(
    ({
        resolutionReasons,
        isAcknowledged,
        areButtonsDisabled,
        onSubmit,
        onAcknowledge,
        onCancel,
    }) => {
        return (
            <div className="v2__form-section">
                <Formik
                    initialValues={{
                        controllerResolutionReason: resolutionReasons[0].value,
                        controllerResolutionNotes: '',
                    }}
                    onSubmit={onSubmit}
                >
                    <Form>
                        <p className="v2__form-group--pos-1-1">
                            Acknowledging the alert will not provide feedback to the HCP. The alert
                            will remain unresolved and you will be able to access it via the "Urgent
                            Warnings" or the patient visit to resolve at a later time.
                        </p>

                        <p>
                            <Select
                                label="Resolution to alert"
                                name="controllerResolutionReason"
                                className="v2__form-group--pos-1-1"
                                options={resolutionReasons}
                            />
                        </p>
                        <p>
                            <TextArea
                                label="Notes"
                                name="controllerResolutionNotes"
                                className="v2__form-group--pos-1-1"
                            />
                        </p>
                        <div className="v2__form-group v2__form-group--pos-1-1">
                            <Button
                                name="Close"
                                elem={ButtonElems.BUTTON}
                                size={ButtonSizes.MEDIUM}
                                className="v2__form-submit-button"
                                color={ButtonColors.GREY}
                                disabled={areButtonsDisabled}
                                clickEvent={onCancel}
                            />
                            <Button
                                className="v2__form-submit-button"
                                name="Acknowledge Alert"
                                clickEvent={onAcknowledge}
                                disabled={isAcknowledged || areButtonsDisabled}
                            />
                            <Button
                                name="Resolve alert"
                                elem={ButtonElems.BUTTON}
                                size={ButtonSizes.MEDIUM}
                                className="v2__form-submit-button"
                                type="submit"
                                color={ButtonColors.RED}
                                disabled={areButtonsDisabled}
                            />
                        </div>
                    </Form>
                </Formik>
            </div>
        );
    },
);
