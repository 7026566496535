import {
    LinkedVisit,
    S1ClientConnectionStates,
    S1ClientConnectionStatus,
    s1ClientConnectionStatusSchema,
    S1Message,
    S1Referral,
} from '@doc-abode/data-models';
import moment from 'moment';
import { z } from 'zod';

import { baseUrl, headers, request, retryable } from './baseApi';

export type GetS1MessagesParams = {
    paginationToken?: string;
    nhsNumber?: string;
};

export const getS1Messages = (authToken: string, params: GetS1MessagesParams = {}) =>
    retryable<{ messages: S1Message[]; paginationToken?: string }>(() =>
        fetch(`${baseUrl}/s1/messages?${new URLSearchParams(params)}`, {
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );

export const getS1Referrals = (authToken: string, nhsNumber: string) =>
    retryable<{ referrals: S1Referral[]; updatedAt: string }>(() =>
        fetch(`${baseUrl}/s1/patient/${nhsNumber}/referrals`, {
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );

export const syncS1Referrals = (authToken: string, nhsNumber: string) =>
    retryable<{ requestId: string }>(() =>
        fetch(`${baseUrl}/s1/patient/${nhsNumber}/referrals/sync`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );

const filterResultsByInterval = (intervalMinutes: number, statuses: S1ClientConnectionStatus[]) => {
    const now = moment();
    const cutoff = now.clone().subtract(intervalMinutes, 'minutes');
    return statuses.filter((status) => {
        /* istanbul ignore next */
        if (!status.lastActivity) return false;
        return moment(status.lastActivity).isAfter(cutoff);
    });
};

/**
 * Returns a status based on the last activity and reported status of a client
 */
const calculateStatusForDisplay = (
    lastActivity: string | undefined,
    status: string | undefined,
) => {
    if (!status || !lastActivity || status === 'disconnected')
        return S1ClientConnectionStates.DISCONNECTED;
    const lastActivityTime = moment(lastActivity);
    if (moment().diff(lastActivityTime, 'minutes') > 15) return S1ClientConnectionStates.WARNING;

    return S1ClientConnectionStates.CONNECTED;
};

export const getS1ClientConnectionStatuses = async (
    displayIntervalInMinutes: number,
): Promise<S1ClientConnectionStatus[]> => {
    const response = await request({
        url: `${baseUrl}/s1/clients/statuses`,
        method: 'GET',
    });

    const responseParseAttempt = z
        .object({
            results: z.array(s1ClientConnectionStatusSchema),
        })
        .safeParse(response);

    if (!responseParseAttempt.success) {
        throw new Error('Failed to parse response');
    }

    const results = responseParseAttempt.data.results;
    const resultsFilteredByInterval = filterResultsByInterval(displayIntervalInMinutes, results);
    const statuses = resultsFilteredByInterval.map((connectionStatusRecord) => {
        return {
            ...connectionStatusRecord,
            status: calculateStatusForDisplay(
                connectionStatusRecord.lastActivity,
                connectionStatusRecord.status,
            ),
        };
    });
    return statuses;
};

export const getS1LinkedVisits = (
    authToken: string,
    jobs: { jobId: string; personJobId: string; version: number }[],
) =>
    retryable<{ results: LinkedVisit[] }>(() =>
        fetch(`${baseUrl}/s1/visits/linked`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
                ...headers,
            },
            body: JSON.stringify({ jobs }),
        }),
    );

export const purgeQueue = (authToken: string) =>
    retryable<{ statusCode: number }>(() =>
        fetch(`${baseUrl}/s1/queue/purge`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );

export const purgeLocks = (authToken: string) =>
    retryable<{ statusCode: number }>(() =>
        fetch(`${baseUrl}/s1/lock-table/purge`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
                ...headers,
            },
        }),
    );
