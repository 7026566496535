// Taken from https://github.com/cra-template/pwa/blob/main/packages/cra-template-pwa/template/src/serviceWorkerRegistration.js

export const isLocalhost = () =>
    Boolean(
        window.location.hostname === 'localhost' ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === '[::1]' ||
            // 127.0.0.0/8 are considered localhost for IPv4.
            /^127(\.(25[0-5]|2[0-4]\d|1?\d\d?)){3}$/.test(window.location.hostname),
    );
